import React from 'react';
import { useP2RAuth } from '../AuthContextProvider/authContext';
import { Route, Redirect } from 'react-router-dom';

const SecureRoute = ({ path, isLogin, authenticatedRoute, Component, loginRoute }) => {
    const { isAuthenticated } = useP2RAuth();
    return (
        <Route path={path} render={(props) => {

            if (authenticatedRoute && !isAuthenticated) {
                return <Redirect to={loginRoute} {...props} />;
            }

            if (isAuthenticated && isLogin) {
                return <Redirect to="/home" />;
            }

            return <Component {...props} />;
        }} />
    );
};

export default SecureRoute;