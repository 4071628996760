import React from 'react';
import { useFormWizard } from './FormWizardContext';

import './formWizard.scss';
import { PAGE_STATUSES } from '../../utils/constants';

const Separator = ({ groupId, pageId }) => {

    const { pageGroupMap } = useFormWizard();
    const active = pageGroupMap[groupId][pageId].status === PAGE_STATUSES.COMPLETE;

    return (
        <div className={`form-stepper-separator ${active ? "seperator-completed" : ""}`} />
    );
};

export default Separator;