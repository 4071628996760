import React, { useState, useEffect } from 'react';
import { AccountTable } from '../../components/Table';
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faSackDollar } from "@fortawesome/pro-solid-svg-icons/faSackDollar";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons/faPencilAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EVENT_CHANNEL, ID_ATTRIBUTE } from '../../utils/constants';
import { EventEmitter, EventHanlders } from '../../components/EventEmitter';
import { createSelector } from 'redux-orm';
import orm from '../../models/orm.register';
import BaseORMModel from "../../models/BaseORMModel";
import fundingSourceHandlers from "./FundingSource.event.handler";
import { connect } from 'react-redux';
import _ from 'lodash';
import './fundingsource.scss';
//import EventSubscriber from '../../components/EventSubscriber/EventSubscriber';

const getTableColumns = ({ fundingBodies }) => {
    return [
        { title: 'Project Title', field: 'projectTitle', sortable: true },
        { title: 'Reference number', field: 'refNo', sortable: true },
        { title: 'Funding provider', field: 'providerName', sortable: true },
        {
            title: "Action", field: [ID_ATTRIBUTE], render: ({ row, cell }) => {
                return <FontAwesomeIcon icon={faPencilAlt} onClick={(e) => {
                    e.preventDefault();
                    EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_FUNDING_PROGRAM_MODAL, {
                        payload: {
                            edit: {
                                fundingBodyId: row.providerId,
                                programId: row.programId,
                                projectTitle: row.projectTitle ?? "Project title doesn't exist",
                                referenceNumber: row.refNo ?? "Reference number unknown",
                            },
                            fundingBodies
                        }
                    });
                }}
                />;
            }
        }
    ];
};

const FundingSource = ({ fundingBodies = [] }) => {
    const [stFundingBodies, setStFundingBodies] = useState([]);

    useEffect(() => {
        setStFundingBodies(fundingBodies);
    }, [
        BaseORMModel.getVersionId(fundingBodies ?? [])
    ]);

    useEffect(() => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_FUNDING_LOADING, {});
    }, []);

    if (!stFundingBodies) {
        return <p>loading...</p>;
    }

    const tableData = _.flattenDeep((stFundingBodies ?? []).map(({ [ID_ATTRIBUTE]: bodyId, fundingBody, fundingPrograms = [] }) => {
        return (fundingPrograms ?? []).map(program => {
            return {
                providerId: bodyId,
                programId: program[ID_ATTRIBUTE],
                projectTitle: program.projectTitle ?? "Project title doesn't exist",
                refNo: program.projectReferenceNumber ?? "Reference number unknown",
                providerName: fundingBody ?? "Could not find funding provider"
            };
        });
    }));

    const tableColumns = getTableColumns({ fundingBodies: stFundingBodies });

    return (
        <>
            {/* <EventSubscriber eventHandlers={fundingSourceHandlers}></EventSubscriber> */}

            <div className="funding-tab tab-content">
                <div className="row header">
                    <h2>Funding sources</h2>
                </div>
                <div className="row d-flex justify-content-end buttons">
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={(e) => EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_FUNDING_BODY_MODAL, {})}
                    // onClick={(e) => {

                    //     store.dispatch({
                    //         type: generateORMActionName({ slice: ORM_FUNDING_BODY_SLICE, actionName: CREATE_ACTION }),
                    //         payload: {
                    //             [ID_ATTRIBUTE]: "fundingBody-viet-id",
                    //             fundingBody: "fundingBody viet"
                    //         }
                    //     });

                    // }}
                    >
                        Add funding provider
                        <FontAwesomeIcon className="ml-2" icon={faSackDollar} />
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_FUNDING_PROGRAM_MODAL, {
                                payload: {
                                    fundingBodies: stFundingBodies
                                }
                            });
                        }}
                    // onClick={(e) => {
                    //     // create a funding source


                    //     store.dispatch({
                    //         type: generateORMActionName({ slice: ORM_FUNDING_PROGRAM_SLICE, actionName: CREATE_ACTION }),
                    //         payload: {
                    //             [ID_ATTRIBUTE]: "viet-id",
                    //             projectReferenceNumber: "viet",
                    //             proponentCodes: "",
                    //             projectTitle: "viet blah",
                    //             fundingBody: "fundingBody-viet-id"
                    //         }
                    //     });
                    // }}
                    >
                        Add funding program
                    <FontAwesomeIcon className="ml-2" icon={faPlus} />
                    </button>
                </div>
                <div className="row">
                    {/* Funding Table */}
                    <AccountTable
                        className="w-100"
                        columns={tableColumns}
                        data={tableData}
                        title="Demo Title"
                    />
                </div>
            </div>
        </>
    );
};

const getFundingData = createSelector(
    orm,
    session => {
        const { FundingBody } = session;

        // many to many
        const fundingBodies = FundingBody.select(session, {
            include: ['fundingPrograms'],
            filter: { deleted: false }
        });

        return fundingBodies;
    }
);


function mapORMStateToProps(state) {

    return {
        fundingBodies: getFundingData(state),
    };
}

export default connect(mapORMStateToProps)(FundingSource);

// export default FundingSource;