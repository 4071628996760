import React from 'react';
import { EventEmitter } from '../../components/EventEmitter';
import { EVENT_CHANNEL } from '../../utils/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import PropTypes from 'prop-types';
import './toolbar.scss';
import { SHARED_SELECTOR } from '../../selectors/appSelector';

const CollectionAddProject = ({ collectionId, collectionReadOnly }) => {
    const handleOnClick = (e) => {
        // Event to create skeleton project detail
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_CREATE_OR_EDIT_PROJECT, {
            payload: {
                collectionId,
                collectionReadOnly,
                projectId: undefined
            }
        });
    };

    return (
        <button
            className="btn btn-primary ml-0"
            onClick={handleOnClick}
            disabled={!collectionId || collectionReadOnly}
        >
            Add project<FontAwesomeIcon icon={faPlus} className="icon-text-left-spacing" />
        </button>
    );
};

CollectionAddProject.propTypes = {
    // workspace: PropTypes.shape({ // From Redux
    //     boundsFitBlockId: PropTypes.string,
    //     boundsFitProjectId: PropTypes.string,
    //     collectionReadOnly: PropTypes.bool,
    //     editingProject: PropTypes.object,
    //     editingProjectId: PropTypes.string,
    //     editingSourceProjectId: PropTypes.string,
    //     newProjectId: PropTypes.string,
    //     selectedCollectionId: PropTypes.string,
    //     _id: PropTypes.string
    // }).isRequired,
    collectionId: PropTypes.string,
    collectionReadOnly: PropTypes.bool
};

export default CollectionAddProject;
