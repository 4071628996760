import React, { useEffect, useState } from "react";
import BarChart from "../../components/BarChart/index";
import { faLayerPlus } from "@fortawesome/pro-solid-svg-icons/faLayerPlus";
import { faChevronCircleDown } from "@fortawesome/pro-solid-svg-icons/faChevronCircleDown";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import { MEASURE_VALS, MEASURE_MAP, ORM_WORKSPACE_SLICE, ORM_PROJECT_SLICE, ID_ATTRIBUTE, WORKSPACE_ID, MEASURE_UNIT_MAP } from '../../utils/constants';
import { connect, useSelector } from "react-redux";
import { createSelector } from 'redux-orm';

import orm from '../../models/orm.register';

import "./VisualReport.module.scss";
import BaseORMModel from "../../models/BaseORMModel";

const CollectionCreateInstruction = () => {
    // if (collection)
    //     return null;

    return <div className="instruction report">


                <h1 className="header">
                    <span className="icon-container">
                    <FontAwesomeIcon
                    className="icon"
                    icon={faLayerPlus}
                    onClick={(e) => {

                    }}/>
                    </span>
                    Create a collection</h1>


        <h2>Organise your projects with collections</h2>
        <p>Use the collections menu
            <FontAwesomeIcon
                className="ml-1"
                icon={faChevronCircleDown}
                className="icon "
            />
            to
        create new collections, load collections from file, or load saved collections.</p>
        <p>You can <strong>edit, copy, submit, or delete a collection</strong> via the actions next to each collection in the dropdown list.</p>
    </div>;
};


export default CollectionCreateInstruction;

