import { EventHanlders, EventEmitter } from "../EventEmitter";
import { useRef, useState, useEffect } from "react";
import { ERROR_CHANNEL } from "../../utils/constants";
import { Toast } from "../Toast";

const ErrorNotifier = ({ errorChannel, useToast = true,
    errorFn = null,
    // successFn = null,
    // onSuccess = null 
}) => {

    let [error, setError] = useState(null);

    const eventHandlers = useRef(new EventHanlders());

    useEffect(() => {

        if (ERROR_CHANNEL[errorChannel]) {
            eventHandlers.current.addEvents(ERROR_CHANNEL[errorChannel], async (event) => {
                event && setError(event);

                // event && event.error && 
                // event && setError({
                //     type: "error",
                //     event: event
                // });
                // event && event.result && onSuccess(event.result);
                // event && event.result && setError({
                //     type: "result",
                //     event: event.result
                // });
            });

            EventEmitter.subscribe(ERROR_CHANNEL[errorChannel], eventHandlers.current.events[ERROR_CHANNEL[errorChannel]]);

            return () => {
                EventEmitter.off(ERROR_CHANNEL[errorChannel], eventHandlers.current.events[ERROR_CHANNEL[errorChannel]]);
            };
        }

        return () => {
        };
    }, [errorChannel]);

    useEffect(() => {

        // if (error && error.type === "error") {
        //     useToast && Toast.error(error.message);
        // }

        if (error) {
            useToast && Toast.error(error.message);
        }
    }, [error]);

    return !useToast && (
        (
            (error) && errorFn && errorFn(error)
        )
        // (
        //     (error && error.type === "error") && errorFn && errorFn(error.event)
        // )
        // ||
        // (
        //     (error && error.type === "result") && successFn && successFn(error.event)
        // )
    );
};

export default ErrorNotifier;