import UserGroup from "./UserGroup";
import ReduxModel from "./ReduxModel";
import { ID_ATTRIBUTE } from "../utils/constants";

export default class UserProfile extends ReduxModel {
    constructor({
        id,
        username,
        firstname,
        lastname,
        role,
        email,
        invitePending,
        selectedGroupId,
        oldPassword,
        password,
        groups = [],
        acceptedTerms,
    }) {
        super();
        this.id = id;
        this.username = username;
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.role = role;
        this.invitePending = invitePending;
        this.oldPassword = oldPassword;
        this.password = password;

        this.groups = groups ?? [];
        this.selectGroup(selectedGroupId);

        this.acceptedTerms = acceptedTerms;
    }

    getGroupsByPrivilege(privilege) {
        return this.groups.filter((group) => group.privilege === privilege);
    }

    getGroupsByMinPrivilege(privilege) {
        return this.groups.filter((group) => group.privilege >= privilege);
    }

    static deserialise({
        [ID_ATTRIBUTE]: userId,
        username,
        firstname,
        lastname,
        role,
        password,
        oldPassword,
        invite_pending,
        groups,
        email,
        activated_group_id,
        accepted_terms,
    }) {
        let ngroups = (groups ?? []).map((group) => UserGroup.deserialise(group));
        return new UserProfile({
            id: userId,
            username,
            firstname,
            lastname,
            role,
            password,
            oldPassword,
            invitePending: invite_pending,
            groups: ngroups,
            email,
            selectedGroupId: activated_group_id,
            acceptedTerms: accepted_terms,
        });
    }

    toReduxStore() {
        return {
            id: this.id,
            username: this.username,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            role: this.role,
            invitePending: this.invitePending,
            oldPassword: this.oldPassword,
            password: this.password,
            groups: this.groups,
            selectedGroupId: this.selectedGroupId,
            selectedGroup: this.selectedGroup,
            acceptedTerms: this.acceptedTerms,
        };
    }

    static fromReduxStore(d) {
        if (!d) return null;

        const {
            id,
            username,
            firstName,
            lastName,
            email,
            role,
            invitePending,
            oldPassword,
            password,
            groups,
            selectedGroupId,
            acceptedTerms,
        } = d;

        return new UserProfile({
            id,
            username,
            firstName,
            lastName,
            role,
            email,
            invitePending,
            selectedGroupId,
            oldPassword,
            password,
            groups,
            acceptedTerms,
        });
    }

    clone() {
        return new UserProfile({
            id: this.id,
            username: this.username,
            firstname: this.firstname,
            lastname: this.lastname,
            role: this.role,
            email: this.email,
            invitePending: this.invitePending,
            selectedGroupId: this.selectedGroupId,
            oldPassword: this.oldPassword,
            password: this.password,
            groups: this.groups,
            acceptedTerms: this.acceptedTerms,
        });
    }

    selectGroup(id) {
        this.selectedGroupId = id;
        this.selectedGroup = this.groups.filter((group) => group.id === this.selectedGroupId)[0] || this.groups[0];
        this.selectedGroupId = (this.selectedGroup || {}).id;
    }

    updateGroup(groupToUpdate) {
        this.groups.forEach((group) => {
            if (group.id !== groupToUpdate._id) {
                return;
            }

            group.name = groupToUpdate.name;
            group.allowManualSoilSelection = groupToUpdate.allowManualSoilSelection;
        });

        if (this.selectedGroup && this.selectedGroupId === groupToUpdate._id) {
            this.selectedGroup = {
                ...this.selectedGroup,
                name: groupToUpdate.name,
                allowManualSoilSelection: groupToUpdate.allowManualSoilSelection,
            };
        }
    }
}
