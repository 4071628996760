import React, { useEffect, useState } from 'react';
import { EventHanlders, EventEmitter } from '../EventEmitter';
import { ERROR_CHANNEL, EVENT_CHANNEL, SUCCESS_CHANNEL, ID_ATTRIBUTE } from '../../utils/constants';
import { MDBModal, MDBModalBody } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons/faTrashAlt";

import "./CollectionDeletor.scss";
import { SuccessNotifier } from '../Success';

const CollectionDeletor = ({ collection = null, setModalClose } = {}) => {
    const [sucessNotifierId, setSucessNotifierId] = useState("success-notifier");
    const [isSending, setSending] = useState(false);

    useEffect(() => {
        setSucessNotifierId("success-notifier");
    }, [collection]);

    const notifyDeletedCollection = (collection) => {
        const collectionId = collection[ID_ATTRIBUTE];
        const contents = (
            <span>
                Deleted
                <strong> '{ collection.title }'</strong> collection
            </span>
        );
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, {
            contents,
            id: collectionId,
        });
    };

    return (
        <div className="collection-delete-modal">
            <div className="flex-grow-1 main-section collection-delete-content">

                <div className="delete-title-notice">
                   
                    {
                        !collection && <h3 className="delete-content-notice">There is no collection to delete</h3>
                    }
                    {
                        collection && <h3 className="delete-content-notice">Are you sure you want to delete <br />{(collection ?? {}).title}?</h3>
                    }

                <SuccessNotifier
                    key={sucessNotifierId}
                    successChannel={SUCCESS_CHANNEL.COLLECTION_DELETE_SUCCESS}
                    errorChannel={ERROR_CHANNEL.COLLECTION_DELETE_ERROR}
                    startFn={() => 'The collection is being deleted'}
                    successFn={() => 'The collection is successfully deleted'}
                    onError={() => setSending(false)}
                    onSuccess={(result) => {
                        // console.log(result);
                        setSending(false);
                        notifyDeletedCollection(result);
                        setModalClose && setModalClose();
                    }} />
    </div>
            </div>
            <div className="footer-section">
                <div className="btn-container">
                    <button
                        type="button"
                        title="Cancel the deleting of the collection"
                        className="btn btn-default"
                        disabled={isSending}
                        onClick={() => setModalClose && setModalClose()}
                    >
                        No, cancel
                    </button>

                    {
                        collection && <button
                            type="button"
                            title="Delete the collection"
                            className="btn btn-primary"
                            disabled={isSending}
                            onClick={() => {
                                setSending(true);
                                EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COLLECTION_API_DELETE, {
                                    payload: collection,
                                    errorController: { errorChannel: ERROR_CHANNEL.COLLECTION_DELETE_ERROR }
                                });
                            }}
                        >
                            Yes, delete collection
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};

export default CollectionDeletor;