import React, { useState, useEffect } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/pro-solid-svg-icons/faPlusSquare";
import { faMinusSquare } from "@fortawesome/pro-solid-svg-icons/faMinusSquare";
import {
    ID_ATTRIBUTE,
    MEASURE_MAP,
    MEASURE_UNIT_MAP,
    MEASURE_VALS,
    REDUCTION_PRINT_FORMAT,
    AREA_PRINT_FORMAT,
    DATE_CREATED,
    PROJECT_ISSUES,
    BLOCK_ISSUES,
    EVENT_CHANNEL,
} from "../../utils/constants";

import "./ProjectTable.module.scss";
import ProjectReactTable from "./ProjectReactTable";
import {
    reportResultsOfBlocks,
    reportConflictsOfBlocks,
    generateProjectIssues,
    generateBlockIssues,
} from "../../utils/functions";
import { TABLE_OPERATION_MODE, PROJECT_TYPE, BLOCK_TYPE, TABLE_CUSTOM_VERSION } from "./tableConstants";
import { SEPARATOR } from "./tableConstants";
import BaseORMModel from "../../models/BaseORMModel";
import numeral from "numeral";
import SurveyFixer from "../Survey/SurveyFixer";
import { Checkbox } from "../CheckboxOrRadio";
import IssueDisplay from "./IssueDisplay";
import { faWrench } from "@fortawesome/pro-solid-svg-icons/faWrench";
import { EventEmitter } from "../EventEmitter";
import { toSignificantDigit } from "../../utils/math.utils";

const defaultSortBy = [];
const HIGHLIGHT_SENSITIVE_DELAY = 2;

/**
 * The table should have those properties
 * @property {Array<ProjectORMModel>} projects - an array of selected projects
 * @property {Array<string>} selectedProjectIds - an array of selected project ids
 * @property {Array<string>} selectedBlockIds - an array of selected block ids
 * @property {(selectedProjectIds: Array<string>)} onProjectSelect - an array of selected project ids
 * @property {(selectedBlockIds: Array<string>)} onBlockSelect - an array of selected block ids
 * @property {TABLE_OPERATION_MODE} mode - decide if editing is allowed on the table
 * @property {{project: [{key, type, render: (project)=>{}: JSX, handler: (project)=>{}}], block: [{key, type, render: (block)=>{}: JSX, handler: (block)=>{}}]}} actions
 */
const ProjectTable = ({
    projects: projectsAsProp = [],
    selectedProjectIds: selectedProjectIdsAsProp = [],
    selectedBlockIds: selectedBlockIdsAsProp = [],
    onProjectsSelect = () => {},
    onBlocksSelect = () => {},
    onProjectBlockSelect = () => {},
    mode = TABLE_OPERATION_MODE.READ_ONLY,
    projectActions = [],
    blockActions = [],
}) => {
    // expansion is tracked via row id within react table
    // pagination reset needs to be tracked when project list is changed in size
    // sort reset needs to be tracked when project list is changed in size

    // measures: [{measure: {label: string, state: string}, unit: string, unitClass: string}]
    const [projects, setProjects] = useState({
        key: null,
        ids: [],
        map: {},
        blockMap: {},
        sortBy: defaultSortBy,
        paginationReset: {},
        measures: [],
        [TABLE_CUSTOM_VERSION]: 0,
    });
    const [selectedProjectIds, setSelectedProjectIds] = useState({ ids: [], map: {} });
    const [selectedBlockIds, setSelectedBlockIds] = useState({ ids: [], map: {} });

    const forceRecompute = (project) => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_FORCE_COMPUTE, {
            payload: {
                projects: [project],
            },
        });
    };

    useEffect(() => {
        setProjects(({ ids, map: projectsMap, measures = [], [TABLE_CUSTOM_VERSION]: stateCustomVersion }) => {
            projectsAsProp = _.orderBy(projectsAsProp ?? [], (d) => new Date(d[DATE_CREATED]).getTime(), "desc");

            projectsAsProp = (projectsAsProp ?? [])
                .map((projectAsProp) => {
                    if (!projectAsProp[ID_ATTRIBUTE]) return null;

                    if (
                        projectsMap[projectAsProp[ID_ATTRIBUTE]] &&
                        BaseORMModel.getVersionId(projectsMap[projectAsProp[ID_ATTRIBUTE]]) ===
                            BaseORMModel.getVersionId(projectAsProp[ID_ATTRIBUTE])
                    )
                        return projectAsProp;

                    const oldProject = projectsMap[projectAsProp[ID_ATTRIBUTE]];
                    const oldBlocks = (oldProject ?? {}).blocks ?? [];
                    const oldBlockMap = _.keyBy(oldBlocks ?? [], (d) => d[ID_ATTRIBUTE]);

                    let { blocks, project_title: projectTitle, description, ...rest } = projectAsProp;

                    blocks = (blocks ?? [])
                        .map((block) => {
                            let { geometry, results, ...rest } = block;

                            // consistent class based results
                            const newResults = {};
                            _.values(results ?? {}).forEach((result) => {
                                if (result.class) {
                                    newResults[result.class] = {
                                        ...(result ?? {}),
                                    };

                                    newResults[result.class].reduction = (result.reduction ?? 0) * (result.area ?? 0);
                                }
                            });

                            const oldBlock = oldBlockMap[block[ID_ATTRIBUTE]];
                            let blockCustomVersion = (oldBlock ?? {})[TABLE_CUSTOM_VERSION] ?? 0;

                            // tracking custom version of blocks which require project title and description
                            if (oldProject && projectTitle !== oldProject.project_title) {
                                blockCustomVersion++;
                            }

                            if (oldProject && description !== oldProject.description) {
                                blockCustomVersion++;
                            }

                            if (oldProject && projectAsProp[ID_ATTRIBUTE] !== oldProject[ID_ATTRIBUTE]) {
                                blockCustomVersion++;
                            }

                            return {
                                ...rest,
                                type: BLOCK_TYPE,
                                // results: _.values(results ?? {}).
                                results: newResults,

                                project_title: projectTitle,
                                description,
                                projectId: projectAsProp[ID_ATTRIBUTE],
                                [TABLE_CUSTOM_VERSION]: blockCustomVersion,
                            };
                        })
                        .filter((d) => d);

                    // should calculate project level results in here too
                    const _results = reportResultsOfBlocks(blocks, MEASURE_MAP, MEASURE_UNIT_MAP);
                    const results = {};

                    (MEASURE_VALS ?? []).forEach((val) => {
                        if (_results[val.state]) results[val.state] = _results[val.state];
                    });

                    const projectCustomVersion = (oldProject ?? {})[TABLE_CUSTOM_VERSION] ?? 0;

                    // should report conflicts
                    const conflict = reportConflictsOfBlocks(blocks, projectAsProp);

                    return {
                        ...rest,
                        project_title: projectTitle,
                        description,
                        blocks,
                        results,
                        conflictProperties: conflict,
                        [TABLE_CUSTOM_VERSION]: projectCustomVersion,
                        projectId: projectAsProp[ID_ATTRIBUTE],
                        type: PROJECT_TYPE,
                    };
                })
                .filter((d) => d);

            const blockMap = {};
            let oldMeasures = measures ?? [];
            measures = {};

            // build block map to control valid selected blocks
            // build measureMap and measures to know what are the measures to be reported
            (projectsAsProp ?? []).forEach((projectAsProp) => {
                let { results, blocks } = projectAsProp ?? {};

                (blocks ?? []).forEach((block) => {
                    blockMap[block[ID_ATTRIBUTE]] = block;
                });

                // assume that all project measure units are the same
                (MEASURE_VALS ?? [])
                    .filter((val) => results[val.state])
                    .forEach((val) => {
                        measures[val.state] = {
                            measure: val,
                            unit: results[val.state].unit,
                            unitClass: results[val.state].unitClass,
                        };
                    });
            });

            measures = (MEASURE_VALS ?? []).map((val) => measures[val.state]).filter((d) => d);

            // tracking if results are changed in size (table needs to re-render)
            stateCustomVersion = stateCustomVersion ?? 0;
            if (measures.length !== oldMeasures.length) stateCustomVersion++;

            const newState = {
                map: _.keyBy(projectsAsProp ?? [], (d) => d[ID_ATTRIBUTE]),
                ids: (projectsAsProp ?? []).map((d) => d[ID_ATTRIBUTE]),
                blockMap,
                measures,
                key: BaseORMModel.getVersionId(projectsAsProp ?? []),
                [TABLE_CUSTOM_VERSION]: stateCustomVersion,
            };

            const oldSortedIds = _.sortBy(ids ?? []) ?? [];
            const newSortedIds = _.sortBy(newState.ids ?? []) ?? [];

            return {
                ...newState,
                sortBy:
                    _.join(newSortedIds, SEPARATOR) !== _.join(oldSortedIds, SEPARATOR)
                        ? [...(defaultSortBy ?? [])]
                        : defaultSortBy,
                paginationReset: {
                    reset: _.join(newSortedIds, SEPARATOR) !== _.join(oldSortedIds, SEPARATOR),
                },
            };
        });
    }, [BaseORMModel.getVersionId(projectsAsProp)]);

    // control state of selected projects
    useEffect(() => {
        let { map: projectMap } = projects ?? {};

        const selectedProjectIds = (selectedProjectIdsAsProp ?? []).filter(
            (selectedProjectIdAsProp) => (projectMap ?? {})[selectedProjectIdAsProp]
        );
        const map = {};
        (selectedProjectIds ?? []).forEach((projectId) => (map[projectId] = 1));

        setSelectedProjectIds({
            ids: selectedProjectIds ?? [],
            map,
        });
    }, [_.join(selectedProjectIdsAsProp ?? [], SEPARATOR), projects.key]);

    // control state of selected blocks
    useEffect(() => {
        let { blockMap } = projects ?? {};

        const selectedBlockIds = (selectedBlockIdsAsProp ?? []).filter(
            (selectedBlockIdAsProp) => (blockMap ?? {})[selectedBlockIdAsProp]
        );
        const map = {};
        (selectedBlockIds ?? []).forEach((blockId) => (map[blockId] = 1));

        setSelectedBlockIds({
            ids: selectedBlockIds ?? [],
            map,
        });
    }, [_.join(selectedBlockIdsAsProp ?? [], SEPARATOR), projects.key]);

    let {
        ids: projectIds,
        map: projectMap,
        blockMap,
        paginationReset = {},
        sortBy = defaultSortBy,
        measures,
        [TABLE_CUSTOM_VERSION]: stateVersion = 0,
    } = projects ?? {};

    projectIds = projectIds ?? [];
    projectMap = projectMap ?? {};

    // generate project table data and block data
    const projectsInTable = (projectIds ?? [])
        .filter((projectId) => projectMap[projectId])
        .map((projectId) => {
            return projectMap[projectId];
        })
        .map((project) => {
            const isSelected = selectedProjectIds.map[project[ID_ATTRIBUTE]] === 1;

            return {
                ...(project ?? {}),
                selected: isSelected,
                stateVersion,
                area: _.reduce(project.blocks ?? [], (sum, block) => sum + (block ?? {}).area ?? 0, 0),
                blocks: (project.blocks ?? []).map((block) => {
                    const isBlockSelected = selectedBlockIds.map[block[ID_ATTRIBUTE]] === 1;

                    return {
                        ...(block ?? {}),
                        stateVersion,
                        selected: isBlockSelected,
                        projectSelected: isSelected,
                        extraVersionInfo: _.join(
                            [
                                `isSelected:${isBlockSelected}`,
                                `isProjectSelected:${isSelected}`,
                                `stateVersion:${stateVersion}`,
                            ],
                            SEPARATOR
                        ),
                    };
                }),

                extraVersionInfo: _.join(
                    [
                        `isSelected:${isSelected}`,
                        `isProjectSelected:${isSelected}`,
                        `stateVersion:${stateVersion}`,
                        `mode:${mode}`,
                    ],
                    SEPARATOR
                ),
            };
        });

    const columns = React.useMemo(() => {
        return [
            {
                Header: ({ data }) => {
                    const projects = data ?? [];
                    const blocks = _.flattenDeep(projects.map((project) => project.blocks ?? []));
                    const selectedProjects = projects.filter((project) => project.selected === true);
                    const selected = selectedProjects.length === projects.length && projects.length !== 0;
                    return (
                        <Checkbox
                            className="app-checkbox"
                            checked={selected}
                            onChange={(e) => {
                                const checked = e.target.checked;
                                setTimeout(() => {
                                    onProjectBlockSelect(checked, blocks, projects);
                                }, HIGHLIGHT_SENSITIVE_DELAY);
                            }}
                        />
                    );
                },
                disableSortBy: true,
                accessor: (projectOrBlock) => {
                    return projectOrBlock;
                },
                id: "projectSelected",
                Cell: ({ value: projectOrBlock }) => {
                    return (
                        projectOrBlock.type === PROJECT_TYPE && (
                            <Checkbox
                                className="app-checkbox"
                                checked={projectOrBlock.selected}
                                onChange={(e) => {
                                    const checked = e.target.checked;

                                    setTimeout(() => {
                                        onProjectBlockSelect(checked, projectOrBlock.blocks ?? [], [projectOrBlock]);
                                    }, HIGHLIGHT_SENSITIVE_DELAY);
                                }}
                            />
                        )
                    );
                },
            },
            {
                // Build our expander column
                id: "expanded", // Make sure it has an ID
                disableSortBy: true,
                accessor: (projectOrBlock) => {
                    return projectOrBlock;
                },
                Cell: ({ value: projectOrBlock, row }) => {
                    if (projectOrBlock.type === BLOCK_TYPE) {
                        return (
                            <Checkbox
                                className="app-checkbox"
                                checked={projectOrBlock.selected}
                                onChange={(e) => {
                                    let blocks = [projectOrBlock];
                                    let projects = [];

                                    if (!e.target.checked && projectOrBlock.projectSelected) {
                                        // un-select the project when one block at least is un-selected
                                        // onProjectsSelect && onProjectsSelect(e.target.checked, [{ [ID_ATTRIBUTE]: projectOrBlock.projectId }]);
                                        projects = [
                                            {
                                                [ID_ATTRIBUTE]: projectOrBlock.projectId,
                                            },
                                        ];
                                    }

                                    const checked = e.target.checked;

                                    setTimeout(() => {
                                        onProjectBlockSelect(checked, blocks, projects);
                                    }, HIGHLIGHT_SENSITIVE_DELAY);
                                }}
                            />
                        );
                    }

                    return row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                // style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                // paddingLeft: `${row.depth * 2}rem`,
                                //},
                            })}
                        >
                            {
                                //row.isExpanded ? '👇' : '👉'}
                                row.isExpanded ? (
                                    <FontAwesomeIcon icon={faMinusSquare} className="mr-0" />
                                ) : (
                                    <FontAwesomeIcon icon={faPlusSquare} className="mr-0" />
                                )
                            }
                        </span>
                    ) : null;
                },
            },
            {
                Header: "Project title",
                accessor: "project_title",
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Size (Ha, Km)",
                accessor: (projectOrBlock) => {
                    return (projectOrBlock ?? {}).area ?? 0;
                },
                id: `area`,
                Cell: ({ value: area }) => {
                    return numeral(area ?? 0).format(AREA_PRINT_FORMAT);
                },
            },
            {
                Header: "Block id",
                accessor: (projectOrBlock) => {
                    return projectOrBlock.type === BLOCK_TYPE ? projectOrBlock[ID_ATTRIBUTE] : "";
                },
                disableSortBy: true,
                id: "blockId",
                Cell: ({ value }) => {
                    return value;
                },
            },

            ...(measures ?? []).map(({ measure, unit }) => {
                return {
                    Header: `${measure.label} saved ${unit}`,
                    accessor: (projectOrBlock) => {
                        const isError = ((projectOrBlock ?? {}).conflictProperties ?? {}).conflict ?? false;

                        let result = ((projectOrBlock ?? {}).results ?? {})[measure.state];
                        result = isError && (!result || !result.reduction) ? {} : result;

                        if (!result || result.reduction === null || result.reduction === undefined) return "";

                        const before = result.before;
                        const after = result.after;

                        const reduction =
                            before < 0 ||
                            after < 0 ||
                            // when it generates some DIN or PEST, show 0 rather than a negative number
                            before < after
                                ? "No result"
                                : numeral(toSignificantDigit(result.reduction, 3)).format(REDUCTION_PRINT_FORMAT);

                        return reduction;
                    },
                    id: `${measure.state}_reduction`,
                    Cell: ({ value: reduction }) => {
                        return reduction;
                    },
                };
            }),

            // issue column
            {
                Header: "Issue",
                accessor: (projectOrBlock) => {
                    return projectOrBlock;
                },
                id: "issue",
                Cell: ({ value: projectOrBlock }) => {
                    const { type } = projectOrBlock;
                    let issueMap = {};
                    let issueMessages = {};

                    if (type === BLOCK_TYPE) {
                        issueMap = generateBlockIssues(projectOrBlock);
                        issueMessages = BLOCK_ISSUES;
                    }

                    if (type === PROJECT_TYPE) {
                        issueMap = generateProjectIssues(projectOrBlock);
                        issueMessages = PROJECT_ISSUES;
                    }

                    const issues = Object.entries(issueMap)
                        .filter(([, issueDetail]) => issueDetail.status)
                        .map(
                            (
                                [type, issue] // Preprocessing
                            ) => ({
                                type,
                                issue,
                                meta: {
                                    ...issueMap[type].meta,
                                    readOnly: mode === TABLE_OPERATION_MODE.READ_ONLY,
                                },
                                message: issueMessages[type].message,
                                detailedMessage: issueMessages[type].detailedMessage,
                            })
                        )
                        .map(({ type, message = () => "", detailedMessage = () => "", meta }) => {
                            return {
                                type,
                                message,
                                meta,
                                detailedMessage,
                                render: () => {
                                    if (type === PROJECT_ISSUES.incompleteSurveyConflict.type) {
                                        return <SurveyFixer project={projectOrBlock} readOnly={meta.readOnly} />;
                                    }

                                    if (
                                        mode !== TABLE_OPERATION_MODE.READ_ONLY &&
                                        (type === PROJECT_ISSUES.customSoilClimatePermissionConflict.type ||
                                            type === PROJECT_ISSUES.surveyVersionMismatchConflict.type)
                                    ) {
                                        return (
                                            <span
                                                title="Recompute"
                                                className="ml-2"
                                                onClick={() => forceRecompute(projectOrBlock)}
                                            >
                                                <FontAwesomeIcon icon={faWrench} className="icon" />
                                            </span>
                                        );
                                    }

                                    return null;
                                },
                            };
                        });

                    return <IssueDisplay issues={issues} />;
                },
            },

            // action column
            {
                Header: "",
                disableSortBy: true,
                accessor: (projectOrBlock) => {
                    return projectOrBlock;
                },
                minWidth: 110,
                className: "action-header",
                id: "action",
                Cell: ({ value: projectOrBlock }) => {
                    let pas = null;
                    let bas = null;

                    if (projectOrBlock.type === PROJECT_TYPE) {
                        pas = (projectActions ?? []).map(({ type, render }, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {render({ data: projectOrBlock, type, mode })}
                                </React.Fragment>
                            );
                        });
                    }

                    if (projectOrBlock.type === BLOCK_TYPE) {
                        bas = (blockActions ?? []).map(({ type, render }, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {render({ data: projectOrBlock, type, mode })}
                                </React.Fragment>
                            );
                        });
                    }

                    return (
                        <div className="action-area">
                            {pas}
                            {bas}
                        </div>
                    );
                },
            },
        ];
    }, [
        _.join(
            (measures ?? []).map((measure) => measure.measure.state),
            SEPARATOR
        ),
        projectActions,
        blockActions,
        onBlocksSelect,
        onProjectsSelect,
        mode,
    ]);

    const getRowProps = React.useMemo(() => {
        return (rowProps, { row }) => {
            if (!row || !row.original) return {};

            return {
                className: `${row.original.selected ? "selected" : ""} ${(rowProps ?? {}).className ?? ""}`,
            };
        };
    }, []);

    return (
        <>
            <ProjectReactTable
                className="project-table"
                data={projectsInTable}
                columns={columns}
                resetPage={paginationReset}
                sortBy={sortBy}
                getRowProps={getRowProps}
            />
        </>
    );
};

export default ProjectTable;
