import React from "react";
import { Radio } from "../CheckboxOrRadio";
import { ID_ATTRIBUTE } from "../../utils/constants";
import { GRAZING_STREAMBANK_SIZES } from "../../utils/miscellaneous";
import { EventEmitter } from "../EventEmitter";

const PickerRadio = (props) => {
    const { id, label, checked, value, onChange } = props;

    return (
        <div className="d-flex response" key={id}>
            <div className="radio-container" key={id}>
                <div className="custom-control custom-radio">
                    <Radio
                        className="custom-control-input"
                        name={id}
                        id={id}
                        value={value}
                        checked={checked}
                        onChange={(e) => {
                            onChange(value, e.target.checked);
                        }}
                        checked={checked}
                    />
                    <label className="orange-check-form custom-control-label" htmlFor={id} />
                </div>
            </div>
            <div className="flex-grow-1">{label}</div>
        </div>
    );
};

const GrazingStreambankSpecificQuestions = ({ projectId, survey, saveChannel }) => {
    if (!projectId || !survey) {
        return null;
    }

    const handleStreamSizeChange = (selectedSize) => {
        EventEmitter.dispatch(saveChannel, {
            payload: {
                data: {
                    [ID_ATTRIBUTE]: projectId,
                    survey: {
                        q0_4: selectedSize,
                    },
                },
            },
        });
    };

    const size = survey.q0_4 ?? GRAZING_STREAMBANK_SIZES.MEDIUM;

    return (
        <div>
            <div className="question-set-container" key="stream-size">
                <div className="font-weight-bold">What best describes the size of streams in your frontages?</div>
                <div className="question-container">
                    <div>
                        <PickerRadio
                            id="sb-size-small"
                            name="sb-size-small"
                            checked={size === GRAZING_STREAMBANK_SIZES.SMALL}
                            value={GRAZING_STREAMBANK_SIZES.SMALL}
                            label="Small (<10m wide)"
                            onChange={handleStreamSizeChange}
                        />

                        <PickerRadio
                            id="sb-size-medium"
                            name="sb-size-medium"
                            checked={size === GRAZING_STREAMBANK_SIZES.MEDIUM}
                            value={GRAZING_STREAMBANK_SIZES.MEDIUM}
                            label="Medium (10-20m wide)"
                            onChange={handleStreamSizeChange}
                        />

                        <PickerRadio
                            id="sb-size-large"
                            name="sb-size-large"
                            checked={size === GRAZING_STREAMBANK_SIZES.LARGE}
                            value={GRAZING_STREAMBANK_SIZES.LARGE}
                            label="Large (>20m wide)"
                            onChange={handleStreamSizeChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GrazingStreambankSpecificQuestions;
