import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ID_ATTRIBUTE, EVENT_CHANNEL } from "../../utils/constants";
import "./ProjectTable.module.scss";
import ProjectTable from "./ProjectTable";
import { TABLE_OPERATION_MODE } from "./tableConstants";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons/faPencilAlt";
import { faBinoculars } from "@fortawesome/pro-solid-svg-icons/faBinoculars";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { EventEmitter } from "../EventEmitter";
import BatchActionGroup from "./BatchActionGroup";
import { faSearchPlus } from "@fortawesome/pro-solid-svg-icons";
import BatchDelete from "./BatchDeleteAction";
import BatchProjectCopy from "./BatchProjectCopyAction";
import BatchProjectMove from "./BatchProjectMoveAction";
import BatchBlockProjectCreateAction from "./BatchBlockProjectCreateAction";
import { SHARED_SELECTOR } from "../../selectors/appSelector";
import { MDBTooltip } from "mdbreact";

const _AppTable = ({
    projects = [],
    selectedProjectIds = [],
    selectedBlockIds = [],
    readOnly = true,
    selectedCollection,
}) => {
    const mode = readOnly ? TABLE_OPERATION_MODE.READ_ONLY : TABLE_OPERATION_MODE.EDIT;

    const onBlocksSelect = React.useMemo(() => {
        return (checked, blocks) => {
            if (!blocks) return;

            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_BLOCK_BATCH_HIGHLIGHT, {
                payload: {
                    highlighted: checked,
                    blocks,
                },
            });
        };
    }, []);

    const onProjectBlockSelect = React.useMemo(() => {
        return (checked, blocks, projects) => {
            if (!blocks && !projects) return;

            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_BLOCK_BATCH_HIGHLIGHT, {
                payload: {
                    highlighted: checked,
                    blocks: blocks ?? [],
                    projects: projects ?? [],
                },
            });
        };
    }, []);

    const onProjectsSelect = React.useMemo(() => {
        return (checked, projects) => {
            if (!projects) return;

            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_BATCH_HIGHLIGHT, {
                payload: {
                    highlighted: checked,
                    projects,
                },
            });
        };
    }, []);

    const onProjectDelete = (project) => () => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECTS_DELETE, {
            payload: { projects: [project], blocks: project.blocks },
        });
    };

    const onBlockDelete = (block) => () => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_BLOCK_BATCH_DELETE, {
            payload: { blocks: [block], notify: true },
        });
    };

    const projectActions = React.useMemo(() => {
        return [
            {
                type: "edit",
                render: ({ data: project, mode }) => {
                    const readOnlyMode = mode === TABLE_OPERATION_MODE.READ_ONLY;

                    return (
                        <div className="action">
                            <MDBTooltip domElement tag="div" style={{}}>
                                <span>
                                    <FontAwesomeIcon
                                        icon={readOnlyMode ? faBinoculars : faPencilAlt}
                                        onClick={() => {
                                            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_CREATE_OR_EDIT_PROJECT, {
                                                payload: {
                                                    projectId: project[ID_ATTRIBUTE],
                                                    collectionId: project.collectionId,
                                                    collectionReadOnly: readOnlyMode,
                                                },
                                            });
                                        }}
                                    />
                                </span>

                                <span>Edit project</span>
                            </MDBTooltip>
                        </div>
                    );
                },
            },
            {
                type: "zoom",
                render: ({ data: project }) => {
                    return (
                        <div className={`action ${project.fitBoundsRequest ? "enabled" : ""}`}>
                            <MDBTooltip domElement tag="div" style={{}}>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faSearchPlus}
                                        onClick={() => {
                                            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_BLOCK_SET_EXTENT, {
                                                payload: {
                                                    isProject: true,
                                                    objectId: project[ID_ATTRIBUTE],
                                                },
                                            });
                                        }}
                                    />
                                </span>

                                <span>Zoom to project</span>
                            </MDBTooltip>
                        </div>
                    );
                },
            },
            {
                type: "delete",
                render: ({ data, mode }) => {
                    if (mode === TABLE_OPERATION_MODE.READ_ONLY) {
                        return null;
                    }

                    return (
                        <div className={`action`}>
                            <MDBTooltip domElement tag="div" style={{}}>
                                <span>
                                    <FontAwesomeIcon icon={faTrash} onClick={onProjectDelete(data)} />
                                </span>

                                <span>Delete project</span>
                            </MDBTooltip>
                        </div>
                    );
                },
            },
        ];
    }, []);

    const blockActions = React.useMemo(() => {
        return [
            {
                type: "zoom",
                render: ({ data: block }) => {
                    return (
                        <div className={`action ${block.fitBoundsRequest ? "enabled" : ""}`}>
                            <MDBTooltip domElement tag="div" style={{}}>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faSearchPlus}
                                        onClick={() => {
                                            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_BLOCK_SET_EXTENT, {
                                                payload: {
                                                    isProject: false,
                                                    objectId: block[ID_ATTRIBUTE],
                                                },
                                            });
                                        }}
                                    />
                                </span>

                                <span>Zoom to block</span>
                            </MDBTooltip>
                        </div>
                    );
                },
            },
            {
                type: "delete",
                render: ({ data, mode }) => {
                    if (mode === TABLE_OPERATION_MODE.READ_ONLY) {
                        return null;
                    }

                    return (
                        <div className={`action`}>
                            <MDBTooltip domElement tag="div" style={{}}>
                                <span>
                                    <FontAwesomeIcon icon={faTrash} onClick={onBlockDelete(data)} />
                                </span>

                                <span>Delete block</span>
                            </MDBTooltip>
                        </div>
                    );
                },
            },
        ];
    }, []);

    return (
        <div className="row">
            <div className="table-section col-12">
                <h3>Selected project(s) </h3>
                <p className="small">Click on a column header to sort by that result</p>
                <div className="d-flex">
                    <div className="small mr-1">Actions: </div>
                    <div className="flex-grow-1 batch-action">
                        <BatchActionGroup
                            actions={[
                                {
                                    render: () => {
                                        return (
                                            <BatchDelete
                                                projectIds={selectedProjectIds}
                                                blockIds={selectedBlockIds}
                                                readOnly={mode === TABLE_OPERATION_MODE.READ_ONLY}
                                            />
                                        );
                                    },
                                },
                                {
                                    render: () => {
                                        return (
                                            <BatchProjectCopy
                                                projectIds={selectedProjectIds}
                                                blockIds={selectedBlockIds}
                                                readOnly={mode === TABLE_OPERATION_MODE.READ_ONLY}
                                            />
                                        );
                                    },
                                },
                                {
                                    render: () => {
                                        return (
                                            <BatchProjectMove
                                                projectIds={selectedProjectIds}
                                                blockIds={selectedBlockIds}
                                                readOnly={mode === TABLE_OPERATION_MODE.READ_ONLY}
                                                excludeCollectionIds={[(selectedCollection ?? {})[ID_ATTRIBUTE]]}
                                            />
                                        );
                                    },
                                },
                                {
                                    render: () => {
                                        return (
                                            <BatchBlockProjectCreateAction
                                                projectIds={selectedProjectIds}
                                                blockIds={selectedBlockIds}
                                                readOnly={mode === TABLE_OPERATION_MODE.READ_ONLY}
                                            />
                                        );
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
                <ProjectTable
                    projects={projects}
                    selectedProjectIds={selectedProjectIds}
                    selectedBlockIds={selectedBlockIds}
                    mode={mode}
                    onBlocksSelect={onBlocksSelect}
                    onProjectsSelect={onProjectsSelect}
                    onProjectBlockSelect={onProjectBlockSelect}
                    projectActions={projectActions}
                    blockActions={blockActions}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const workspace = SHARED_SELECTOR.EVERY_THING(state);
    const selectedProjectIds = Object.keys(workspace.highlightedProjectIds ?? {});
    const selectedBlockIds = Object.keys(workspace.highlightedBlockIds ?? {});

    return {
        projects: workspace.selectedProjects ?? [],
        selectedProjectIds,
        selectedBlockIds,
        readOnly: !workspace.selectedCollectionId || workspace.collectionReadOnly,
    };
};

const AppTable = connect(mapStateToProps)(_AppTable);

export default AppTable;
