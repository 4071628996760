import React from 'react';
import AuGovLogo from '../../styles/images/gov-logo-au.png';
import QldGovLogo from '../../styles/images/gov-logo-qld.png';
import TruiiLogo from '../../styles/images/truii-logo-60h.png';
import './footer.scss';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row logos">
                    <div className="col-sm"><img src={AuGovLogo} alt="Australian Government" /></div>
                    <div className="col-sm"><img src={QldGovLogo} alt="Queensland Government" /></div>
                </div>
                <p>
                    <a href="http://truii.com" rel="noopener noreferrer" target="_blank" title="Truii - Find the truth in your data">
                        <img src={TruiiLogo} alt="Truii - Find the truth in your data" />
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;