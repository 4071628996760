import { services, serviceWrapper } from '../services';
import {
    ERROR_CHANNEL,
    ACTIONS,
    COLLECTION_SLICE,
    CREATE_BATCH_ACTION,
    DELETE_ALL_ACTION,
    ORM_COLLECTION_SLICE,
    DELETE_ACTION,
    ORM_PROJECT_SLICE,
    ORM_BLOCK_SLICE,
    EVENT_CHANNEL,
    ORM_COLLECTION_EDITING_STATE_SLICE,
    ORM_WORKSPACE_SLICE,
    ORM_FUNDING_BODY_SLICE,
    ORM_FUNDING_PROGRAM_SLICE,
    DELETE_SOFT_ALL_ACTION
} from '../utils/constants';
// import { Collection } from '../models';
import { store } from '../redux';
import generateORMActionName from '../redux/reducers/orm.action.gen';

const { fundingService } = services;

const fundingLoading = async (event) => {
    let bodies = await serviceWrapper({}, {
        instance: fundingService,
        name: "getFunding",
        params: [
            true
        ]
    });

    if ((bodies ?? {}).fundingBodies) {

        store.dispatch({
            type: generateORMActionName({ slice: ORM_FUNDING_BODY_SLICE, actionName: DELETE_SOFT_ALL_ACTION }),
            payload: {}
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_FUNDING_PROGRAM_SLICE, actionName: DELETE_SOFT_ALL_ACTION }),
            payload: {}
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_FUNDING_BODY_SLICE, actionName: CREATE_BATCH_ACTION }),
            payload: ((bodies ?? {}).fundingBodies ?? [])
        });
    }
};

const fundingEventsDesc = [{
    event: EVENT_CHANNEL.EVENT_CMD_FUNDING_LOADING,
    handler: fundingLoading
}];

export default fundingEventsDesc;