import orm from "../../models/orm.register";
import { createSelector } from "redux-orm";

const collectionSelector = ({ filter, include }) => {
    return createSelector(orm, (session) => {
        const { Collection } = session;

        // many to many
        const collections = Collection.select(session, {
            include: include ?? [],
            filter,
        });

        return { collections };
    });
};

export default collectionSelector;
