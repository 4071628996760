import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";
import toolbarStyles from '../../../node_modules/leaflet-toolbar/dist/leaflet.toolbar.css';

require('leaflet-toolbar');

export const selectAllActionGen = (actionFn) => {
    return L.Toolbar2.Action.extend({
        options: {
            toolbarIcon: {
                html: '<div>Select all</div>',
                tooltip: 'Select all paddocks'
            }
        },
        addHooks: actionFn
    });

}

export const deSelectAllActionGen = (actionFn) => {
    return L.Toolbar2.Action.extend({
        options: {
            toolbarIcon: {
                html: '<div>Deselect all</div>',
                tooltip: 'Deselect all paddocks'
            }
        },
        addHooks: actionFn
    });
}

export const toolbarGen = ({ className, actions }) => {
    return new L.Toolbar2.Control({
        className: className,
        actions: actions ?? []
    });
}