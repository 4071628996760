import React, { useEffect, useState } from "react";
import BarChart from "../../components/BarChart/index";
import { faHandPointer as farHandPointer } from "@fortawesome/pro-regular-svg-icons/faHandPointer";
import { faCircle } from "@fortawesome/pro-light-svg-icons/faCircle";
import { faLayerPlus } from "@fortawesome/pro-regular-svg-icons/faLayerPlus";
import { faChevronCircleDown } from "@fortawesome/pro-solid-svg-icons/faChevronCircleDown";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import { MEASURE_VALS, MEASURE_MAP, ORM_WORKSPACE_SLICE, ORM_PROJECT_SLICE, ID_ATTRIBUTE, WORKSPACE_ID, MEASURE_UNIT_MAP } from '../../utils/constants';
import { connect, useSelector } from "react-redux";
import { createSelector } from 'redux-orm';

import orm from '../../models/orm.register';

import "./VisualReport.module.scss";
import BaseORMModel from "../../models/BaseORMModel";
import CollectionCreateInstruction from "./CollectionCreateInstruction";
import ProjectSelectionInstruction from "./ProjectSelectionInstruction";
import EmptyCollectionInstruction from "./EmptyCollectionInstruction";
import MetricReport from "./MetricReport";
import { SHARED_SELECTOR } from "../../selectors/appSelector";

// const selectedProjectsSelector = () => {
//     return createSelector(
//         orm,
//         session => {
//             const { [ORM_WORKSPACE_SLICE]: Workspace, [ORM_PROJECT_SLICE]: Project } = session;


//             const workspace = (Workspace.select(session, {
//                 include: [{
//                     'selectedProjects': ['blocks']
//                 }, {
//                     'selectedCollection': [{
//                         'projects': []
//                     }]
//                 }],
//                 filter: { deleted: false, [ID_ATTRIBUTE]: WORKSPACE_ID }
//             }) ?? [])[0] ?? {};


//             return {
//                 projects: workspace.selectedProjects ?? [],
//                 selectedCollection: workspace.selectedCollection
//             }
//         }
//     );
// }


const __VisualReport = ({ selectedCollection, selectedCollectionReadOnly, projects }) => {
    if (!selectedCollection) {
        return <CollectionCreateInstruction />;
    }

    if (!selectedCollection.projects || !selectedCollection.projects.length) {
        return <EmptyCollectionInstruction collection={selectedCollection} readOnly={selectedCollectionReadOnly} />;
    }

    let selectionInstruction = null;
    if (!projects || !projects.length)
        selectionInstruction = <ProjectSelectionInstruction />;

    return <>{
        selectionInstruction
    }<MetricReport projects={projects} /></>;

};


export const _VisualReport = React.memo(__VisualReport, ({
    projects: prevProjects,
    selectedCollection: prevSelectedCollection
},
    {
        projects: nextProjects,
        selectedCollection: nextSelectedCollection,
    }) => {


    return BaseORMModel.getVersionId(prevProjects ?? []) === BaseORMModel.getVersionId(nextProjects ?? [])
        && BaseORMModel.getVersionId(prevSelectedCollection ?? {}) === BaseORMModel.getVersionId(nextSelectedCollection ?? {});
});



const mapStateToProps = (state) => {

    const workspace = SHARED_SELECTOR.EVERY_THING(state);

    return {
        projects: workspace.selectedProjects ?? [],
        selectedCollection: workspace.selectedCollection,
        selectedCollectionReadOnly: workspace.collectionReadOnly
    };
};


const VisualReport = connect(mapStateToProps)(_VisualReport);

export default VisualReport;