import { Model, ORM, fk, many, oneToOne, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import { ORM_COLLECTION_SLICE, ORM_PROJECT_SLICE, ORM_WORKSPACE_SLICE, ID_ATTRIBUTE } from "../utils/constants";

export default class WorkspaceORMModel extends BaseORMModel {}

WorkspaceORMModel.modelName = ORM_WORKSPACE_SLICE;

WorkspaceORMModel.fields = {
    [ID_ATTRIBUTE]: attr(),

    selectedCollectionId: oneToOne({
        to: ORM_COLLECTION_SLICE,
        as: "selectedCollection",
        relatedName: "selectedCollectionWorkspace",
    }),

    collectionReadOnly: attr(),
    // true or false (true for submitted or non-submitted)

    editingSourceProjectId: oneToOne({
        to: ORM_PROJECT_SLICE,
        as: "editingSourceProject",
        relatedName: "editingSourceProjectWorkspace",
    }),

    editingProjectId: oneToOne({
        to: ORM_PROJECT_SLICE,
        as: "editingProject",
        relatedName: "editingProjectWorkspace",
    }),

    newProjectId: oneToOne({
        to: ORM_PROJECT_SLICE,
        as: "newProject",
        relatedName: "newProjectWorkspace",
    }),

    selectedProjectIds: attr(),
    highlightedProjectIds: attr(),
    highlightedBlockIds: attr(),
};
