import { Model, ORM, fk, many, oneToOne, attr } from "redux-orm";
// import generateActionName from "../redux/reducers/action.gen";

import BaseORMModel from "./BaseORMModel";
import { ORM_COLLECTION_SLICE, ORM_PROJECT_SLICE, ORM_COLLECTION_EDITING_STATE_SLICE, ID_ATTRIBUTE } from "../utils/constants";

export default class CollectionORMEditingModel extends BaseORMModel { }
CollectionORMEditingModel.modelName = ORM_COLLECTION_EDITING_STATE_SLICE;
CollectionORMEditingModel.fields = {
    [ID_ATTRIBUTE]: attr(),
    editing: attr(),
    title: attr(),

    isCopying: attr(),
    // true or false when a user is copying this collection

    collectionId: oneToOne({
        to: ORM_COLLECTION_SLICE,
        as: 'collection',
        relatedName: 'editingState',
    }),
};