import { combineReducers } from 'redux';
// import baseReducer from './reducers/baseReducer';
// import userReducer from './reducers/userReducer';
// import { groupReducer } from './reducers/groupReducer';
// import homeReducer from './reducers/homeReducer';
// import mapPaneReducer from './reducers/mapPaneReducer';
// import collectionReducer from './reducers/collectionReducer';
// import fundingReducer from './reducers/fundingReducer';
// import chartReducer from './reducers/chartReducer';

import { reducers } from './reducers';

const root = {};

(reducers ?? []).forEach(reducer => {
    root[reducer.slice] = reducer.reducer();
});

// const rootReducer = combineReducers({
//     // user: baseReducer(userReducer),
//     // group: baseReducer(groupReducer),
//     // collection: baseReducer(collectionReducer),
//     // home: baseReducer(homeReducer),
//     // mapPane: baseReducer(mapPaneReducer),
//     // funding: baseReducer(fundingReducer)
//     // chart: chartReducer
// });

const rootReducer = combineReducers(root ?? {});
export default rootReducer;