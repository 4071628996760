import { ServerType, Url } from '../utils/constants';
import { Config } from '../utils/config';

const UrlBuilder = (serverType, urlPath, params = [], query = {}) => {
    if (params.length) {
        urlPath = mapParamsToURL(urlPath, params);
    }
    if (Object.keys(query).length && query.constructor === Object) {
        urlPath = urlPath.indexOf("?") !== -1 ? urlPath.concat("&") : urlPath.concat("?");
        Object.keys(query).forEach(q => {
            urlPath = `${urlPath}${q}=${query[q]}&`;
        });
        urlPath = urlPath.substring(0, urlPath.length - 1);
    }

    switch (serverType) {
        case ServerType.Authentication:
            // return Url.Base.concat(ServerType.Authentication, urlPath);
            return Config.AUTH_ENDPOINT.concat(urlPath);

        case ServerType.Data:
            // return Url.Base.concat(ServerType.Data, urlPath);
            return Config.API_ENDPOINT.concat(urlPath);

        case ServerType.Computation:
            // return Url.Base.concat(ServerType.Data, urlPath);
            return Config.CAL_ENDPOINT.concat(urlPath);

        case ServerType.LB: 
            return Config.LB_ENDPOINT.concat(urlPath);
        default:
            return null;
    }
};

const mapParamsToURL = (urlPath, params) => {
    let regex = /{}/gi, result = [];
    while ((result = regex.exec(urlPath))) {
        urlPath = urlPath.substring(0, result.index).concat(params.shift())
            .concat(urlPath.substring(result.index + 2));
    }

    return urlPath;
};

export default UrlBuilder;