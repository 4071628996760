import React from 'react';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import SecureRoute from './components/SecureRoute/index';
import { useP2RAuth } from './components/AuthContextProvider/authContext';
import { ERROR_CHANNEL } from './utils/constants';
import Routes from './utils/routes';
import AppModal from './components/Modal/AppModal';
import { ErrorNotifier } from './components/Error';
import appEventWatcher from './events';
import EventSubscriber from './components/EventSubscriber/EventSubscriber';
import { NotificationManager } from './components/Toast/notification';

// import './App.css';
// import './styles/styles.scss'
// import "./styles/scss/mdb.scss"

function App() {
    const { isAuthenticated } = useP2RAuth();

    return (
        <>
            <EventSubscriber eventHandlers={appEventWatcher} />
            <ErrorNotifier errorChannel={ERROR_CHANNEL.GENERAL} />
            <AppModal />
            <div className="App">
                <Switch>
                    {Object.keys(Routes).map(routeKey =>
                        <SecureRoute
                            key={routeKey}
                            path={Routes[routeKey].Route}
                            Component={Routes[routeKey].Scene}
                            isLogin={Routes[routeKey].isLogin}
                            authenticatedRoute={Routes[routeKey].authenticatedRoute}
                            loginRoute={Routes.Login.Route}
                            exact
                        />
                    )}
                    {/* Default route */}
                    {isAuthenticated ? <Route component={Routes.Default.Scene} exact /> : <Redirect to={Routes.Login.Route} />}
                </Switch>
            </div>

            <NotificationManager />
        </>
    );
}

export default App;
