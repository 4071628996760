import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import { ID_ATTRIBUTE } from '../../utils/constants';

const ASCENDING = "asc";
const DESCENDING = "desc";

const _TableRow = ({
    data,
    columns,
    className,
    detailPanel,
    detailPanelDisplay = false,
}) => {

    const [displayDetailPanel, setDisplayDetailPanel] = useState(detailPanelDisplay);

    const handleOpenDetailPanel = (e) => {
        e.preventDefault();
        setDisplayDetailPanel(!displayDetailPanel);
    };

    return (
        <>
            <tr className={className} onClick={handleOpenDetailPanel}>
                {columns.map((col) => {
                    return (
                        <td key={`${col.field}-data`}>
                            {
                                col.render ? col.render(
                                    {
                                        row: data ?? {},
                                        cell: (data ?? {})[col.field]
                                    }
                                ) : data[col.field]
                            }
                        </td>
                    );
                })}
            </tr>
            {(displayDetailPanel && typeof detailPanel.render === 'function') &&
                <tr className="detail-panel">
                    <td colSpan={columns.length}>
                        {detailPanel.render(data)}
                    </td>
                </tr>
            }
        </>

    );
};

const TableRow = React.memo(_TableRow);

const _TableHeader = ({
    columns,
    setSort
}) => {
    const updateSort = (fieldName) => {
        setSort(state => {

            return {
                fieldName,
                order: (state ?? {}).fieldName === fieldName ? (
                    (state ?? {}).order === ASCENDING ? DESCENDING : ASCENDING
                ) : ASCENDING
            };
        });
    };

    return (
        <thead>
            <tr>
                {columns.map((col, index) => {
                    const sortable = col.sortable ?? false;
                    //const fieldToSort = col.sortableField ? col.sortableField : col.field;
                    const fieldToSort = col.field;
                    return (
                        <th key={index}>
                            <span className="">{col.title}</span>
                            {sortable && <> &nbsp;
                                <FontAwesomeIcon icon={faSort} onClick={() => updateSort(fieldToSort)} />
                            </>
                            }
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

const TableHeader = React.memo(_TableHeader);

const Table = ({
    data,
    columns = [],
    className = "",
    trClassName = "",
    detailPanel = {}
}) => {


    // const fieldToSort = columns[0].sortableField ? columns[0].sortableField : columns[0].field;
    const [sort, setSort] = useState({
        fieldName: null,
        order: null
    });

    const sortData = (data) => {
        if (!sort.fieldName) return data;
        let sortedData = _.sortBy(data, [sort.fieldName]);
        if (sort.order !== ASCENDING)
            sortedData = _.reverse(sortedData);
        return sortedData;
    };

    const sortedData = sortData(data);

    return (
        <table className={className}>
            <TableHeader
                columns={columns}
                setSort={setSort}
            // tableSortState={sort}
            />
            <tbody>
                {sortedData.map((rowData, idx) => {
                    return (
                        <TableRow
                            key={idx}
                            data={rowData}
                            detailPanelDisplay={(rowData ?? {}).detailPanelDisplay ?? false}
                            columns={columns}
                            className={trClassName}
                            detailPanel={detailPanel}
                        />
                    );
                })}
            </tbody>
        </table>
    );
};

export default Table;
