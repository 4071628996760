import _ from 'lodash';
import { EventEmitter } from '../EventEmitter';
import { EVENT_CHANNEL } from '../../utils/constants';

export default class ProgressController {
    constructor(props) {
        this.current = 0;

        const { stages = [] } = props ?? {};

        // contribution from 0 to 1
        // stages: [{label, state, contribution}]

        // find out start and end of a stage
        const newStages = [];
        (stages ?? []).forEach((stage, index) => {

            const newStage = {
                label: stage.label,
                state: stage.state,
                contribution: stage.contribution,
                start: 0,
                end: stage.contribution
            };

            if (newStages[index - 1]) {
                newStage.start = newStages[index - 1].end ?? 0;
            }
            else
                newStage.start = 0;

            newStage.end = newStage.start + newStage.contribution;

            newStages.push(newStage);
        })

        this.stages = newStages;
        this.stageMap = _.keyBy(newStages, stage => stage.state);
        this.scale = 100;
    }

    // percent from 0 to 1
    report(stageKey, percent, reportOnUi = false) {

        if (!this.stageMap[stageKey])
            return this.current;

        const { start, end, label, contribution } = this.stageMap[stageKey];
        this.current = start + percent * contribution;

        if (!reportOnUi)
            return this.current;

        const progress = this.current * this.scale;

        const progressPayload = {
            progress,
            message: label,
            scale: this.scale
        };

        console.log(progress);

        if (progress === 0) {
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
                payload: progressPayload
            });
        }

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_REPORT_VIA_MODAL, {
            payload: progressPayload
        });

        return this.current;
    }

}