import React, { useEffect, useState, useRef } from "react";

const Radio = ({ checked: checkedProp, onChange = (e) => { }, ...rest }) => {
    const [checked, setChecked] = useState(checkedProp);

    useEffect(() => {
        setChecked(checkedProp);
    }, [checkedProp]);

    return <input type="radio" checked={checked} onChange={(e) => {
        setChecked(e.target.checked);
        onChange(e);
    }} {...rest} />;
};

export default Radio;