import React, { useState, useEffect, useRef } from "react";
import { SURVEY_ANSWER_BEFORE_AFTER, SURVEY_QUESTION_TYPES } from "../../utils/constants";
import numeral from "numeral";
import PropTypes from "prop-types";
import { boundValue, keyValueStringContent } from "../../utils/functions";
import { useFormWizard } from "../FormWizard/FormWizardContext";
import { InputField } from "../InputField";
const {
    BEFORE: { label: beforeLabel, key: beforeKey },
    AFTER: { label: afterLabel, key: afterKey },
} = SURVEY_ANSWER_BEFORE_AFTER;

const EVENT_DEF = () => {};

const _NumericInput = ({ response, meta, selectedAnswerMap, onChange = EVENT_DEF, beforeOrAfterVisibility }) => {
    const { description, id: responseId } = response ?? {};
    const { range, format, unit } = meta;
    const [valueMap, setValueMap] = useState(selectedAnswerMap);
    const { readOnly } = useFormWizard();

    const updateValueMap = (value, category) => {
        setValueMap((prevAnswerMap) => {
            return {
                ...prevAnswerMap,
                [category]: value,
            };
        });
    };

    useEffect(() => {
        setValueMap(selectedAnswerMap);
    }, [selectedAnswerMap]);

    const formatValue = (value, range) => {
        const valueToFormat = range ? boundValue(value, range) : value;
        const _value = numeral(valueToFormat).value();
        return isNaN(_value) ? null : _value;
    };

    return (
        <div className="row mb-2">
            {Object.values(SURVEY_ANSWER_BEFORE_AFTER).map(({ key: beforeOrAfter }) => {
                const beforeOrAfterVisible = beforeOrAfterVisibility[beforeOrAfter];
                const key = `${beforeOrAfter}_${responseId}`;
                if (beforeOrAfterVisible) {
                    return (
                        <div className="col-3" key={key}>
                            <InputField
                                className="w-75"
                                name={response ? beforeOrAfter : key}
                                value={{
                                    value:
                                        valueMap[beforeOrAfter] == null
                                            ? ""
                                            : numeral(valueMap[beforeOrAfter]).format(format),
                                }}
                                onBlur={(e) => {
                                    const formattedValue = formatValue(e.target.value, range);
                                    updateValueMap(formattedValue, beforeOrAfter);
                                    if (
                                        beforeOrAfter === SURVEY_ANSWER_BEFORE_AFTER.BEFORE.key &&
                                        !valueMap[SURVEY_ANSWER_BEFORE_AFTER.AFTER.key]
                                    ) {
                                        onChange(
                                            { event: e, type: "onBlur" },
                                            SURVEY_ANSWER_BEFORE_AFTER.AFTER.key,
                                            formattedValue
                                        );
                                    }
                                    onChange({ event: e, type: "onBlur" }, beforeOrAfter, formattedValue);
                                }}
                                disabled={readOnly}
                            />
                            <span>&nbsp;{unit}</span>
                        </div>
                    );
                }

                // Empty column to create a gap...
                return <div key={key} className="col-3"></div>;
            })}
            <div className="col-lg">{description}</div>
        </div>
    );
};

const __NumericInput = React.memo(
    _NumericInput,
    (
        {
            response: responseBefore,
            meta: metaBefore,
            selectedAnswerMap: ansMapBefore,
            onChange: onChangeBefore,
            beforeOrAfterVisibility: visBefore,
        },
        {
            response: responseAfter,
            meta: metaAfter,
            selectedAnswerMap: ansMapAfter,
            onChange: onChangeAfter,
            beforeOrAfterVisibility: visAfter,
        }
    ) => {
        return (
            responseBefore === responseAfter &&
            metaBefore === metaAfter &&
            keyValueStringContent(ansMapBefore) === keyValueStringContent(ansMapAfter) &&
            keyValueStringContent(visBefore) === keyValueStringContent(visAfter) &&
            onChangeBefore === onChangeAfter
        );
    }
);

const ___NumericInput = ({
    response,
    meta,
    selectedAnswerMap,
    onChange = EVENT_DEF,
    onBlur = EVENT_DEF,
    beforeOrAfterVisibility,
}) => {
    const [answerMap, setAnswerMap] = useState({});
    const { range, format, unit } = meta;

    const key = keyValueStringContent(selectedAnswerMap);

    useEffect(() => {
        setAnswerMap(selectedAnswerMap);
    }, [key]);

    const updateAnswerMap = (column, value) => {
        setAnswerMap((prevAnswer) => {
            return {
                ...(prevAnswer ?? {}),
                [column]: value,
            };
        });
    };

    useEffect(() => {
        onChange && onChange(answerMap);
    }, [keyValueStringContent(answerMap)]);

    const handleOnBlur = (e, category, value) => {
        updateAnswerMap(category, value);
        onBlur({
            ...answerMap,
            [category]: value,
        });
    };

    const handleOnChange = (e, category) => {
        updateAnswerMap(category, e.target.value);
    };

    const refOnChange = useRef(({ event, type }, category, value) => {
        if (type === "onBlur") return handleOnBlur(event, category, value);

        if (type === "onChange") return handleOnChange(event, category);
    });

    return (
        <__NumericInput
            meta={meta}
            selectedAnswerMap={answerMap}
            response={response}
            onChange={refOnChange.current}
            beforeOrAfterVisibility={beforeOrAfterVisibility}
        />
    );
};

const NumericInput = ___NumericInput;

const Numeric = ({
    question,
    onAnswerChange = EVENT_DEF,
    onAnswerBlur = EVENT_DEF,
    selectedAnswerMap,
    beforeOrAfterVisibility,
}) => {
    const { responses = [], visible, meta } = question ?? {};
    const [result, setResult] = useState({});
    const latestAnswer = useRef(result);

    useEffect(() => {
        latestAnswer.current = result;
    }, [result]);

    const inputDisplays = (responses) => {
        if (responses.length) {
            return responses.map((response) => {
                const selectedAnswerFormatted = {
                    [beforeKey]: (selectedAnswerMap[beforeKey] ?? {})[response.id],
                    [afterKey]: (selectedAnswerMap[afterKey] ?? {})[response.id],
                };

                return (
                    <NumericInput
                        key={response.id}
                        response={response}
                        meta={meta}
                        selectedAnswerMap={selectedAnswerFormatted}
                        beforeOrAfterVisibility={beforeOrAfterVisibility}
                        onChange={(data) => {
                            setResult((prevResult) => {
                                return {
                                    [beforeKey]: {
                                        ...prevResult[beforeKey],
                                        [response.id]: data[beforeKey],
                                    },
                                    [afterKey]: {
                                        ...prevResult[afterKey],
                                        [response.id]: data[afterKey],
                                    },
                                };
                            });
                        }}
                        onBlur={(data) => {
                            // Format data to Number
                            let formattedValueMap = {};
                            const changedCategory = Object.keys(data)[0];

                            formattedValueMap[changedCategory] = {};

                            formattedValueMap[changedCategory][response.id] = numeral(data[changedCategory]).value();
                            onAnswerBlur && onAnswerBlur(formattedValueMap);
                        }}
                    />
                );
            });
        }
        return (
            <NumericInput
                meta={meta}
                selectedAnswerMap={selectedAnswerMap}
                onChange={(data) => {
                    setResult((prevResult) => {
                        return {
                            [beforeKey]: data[beforeKey],
                            [afterKey]: data[afterKey],
                        };
                    });
                }}
                beforeOrAfterVisibility={beforeOrAfterVisibility}
                onBlur={(data) => {
                    onAnswerBlur && onAnswerBlur(data);
                }}
            />
        );
    };

    if (!visible) return null;

    return (
        <>
            <div className="row">
                <div className="col-lg-3">
                    <span className="help-block">{beforeLabel}</span>
                </div>
                <div className="col-lg-3">
                    <span className="help-block">{afterLabel}</span>
                </div>
            </div>
            {inputDisplays(responses)}
        </>
    );
};

Numeric.propTypes = {
    question: PropTypes.shape({
        responses: PropTypes.array,
        visible: PropTypes.bool, // From the api response... Used to hide questions that the comp does not need.
        description: PropTypes.string,
        type: PropTypes.oneOf([SURVEY_QUESTION_TYPES.NUMERIC, SURVEY_QUESTION_TYPES.MULTIPLECHOICE]).isRequired,
        id: PropTypes.string.isRequired,
    }).isRequired,
    onAnswerChange: PropTypes.func,
    onBlur: PropTypes.func,
    beforeOrAfterVisibility: PropTypes.shape({
        [beforeKey]: PropTypes.bool,
        [afterKey]: PropTypes.bool,
    }),
};

function areEquals(prevProps, nextProps) {
    const { beforeOrAfterVisibility: prevCV, selectedAnswer: prevSelectedAnswer } = prevProps;
    const { beforeOrAfterVisibility: nextCV, selectedAnswer: nextSelectedAnswer } = nextProps;
    return (
        keyValueStringContent(prevCV) === keyValueStringContent(nextCV) &&
        keyValueStringContent(nextSelectedAnswer) === keyValueStringContent(prevSelectedAnswer)
    );
}

export default React.memo(Numeric, areEquals);
