import React, { useMemo } from "react";
import { FeatureGroup } from "react-leaflet";
import drawStyles from "../../../node_modules/leaflet-draw/dist/leaflet.draw.css";
import styles from "./LeafletMap2.module.scss";
import { BlockPolygon } from "./BlockPolygon";
import { ID_ATTRIBUTE, INDUSTRY } from "../../utils/constants";
import BaseORMModel from "../../models/BaseORMModel";
import { EditControl } from "react-leaflet-draw";
import { useMapEvent } from "./MapEventContext";
import { SEPARATOR, EDIT_MODE, REMOVE_MODE, DRAWING_MODE, GEOMETRY_POINT } from "./mapConstants";
import { guid } from "../../utils/functions";
import ReactDOM from "react-dom";
import { getReversedCoordinates } from "./utils";

require("leaflet-draw-drag");

const polygonDrawSetting = {
    allowIntersection: false, // Restricts shapes to simple polygons
    drawError: {
        color: "#e1e100", // Color the shape will turn when intersects
        message: "<strong>Oh snap!<strong> you can't draw that!", // Message that will show when intersect
    },
    shapeOptions: {
        color: "red",
    },
};

const _ProjectFeatureGroup = ({ project, isSelected, isHovered }) => {
    let {
        selectedProjectIds = [],
        onSelectedProjectsChange,
        editMode,
        removeMode,
        coordsLatLn = false,
        setHoveredProjectId,
        tooltip,
        highlightedBlockMap,
    } = useMapEvent();

    if (!project) {
        return null;
    }

    const renderTooltip = (e, project, block, isDisplay) => {
        if (!tooltip) return;

        const element = tooltip.element;

        if (!isDisplay) {
            element.style.display = "none";
            return;
        }

        const event = e.originalEvent;
        const { pageX, pageY } = event ?? {};

        let x = pageX + 30;

        let y = pageY - element.getBoundingClientRect().height - 30;

        ReactDOM.render(
            <>
                <h3 className="project-title">{project.project_title}</h3>
                <p>
                    Project description: <strong className="project-description">{project.description}</strong>
                </p>
                <p>
                    Block id: <strong className="block-id">{block[ID_ATTRIBUTE]}</strong>
                </p>
            </>,
            element
        );

        element.style.left = `${x}px`;
        element.style.top = `${y}px`;
        element.style.display = "inline-block";
    };

    const featureGroup = (project.blocks ?? []).map((block, index) => {
        const currentLatLnFormat = coordsLatLn ?? true;

        if (!currentLatLnFormat) {
            const geometry = block.geometry;

            const coordinates = getReversedCoordinates(geometry);

            const newGeometry = {
                type: geometry.type,
                coordinates,
            };

            block.geometry = newGeometry;
        }

        return (
            <BlockPolygon
                block={block}
                geometryType={block.geometry.type}
                key={block[ID_ATTRIBUTE]}
                isSelected={isSelected}
                isHighlighted={highlightedBlockMap[block[ID_ATTRIBUTE]] ? true : false}
                isHovered={isHovered}
                draggable={isSelected && editMode === EDIT_MODE.START}
                onClick={(e, block) => {
                    // can't select if edit is not in the stop mode
                    if (editMode !== EDIT_MODE.STOP || removeMode !== REMOVE_MODE.STOP) return;

                    const isSelected =
                        (selectedProjectIds ?? []).filter((id) => id === project[ID_ATTRIBUTE]).length > 0;
                    if (isSelected) {
                        const blockIds = ((project ?? {}).blocks ?? []).map((block) => block[ID_ATTRIBUTE]);
                        onSelectedProjectsChange &&
                            onSelectedProjectsChange([project[ID_ATTRIBUTE]], blockIds ?? [], false);
                    } else {
                        const blockIds = ((project ?? {}).blocks ?? []).map((block) => block[ID_ATTRIBUTE]);
                        onSelectedProjectsChange &&
                            onSelectedProjectsChange([project[ID_ATTRIBUTE]], blockIds ?? [], true);
                    }
                }}
                onMouseOver={(e, block) => {
                    renderTooltip(e, project, block, true);
                    setHoveredProjectId(project[ID_ATTRIBUTE]);
                }}
                onMouseMove={(e, block) => {
                    renderTooltip(e, project, block, true);
                }}
                onMouseOut={(e, block) => {
                    renderTooltip(e, project, block, false);
                    setHoveredProjectId(null);
                }}
            />
        );
    });

    return featureGroup;
};

export const ProjectFeatureGroup = React.memo(
    _ProjectFeatureGroup,
    (
        { project: prevProject, isSelected: prevIsSelected, isHovered: prevIsHovered },
        { project: nextProject, isSelected: nextIsSelected, isHovered: nextIsHovered }
    ) => {
        return (
            BaseORMModel.getVersionId(prevProject ?? {}) === BaseORMModel.getVersionId(nextProject ?? {}) &&
            prevIsSelected === nextIsSelected &&
            prevIsHovered === nextIsHovered
        );
    }
);

export const ProjectsFeatureGroup = ({ projects = [] }) => {
    let {
        selectedProjectIdsMap = {},
        selectedProjectIds,
        hoveredProjectId,
        couldEdit,
        setEditMode,
        setRemoveMode,
        onBlockEdit,
        onBlockCreate,
        onBlockDelete,
        setDrawingMode,
    } = useMapEvent();
    const selectedProjects = (projects ?? []).filter((project) => selectedProjectIdsMap[project[ID_ATTRIBUTE]]);
    const nonSelectedProjects = (projects ?? []).filter((project) => !selectedProjectIdsMap[project[ID_ATTRIBUTE]]);

    const onEdited = useMemo(() => {
        return (e) => {
            setEditMode(EDIT_MODE.EDIT);

            const layers = e.layers.getLayers();

            const blocks = (layers ?? [])
                .map((layer) => {
                    const { options } = layer ?? {};

                    if (options && options.metadata) {
                        const { geometry } = layer.toGeoJSON();

                        const { [ID_ATTRIBUTE]: blockId, ...rest } = options.metadata ?? {};

                        return {
                            [ID_ATTRIBUTE]: blockId,
                            ...(rest ?? {}),
                            geometry: {
                                ...(geometry ?? {}),
                            },
                        };
                    }

                    return null;
                })
                .filter((d) => d);

            onBlockEdit && onBlockEdit(blocks);
        };
    }, [`SEL${SEPARATOR}${BaseORMModel.getVersionId(selectedProjects ?? [])}`]);

    const onEditStart = useMemo(() => {
        return () => {
            setEditMode(EDIT_MODE.START);
        };
    }, [`SEL${SEPARATOR}${BaseORMModel.getVersionId(selectedProjects ?? [])}`]);

    const onEditStop = useMemo(() => {
        return () => {
            setEditMode(EDIT_MODE.STOP);
        };
    }, [`SEL${SEPARATOR}${BaseORMModel.getVersionId(selectedProjects ?? [])}`]);

    const onDeleteStart = useMemo(() => {
        return () => {
            setRemoveMode(REMOVE_MODE.START);
        };
    }, [`SEL${SEPARATOR}${BaseORMModel.getVersionId(selectedProjects ?? [])}`]);

    const onDeleteStop = useMemo(() => {
        return () => {
            setRemoveMode(REMOVE_MODE.STOP);
        };
    }, [`SEL${SEPARATOR}${BaseORMModel.getVersionId(selectedProjects ?? [])}`]);

    const onDrawStart = useMemo(() => {
        return () => {
            setDrawingMode(DRAWING_MODE.START);
        };
    }, [`SEL${SEPARATOR}${BaseORMModel.getVersionId(selectedProjects ?? [])}`]);

    const onDrawStop = useMemo(() => {
        return () => {
            setDrawingMode(DRAWING_MODE.STOP);
        };
    }, [`SEL${SEPARATOR}${BaseORMModel.getVersionId(selectedProjects ?? [])}`]);

    const onDeleted = useMemo(() => {
        return (e) => {
            setRemoveMode(REMOVE_MODE.REMOVE);

            const layers = e.layers.getLayers();
            const blocks = (layers ?? [])
                .map((layer) => {
                    const { options } = layer ?? {};

                    if (options && options.metadata) {
                        const { [ID_ATTRIBUTE]: blockId } = options.metadata ?? {};

                        return {
                            [ID_ATTRIBUTE]: blockId,
                        };
                    }

                    return null;
                })
                .filter((d) => d);

            onBlockDelete && onBlockDelete(blocks);
        };
    }, [`SEL${SEPARATOR}${BaseORMModel.getVersionId(selectedProjects ?? [])}`]);

    const hasOnlyOneSelectedProject = (selectedProjects) => {
        return selectedProjects && selectedProjects.length === 1;
    };

    const hasOnlyOneSelectedProjectOfIndustry = (selectedProjects, industry) => {
        return hasOnlyOneSelectedProject(selectedProjects) && selectedProjects[0].industry === industry;
    };

    const getMarkerDrawSettings = (selectedProjects) => {
        if (!hasOnlyOneSelectedProjectOfIndustry(selectedProjects, INDUSTRY.GRAZING.key)) {
            return false;
        }

        const selectedProject = selectedProjects[0];

        if (selectedProject.blocks && selectedProject.blocks.some((block) => block.geometry.type === GEOMETRY_POINT)) {
            return false;
        }

        return polygonDrawSetting;
    };

    return (
        <>
            <FeatureGroup key={`SEL${SEPARATOR}${BaseORMModel.getVersionId(selectedProjects ?? [])}`}>
                {couldEdit && (
                    <EditControl
                        position="topleft"
                        onCreated={(e) => {
                            if (!selectedProjects || selectedProjects.length !== 1) {
                                return;
                            }

                            const project = selectedProjects[0];

                            const { geometry } = e.layer.toGeoJSON();

                            const newBlock = {
                                [ID_ATTRIBUTE]: guid(),
                                properties: {},
                                geometry,
                            };

                            onBlockCreate && onBlockCreate(project[ID_ATTRIBUTE], newBlock);
                        }}
                        // should allow drag, but not select
                        onEditStart={onEditStart}
                        // on edit saved
                        onEdited={onEdited}
                        // should not allow drag, but allow select
                        onEditStop={onEditStop}
                        // should not allow drag and select
                        onDeleteStart={onDeleteStart}
                        // should not allow drag, but allow select
                        onDeleteStop={onDeleteStop}
                        onDeleted={onDeleted}
                        onDrawStart={onDrawStart}
                        onDrawStop={onDrawStop}
                        draw={{
                            polygon: hasOnlyOneSelectedProject(selectedProjects) ? polygonDrawSetting : false,
                            rectangle: false,
                            marker: false,
                            circlemarker: getMarkerDrawSettings(selectedProjects),
                            circle: false,
                            polyline: hasOnlyOneSelectedProjectOfIndustry(selectedProjects, INDUSTRY.GRAZING.key)
                                ? polygonDrawSetting
                                : false,
                        }}
                        edit={{
                            moveMarkers: true,
                        }}
                    />
                )}
                {(selectedProjects ?? []).map((project) => {
                    return (
                        <ProjectFeatureGroup
                            key={project[ID_ATTRIBUTE]}
                            project={project}
                            isSelected={true}
                            isHovered={hoveredProjectId === project[ID_ATTRIBUTE]}
                        />
                    );
                })}
            </FeatureGroup>
            <FeatureGroup>
                {(nonSelectedProjects ?? []).map((project) => {
                    return (
                        <ProjectFeatureGroup
                            key={project[ID_ATTRIBUTE]}
                            project={project}
                            isSelected={false}
                            isHovered={hoveredProjectId === project[ID_ATTRIBUTE]}
                        />
                    );
                })}
            </FeatureGroup>
        </>
    );
};
