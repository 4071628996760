import { HttpMethods, ServerType, Url } from '../utils/constants';
import { UrlBuilder, https } from '../api';
import BaseService from './base.service';

class TokenService extends BaseService {
    constructor(props) {
        super(props);
    }

    async getPublicToken({ model } = { model: null }) {
        const url = UrlBuilder(ServerType.Authentication, Url.PublicTokenPath);
        const data = await https({ url, method: HttpMethods.Get });
        return (data !== null) ? data.token : null;
    }
}

export default TokenService;