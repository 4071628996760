import { HttpMethods, ServerType, Url } from "../utils/constants";
import { UrlBuilder, https } from "../api";
import BaseService from "./base.service";
import { INDUSTRY } from "../utils/constants";

const GBR_MASK_TYPE = "gbr";

class MapMaskCache {
    constructor() {
        this.masks = {
            [GBR_MASK_TYPE]: null,
            [INDUSTRY.CANE.key]: null,
            [INDUSTRY.BANANAS.key]: null,
            [INDUSTRY.GRAIN.key]: null,
            [INDUSTRY.GRAZING.key]: null,
        };
    }

    set(maskType, mask) {
        this.masks[maskType] = mask;
    }

    get(maskType) {
        return this.masks[maskType];
    }
}

class MapService extends BaseService {
    constructor(props) {
        super(props);

        this.maskCache = new MapMaskCache();
    }

    getInvertionCoordinatesFromFeatureType(feature) {
        switch (feature.geometry.type) {
            default:
            case "Polygon":
                return [
                    [-180, 90],
                    [180, 90],
                    [180, -90],
                    [-180, -90],
                ];

            case "MultiPolygon":
                return [
                    [
                        [-180, 90],
                        [180, 90],
                        [180, -90],
                        [-180, -90],
                        [-180, 90],
                    ],
                ];
        }
    }

    invertGeoPolygon(geo) {
        try {
            const feature = geo.features[0];

            const mask = this.getInvertionCoordinatesFromFeatureType(feature);

            feature.geometry.coordinates = [mask, ...feature.geometry.coordinates];

            return geo;
        } catch (ex) {
            console.log(ex);
        }
    }

    async getMask(maskType) {
        if (!maskType) {
            maskType = GBR_MASK_TYPE;
        }

        let mask = this.maskCache.get(maskType);

        if (!mask) {
            const url = UrlBuilder(ServerType.Data, Url.GetMask, [maskType]);

            mask = await https({ url, method: HttpMethods.Get, data: {} });

            const inverted = this.invertGeoPolygon(mask);

            this.maskCache.set(maskType, inverted);
        }

        return mask;
    }
}

export default MapService;
