import React, { useMemo, useState } from "react";
import merge from "lodash/merge";
import flattenDeep from "lodash/flattenDeep";
import { EVENT_CHANNEL, INDUSTRY } from "../../utils/constants";
import { useP2RAuth } from "../AuthContextProvider/authContext";
import { EventEmitter } from "../EventEmitter";
import {
    ID_ATTRIBUTE,
    BANANA_SURVEY_VERSION,
    LATEST_SURVEY_VERSION,
    GRAIN_SURVEY_VERSION,
    CANE_SURVEY_VERSION,
    GRAZING_SURVEY_VERSION,
} from "../../utils/miscellaneous";
import { useForm, Controller } from "react-hook-form";
import { ToggleButton } from "../ToggleButton";
import "./CollectionImport.scss";

const UNKNOWN = "Unknown";

const industryItems = [
    { [ID_ATTRIBUTE]: INDUSTRY.CANE.key, className: "btn ", label: "Cane", disabled: false, default: true },
    { [ID_ATTRIBUTE]: INDUSTRY.BANANAS.key, className: "btn ", label: "Bananas", disabled: false },
    { [ID_ATTRIBUTE]: INDUSTRY.GRAIN.key, className: "btn ", label: "Grain", disabled: false },
    { [ID_ATTRIBUTE]: INDUSTRY.GRAZING.key, className: "btn ", label: "Grazing", disabled: false },
];

const surveyVersionSelectionItemsByIndustry = {
    [INDUSTRY.BANANAS.key]: {
        defaultVersion: BANANA_SURVEY_VERSION.VERSION_3_0,
        items: [
            {
                [ID_ATTRIBUTE]: BANANA_SURVEY_VERSION.VERSION_2,
                className: "btn button-version",
                label: "Old questions (pre-2018)",
                disabled: false,
            },
            {
                [ID_ATTRIBUTE]: BANANA_SURVEY_VERSION.VERSION_3_0,
                className: "btn button-version",
                label: "New questions (post-2018)",
                disabled: false,
            },
        ],
    },
};

const CollectionImport = ({ setModalClose, projects, blobDetails, file, selectedColId, selectedGroupId }) => {
    const { profile } = useP2RAuth();
    const { handleSubmit, control, errors, watch } = useForm();
    const [isSubmitting, setSubmitting] = useState(false);
    const selectedIndustry = watch("industry");

    const industryCountMap = useMemo(() => {
        const PROJECT_INDUSTRY_MAP = {};

        [...Object.values(INDUSTRY), { key: UNKNOWN }].forEach((industry) => {
            PROJECT_INDUSTRY_MAP[industry.key] = {
                projects: [],
                unknown: [],
            };
        });

        return projects.reduce((result, project) => {
            const { industry, isUnknown } = project;

            if (isUnknown || !industry) {
                result[UNKNOWN].unknown.push(project);
                return result;
            }

            result[industry].projects.push(project);

            return result;
        }, PROJECT_INDUSTRY_MAP);
    }, []);

    const handleCloseColImport = (e) => {
        setModalClose && setModalClose();
    };

    const handleUploadSubmit = async (data, e) => {
        setSubmitting(true);

        const { industry: industryToPatch, unknownProjectVersion } = data;

        handleCloseColImport(e);

        const patchedProjects = Object.keys(industryCountMap).map((industry) => {
            const industryMap = industryCountMap[industry];

            // Add valid projects for industry
            const projects = [...industryMap.projects];

            if (industry === INDUSTRY.CANE.key)
                industryMap.unknown.forEach((caneProjUnknown) => {
                    projects.push(merge({}, caneProjUnknown, { surveyVersion: CANE_SURVEY_VERSION.VERSION_3_0 }));
                });

            if (industry === INDUSTRY.BANANAS.key)
                industryMap.unknown.forEach((bananaProjUnknown) => {
                    projects.push(merge({}, bananaProjUnknown, { surveyVersion: BANANA_SURVEY_VERSION.VERSION_3_0 }));
                });

            if (industry === INDUSTRY.GRAIN.key) {
                industryMap.unknown.forEach((grainProjUnknown) => {
                    return merge({}, grainProjUnknown, { surveyVersion: GRAIN_SURVEY_VERSION.VERSION_1_0 });
                });
            }

            if (industry === INDUSTRY.GRAZING.key) {
                industryMap.unknown.forEach((grazingProjUnknown) => {
                    return merge({}, grazingProjUnknown, { surveyVersion: GRAZING_SURVEY_VERSION.VERSION_1_0 });
                });
            }

            if (industry === UNKNOWN) {
                industryMap.unknown.forEach((unknownProj) => {
                    projects.push(
                        merge({}, unknownProj, {
                            industry: industryToPatch,
                            surveyVersion: unknownProjectVersion ?? LATEST_SURVEY_VERSION[industryToPatch],
                        })
                    );
                });
            }

            return projects;
        });

        const fileNameSplit = file.name.split(".");
        const extension = fileNameSplit.pop();
        const name = fileNameSplit.join(".");

        // Settime out to give the import modal enough time to close
        setTimeout(() => {
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COMPLETE_COLLECTION_UPLOAD, {
                payload: {
                    uploadPayload: {
                        blobName: blobDetails.blobName,
                        collectionId: null,
                        fileMeta: {
                            extension,
                            name,
                        },
                        groupId: selectedGroupId, // From workspace,
                        projects: flattenDeep(patchedProjects),
                    },
                    group: profile.selectedGroup,
                },
            });
            setSubmitting(false);
        }, 550);
    };

    const hasUnknownProjects = industryCountMap[UNKNOWN].unknown.length > 0;

    return (
        <form className="collection-import-form" onSubmit={handleSubmit(handleUploadSubmit)}>
            <div className="collection-import-modal">
                <div className="content">
                    <h3>Projects detected</h3>

                    {/* Valid banana projects */}
                    {industryCountMap[INDUSTRY.BANANAS.key].projects.length > 0 && (
                        <p>
                            <strong>{industryCountMap[INDUSTRY.BANANAS.key].projects.length}</strong> x banana projects
                        </p>
                    )}

                    {/* Valid cane projects */}
                    {industryCountMap[INDUSTRY.CANE.key].projects.length > 0 && (
                        <p>
                            <strong>{industryCountMap[INDUSTRY.CANE.key].projects.length}</strong> x cane projects
                        </p>
                    )}

                    {/* Valid grain projects */}
                    {industryCountMap[INDUSTRY.GRAIN.key].projects.length > 0 && (
                        <p>
                            <strong>{industryCountMap[INDUSTRY.GRAIN.key].projects.length}</strong> x grain projects
                        </p>
                    )}

                    {/* Valid grazing projects */}
                    {industryCountMap[INDUSTRY.GRAZING.key].projects.length > 0 && (
                        <p>
                            <strong>{industryCountMap[INDUSTRY.GRAZING.key].projects.length}</strong> x grazing projects
                        </p>
                    )}

                    {/* Unknown projects */}
                    {industryCountMap[UNKNOWN].unknown.length > 0 && (
                        <p>
                            <strong>{industryCountMap[UNKNOWN].unknown.length}</strong> x unknown projects
                        </p>
                    )}

                    {/* No projects */}
                    {!projects.length && (
                        <p>
                            We could not detect any projects in the file you are trying to import. An empty collection
                            with the name
                            {file.name} will be created.
                        </p>
                    )}

                    {hasUnknownProjects && (
                        <>
                            <h3>Select the industry to which the projects relate:</h3>

                            <Controller
                                control={control}
                                name="industry"
                                rules={{
                                    validate: {
                                        existingIndustry: (value) =>
                                            [
                                                INDUSTRY.BANANAS.key,
                                                INDUSTRY.CANE.key,
                                                INDUSTRY.GRAIN.key,
                                                INDUSTRY.GRAZING.key,
                                            ].includes(value) || "You must select a valid industry",
                                    },
                                }}
                                render={({ onChange, onBlur, value }) => (
                                    <ToggleButton
                                        items={industryItems}
                                        selectedItemId={undefined}
                                        onItemSelected={(industry) => {
                                            if (industry) {
                                                const selectedIndustry = industry[ID_ATTRIBUTE];
                                                onChange(selectedIndustry);
                                            }
                                        }}
                                    />
                                )}
                            />

                            <div className="help-block with-errors mb-4">
                                {errors.industry && errors.industry.message}
                            </div>

                            {selectedIndustry && surveyVersionSelectionItemsByIndustry[selectedIndustry] && (
                                <>
                                    <h3>Select the survey version matching the projects</h3>

                                    <Controller
                                        control={control}
                                        name="unknownProjectVersion"
                                        render={({ onChange, onBlur, value }) => (
                                            <ToggleButton
                                                items={surveyVersionSelectionItemsByIndustry[selectedIndustry].items}
                                                selectedItemId={
                                                    surveyVersionSelectionItemsByIndustry[selectedIndustry]
                                                        .defaultVersion
                                                }
                                                onItemSelected={(version) => {
                                                    if (version) {
                                                        onChange(version[ID_ATTRIBUTE]);
                                                    } else {
                                                        onChange(null);
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    <div className="help-block with-errors mb-4">
                                        {errors.unknownProjectVersion && errors.unknownProjectVersion.message}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="action-container">
                    <button type="button" className="btn btn-default" onClick={handleCloseColImport}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        Import collection
                    </button>
                </div>
            </div>
        </form>
    );
};

export default CollectionImport;
