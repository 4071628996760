import { createSelector as ormCreateSelector } from "redux-orm";
import orm from "../models/orm.register";
import { ORM_WORKSPACE_SLICE, ID_ATTRIBUTE, WORKSPACE_ID } from "../utils/constants";
import _ from "lodash";

export const ALL_PROJECTS = [
    {
        selectedCollection: [
            {
                projects: ["blocks"],
            },
        ],
    },
];

export const SELECTED_PROJECTS = [
    {
        selectedProjects: ["blocks"],
    },
];

export const HIGHLIGHTED_PROJECTS = [
    {
        highlightedProjects: ["blocks"],
    },
    {
        highlightedBlocks: [],
    },
];

export const BOUNDS_FIT_PROJECTS = [
    {
        boundsFitProject: [],
    },
    {
        boundsFitBlock: [],
    },
];

export const EVERY_THING_INCLUDE = [...ALL_PROJECTS];

export const ALL_PROJECTS_INCLUDE = [...ALL_PROJECTS];

export const SELECTION_INCLUDE = [...SELECTED_PROJECTS];

export const HIGHLIGHT_INCLUDE = [...HIGHLIGHTED_PROJECTS];

export const BOUND_FIT_INCLUDE = [...BOUNDS_FIT_PROJECTS];

export const getAppData = (include) => {
    return ormCreateSelector(orm, (session) => {
        const { [ORM_WORKSPACE_SLICE]: Workspace } = session;

        const workspace =
            (Workspace.select(session, {
                include,
                filter: { deleted: false, [ID_ATTRIBUTE]: WORKSPACE_ID },
            }) ?? [])[0] ?? {};

        // filter selected projects
        const projects = ((workspace ?? {}).selectedCollection ?? {}).projects ?? [];
        const selectedProjects = projects.filter(
            (project) => ((workspace ?? {}).selectedProjectIds ?? {})[project[ID_ATTRIBUTE]]
        );

        (workspace ?? {}).selectedProjects = selectedProjects;

        const selectedBlocks = _.flattenDeep((selectedProjects ?? []).map((project) => project.blocks ?? []));

        (workspace ?? {}).selectedBlocks = selectedBlocks;

        return workspace;
    });
};

export const SHARED_SELECTOR = {
    EVERY_THING: getAppData(EVERY_THING_INCLUDE),
    ALL_PROJECTS: getAppData(ALL_PROJECTS_INCLUDE),
    SELECTION: getAppData(SELECTION_INCLUDE),
    HIGHLIGHT: getAppData(HIGHLIGHT_INCLUDE),
    BOUND_FIT: getAppData(BOUND_FIT_INCLUDE),
};
