import React, { useState, useEffect } from "react";
import { SuccessNotifier } from "../../components/Success";
import { EVENT_CHANNEL, ERROR_CHANNEL, SUCCESS_CHANNEL } from "../../utils/constants";
import { EventEmitter } from "../../components/EventEmitter";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import { serviceWrapper, services } from "../../services";
import Footer from "../../components/Footer";
import { useP2RAuth } from "../../components/AuthContextProvider/authContext";
import { urlQueryParse } from "../../utils/urlParser";
import routes from "../../utils/routes";
import { guid } from "../../utils/functions";
import { Config } from "../../utils/config";

const { userService } = services;

const ConfirmResetPassword = () => {
    const { register, handleSubmit, errors, watch } = useForm();
    const { search } = useLocation();
    const { getPublicToken, setUserToken } = useP2RAuth();
    const [user, setUser] = useState(undefined);

    const history = useHistory();
    const queryToReceive = { t: "" };
    const userToken = urlQueryParse(search, queryToReceive);

    const getUser = async (token) => {
        if (token) {
            await getPublicToken();
            const userPassToReset = await serviceWrapper(
                { model: null },
                {
                    instance: userService,
                    name: "authDecodeToken",
                    params: [token],
                }
            );
            if (userPassToReset) {
                setUser(userPassToReset.data.user);
            } else {
                history.push("/");
            }
        }
    };

    const onSubmit = async (data) => {
        const payload = {
            token: userToken.t,
            password: data.password,
            username: user.email,
        };
        // Acquire public token - this method will set it in localstorage
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_CONFIRM_PASS_RESET, {
            payload,
            getPublicToken,
            setUserToken,
        });
    };

    useEffect(() => {
        if (!userToken) {
            history.push("/");
        } else {
            getUser(userToken.t);
        }
    }, []);

    if (!user) {
        return <p>Loading user ...</p>;
    }

    return (
        <div className="account login">
            <main>
                <div className="container">
                    <h1>P2R Projector </h1>
                    <h2>(Paddock to Reef Project Selector) </h2>
                    <p>{user.email}</p>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(onSubmit)();
                        }}
                        name="form"
                        className="login-form"
                    >
                        <div className="form-group">
                            <input
                                name="password"
                                type="password"
                                className="form-control"
                                defaultValue=""
                                placeholder="Password"
                                ref={register({
                                    required: { value: true, message: "Please enter your password" },
                                    validate: {
                                        passRequirement: (value) =>
                                            /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                value
                                            ) ||
                                            "Password must be 6 to 30 characters and contain at least one each of - uppercase, lowercase, number and special character",
                                    },
                                })}
                                style={{ marginBottom: 10 }}
                            />

                            {errors.password && <div className="help-block with-errors">{errors.password.message}</div>}
                        </div>

                        <div className="form-group">
                            <input
                                name="confirmPassword"
                                type="password"
                                className="form-control"
                                defaultValue=""
                                placeholder="Confirm password"
                                ref={register({
                                    required: { value: true, message: "Confirm password cannot be empty" },
                                    validate: {
                                        confirmPassCheck: (value) =>
                                            value === watch("password") ||
                                            "Confirm password does not match your new password",
                                    },
                                })}
                            />

                            {errors.confirmPassword && (
                                <div className="help-block with-errors">{errors.confirmPassword.message}</div>
                            )}
                        </div>

                        {
                            <SuccessNotifier
                                successChannel={SUCCESS_CHANNEL.USER_CONFIRM_RESET_PASSWORD}
                                errorChannel={ERROR_CHANNEL.CONFIRM_RESET_PASSWORD}
                                startFn={() => {
                                    return <div className="help-block with-errors">Please wait...</div>;
                                }}
                                successFn={() => {
                                    const contents = <div>Your password has been reset successfully.</div>;

                                    EventEmitter.dispatch(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, {
                                        contents,
                                        id: guid(),
                                        autoClose: true,
                                    });

                                    history.push(routes.Home.Route);
                                }}
                                errorFn={(error) => {
                                    return <div className="help-block with-errors">{error.message}</div>;
                                }}
                            />
                        }

                        <button className="btn btn-color ml-0 mt-4">Reset password</button>
                    </form>
                    <div className="row actions">
                        <div className="col-sm">
                            <p>
                                <a href={Config.PUBLIC_APP_URL} target="_blank">Learn more about this tool</a>
                            </p>
                        </div>
                        <div className="col-sm">
                            <p>
                                <a href="/resetPassword">Reset your password</a>
                            </p>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default ConfirmResetPassword;
