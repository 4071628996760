import { services, serviceWrapper } from "../services";
import {
    CREATE_BATCH_ACTION,
    DELETE_ALL_ACTION,
    ORM_COLLECTION_SLICE,
    ORM_PROJECT_SLICE,
    ORM_BLOCK_SLICE,
    EVENT_CHANNEL,
    ORM_WORKSPACE_SLICE,
    SUCCESS_CHANNEL,
    UPDATE_ACTION,
    ID_ATTRIBUTE,
    WORKSPACE_ID,
} from "../utils/constants";
import { store } from "../redux";
import generateORMActionName from "../redux/reducers/orm.action.gen";

const { groupService } = services;

const groupSelected = async (event) => {
    const { payload, errorController } = event ?? {};

    if (payload && payload.id) {
        const collections = await serviceWrapper(
            {
                ...{
                    successChannel: SUCCESS_CHANNEL.COLLECTION_GET_ALL_SUCCESS,
                },
                ...(errorController ?? {}),
            },
            {
                instance: groupService,
                name: "getCollectionsByGroupId",
                params: [payload.id],
            }
        );

        // clean up the data within orm slices
        store.dispatch({
            type: generateORMActionName({
                slice: ORM_BLOCK_SLICE,
                actionName: DELETE_ALL_ACTION,
            }),
            payload: {},
        });

        store.dispatch({
            type: generateORMActionName({
                slice: ORM_PROJECT_SLICE,
                actionName: DELETE_ALL_ACTION,
            }),
            payload: {},
        });

        store.dispatch({
            type: generateORMActionName({
                slice: ORM_COLLECTION_SLICE,
                actionName: DELETE_ALL_ACTION,
            }),
            payload: {},
        });

        store.dispatch({
            type: generateORMActionName({
                slice: ORM_WORKSPACE_SLICE,
                actionName: UPDATE_ACTION,
            }),
            payload: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
                selectedCollectionId: null,
                selectedProjectIds: {},
                highlightedProjectIds: {},
                highlightedBlockIds: {},
                editingSourceProjectId: null,
                editingProjectId: null,
                newProjectId: null,
                collectionReadOnly: false,
                selectedGroupId: payload.id
            },
        });

        // store new collection data
        store.dispatch({
            type: generateORMActionName({
                slice: ORM_COLLECTION_SLICE,
                actionName: CREATE_BATCH_ACTION,
            }),
            payload: collections,
        });
    }
};

const groupEventsDesc = [
    {
        event: EVENT_CHANNEL.EVENT_GROUP_SELECTED,
        handler: groupSelected,
    },
];

export default groupEventsDesc;
