import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload } from "@fortawesome/pro-solid-svg-icons/faCloudUpload";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkedAlt";
import { EventEmitter } from "../EventEmitter";
import { EVENT_CHANNEL, ID_ATTRIBUTE } from "../../utils/constants";
import "./VisualReport.module.scss";

const EmptyCollectionInstruction = ({ collection, readOnly }) => {

    const handleAddProject = (e) => {
        // Event to create skeleton project detail
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_CREATE_OR_EDIT_PROJECT, {
            payload: {
                collectionId: collection[ID_ATTRIBUTE],
                projectId: undefined
            }
        });
    };

    return <div className="report instruction">
        <div className="header">
            <div>
                <FontAwesomeIcon
                    className="icon"
                    icon={faMapMarkedAlt}
                />
            </div>
            <div>
                <h1>Add projects</h1>
            </div>
        </div>

        <button
            className="btn btn-as-text"
            onClick={handleAddProject}
            disabled={readOnly}
        >
            <p className={`upload-link ${readOnly ? 'disabled' : ''}`} title="Add project">Add a new project
            <FontAwesomeIcon
                    className="icon"
                    icon={faPlus}
                />
            </p>
        </button>
    </div>;
};

export default EmptyCollectionInstruction;

