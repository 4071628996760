/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
import leafletStyles from "../../../node_modules/leaflet/dist/leaflet.css";
import styles from "./LeafletMap2.module.scss";
import { Map, LayersControl } from "react-leaflet";
import L from "leaflet";

import _ from "lodash";
import BaseORMModel from "../../models/BaseORMModel";
import { ID_ATTRIBUTE, EVENT_CHANNEL } from "../../utils/constants";
import { ProjectsFeatureGroup } from "./ProjectFeatureGroup";
import { LeafletBaseLayers } from "./LeafletBaseLayers";
import { selectAllActionGen, toolbarGen, deSelectAllActionGen } from "./mapToolbar";
import { MapEventContextProvider } from "./MapEventContext";

import variables from "./mapVariables.scss";
import { MAP_OPERATION_MODE } from ".";
import {
    SELECT_ALL_TOOLBAR,
    SEPARATOR,
    EDIT_MODE,
    REMOVE_MODE,
    DRAWING_MODE,
    MAP_BOUNDS_WINDOW_PROPS_NAME,
    REPAINT_TILES_ON_UPDATE,
    GEOMETRY_LINE_STRING_TYPE,
    GEOMETRY_POLYGON_TYPE,
    GEOMETRY_POINT,
} from "./mapConstants";
import { guid, extractCSSVar } from "../../utils/functions";
import { EventHanlders, EventEmitter } from "../EventEmitter";
import grid from "../../../src/Leaflet.VectorGrid";
import { stat } from "fs";
import { services, serviceWrapper } from "../../services";
import { features } from "process";
import { getReversedCoordinates } from "./utils";

const { mapService } = services;

const BLOCK_STYLES_KEY = "blockStyles";
const BLOCK_ERROR_STYLES_KEY = "blockErrorStyles";
const BLOCK_SELECTED_STYLES_KEY = "blockSelectedStyles";
const BLOCK_HOVERED_STYLES_KEY = "blockHoveredStyles";
const BLOCK_HIGHLIGHTED_STYLES_KEY = "blockHighlightedStyles";
const MASK_LAYER_STYLES_KEY = "maskLayerStyles";

const blockStyles = extractCSSVar(variables, BLOCK_STYLES_KEY);
const blockConflictStyles = extractCSSVar(variables, BLOCK_ERROR_STYLES_KEY);
const blockSelectedStyles = extractCSSVar(variables, BLOCK_SELECTED_STYLES_KEY);
const blockHoveredStyles = extractCSSVar(variables, BLOCK_HOVERED_STYLES_KEY);
const blockHighlightedStyles = extractCSSVar(variables, BLOCK_HIGHLIGHTED_STYLES_KEY);
const maskLayerStyles = extractCSSVar(variables, MASK_LAYER_STYLES_KEY);

L.drawLocal.edit.toolbar.buttons.editDisabled = "Select at least a project to edit blocks";
L.drawLocal.edit.toolbar.buttons.edit = "Edit blocks";
L.drawLocal.edit.toolbar.buttons.removeDisabled = "Select at least a project to delete blocks";
L.drawLocal.edit.toolbar.buttons.remove = "Delete blocks";
L.drawLocal.edit.handlers.edit.tooltip.text = "Drag handles or markers to edit blocks.";
L.drawLocal.edit.handlers.remove.tooltip.text = "Click on a block to remove.";

L.drawLocal.draw.toolbar.buttons.polygon = "Draw a block for the selected project";
L.drawLocal.draw.toolbar.buttons.polyline = "Draw a line for the selected project";
L.drawLocal.draw.toolbar.buttons.circlemarker = "Set a marker for the selected project";

const { BaseLayer, Overlay } = LayersControl;

L.EditToolbar.Delete.include({
    removeAllLayers: false,
});

const ZOOM = 5;
const MAX_ZOOM = 18;
const CENTER = [-25.2871, 147.69];
const MAX_BOUNDS = [
    [-8.624472107633936, 153.984375],
    [-28.07652055985696, 140.2734375],
];

/**
 * The map should have those properties
 * @property {Array<ProjectORMModel>} projects - an array of projects
 * @property {Array<string>} selectedProjectIds - an array of projectId strings
 * @property {Array<string>} highlightedBlockIds - an array of block id strings
 * @property {MAP_OPERATION_MODE} mode - decide if editing is allowed on the map
 * @property {SELECT_ALL_TOOLBAR} selectAllMode - decide if select all toolbar should be shown
 * @property { (selectedProjectIds: Array<string>)=>{} } onSelectedProjects - (removed) An event when projects are selected
 * @property { (ids: Array<string>, selected: boolean)=>{} } onSelectedProjectsChange - An event when project selection updates are triggered
 * @property {(projectId: string, block: BlockORMModel)=>{}} onBlockCreate - An event when a block is created
 * @property {(blocks: Array<BlockORMModel>)=>{}} onBlockEdit - An event when a block is edited
 * @property {(blocks: Array<BlockORMModel>)=>{}} onBlockDelete - An event when a block is deleted
 */
export const LeafletMap = ({
    projects: projectsAsProp = [],
    selectedProjectIds: selectedProjectIdsAsProp = [],
    highlightedBlockIds: highlightedBlockIdsAsProp = [],
    mode = MAP_OPERATION_MODE.READ_ONLY,
    selectAllMode = SELECT_ALL_TOOLBAR.ON,
    mainMapBoundsWindowPropName = MAP_BOUNDS_WINDOW_PROPS_NAME,
    repaintTilesOnUpdate = REPAINT_TILES_ON_UPDATE,
    onSelectedProjectsChange = () => {},
    onBlockCreate = () => {},
    onBlockEdit = () => {},
    onBlockDelete = () => {},
    maskType = null,
}) => {
    projectsAsProp = projectsAsProp ?? [];
    selectedProjectIdsAsProp = selectedProjectIdsAsProp ?? [];
    highlightedBlockIdsAsProp = highlightedBlockIdsAsProp ?? [];

    // projects is a dictionary, keyed by id
    const [firstFitBoundsDone, setFirstFitBoundsDone] = useState(
        window[mainMapBoundsWindowPropName] !== null && window[mainMapBoundsWindowPropName] !== undefined
    );
    const [projects, setProjects] = useState({ ids: [], map: {}, featureMap: {} });
    const [selectedProjectIds, setSelectedProjectIds] = useState([]);
    const [highlightedBlockMap, setHighlightedBlockMap] = useState({});

    const [hoveredProjectId, setHoveredProjectId] = useState(null);
    const [editMode, setEditMode] = useState(EDIT_MODE.STOP);
    const [removeMode, setRemoveMode] = useState(REMOVE_MODE.STOP);
    const [drawingMode, setDrawingMode] = useState(DRAWING_MODE.STOP);
    const [tooltip, setTooltip] = useState(null);
    const maskData = useRef(null);

    const loadMask = useMemo(() => {
        return async () => {
            let collectionData = await serviceWrapper(
                {},
                {
                    instance: mapService,
                    name: "getMask",
                    params: [maskType],
                }
            );

            maskData.current = collectionData;

            setForceRender({});
        };
    }, []);

    const [forceRender, setForceRender] = useState({});

    const mapControl = useRef(null);
    const tileLayer = useRef(null);
    const mapWrapper = useRef(null);

    const projectHoveredId = useRef(null);

    const onBlockSelect = useRef(() => {});
    const getBlockStatus = useRef(() => {});

    const previousSelectedProjectIds = useRef([]);
    const previousHighlightedBlockIds = useRef([]);

    // setup when map is ready
    useEffect(() => {
        let tooltipDiv = null;

        const tooltipId = guid();

        tooltipDiv = document.createElement("div");
        tooltipDiv.id = tooltipId;
        tooltipDiv.style.display = "none";
        tooltipDiv.style.position = "absolute";
        tooltipDiv.className = "map-tooltip";

        document.body.appendChild(tooltipDiv);

        setTooltip({
            id: tooltipId,
            element: tooltipDiv,
        });

        return () => {
            window[mainMapBoundsWindowPropName] = mapControl.current.getBounds();

            mapControl.current = null;
            maskData.current = null;

            tooltipDiv && document.body.removeChild(tooltipDiv);
        };
    }, []);

    useEffect(() => {
        if (maskData.current && mapControl.current) {
            L.geoJSON(maskData.current, {
                style: {
                    ...maskLayerStyles,
                    ...{ stroke: false },
                },
            }).addTo(mapControl.current);
        } else if (!maskData.current && mapControl.current) {
            loadMask();
        }
    }, [mapControl.current, maskData.current]);

    useEffect(() => {
        setProjects(({ ids, map: projectsMap, featureMap }) => {
            // convert to internal lat lon (external should be lon lat structures)
            // when the version is different
            projectsAsProp = (projectsAsProp ?? [])
                .map((projectAsProp) => {
                    if (!projectAsProp[ID_ATTRIBUTE]) return null;

                    if (
                        projectsMap[projectAsProp[ID_ATTRIBUTE]] &&
                        BaseORMModel.getVersionId(projectsMap[projectAsProp[ID_ATTRIBUTE]]) ===
                            BaseORMModel.getVersionId(projectAsProp[ID_ATTRIBUTE])
                    )
                        return projectAsProp;

                    let { blocks, ...projectRest } = projectAsProp;
                    blocks = (blocks ?? [])
                        .map((block) => {
                            let { geometry, ...rest } = block;

                            if (
                                !geometry ||
                                (geometry.type !== GEOMETRY_POLYGON_TYPE &&
                                    geometry.type !== GEOMETRY_LINE_STRING_TYPE &&
                                    geometry.type !== GEOMETRY_POINT)
                            ) {
                                return null;
                            }

                            const coordinates = geometry.coordinates ?? [];

                            const reverseCoordinates = getReversedCoordinates(geometry);

                            // build the featureMap
                            featureMap[block[ID_ATTRIBUTE]] = {
                                type: "Feature",
                                geometry: {
                                    type: geometry.type,
                                    coordinates,
                                },
                                properties: {
                                    ...(rest ?? {}),
                                    project: projectRest,
                                },
                            };

                            return {
                                ...rest,
                                ...{
                                    geometry: {
                                        type: geometry.type,
                                        coordinates: reverseCoordinates,
                                    },
                                },
                            };
                        })
                        .filter((d) => d);

                    return {
                        ...projectRest,
                        blocks,
                    };
                })
                .filter((d) => d);

            const blocks = _.flattenDeep((projectsAsProp ?? []).map((d) => d.blocks ?? []));
            const blockMap = _.keyBy(blocks, (block) => block[ID_ATTRIBUTE]);

            for (let blockId in featureMap) {
                if (!blockMap[blockId]) {
                    delete featureMap[blockId];
                }
            }

            return {
                map: _.keyBy(projectsAsProp ?? [], (d) => d[ID_ATTRIBUTE]),
                ids: (projectsAsProp ?? []).map((d) => d[ID_ATTRIBUTE]),
                featureMap,
            };
        });
    }, [BaseORMModel.getVersionId(projectsAsProp)]);

    useEffect(() => {
        setSelectedProjectIds(selectedProjectIdsAsProp);
    }, [_.join(selectedProjectIdsAsProp ?? [], SEPARATOR)]);

    useEffect(() => {
        // generate map for easy lookup within the map component below
        const highlightedBlockMap = _.keyBy(
            (highlightedBlockIdsAsProp ?? []).map((id) => ({ [ID_ATTRIBUTE]: id })),
            (d) => d[ID_ATTRIBUTE]
        );
        setHighlightedBlockMap(highlightedBlockMap);
    }, [_.join(highlightedBlockIdsAsProp ?? [], SEPARATOR)]);

    // control set bounds event
    const eventHandlers = useRef(new EventHanlders());
    useEffect(() => {
        eventHandlers.current.addEvents(EVENT_CHANNEL.EVENT_CMD_MAP_SET_EXTENT, async (event) => {
            const { payload: bounds } = event;

            if (bounds && mapControl && mapControl.current) {
                // this function exists to execute when the page scrolls,
                // aiming to zoom into the block/project extent when the page has finished
                // scrolling to the top
                const el = document.getElementById("app-map");

                // listen/wait for the element to be in the viewport of the screen,
                // once it is we can then zoom in on the map!
                // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
                const options = { rootMargin: "0px", threshold: 1.0 };
                new IntersectionObserver(function (entries) {
                    if (entries.find((e) => e.intersectionRatio === 1) !== undefined) {
                        mapControl.current.fitBounds(bounds);
                        this.unobserve(el);
                        this.disconnect();
                    }
                }, options).observe(el);

                // scroll the map into view
                window.requestAnimationFrame(() => {
                    el.scrollIntoView({ behavior: "smooth", block: "center" });
                });
            }
        });

        EventEmitter.subscribe(
            EVENT_CHANNEL.EVENT_CMD_MAP_SET_EXTENT,
            eventHandlers.current.events[EVENT_CHANNEL.EVENT_CMD_MAP_SET_EXTENT]
        );

        return () => {
            EventEmitter.off(
                EVENT_CHANNEL.EVENT_CMD_MAP_SET_EXTENT,
                eventHandlers.current.events[EVENT_CHANNEL.EVENT_CMD_MAP_SET_EXTENT]
            );
        };
    }, []);

    let { ids: projectIds, map: projectMap, featureMap } = projects ?? {};

    projectIds = projectIds ?? [];
    projectMap = projectMap ?? {};

    const validSelectedProjectIds = (selectedProjectIds ?? []).filter(
        (selectedProjectId) => projectMap[selectedProjectId]
    );

    const couldDrawProjectId =
        mode === MAP_OPERATION_MODE.EDIT && (validSelectedProjectIds ?? []).length === 1
            ? validSelectedProjectIds[0]
            : null;

    const selectAll = selectAllActionGen(
        _.debounce(
            () => {
                // select all projects

                if (editMode !== EDIT_MODE.STOP || removeMode !== REMOVE_MODE.STOP || drawingMode !== DRAWING_MODE.STOP)
                    return;

                const blockIds = Object.keys(featureMap ?? {});

                onSelectedProjectsChange && onSelectedProjectsChange(projectIds ?? [], blockIds, true);
            },
            0,
            {
                leading: false,
                trailing: true,
            }
        )
    );

    const deselectAll = deSelectAllActionGen(
        _.debounce(
            () => {
                if (editMode !== EDIT_MODE.STOP || removeMode !== REMOVE_MODE.STOP || drawingMode !== DRAWING_MODE.STOP)
                    return;

                const blockIds = Object.keys(featureMap ?? {});

                onSelectedProjectsChange && onSelectedProjectsChange(projectIds ?? [], blockIds, false);
            },
            0,
            {
                leading: false,
                trailing: true,
            }
        )
    );

    const addControl = (map, actions) => {
        if (selectAllMode === SELECT_ALL_TOOLBAR.ON) {
            map &&
                projectIds.length > 0 &&
                map.addControl(
                    toolbarGen({
                        className: `select-toolbar  ${
                            editMode !== EDIT_MODE.STOP ||
                            removeMode !== REMOVE_MODE.STOP ||
                            drawingMode !== DRAWING_MODE.STOP
                                ? "disabled"
                                : ""
                        }`,
                        actions,
                    })
                );
        } else if (map && map._toolbars) {
            Object.values(map._toolbars).forEach((toolbar) => {
                map.removeControl(toolbar);
            });
        }
    };

    useEffect(() => {
        if (mapControl && mapControl.current) {
            // create a toolbar based on the number of selected projects
            const toolbarAction =
                (validSelectedProjectIds ?? []).length === projectIds.length ? deselectAll : selectAll;
            addControl(mapControl.current, [toolbarAction]);
        }
    });

    const validSelectedProjectIdsMap = {};

    (validSelectedProjectIds ?? []).forEach((id) => (validSelectedProjectIdsMap[id] = 1));

    const getStyles = useMemo(
        () => (properties, isSelected = false, isHovered = false, isHighlighted = false, isEdit = false) => {
            const isError = ((properties ?? {}).conflictProperties ?? {}).conflict ?? false;

            return {
                ...(blockStyles ?? {}),
                ...(isError ? blockConflictStyles ?? {} : {}),
                ...(isSelected ? blockSelectedStyles ?? {} : {}),
                ...(isHovered ? blockHoveredStyles ?? {} : {}),
                ...(isHighlighted ? blockHighlightedStyles ?? {} : {}),
                ...(isEdit
                    ? {
                          fillOpacity: 0,
                          stroke: false,
                      }
                    : {}),
            };
        },
        []
    );

    const renderTooltip = useMemo(() => {
        return (e, project, block, isDisplay) => {
            if (!tooltip) return;

            const element = tooltip.element;

            if (!isDisplay) {
                element.style.display = "none";
                return;
            }

            const event = e.originalEvent;
            const { pageX, pageY } = event ?? {};

            let x = pageX + 30;
            let y = pageY - element.getBoundingClientRect().height - 30;

            ReactDOM.render(
                <>
                    <h3 className="project-title">{project.project_title}</h3>
                    <p>
                        Project description: <strong className="project-description">{project.description}</strong>
                    </p>
                    <p>
                        Block id: <strong className="block-id">{block[ID_ATTRIBUTE]}</strong>
                    </p>
                </>,
                element
            );

            element.style.left = `${x}px`;
            element.style.top = `${y}px`;
            element.style.display = "inline-block";
        };
    }, [tooltip]);

    useEffect(() => {
        onBlockSelect.current = (currentProjectId) => {
            // can't select if edit is not in the stop mode
            if (editMode !== EDIT_MODE.STOP || removeMode !== REMOVE_MODE.STOP) return;

            if (!projectMap[currentProjectId]) return;

            const blocks = projectMap[currentProjectId].blocks ?? [];
            const blockIds = (blocks ?? []).map((block) => block[ID_ATTRIBUTE]);

            const isSelected = (validSelectedProjectIds ?? []).filter((id) => id === currentProjectId).length > 0;
            if (isSelected) {
                onSelectedProjectsChange && onSelectedProjectsChange([currentProjectId], blockIds, false);
            } else {
                onSelectedProjectsChange && onSelectedProjectsChange([currentProjectId], blockIds, true);
            }
        };
    }, [
        onSelectedProjectsChange,
        validSelectedProjectIds,
        editMode,
        removeMode,
        BaseORMModel.getVersionId(Object.values(projectMap ?? {})),
    ]);

    useEffect(() => {
        getBlockStatus.current = (block, projectId) => {
            const status = {};
            if (validSelectedProjectIdsMap[projectId]) status.selected = true;

            if (highlightedBlockMap[block[ID_ATTRIBUTE]]) status.highlighted = true;

            status.error = ((block ?? {}).conflictProperties ?? {}).conflict ?? false;

            status.hovered = projectHoveredId.current === projectId;

            status.edit = validSelectedProjectIds.length === 1 && status.selected ? true : false;

            return status;
        };
    }, [_.join(validSelectedProjectIds ?? [], SEPARATOR), highlightedBlockMap, projectHoveredId.current]);

    const featureProps = _.values(featureMap ?? {}).map((feature) => feature.properties);

    useEffect(() => {
        if (mapControl.current && (repaintTilesOnUpdate || (!repaintTilesOnUpdate && !tileLayer.current))) {
            const map = mapControl.current;

            const fcol = {
                type: "FeatureCollection",
                features: _.values(featureMap ?? {}) ?? [],
            };

            const projectBlockMap = _.groupBy(
                Object.values(featureMap ?? {}),
                (f) => ((f.properties ?? {}).project ?? {})[ID_ATTRIBUTE]
            );

            // tile layer would hide currently editing project
            const newTileLayer = L.vectorGrid
                .slicer(fcol, {
                    rendererFactory: L.canvas.tile,
                    vectorTileLayerStyles: {
                        sliced: (properties) => {
                            const status = getBlockStatus.current(properties, (properties.project ?? {})[ID_ATTRIBUTE]);
                            return getStyles(
                                properties,
                                status.selected,
                                status.hovered,
                                status.highlighted,
                                status.edit
                            );
                        },
                    },
                    maxZoom: 22,
                    indexMaxZoom: 5, // max zoom in the initial tile index
                    interactive: true,
                    getFeatureId: (block) => {
                        return block.properties[ID_ATTRIBUTE];
                    },
                })
                .addTo(map);

            if (tileLayer && tileLayer.current) {
                map.removeLayer(tileLayer.current);
            }

            tileLayer.current = newTileLayer;

            newTileLayer
                .on("mouseover", (e) => {
                    const properties = e.layer.properties;
                    properties.hovered = true;
                    projectHoveredId.current = (properties.project ?? {})[ID_ATTRIBUTE];

                    // blockHoveredId.current = properties[ID_ATTRIBUTE];
                    const blocks = projectBlockMap[(properties.project ?? {})[ID_ATTRIBUTE]] ?? [];
                    let isEditing = false;

                    blocks.forEach((block) => {
                        const blockProperties = block.properties;
                        const status = getBlockStatus.current(
                            blockProperties,
                            (blockProperties.project ?? {})[ID_ATTRIBUTE]
                        );

                        // if one block is being edited, the project is being edited
                        if (status.edit) isEditing = true;

                        const styles = getStyles(
                            blockProperties,
                            status.selected,
                            status.hovered,
                            status.highlighted,
                            status.edit
                        );
                        newTileLayer.setFeatureStyle(blockProperties[ID_ATTRIBUTE], styles);
                    });

                    if (!isEditing) {
                        renderTooltip(e, properties.project, properties, true);
                    }
                })
                .on("mousemove", (e) => {
                    const properties = e.layer.properties;
                    properties.hovered = true;
                    projectHoveredId.current = (properties.project ?? {})[ID_ATTRIBUTE];

                    const blocks = projectBlockMap[(properties.project ?? {})[ID_ATTRIBUTE]] ?? [];
                    let isEditing = false;

                    blocks.forEach((block) => {
                        const blockProperties = block.properties;
                        const status = getBlockStatus.current(
                            blockProperties,
                            (blockProperties.project ?? {})[ID_ATTRIBUTE]
                        );

                        // if one block is being edited, the project is being edited
                        if (status.edit) isEditing = true;
                    });

                    if (!isEditing) renderTooltip(e, properties.project, properties, true);
                })
                .on("mouseout", (e) => {
                    const properties = e.layer.properties;
                    properties.hovered = false;
                    projectHoveredId.current = null;

                    // blockHoveredId.current = null;
                    const blocks = projectBlockMap[(properties.project ?? {})[ID_ATTRIBUTE]] ?? [];
                    let isEditing = false;

                    blocks.forEach((block) => {
                        const blockProperties = block.properties;
                        const status = getBlockStatus.current(
                            blockProperties,
                            (blockProperties.project ?? {})[ID_ATTRIBUTE]
                        );

                        // if one block is being edited, the project is being edited
                        if (status.edit) isEditing = true;

                        const styles = getStyles(
                            blockProperties,
                            status.selected,
                            status.hovered,
                            status.highlighted,
                            status.edit
                        );
                        newTileLayer.setFeatureStyle(blockProperties[ID_ATTRIBUTE], styles);
                    });

                    if (!isEditing) renderTooltip(e, properties.project, properties, false);
                })
                .on("click", (e) => {
                    const properties = e.layer.properties;
                    onBlockSelect.current((properties.project ?? {})[ID_ATTRIBUTE]);
                });
        }
    }, [BaseORMModel.getVersionId(featureProps ?? []), mapControl.current]);

    // set the first bounds
    useEffect(() => {
        const features = Object.values(featureMap ?? {}) ?? [];
        if (mapControl.current && !firstFitBoundsDone && features.length && !window[mainMapBoundsWindowPropName]) {
            const fCol = {
                type: "FeatureCollection",
                features,
            };

            const geoLayer = L.geoJSON(fCol);

            const bounds = geoLayer.getBounds();
            mapControl.current.fitBounds(bounds);

            setFirstFitBoundsDone(true);
        }
    }, [BaseORMModel.getVersionId(featureProps ?? []), mapControl.current, firstFitBoundsDone]);

    useEffect(() => {
        if (mapControl.current && window[mainMapBoundsWindowPropName]) {
            mapControl.current.fitBounds(window[mainMapBoundsWindowPropName]);
        }
    }, [mapControl.current]);

    useEffect(() => {
        if (tileLayer && tileLayer.current) {
            const shouldSelectedProjIds = _.difference(
                validSelectedProjectIds ?? [],
                previousSelectedProjectIds.current ?? []
            );
            const shouldUnselectedProjIds = _.difference(
                previousSelectedProjectIds.current ?? [],
                validSelectedProjectIds ?? []
            );

            const currentSelectedProjIds = _.intersection(
                validSelectedProjectIds ?? [],
                previousSelectedProjectIds.current ?? []
            );

            const shouldSelectedBlocks = _.flattenDeep(
                (shouldSelectedProjIds ?? [])
                    .map((projectId) => {
                        return projectMap[projectId];
                    })
                    .filter((d) => d)
                    .map((project) =>
                        (project.blocks ?? []).map((block) => {
                            return {
                                ...(block ?? {}),
                                projectId: project[ID_ATTRIBUTE],
                            };
                        })
                    )
            );

            const shouldUnselectedBlocks = _.flattenDeep(
                (shouldUnselectedProjIds ?? [])
                    .map((projectId) => {
                        return projectMap[projectId];
                    })
                    .filter((d) => d)
                    .map((project) =>
                        (project.blocks ?? []).map((block) => {
                            return {
                                ...(block ?? {}),
                                projectId: project[ID_ATTRIBUTE],
                            };
                        })
                    )
            );

            shouldSelectedBlocks.forEach((block) => {
                const status = getBlockStatus.current(block, block.projectId);

                const styles = getStyles(block, true, status.hovered, status.highlighted, status.edit);
                tileLayer.current.setFeatureStyle(block[ID_ATTRIBUTE], styles);
            });

            shouldUnselectedBlocks.forEach((block) => {
                const status = getBlockStatus.current(block, block.projectId);

                const styles = getStyles(block, false, status.hovered, status.highlighted, status.edit);
                tileLayer.current.setFeatureStyle(block[ID_ATTRIBUTE], styles);
            });

            if (
                previousSelectedProjectIds.current &&
                previousSelectedProjectIds.current.length !== 1 &&
                validSelectedProjectIds.length === 1
            ) {
                currentSelectedProjIds
                    .map((projectId) => projectMap[projectId])
                    .filter((d) => d)
                    .forEach((project) =>
                        (project.blocks ?? []).forEach((block) => {
                            const status = getBlockStatus.current(block, project[ID_ATTRIBUTE]);

                            const styles = getStyles(block, true, status.hovered, status.highlighted, true);
                            tileLayer.current.setFeatureStyle(block[ID_ATTRIBUTE], styles);
                        })
                    );
            } else if (
                previousSelectedProjectIds.current &&
                previousSelectedProjectIds.current.length === 1 &&
                validSelectedProjectIds.length !== 1
            ) {
                currentSelectedProjIds
                    .map((projectId) => projectMap[projectId])
                    .filter((d) => d)
                    .forEach((project) =>
                        (project.blocks ?? []).forEach((block) => {
                            const status = getBlockStatus.current(block, project[ID_ATTRIBUTE]);

                            const styles = getStyles(block, true, status.hovered, status.highlighted, false);
                            tileLayer.current.setFeatureStyle(block[ID_ATTRIBUTE], styles);
                        })
                    );
            }
        }

        previousSelectedProjectIds.current = validSelectedProjectIds;
    }, [_.join(validSelectedProjectIds, SEPARATOR)]);

    useEffect(() => {
        const highlightedBlockIds = Object.keys(highlightedBlockMap) ?? [];

        if (tileLayer && tileLayer.current) {
            const shouldHighlightedBlockIds = _.difference(
                highlightedBlockIds ?? [],
                previousHighlightedBlockIds.current ?? []
            );
            const shouldUnhighlightedBlockIds = _.difference(
                previousHighlightedBlockIds.current ?? [],
                highlightedBlockIds ?? []
            );

            const shouldHighlightedBlocks = (shouldHighlightedBlockIds ?? [])
                .map((blockId) => {
                    return (featureMap[blockId] ?? {}).properties;
                })
                .filter((d) => d);

            const shouldUnhighlightedBlocks = (shouldUnhighlightedBlockIds ?? [])
                .map((blockId) => {
                    return (featureMap[blockId] ?? {}).properties;
                })
                .filter((d) => d);

            shouldHighlightedBlocks.forEach((block) => {
                const status = getBlockStatus.current(block, block.project[ID_ATTRIBUTE]);

                const styles = getStyles(block, status.selected, status.hovered, true, status.edit);
                tileLayer.current.setFeatureStyle(block[ID_ATTRIBUTE], styles);
            });

            shouldUnhighlightedBlocks.forEach((block) => {
                const status = getBlockStatus.current(block, block.project[ID_ATTRIBUTE]);

                const styles = getStyles(block, status.selected, status.hovered, false, status.edit);
                tileLayer.current.setFeatureStyle(block[ID_ATTRIBUTE], styles);
            });
        }

        previousHighlightedBlockIds.current = highlightedBlockIds;
    }, [highlightedBlockMap]);

    return (
        <div className="leaflet-map">
            <MapEventContextProvider
                value={{
                    couldDrawProjectId,
                    couldEdit: mode,
                    hoveredProjectId: hoveredProjectId && projectMap[hoveredProjectId] ? hoveredProjectId : null,
                    setHoveredProjectId,
                    selectedProjectIds: validSelectedProjectIds ?? [],
                    highlightedBlockMap,
                    selectedProjectIdsMap: validSelectedProjectIdsMap,
                    onSelectedProjectsChange,
                    onBlockEdit,
                    onBlockCreate,
                    onBlockDelete,
                    editMode,
                    setEditMode,
                    removeMode,
                    setRemoveMode,
                    setDrawingMode,
                    tooltip,
                    coordsLatLn: true,
                }}
            >
                <Map
                    id="app-map"
                    zoom={ZOOM}
                    center={CENTER}
                    maxBounds={MAX_BOUNDS}
                    className="app-map"
                    whenReady={(e) => {
                        if (e && e.target) {
                            const map = e.target;

                            mapControl.current = map;

                            map.setMinZoom(ZOOM);
                            map.setMaxZoom(MAX_ZOOM);

                            addControl(map, [selectAll]);

                            setForceRender({});
                        }
                    }}
                >
                    <LeafletBaseLayers />

                    <ProjectsFeatureGroup
                        projects={
                            validSelectedProjectIds.length !== 1
                                ? []
                                : (validSelectedProjectIds ?? []).map((id) => projectMap[id]).filter((d) => d)
                        }
                    />
                </Map>
            </MapEventContextProvider>
        </div>
    );
};
