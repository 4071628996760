import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { MDBTooltip } from "mdbreact";
import React from "react";
import { connect } from "react-redux";
import { guid } from "../../utils/functions";
import BaseORMModel from "../../models/BaseORMModel";
import { EVENT_CHANNEL, ID_ATTRIBUTE } from "../../utils/constants";
import { EventEmitter } from "../EventEmitter";
import { mapStateToPropsOfActions } from "./projectBlockSelector";
import "./ProjectTable.module.scss";

const TOOLTIP_TITLE = "Create new projects from highlighted blocks";

const canCopyBlocks = (selectedProject, selectedBlocks) => {
    if (!selectedProject) {
        return false;
    }

    //Check that we would still have at least 1 block in the selected project
    // if we were to remove the selected blocks from the project
    //This makes sure that a project cannot be made empty
    if (selectedProject.blocks.length - selectedBlocks.length <= 0) {
        return false;
    }

    return true;
};

const __BatchBlockProjectCreateAction = ({
    blocks = [],
    selectedProjectMap = {},
    selectedBlockMap = {},
    readOnly = false,
    selectedCollection = null,
}) => {
    let shouldEnabled = false;

    blocks = blocks ?? [];

    const projectIds = _.uniqBy(blocks.map((block) => block.projectId));

    shouldEnabled =
        projectIds && projectIds.length === 1 && canCopyBlocks(selectedProjectMap[projectIds[0]], blocks)
            ? true
            : false;

    shouldEnabled = shouldEnabled && !readOnly;

    const project = selectedProjectMap[projectIds[0]] ?? {};

    const createNewProjectWithBlocks = async () => {
        const { [ID_ATTRIBUTE]: collectionId } = selectedCollection;

        // take our block(s), clone it and add the project id to it
        const newBlocks = blocks.map((block) => {
            const b = _.cloneDeep(block);
            b[ID_ATTRIBUTE] = guid();
            b.existing_block = false;
            delete b.dateCreated;
            delete b.dateUpdated;
            return b;
        });

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_CREATE_OR_EDIT_PROJECT, {
            payload: {
                collectionId,
                collectionReadOnly: false,
                blocks: newBlocks,
                projectId: project[ID_ATTRIBUTE],
                isNew: true,
                isCopy: true,
            },
        });
    };

    return (
        <MDBTooltip domElement placement="bottom" style={{}}>
            <span>
                <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={`icon ${!shouldEnabled ? "disabled" : ""}`}
                    disabled={!shouldEnabled}
                    onClick={(shouldEnabled && createNewProjectWithBlocks) || undefined}
                />
            </span>
            <div>{TOOLTIP_TITLE}</div>
        </MDBTooltip>
    );
};

export const _BatchBlockProjectCreateAction = React.memo(
    __BatchBlockProjectCreateAction,
    (
        { projects: prevProjects, selectedCollection: prevCollection, readOnly: prevReadonly, blocks: prevBlocks },
        { projects: nextProjects, selectedCollection: nextCollection, readOnly: nextReadonly, blocks: nextBlocks }
    ) => {
        return (
            BaseORMModel.getVersionId(prevProjects ?? []) === BaseORMModel.getVersionId(nextProjects ?? []) &&
            BaseORMModel.getVersionId(prevBlocks ?? []) === BaseORMModel.getVersionId(nextBlocks ?? []) &&
            BaseORMModel.getVersionId(prevCollection ?? {}) === BaseORMModel.getVersionId(nextCollection ?? {}) &&
            prevReadonly === nextReadonly
        );
    }
);

const BatchBlockProjectCreateAction = connect(mapStateToPropsOfActions)(_BatchBlockProjectCreateAction);

export default BatchBlockProjectCreateAction;
