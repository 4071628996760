import React from 'react';

const FormWizardContext = React.createContext();
const useFormWizard = () => React.useContext(FormWizardContext);
const FormWizardContextProvider = (props) => {
    return (
        <FormWizardContext.Provider
            value={props.value}
        >
            {props.children}
        </FormWizardContext.Provider>
    );
};

export { useFormWizard, FormWizardContextProvider };
