import React, { useState, useEffect, useRef } from 'react';
import { SURVEY_ANSWER_BEFORE_AFTER, SURVEY_QUESTION_TYPES, DEF_SELECTED_ANS } from '../../utils/constants';
import PropTypes from 'prop-types';
import "./multiplechoice.scss";
import { keyValueStringContent } from '../../utils/functions';
import { Radio } from '../CheckboxOrRadio';
import { useFormWizard } from '../FormWizard/FormWizardContext';

const {
    BEFORE: { label: beforeLabel, key: beforeKey },
    AFTER: { label: afterLabel, key: afterKey },
} = SURVEY_ANSWER_BEFORE_AFTER;

const ON_ANS_CHANGE_DEF = () => { };

const MultipleChoice = ({
    question,
    onAnswerChange = ON_ANS_CHANGE_DEF,
    onBlur,
    selectedAnswerMap = DEF_SELECTED_ANS,
    beforeOrAfterVisibility
}) => {
    const { readOnly } = useFormWizard();
    const { responses = [], id: questionId, visible } = question;
    const answerMapRef = useRef(selectedAnswerMap);
    const [answerMap, setAnswerMap] = useState(selectedAnswerMap);
    const possibleResponses = responses.map(response => response.id);

    /*
        Function to handle the updating of the 'answers' state with the selected checkboxes
    */
    const handleAnswerChange = (questionId, responseId) => {
        setAnswerMap((prevAnswer) => {
            let newAnswerState = {
                ...prevAnswer,
                [questionId]: responseId
            };

            // Select both after and before if after is not selected/invalid and is visible
            if (questionId === beforeKey && beforeOrAfterVisibility[beforeKey] && (!possibleResponses.includes(prevAnswer[afterKey] || !prevAnswer[afterKey]))) {
                Object.assign(newAnswerState, { [afterKey]: responseId });
            }

            return newAnswerState;
        });
    };

    /*
        Call onChange prop function on answer change
    */
    useEffect(() => {
        if (keyValueStringContent(answerMapRef.current) !== keyValueStringContent(answerMap)) {
            onAnswerChange && onAnswerChange(answerMap);
            answerMapRef.current = answerMap;
        }
    }, [answerMap]);

    // If question has false property of visible, don't display it ever.
    if (!visible) return null;

    return (
        <>
            <div className="d-flex">
                <div className="radio-category-container">
                    <label className="">{beforeLabel}</label>
                </div>
                <div className="radio-category-container">
                    <label className="">{afterLabel}</label>
                </div>
            </div>
            {responses.map((response) => {
                const { description, id: responseId } = response;
                return (
                    <div className="d-flex response" key={responseId}>
                        {Object.values(SURVEY_ANSWER_BEFORE_AFTER).map(({ key: beforeOrAfter }) => {
                            const checked = answerMap && answerMap[beforeOrAfter] === responseId;
                            const beforeOrAfterVisible = beforeOrAfterVisibility[beforeOrAfter];

                            if (beforeOrAfterVisible) {
                                return (
                                    <div className="radio-container" key={`${beforeOrAfter}_${responseId}`}>
                                        <div className="custom-control custom-radio">
                                            <Radio
                                                className="custom-control-input"
                                                name={`${beforeOrAfter}_${questionId}`}
                                                id={`${beforeOrAfter}-${responseId}`}
                                                value={responseId}
                                                onChange={(e) => {
                                                    handleAnswerChange(beforeOrAfter, e.target.value);
                                                }}
                                                checked={checked}
                                                disabled={readOnly}
                                            />
                                            {/* <input
                                                className="custom-control-input"
                                                type="radio"
                                                name={`${category}_${questionId}`}
                                                id={`${category}-${responseId}`}
                                                value={responseId}
                                                onChange={handleAnswerChange(category, responseId)}
                                                checked={checked}
                                            /> */}
                                            <label className="orange-check-form custom-control-label" htmlFor={`${beforeOrAfter}-${responseId}`} />
                                        </div>
                                    </div>
                                );
                            }

                            // Empty column to create a gap...
                            return <div key={`${beforeOrAfter}_${responseId}_empty`} className="col-1"></div>;

                        })}
                        <div className="flex-grow-1">
                            {description}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

MultipleChoice.propTypes = {
    question: PropTypes.shape({
        responses: PropTypes.array,
        visible: PropTypes.bool, // From the api response... Used to hide questions that the comp does not need.
        description: PropTypes.string,
        type: PropTypes.oneOf([SURVEY_QUESTION_TYPES.NUMERIC, SURVEY_QUESTION_TYPES.MULTIPLECHOICE]).isRequired,
        id: PropTypes.string.isRequired
    }).isRequired,
    onAnswerChange: PropTypes.func,
    onBlur: PropTypes.func,
    selectedAnswerMap: PropTypes.shape({
        [beforeKey]: PropTypes.string,
        [afterKey]: PropTypes.string
    }),
    beforeOrAfterVisibility: PropTypes.shape({
        [beforeKey]: PropTypes.bool,
        [afterKey]: PropTypes.bool
    })
};

function areEquals(prevProps, nextProps) {
    const { beforeOrAfterVisibility: prevVis, selectedAnswerMap: prevSelectedAnswer } = prevProps;
    const { beforeOrAfterVisibility: nextVis, selectedAnswerMap: nextSelectedAnswer } = nextProps;
    return keyValueStringContent(prevVis) === keyValueStringContent(nextVis) &&
        keyValueStringContent(nextSelectedAnswer) === keyValueStringContent(prevSelectedAnswer);
}

export default React.memo(MultipleChoice, areEquals);
