import { Model, ORM, fk, many, attr } from "redux-orm";
// import generateActionName from "../redux/reducers/action.gen";

import BaseORMModel from "./BaseORMModel";
import { ORM_FUNDING_PROGRAM_SLICE, ORM_FUNDING_BODY_SLICE, ID_ATTRIBUTE } from "../utils/constants";

export default class FundingProgramORMModel extends BaseORMModel { }
FundingProgramORMModel.modelName = ORM_FUNDING_PROGRAM_SLICE;
FundingProgramORMModel.fields = {
    [ID_ATTRIBUTE]: attr(),

    projectReferenceNumber: attr(),
    projectTitle: attr(),
    proponentCodes: attr(),

    fundingBody: fk({
        to: ORM_FUNDING_BODY_SLICE,
        relatedName: "fundingPrograms"
    })
};