import { HttpMethods, ServerType, Url } from '../utils/constants';
import { UrlBuilder, httpsLoopbackQuery } from '../api';
import BaseService from './base.service';

class SoilClimateDataService extends BaseService {
    constructor(props) {
        super(props);
    }

    async getRegions() {
        let url = UrlBuilder(ServerType.LB, Url.GetNodeTypes);

        const filters = {
            "where": {
               "type": "region"
           },
           "include": ["nodes", "region"]
       };

        return await httpsLoopbackQuery ({
            url, 
            method: HttpMethods.Get, 
            filters: filters,                        
        });
    }

    async getNodeChildren(parentId){
        let url = UrlBuilder(ServerType.LB, Url.GetNodeChildren, [parentId]);

        const filters = {
            "include": [
                {
                    "relation": "nodeType",
                    "scope": {
                        "include": ["region", "catchment", "climate", "soil"]
                    }
                }
            ]
        };

        return await httpsLoopbackQuery ({
            url, 
            method: HttpMethods.Get, 
            filters: filters,                        
        });
    }
}

export default SoilClimateDataService;