import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { GROUP_SLICE, UPDATE_ACTION, MAX_FIELD_LENGTH, ID_ATTRIBUTE } from '../../utils/constants';
import { services, serviceWrapper } from '../../services';
import generateActionName from '../../redux/reducers/action.gen';
import AutocompleteMultiSelect from '../../components/AutocompleteMultiSelect/AutocompleteMultiSelect';
import { useSelector } from 'react-redux';
const { groupService } = services;

const RemoveGroupMemberModal = ({ setModalClose, userEmail, callback }) => {
    const closeModal = () => setModalClose && setModalClose();

    return (
        <div className="group-modal-form">
            <p>
                Are you sure you want to remove <strong>
                    { userEmail } </strong> from the group?
            </p>
            <div className="mt-4 pt-4 mb-3 form-button-container d-flex justify-content-center">
                <button
                    type="button"
                    className="btn btn-default"
                    onClick={closeModal}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        callback();
                        setModalClose && setModalClose();
                    }}
                >
                    Yes, remove member
                </button>
            </div>
        </div>
        // <form onSubmit={handleSubmit(onSubmit)} className="pl-3 pr-3 w-100">
        //     <div className="form-group pb-3">
        //         <label>Name</label>
        //         <input name="groupName" className="form-control" defaultValue={selectedGroup.name} ref={register({
        //             required: { value: true, message: "You must give a name for the group" },
        //             maxLength: { value: MAX_FIELD_LENGTH, message: "Group name can only consist of 100 characters" }
        //         })} />
        //         {errors.groupName &&
        //             <div className="help-block with-errors">{errors.groupName.message}</div>}
        //     </div>
        //     <div>
        //         <label>Parent groups</label>
        //         <AutocompleteMultiSelect selectedValues={groupReceivers} options={groups} onChange={onChangeOfSelectedGroups} />
        //     </div>
        //     <div className="form-button-container d-flex justify-content-center">
        //         <button type="button" className="btn btn-sm cancel-btn" onClick={(e) => setModalClose && setModalClose()}>Cancel</button>
        //         <button type="submit" className="btn btn-sm submit-btn">Save</button>
        //     </div>
        // </form>
    );
};

export default RemoveGroupMemberModal;
