import React from "react";
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './searchbox.scss';

const SearchBox = ({ onChange, keyword, autoFocus = false }) => {
    return (
        <div>
            <div className="form-group has-search">
                <span className=" form-control-feedback">
                    <FontAwesomeIcon className='search-icon' icon={faSearch} />
                </span>
                <input type="text" className="form-control"
                    onChange={(e) => { onChange(e.target.value); }}
                    // onBlur={(e)=>{
                    //     onChange(e.target.value);
                    // }}
                    value={keyword} placeholder="Find a collection"
                    autoFocus={autoFocus}
                />
            </div>
        </div>
    );
};

export default SearchBox;
