import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from "@fortawesome/pro-solid-svg-icons/faCloudUpload";
import { EventEmitter } from '../EventEmitter';
import { EVENT_CHANNEL, SUCCESS_CHANNEL, ID_ATTRIBUTE } from '../../utils/constants';
import { serviceWrapper, services } from '../../services';

const { collectionService } = services;

const ImportCollectionMenuItem = ({ selectedColId, selectedGroupId }) => {

    const inputFile = useRef(null);

    const handleFileChange = async (e) => {

        const files = e.target.files;
        const blobDetails = await serviceWrapper({}, {
            instance: collectionService,
            name: "getBlobDetails",
            params: []
        });

        if (!files.length) throw new Error("No file selected");

        if (!blobDetails) throw new Error("Blob was not generated");

        const file = files[0];

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COLLECTION_UPLOAD, {
            payload: {
                blobDetails,
                file: file,
                selectedColId,
                selectedGroupId
            }
        });
    };

    return (
        <div
            className="blue-header"
            title={"Import a collection from file (GeoJSON or shapefile)"}
            onClick={(e) => {
                // Just incase slow network, and profile data doesn't come from parent yet...
                if (selectedGroupId)
                    inputFile.current.click();
            }}
        >
            <span>Import a collection</span>
            <FontAwesomeIcon icon={faCloudUpload} />
            <input
                type='file'
                ref={inputFile}
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
        </div>
    );

};

export default ImportCollectionMenuItem;