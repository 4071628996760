import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdbreact";
import PropTypes from 'prop-types';
import React from 'react';
import { downloadDetailedResults, downloadGeoJson, downloadSummary } from '../../utils/export';
import './toolbar.scss';

// Button disabled all the time for now
const CollectionExport = ({ collectionId, collectionReadOnly }) => {
    return (
        // <button className="btn app-btn app-btn-primary app-btn-med ml-3">Export</button>
        <MDBDropdown className="export-btn">
            <MDBDropdownToggle
                disabled={!collectionId || collectionReadOnly}
            >
                Export<FontAwesomeIcon icon={faCaretDown} className="icon-text-left-spacing" />
            </MDBDropdownToggle>
            <MDBDropdownMenu right basic className='z-depth-3'>
                <MDBDropdownItem onClick={() => downloadGeoJson(collectionId)}>Spatial data</MDBDropdownItem>
                <MDBDropdownItem onClick={() => downloadDetailedResults()}>Detailed results</MDBDropdownItem>
                <MDBDropdownItem onClick={() => downloadSummary("project")}>Project level summary table</MDBDropdownItem>
                <MDBDropdownItem onClick={() => downloadSummary("block")}>Block level summary table</MDBDropdownItem>
            </MDBDropdownMenu>
        </MDBDropdown>
    );
};

CollectionExport.propTypes = {
    collectionId: PropTypes.string,
    collectionReadOnly: PropTypes.bool
};

export default CollectionExport;