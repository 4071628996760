import React from "react";
import { yupResolver } from "@hookform/resolvers";
import { useForm, Controller } from "react-hook-form";
import { EventEmitter } from "../EventEmitter";
import { ToggleButton } from "../ToggleButton";
import { INDUSTRY, INDUSTRY_BY_KEY, ID_ATTRIBUTE } from "../../utils/constants";
import PropTypes from "prop-types";
import FormHelper from "./helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons/faTimesCircle";
import { useFormWizard } from "./FormWizardContext";
import GrazingPracticePicker from "./GrazingPracticePicker";

const {
    BANANAS: { key: bananaKey, label: bananaLabel },
    CANE: { key: caneKey, label: caneLabel },
    GRAIN: { key: grainKey, label: grainLabel },
    GRAZING: { key: grazingKey, label: grazingLabel },
} = INDUSTRY;

const ProjectDetailForm = ({ project, saveChannel, schema, editing }) => {
    const { readOnly, cancelMethod } = useFormWizard();

    const { register, errors, control, trigger, getValues } = useForm({ resolver: yupResolver(schema) });

    const industryItems = [
        { [ID_ATTRIBUTE]: caneKey, className: "btn", label: "Cane", default: true, disabled: editing },
        { [ID_ATTRIBUTE]: bananaKey, className: "btn", label: "Bananas", disabled: editing },
        { [ID_ATTRIBUTE]: grainKey, className: "btn", label: "Grain", disabled: editing },
        { [ID_ATTRIBUTE]: grazingKey, className: "btn", label: "Grazing", disabled: editing },
    ];

    /** 
        Utility function that updates the redux store to the saveChannel using the data from the form
        @param {Javascript Event} 

        @returns {void} 
    */
    const updateProject = async (e) => {
        await trigger(); // run form validation

        saveChannel &&
            EventEmitter.dispatch(saveChannel, {
                payload: {
                    data: {
                        ...project,
                        ...getValues(),
                    },
                    localOnly: true,
                },
            });
    };

    const renderIndustryComp = (editing, readOnly) => {
        if (editing || readOnly) {
            return (
                <h2 className="mt-4 mb-2">
                    Industry: <strong>{INDUSTRY_BY_KEY[project.industry].label}</strong>
                </h2>
            );
        }

        return (
            <div>
                <h2>Select the industry this project relates to</h2>
                <Controller
                    control={control}
                    name="industry"
                    rules={{
                        validate: {
                            existingIndustry: (value) =>
                                [grainKey, caneKey, bananaKey, grazingKey].includes(value) ||
                                "You must select a valid industry",
                        },
                    }}
                    defaultValue={project.industry ?? ""}
                    render={({ onChange, onBlur, value }) => (
                        <ToggleButton
                            items={industryItems}
                            selectedItemId={project.industry ?? undefined}
                            onItemSelected={(industry) => {
                                const selectedIndustry = (industry ?? {})[ID_ATTRIBUTE];
                                if (selectedIndustry) {
                                    onChange((industry ?? {})[ID_ATTRIBUTE]);
                                    // Update redux store...
                                    updateProject();
                                }
                            }}
                        />
                    )}
                />
                {errors.industry && <div className="help-block with-errors">{errors.industry.message}</div>}
            </div>
        );
    };

    return (
        <>
            <FontAwesomeIcon
                className="close-button"
                onClick={cancelMethod}
                size="1x"
                className={"ml-auto mr-1 modal-close"}
                icon={faTimesCircle}
            />
            <h1 className="text-center">Project details</h1>

            <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                    <input
                        name="project_title"
                        className=""
                        placeholder="Type your project title here (required)"
                        defaultValue={project.project_title ?? ""}
                        ref={register({})}
                        onBlur={updateProject}
                        disabled={readOnly}
                    />

                    {errors.project_title && (
                        <div className="help-block with-errors">{errors.project_title.message}</div>
                    )}

                    <textarea
                        placeholder="Describe the project"
                        name="description"
                        className="mt-4"
                        defaultValue={project.description ?? ""}
                        rows="6"
                        ref={register({})}
                        onBlur={updateProject}
                        disabled={readOnly}
                    />

                    {errors.description && <div className="help-block with-errors">{errors.description.message}</div>}

                    <div className="select-industry">{renderIndustryComp(editing, readOnly)}</div>

                    {project.industry === INDUSTRY.GRAZING.key && (
                        <GrazingPracticePicker project={project} saveChannel={saveChannel} />
                    )}

                    <FormHelper nextText="Provide funding details" />
                </div>
            </form>
        </>
    );
};

ProjectDetailForm.propTypes = {
    project: PropTypes.object, // From redux
    saveChannel: PropTypes.string.isRequired,
    schema: PropTypes.object, // Yup validation (i.e. formSchema.js)
    editing: PropTypes.bool, // are we currently editing a project ?
};

export default React.memo(ProjectDetailForm);
