import { HttpMethods, ServerType, Url } from '../utils/constants';
import { UrlBuilder, https } from '../api';
import BaseService from './base.service';

/**
 * @typedef {Object} UserObject
 * @property {string} _id - User id
 * @property {string} username - User email address
 * @property {string} firstname - User's first name
 * @property {string} lastname - User's last name
 * @property {string} role - User's role name
 * @property {Array} groups - All group that user is a part of
 * @property {boolean}         - A 1-0 status that indiciates if user has accepted invite into P2R
 * @property {string} token - Token that describes the user
 * @property {string} activated_group_id - ""
 */
class UserService extends BaseService {
    constructor(props) {
        super(props);
    }

    /**
     * Service method that grabs the user token
     * @return 
     */
    async getUserByToken() {
        const url = UrlBuilder(ServerType.Authentication, Url.User);
        return await https({ url, method: HttpMethods.Get });
    }

    /**
     * Service method that updates user metadata
     * @param data
     * @return 
     */
    async updateUser(data) {
        const url = UrlBuilder(ServerType.Authentication, Url.User);
        return await https({ url, method: HttpMethods.Put, data });
    }

    /**
     * Service method that grabs user detail from token generatd by resetPassword
     * or register
     * @param {*} token 
     */
    async authDecodeToken(token) {
        let url = UrlBuilder(ServerType.Authentication, Url.AuthTokenDecode, [token]);
        const userRegisterInfo = await https({ url, method: HttpMethods.Get, userRequest: false });
        return userRegisterInfo;
    }

    /**
     * Service method that creates a new user after they have been invited
     * @param {*} data 
     */
    async registerUser(data) {
        let url = UrlBuilder(ServerType.Authentication, Url.RegisterUser);
        const userRegisterInfo = await https({ url, method: HttpMethods.Post, userRequest: false, data });
        return userRegisterInfo;
    }

    /**
     * Service method that authenticates user with username and password using P2R Api.
     * It will return the authenticated user token if authentication was successful otherwise
     * the error from the API.
     * @param credentials Credentials of the user attempting a log in 
     * @param credentials.username Username of the user
     * @param credentials.password Password of the user 
     * @returns {UserObject | undefined} 
     */
    async authenticateUser({ username, password }) {
        const data = {
            username: username ?? "",
            password: password ?? "",
        };
        const url = UrlBuilder(ServerType.Authentication, Url.AdminToken);
        const user = await https({ url, method: HttpMethods.Post, data, userRequest: false });
        return user;
    }

    /**
     * Service method that will change the authenticated user's password
     * @param passwordCredentials Password object of the userw
     * @param credentials.currentPassword Username of the user
     * @param credentials.newPassword Password of the user 
     * @returns {UserObject | undefined}
     */
    async changeUserPassword({ oldPassword, password }) {
        const data =
        {
            oldPassword,
            password
        };
        const url = UrlBuilder(ServerType.Authentication, Url.User);
        const user = await https({ url, method: HttpMethods.Put, data });
        return user;
    }

    /**
     * Service method that will send a reset email password when the user is logged in.
     */
    async resetPassWithUserToken() {
        const url = UrlBuilder(ServerType.Authentication, Url.ResetPassWithUserToken);
        const user = await https({ url, method: HttpMethods.Get });
        console.log(user);
        return user;
    }

    /**
     * Service method that resets the password of a given email
     * @param {*} email 
     */
    async resetPassWithEmail(email) {
        let url = UrlBuilder(ServerType.Authentication, Url.RequestResetPassword, [email]);
        const requestRestPasswordResponse = await https({ url, method: HttpMethods.Get, userRequest: false });
        return requestRestPasswordResponse;
    }

    /**
     * Service method that allow user to reset their password after clicking the email link
     * @param {*} data 
     */
    async confirmResetPassword(data) {
        let url = UrlBuilder(ServerType.Authentication, Url.ResetPassword);
        const resetPassworResponse = await https({ url, method: HttpMethods.Post, data, userRequest: false });
        return resetPassworResponse;
    }
}

export default UserService;