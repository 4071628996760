import * as Model from '../models';

class ModelConverter {
    constructor(props) {
    }

    modelConvert({ model, data }) {

        let modelClass = null;
        if (model !== null && model !== undefined && typeof model === "string" && Model[model])
            modelClass = Model[model];

        if (model !== null && model !== undefined && typeof model === "function")
            modelClass = model;

        const serl = (data) => {
            return modelClass && modelClass.deserialise ? modelClass.deserialise(data) : new modelClass(data);
        };

        const convert = (data) => {
            if (!data)
                return null;


            const isArray = Array.isArray(data);
            if (isArray)
                return data.map(d => {
                    return serl(d);
                });

            return serl(data);
        };

        return !modelClass ? data : (convert(data));
    }

    store(data, store) {
        if (!data)
            return null;

        const isArray = Array.isArray(data);
        if (isArray)
            data.forEach(d => {
                d.reduxPersist({ store });
            });
        else {
            data.reduxPersist({ store });
        }
    }
}

export default ModelConverter;