import React, { useEffect } from "react";
import { faDrawPolygon } from "@fortawesome/pro-solid-svg-icons/faDrawPolygon";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons/faPencilAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { createSelector } from "redux-orm";
import { useForm, Controller } from "react-hook-form";
import BaseORMModel from "../../models/BaseORMModel";
import orm from "../../models/orm.register";
import _ from "lodash";
import { ID_ATTRIBUTE, INDUSTRY, ORM_PROJECT_SLICE } from "../../utils/constants";
import styles from "./BlockCreatingStep.module.scss";
import { areGrazingProjectGeometriesValid } from "./project-geometry-validation";
import DefaultDrawLabel from "./DefaultDrawLabel";
import GrazingDrawLabel from "./GrazingDrawLabel";

const _BlockCreatingStep = ({ project, onValidChange = () => {} }) => {
    const { register, handleSubmit, watch, errors, control, trigger, setValue } = useForm({
        mode: "onChange",
        defaultValues: {
            project: null,
        },
    });

    useEffect(() => {
        setValue("project", project, {
            shouldValidate: true,
        });
    }, [BaseORMModel.getVersionId(project ?? {})]);

    const onSubmit = (data) => {};

    useEffect(() => {
        const hasError = Object.keys(errors ?? {}).length !== 0;
        onValidChange(hasError);
    }, [Object.keys(errors ?? {}).length]);

    const getLabelForIndustry = (industry) => {
        switch (industry) {
            case INDUSTRY.CANE.key:
            case INDUSTRY.BANANAS.key:
            case INDUSTRY.GRAIN.key:
                return DefaultDrawLabel;
            case INDUSTRY.GRAZING.key:
                return GrazingDrawLabel;
            default:
                return DefaultDrawLabel;
        }
    };

    const isProjectValid = (project) => {
        if (!project || !project.blocks || project.blocks.length === 0) {
            return false;
        }

        if (project.industry === INDUSTRY.GRAZING.key) {
            return areGrazingProjectGeometriesValid(project);
        }

        return true;
    };

    if (!project) {
        return null;
    }

    const { project_title: title } = project;

    const DrawLabel = getLabelForIndustry(project.industry);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="report instruction block-create">
                <div className="header">
                    <h1>
                        <FontAwesomeIcon className="icon" icon={faPencilAlt} />
                        {title}
                    </h1>
                </div>

                <DrawLabel />

                <Controller
                    control={control}
                    name="project"
                    render={({ onChange, onBlur, value }) => {
                        return null;
                    }}
                    rules={{
                        validate: {
                            exist: (project) => {
                                if (isProjectValid(project)) {
                                    return true;
                                }

                                return "You must draw blocks to finish the creation";
                            },
                        },
                    }}
                />
            </div>
        </form>
    );
};

const singleProjectSelector = (projectId) => {
    return createSelector(orm, (session) => {
        const { [ORM_PROJECT_SLICE]: Project } = session;

        const project = (Project.select(session, {
            include: [
                {
                    blocks: [],
                },
            ],
            filter: { [ID_ATTRIBUTE]: projectId },
        }) ?? [])[0];

        return project;
    });
};

const mapStateToProps = (state, props) => {
    const { projectId } = props;

    if (projectId) {
        return {
            project: singleProjectSelector(projectId)(state),
            setModalClose: props.setModalClose,
        };
    }

    return null;
};

const BlockCreatingStep = connect(mapStateToProps)(_BlockCreatingStep);

export default BlockCreatingStep;
