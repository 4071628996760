import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { GROUP_SLICE, UPDATE_ACTION, MAX_FIELD_LENGTH, ID_ATTRIBUTE, EVENT_CHANNEL } from "../../utils/constants";
import { services, serviceWrapper } from "../../services";
import generateActionName from "../../redux/reducers/action.gen";
import AutocompleteMultiSelect from "../../components/AutocompleteMultiSelect/AutocompleteMultiSelect";
import { useSelector } from "react-redux";
import { Checkbox } from "../../components/CheckboxOrRadio";
import { EventEmitter } from "../../components/EventEmitter";
import { useP2RAuth } from "../../components/AuthContextProvider/authContext";
import { store } from "../../redux";

const { groupService } = services;

const EditGroupModal = ({ selectedGroup = {}, setModalClose }) => {
    const { profile } = useP2RAuth();
    const { register, handleSubmit, errors } = useForm();
    // const groups = useSelector(state => Object.values(state.group ?? {}).filter((currentGroup) => currentGroup[ID_ATTRIBUTE] !== selectedGroup[ID_ATTRIBUTE]));
    const [groups, setGroups] = useState([]);
    const [groupReceivers, setGroupReceivers] = useState([]);
    const [defaultGroupReceivers, setDefaultReceivers] = useState([]);
    const [allowManualSoilSelection, setAllowManualSoilSelection] = useState(!!selectedGroup.allowManualSoilSelection);

    const selectedGroupId = selectedGroup[ID_ATTRIBUTE];

    const getGroups = async () => {
        if (profile.id) {
            let groupData = await serviceWrapper(
                { model: null },
                {
                    instance: groupService,
                    name: "getGroupsByUserId",
                    params: [profile.id],
                }
            );

            // filter self group from list
            groupData = groupData.filter((g) => g[ID_ATTRIBUTE] !== selectedGroupId);

            if (allowManualSoilSelection) {
                // filter the groups that also have manual soil selection enabled...
                groupData = groupData.filter((g) => g.allowManualSoilSelection);
            }

            setGroups(groupData);
        }
    };

    useEffect(() => {
        getGroups();
    }, [profile.id]);

    /* Fetch the existing parent groups */
    useEffect(() => {
        getGroupReceivers(selectedGroup[ID_ATTRIBUTE]);
    }, []);

    const getGroupReceivers = async () => {
        const groupReceivers = await serviceWrapper(
            { model: null },
            {
                instance: groupService,
                name: "GetGroupReceiversByGroupId",
                params: [selectedGroup[ID_ATTRIBUTE]],
            }
        );

        setGroupReceivers(
            groupReceivers.map((gr) => ({
                label: gr.name,
                value: gr[ID_ATTRIBUTE],
            }))
        );
        setDefaultReceivers(groupReceivers);
    };

    const onChangeOfSelectedGroups = (groups) => {
        setGroupReceivers(groups);
    };

    const onSubmit = async (data) => {
        const updatedGroupResult = await serviceWrapper(
            { model: null },
            {
                instance: groupService,
                name: "updateGroupDetailById",
                params: [{ name: data.groupName, allowManualSoilSelection }, selectedGroup[ID_ATTRIBUTE]],
            }
        );

        /* This will either add or remove some parent groups to the existing group */

        const newReceivers = await serviceWrapper(
            { model: null },
            {
                instance: groupService,
                name: "addReceiversToGroup",
                params: [selectedGroup[ID_ATTRIBUTE], (groupReceivers ?? []).map((group) => group.value)],
            }
        );

        /* If successful, then update the redux store */

        if (updatedGroupResult && newReceivers) {
            const payload = {
                [ID_ATTRIBUTE]: selectedGroup[ID_ATTRIBUTE],
                name: data.groupName,
                allowManualSoilSelection,
            };

            store.dispatch({
                type: generateActionName({ slice: GROUP_SLICE, actionName: UPDATE_ACTION }),
                payload: payload,
            });

            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_GROUP_UPDATED, {});

            profile.updateGroup(payload);

            setModalClose && setModalClose();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="pl-3 pr-3 w-100 group-modal-form">
            <div className="form-group pb-3">
                <label>Name</label>
                <input
                    name="groupName"
                    className="form-control"
                    defaultValue={selectedGroup.name}
                    ref={register({
                        required: { value: true, message: "You must give a name for the group" },
                        maxLength: {
                            value: MAX_FIELD_LENGTH,
                            message: "Group name can only consist of 100 characters",
                        },
                    })}
                />
                {errors.groupName && <div className="help-block with-errors">{errors.groupName.message}</div>}
            </div>

            <div className="form-group pb-3">
                <label>Parent groups</label>
                <AutocompleteMultiSelect
                    selectedValues={defaultGroupReceivers}
                    options={groups}
                    onChange={onChangeOfSelectedGroups}
                />
            </div>

            <div className="form-group pb-3">
                <div className="checkbox-group">
                    <Checkbox
                        className="app-checkbox"
                        id="allowManualSoilSelection"
                        name="allowManualSoilSelection"
                        checked={allowManualSoilSelection}
                        disabled={selectedGroup.allowManualSoilSelection}
                        onChange={(e) => setAllowManualSoilSelection(e.target.checked)}
                    />

                    <label className="ml-2" htmlFor="allowManualSoilSelection">
                        Allow manual soil selection
                    </label>
                </div>

                {!selectedGroup.allowManualSoilSelection && allowManualSoilSelection && (
                    <span className="help-block">*This option cannot be changed later</span>
                )}
            </div>

            <div className="mt-3 mb-3 form-button-container d-flex justify-content-center">
                <button type="button" className="btn btn-default" onClick={() => setModalClose && setModalClose()}>
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                    Save
                </button>
            </div>
        </form>
    );
};

export default EditGroupModal;
