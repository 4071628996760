import Url from "./endpoints";
import { HttpMethods, ServerType } from "./httpConstants";
import { ROLES, PRIVILEGE } from "./permission";
import ERROR_CHANNEL from "./errorChannel";
import SUCCESS_CHANNEL from "./successChannel";
import EVENT_CHANNEL from "./eventChannel";
import {
    COLLECTION_SLICE,
    PROJECT_SLICE,
    ORM_PROJECT_SLICE,
    BLOCK_SLICE,
    GROUP_SLICE,
    ORM_COLLECTION_SLICE,
    ORM_BLOCK_SLICE,
    ORM_COLLECTION_EDITING_STATE_SLICE,
    ORM_WORKSPACE_SLICE,
    ORM_FUNDING_BODY_SLICE,
    ORM_FUNDING_PROGRAM_SLICE,
    ORM_SLICE,
} from "./reduxSlices";
import {
    ACTIONS,
    UPDATE_ACTION,
    CREATE_ACTION,
    DELETE_ACTION,
    DELETE_ADVANCE_ACTION,
    DELETE_SOFT_ACTION,
    CREATE_BATCH_ACTION,
    UPDATE_BATCH_ACTION,
    DELETE_BATCH_ACTION,
    DELETE_SOFT_BATCH_ACTION,
    UPDATE_RELATION_ACTION,
    DELETE_ALL_ACTION,
    DELETE_SOFT_ALL_ACTION,
} from "./reduxActions";

import {
    ID_ATTRIBUTES,
    ID_ATTRIBUTE,
    VERSION_ATTRIBUTE,
    QUERY_VERSION_ATTRIBUTE,
    SOFT_DELETE_ATTRIBUTE,
    DETAIL_DISPLAY_GROUP_NUM,
    MAX_FIELD_LENGTH,
    INDUSTRY,
    SURVEY_QUESTION_TYPES,
    SURVEY_ANSWER_BEFORE_AFTER,
    PAGE_STATUSES,
    WORKSPACE_ID,
    MEASURE_UNITS,
    MEASURE_UNIT_MAP,
    MEASURES,
    MEASURE_VALS,
    MEASURE_MAP,
    NUMERIC_PRINT_FORMAT,
    REDUCTION_PRINT_FORMAT,
    AREA_PRINT_FORMAT,
    DATE_CREATED,
    DATE_UPDATED,
    DEFAULT_SELECTED_OPTION,
    REDUX_ORM_VERSION,
    MODAL_OPEN_CLOSE_STATE,
    DEF_SELECTED_ANS,
    LATEST_SURVEY_VERSION,
    INDUSTRY_BY_KEY,
    INCOMPLETE_SUVEY_MESSAGE,
    PROJECT_ISSUES,
    BLOCK_ISSUES,
    ORM_VERSION_CONTROL_FIELDS,
    GRAZING_PRACTICE_FLAG_QUESTION_ID,
    GRAZING_STREAMBANK_SPECIFIC_QUESTIONS,
} from "./miscellaneous";

import VALIDATION_MESSAGES from "./validationMessages";

const AppGUID = "b0f75c71-27c9-45b2-b97f-3f0bf42cdcff";

export {
    Url,
    AppGUID,
    HttpMethods,
    ServerType,
    ROLES,
    PRIVILEGE,
    ERROR_CHANNEL,
    SUCCESS_CHANNEL,
    EVENT_CHANNEL,
    COLLECTION_SLICE,
    BLOCK_SLICE,
    GROUP_SLICE,
    PROJECT_SLICE,
    ORM_COLLECTION_SLICE,
    ORM_PROJECT_SLICE,
    ORM_BLOCK_SLICE,
    ORM_COLLECTION_EDITING_STATE_SLICE,
    ORM_WORKSPACE_SLICE,
    ORM_FUNDING_BODY_SLICE,
    ORM_FUNDING_PROGRAM_SLICE,
    ORM_SLICE,
    ACTIONS,
    UPDATE_ACTION,
    CREATE_ACTION,
    DELETE_ACTION,
    DELETE_ADVANCE_ACTION,
    DELETE_SOFT_ACTION,
    CREATE_BATCH_ACTION,
    UPDATE_BATCH_ACTION,
    DELETE_BATCH_ACTION,
    DELETE_SOFT_BATCH_ACTION,
    UPDATE_RELATION_ACTION,
    DELETE_ALL_ACTION,
    DELETE_SOFT_ALL_ACTION,
    ID_ATTRIBUTES,
    ID_ATTRIBUTE,
    VERSION_ATTRIBUTE,
    QUERY_VERSION_ATTRIBUTE,
    SOFT_DELETE_ATTRIBUTE,
    DETAIL_DISPLAY_GROUP_NUM,
    MAX_FIELD_LENGTH,
    INDUSTRY,
    INDUSTRY_BY_KEY,
    SURVEY_QUESTION_TYPES,
    SURVEY_ANSWER_BEFORE_AFTER,
    PAGE_STATUSES,
    WORKSPACE_ID,
    MEASURE_UNITS,
    MEASURE_UNIT_MAP,
    MEASURES,
    MEASURE_VALS,
    MEASURE_MAP,
    NUMERIC_PRINT_FORMAT,
    REDUCTION_PRINT_FORMAT,
    AREA_PRINT_FORMAT,
    DATE_CREATED,
    DATE_UPDATED,
    DEFAULT_SELECTED_OPTION,
    REDUX_ORM_VERSION,
    MODAL_OPEN_CLOSE_STATE,
    DEF_SELECTED_ANS,
    LATEST_SURVEY_VERSION,
    INCOMPLETE_SUVEY_MESSAGE,
    VALIDATION_MESSAGES,
    PROJECT_ISSUES,
    BLOCK_ISSUES,
    ORM_VERSION_CONTROL_FIELDS,
    GRAZING_PRACTICE_FLAG_QUESTION_ID,
    GRAZING_STREAMBANK_SPECIFIC_QUESTIONS,
};
