import ReduxModel from "./ReduxModel";
import { ID_ATTRIBUTE } from "../utils/constants";

export default class UserGroup extends ReduxModel {
    constructor({ id, name, _v, privilege,
        dateCreated, dateUpdated, users = [], groupParents = [], allowManualSoilSelection }) {
        super();
        this.id = id;
        this.name = name;
        this.privilege = privilege;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
        this.users = users ?? [];
        this.groupParents = groupParents;
        this.allowManualSoilSelection = allowManualSoilSelection;
        this._v = _v;
    }

    static deserialise({ [ID_ATTRIBUTE]: groupId, name, _v, privilege, dateCreated, dateUpdated, groupParents, allowManualSoilSelection }) {
        return new UserGroup({
            id: groupId,
            name,
            _v,
            privilege,
            dateCreated,
            dateUpdated,
            groupParents,
            allowManualSoilSelection
        });
    }

    toReduxStore() {
        return {
            id: this.id,
            name: this.name,
            _v: this._v,
            privilege: this.privilege,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            groupParents: this.groupParents,
            allowManualSoilSelection: this.allowManualSoilSelection
        };
    }

    static fromReduxStore({ id, name, _v, privilege, dateCreated, dateUpdated, groupParents, allowManualSoilSelection }) {
        return new UserGroup({ id, name, _v, privilege, dateCreated, dateUpdated, groupParents, allowManualSoilSelection });
    }

    clone() {
        return new UserGroup({
            id: this.id,
            name: this.name,
            privilege: this.privilege,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            groupParents: this.groupParents,
            allowManualSoilSelection: this.allowManualSoilSelection,
            _v: this._v
        });
    }
}