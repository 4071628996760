import React from 'react';
import { CollectionMenu } from '../../components/CollectionMenu';
import { Toolbar } from '../../components/CollectionToolbar';
import UserMenu from '../../components/UserMenu';
import './home.scss';
import { Map } from '../../components/Map';
import { VisualReport } from '../../components/VisualReport';
import { Table } from '../../components/Table';

const Home = () => {
    return (
        <div className="home container-fluid">
            <header className="row">
                
                <div className="collection-menu col-12 col-xl-7 col-xxl-8">
                    <CollectionMenu />
                </div>
                <div className="toolbar col-12 col-xl-5 col-xxl-4 d-flex flex-row justify-content-between">
                    <Toolbar /> <UserMenu />
                </div>
                {/* <div className="user-account col-3 col-sm-5 col-lg-1 col-xl-1 col-xxl-1 d-flex">
                    
                </div> */}
            </header>

            <div className="row map-chart-wrapper">
                <div className="col-lg-8 left-panel">
                    <Map />
                </div>
                <div className="col-lg-4 right-panel visual-report-wrapper">
                    <VisualReport />
                </div >
            </div >

            <div className="table-wrapper">
                <Table />
            </div>
        </div >
    );
};

export default Home;
