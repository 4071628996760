import React, { useContext, useState, useEffect } from 'react';
import { services, serviceWrapper } from '../../services';
import { AppGUID, ERROR_CHANNEL, EVENT_CHANNEL } from '../../utils/constants';
import { UserProfile } from '../../models';
import { EventEmitter } from '../EventEmitter';

const { userService, tokenService } = services;
const AuthContext = React.createContext();
const useP2RAuth = () => useContext(AuthContext);

/**
 * React context that returns an object with utility methods to authenticate and grab user details.
 *    @return {object} {
 *    getPublicToken @function: an async function that returns the public token required for P2R. It 
 *                              will also fetch and set it to the localStorage if it doesn't exist
 *    isAuthenticated @returns {Boolean}: A boolean indicating whether the user is authenticated.
 *    setUserToken @function: sets user token into localstorage
 *    getUserByToken @function: retrive the user token from redux store if it exist, otherwise fetches from 
 *                              P2R backend
 *    logout @function: Clears P2R related localstorage keys to logout the user.
 * 
 * }
 */
const AuthContextProvider = props => {
    const getPublicToken = async () => {
        let publicToken = localStorage.getItem(`${AppGUID}_public_token`);
        if (!publicToken) {
            publicToken = await tokenService.getPublicToken();
            localStorage.setItem(`${AppGUID}_public_token`, publicToken);
        }

        return publicToken;
    };

    const getUserToken = () => {
        let token = localStorage.getItem(`${AppGUID}_user_token`);
        token = token === null || token === undefined ? "" : token;
        return token;
    };

    const isUserTokenValid = (token) => {
        return token !== null && token !== undefined && token.toString().trim() !== ""
            && token.toString().trim() !== "null" && token.toString().trim() !== "undefined";
    };

    const [token, setToken] = useState(getUserToken());
    const [profile, setProfile] = useState(new UserProfile({}));

    const setUserToken = (token) => {
        token = token === null || token === undefined ? "" : token;
        localStorage.setItem(`${AppGUID}_user_token`, token);
        setToken(token);
    };

    const logout = () => {
        setUserToken(null);
    };

    const getUserByToken = async () => {
        let profile = await serviceWrapper({
            model: UserProfile,
            errorChannel: ERROR_CHANNEL.LOG_PURPOSE_ONLY
        }, {
            instance: userService,
            errorChannel: ERROR_CHANNEL.LOG_PURPOSE_ONLY,
            name: "getUserByToken",

            params: []
        });

        profile && setProfile(profile);

        if (!profile) {
            logout();
        }
        else if (profile.selectGroup) {
            selectGroup(profile.selectedGroup.id);
        }
    };

    useEffect(() => {
        if (isUserTokenValid(token))
            getUserByToken();
    }, [token]);

    const isAuthenticated = isUserTokenValid(token);

    const selectGroup = async (id) => {
        profile && profile.selectGroup(id);

        let _profile = await serviceWrapper({ model: UserProfile }, {
            instance: userService,
            name: "updateUser",
            params: [{
                activated_group_id: id
            }]
        });

        _profile && setProfile(_profile);


        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_GROUP_SELECTED, {
            payload: _profile.selectedGroup
        });
    };

    const acceptTermsForUser = async () => {
        let _profile = await serviceWrapper({ model: UserProfile }, {
            instance: userService,
            name: 'updateUser',
            params: [{ accepted_terms: 1 }]
        });

        _profile && setProfile(_profile);
    };

    return (
        <AuthContext.Provider
            value={{
                setUserToken,
                getPublicToken,
                logout,
                isAuthenticated,
                profile,
                token,
                selectGroup,
                acceptTermsForUser
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export { useP2RAuth, AuthContextProvider };