/* NO LONGER USED - see components CollectionMenu/index.js */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu } from "mdbreact";
import { faChevronCircleDown } from "@fortawesome/pro-solid-svg-icons/faChevronCircleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./collection.menu.scss";
import { createSelector } from "redux-orm";
import orm from "../../models/orm.register";
import { connect } from "react-redux";
import _ from "lodash";
import { EVENT_CHANNEL, ID_ATTRIBUTE, ORM_WORKSPACE_SLICE, WORKSPACE_ID } from "../../utils/constants";
import { EventEmitter } from "../EventEmitter";
import ContentEditable from "react-contenteditable";
import { COLLECTION_ACTION } from "./collectionMenuConstant";
import CollectionDropdownContent from "./CollectionDropdownContent";

const CollectionMenu = ({ selectedCollection }) => {
    const changeCollectionTitle = (collection, title) => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COLLECTION_TITLE_EDITING, {
            payload: {
                [ID_ATTRIBUTE]: collection[ID_ATTRIBUTE],
                title,
                oldTitle: collection.title,
            },
        });
    };

    return (
        <>
            {!selectedCollection && <span className="active-collection">Load a collection</span>}

            {selectedCollection && (
                <ContentEditable
                    className="contentEditable active-collection"
                    html={selectedCollection.title}
                    disabled={false}
                    onBlur={(e) => {
                        const editedTitle = e.currentTarget.textContent;
                        changeCollectionTitle(selectedCollection, editedTitle);
                    }}
                />
            )}

            <MDBDropdown dropleft className="collection-selector">
                <MDBDropdownToggle color="color">
                    <FontAwesomeIcon icon={faChevronCircleDown} className="heading-style" />
                </MDBDropdownToggle>

                <MDBDropdownMenu basic className="z-depth-3 collection-menu-content">
                    <CollectionDropdownContent
                        highlightSelection={true}
                        couldCreate={true}
                        couldImport={true}
                        couldSearch={true}
                        actions={[
                            COLLECTION_ACTION.EDIT.state,
                            COLLECTION_ACTION.DELETE.state,
                            COLLECTION_ACTION.COPY.state,
                            COLLECTION_ACTION.SUBMIT.state,
                        ]}
                        onCollectionSelected={(collection) => {
                            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_COLLECTION_SELECTED, {
                                payload: collection,
                            });
                        }}
                    />
                </MDBDropdownMenu>
            </MDBDropdown>
        </>
    );
};

const collectionSelector = createSelector(orm, (session) => {
    const { [ORM_WORKSPACE_SLICE]: Workspace } = session;

    const workspace =
        (Workspace.select(session, {
            include: [
                {
                    selectedCollection: [],
                },
            ],
            filter: { deleted: false, [ID_ATTRIBUTE]: WORKSPACE_ID },
        }) ?? [])[0] ?? {};

    return workspace.selectedCollection;
});

function mapORMStateToProps(state) {
    return {
        selectedCollection: collectionSelector(state),
    };
}

export default connect(mapORMStateToProps)(CollectionMenu);
