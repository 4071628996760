import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/pro-solid-svg-icons/faArrowCircleLeft';
import { faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons/faArrowCircleRight';
import { useFormWizard } from './FormWizardContext';
import { InputField } from '../InputField';
import ContentEditable from 'react-contenteditable';
import { boundValue } from '../../utils/functions';

/** 
    React component that deals with the navigation layout on the bottom of the wizard
    @param {}
    
    @returns {React.Component}
*/

const DEF_FUNCS = () => { };

const FormWizardStepper = () => {
    const {
        currentPageNumber,
        canNext,
        canPrev,
        next = DEF_FUNCS,
        prev = DEF_FUNCS,
        pages,
        setCurrentStep

    } = useFormWizard();

    const range = {
        min: 1,
        max: pages.length
    };

    //TODO optimise re-renders 
    // console.log(canNext, canPrev);
    const handleOnBlur = (newPageNumber) => {
        const boundedInput = boundValue(newPageNumber ?? "", range);
        const pageToGoTo = boundedInput ? pages[boundedInput - 1] : pages[currentPageNumber];
        const { groupId: pageGroup, page: { index } } = pageToGoTo;
        setCurrentStep({
            pageGroup,
            index
        });
    };

    return (
        <div className="d-flex justify-content-between w-100 pt-3">
            <button className="btn btn-as-text help-block" onClick={prev} disabled={!canPrev}>
                <FontAwesomeIcon icon={faArrowCircleLeft} />
            </button>
            <div className="mini-pagination">
                <ContentEditable
                    className="page-number-input"
                    html={(currentPageNumber + 1).toString()} // innerHTML of the editable div
                    disabled={false}       // use true to disable editing 
                    onBlur={e => {
                        const newPageNumber = e.currentTarget.textContent;
                        handleOnBlur(newPageNumber);
                    }}
                />
                <span>&nbsp;of {pages.length}</span>
            </div>
            <button className="btn btn-as-text help-block" onClick={next} disabled={!canNext}>
                <FontAwesomeIcon icon={faArrowCircleRight} />
            </button>
        </div>
    );
};

export default FormWizardStepper;