import { GEOMETRY_LINE_STRING_TYPE, GEOMETRY_POLYGON_TYPE, GEOMETRY_POINT } from "./mapConstants";

export function getReversedCoordinates(geometry) {
    const coordinates = geometry.coordinates ?? [];

    switch (geometry.type) {
        case GEOMETRY_POLYGON_TYPE:
            return coordinates.map((polygon) => {
                return polygon.map((coords) => {
                    return [coords[1], coords[0]];
                });
            });
        case GEOMETRY_LINE_STRING_TYPE:
            return coordinates.map((coord) => {
                return [coord[1], coord[0]];
            });
        case GEOMETRY_POINT:
            return [coordinates[1], coordinates[0]];
    }

    return [];
}
