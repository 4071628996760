import React, { useRef, useEffect, useState } from "react";
import { EventHanlders, EventEmitter } from "../EventEmitter";
import { EVENT_CHANNEL } from "../../utils/constants";

import "./ProgressDisplay.module.scss";

import _ from "lodash";

const CURRENT_SCALE = 100;

const DELTA = 0;

const ProgressDisplay = ({
    progress: _progress = 0,
    message: _message = "",
    scale: _scale = 100,
    setModalClose,
    onClose,
} = {}) => {
    const [data, setData] = useState({ progress: _progress, message: _message, scale: _scale });
    useEffect(() => {
        setData({ progress: _progress, message: _message, scale: _scale });
    }, [_progress, _message, _scale]);

    const { progress, message, scale } = data;

    const ratio = Math.floor((progress / scale) * CURRENT_SCALE);

    const eventHandlers = useRef(new EventHanlders());

    const isStarted = useRef(false);
    const buffer = useRef([]);
    const previousTime = useRef(Date.now());

    useEffect(() => {
        const progressUpdate = (event) => {
            const { payload } = event ?? {};

            if (payload) {
                const { progress: _progress, message: _message, scale: _scale } = payload;

                const current = Date.now();
                const delta = current - previousTime.current;

                if (!isStarted.current && delta > DELTA) {
                    previousTime.current = current;
                    setData({
                        progress: _progress,
                        message: _message,
                        scale: _scale,
                    });

                    isStarted.current = false;
                } else if (!isStarted.current) {
                    previousTime.current = current;

                    buffer.current.push({
                        time: current,
                        delta,
                        payload: {
                            progress: _progress,
                            message: _message,
                            scale: _scale,
                        },
                    });

                    const watcher = (time) => {
                        setTimeout(() => {
                            const first = buffer.current[0];
                            if (first) {
                                const { payload, delta } = first;

                                const { progress } = payload;

                                if (delta <= time) {
                                    // set data and jump to next time
                                    buffer.current.shift();
                                    setData(payload);

                                    const ratio = Math.floor((progress / scale) * CURRENT_SCALE);

                                    ratio < 100 && watcher(DELTA);
                                } else if (delta > time) {
                                    // reduce the delta and jump to next time
                                    first.delta = delta - time;
                                    watcher(first.delta);
                                }
                            } else {
                                // jump to next time
                                watcher(DELTA);
                            }
                        }, time);
                    };

                    watcher(DELTA);
                    isStarted.current = true;
                } else if (isStarted.current) {
                    previousTime.current = current;

                    buffer.current.push({
                        time: current,
                        delta,
                        payload: {
                            progress: _progress,
                            message: _message,
                            scale: _scale,
                        },
                    });

                    isStarted.current = true;
                }
            }
        };

        eventHandlers.current.addEvents(EVENT_CHANNEL.EVENT_CMD_PROGRESS_REPORT_VIA_MODAL, progressUpdate);

        EventEmitter.subscribe(
            EVENT_CHANNEL.EVENT_CMD_PROGRESS_REPORT_VIA_MODAL,
            eventHandlers.current.events[EVENT_CHANNEL.EVENT_CMD_PROGRESS_REPORT_VIA_MODAL]
        );

        return () => {
            EventEmitter.off(
                EVENT_CHANNEL.EVENT_CMD_PROGRESS_REPORT_VIA_MODAL,
                eventHandlers.current.events[EVENT_CHANNEL.EVENT_CMD_PROGRESS_REPORT_VIA_MODAL]
            );
        };
    }, []);

    if (ratio >= CURRENT_SCALE) {
        setTimeout(() => {
            if (onClose) {
                onClose();
            }

            setModalClose();
        }, 1000);
    }

    return (
        <div className="progress md-progress">
            <div className="status">
                <span>{(message ?? "").trim() === "" ? `${ratio}%` : `${message} (${ratio}%)`}</span>
            </div>

            <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${ratio}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax={CURRENT_SCALE}
            ></div>
        </div>
    );
};

export default ProgressDisplay;
