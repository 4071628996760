import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBTooltip } from "mdbreact";
import { PRIVILEGE, ID_ATTRIBUTE, SUCCESS_CHANNEL, ERROR_CHANNEL, EVENT_CHANNEL } from "../../utils/constants";
import {
    CAN_EDIT_GROUP,
    CAN_INVITE_AS,
    CAN_INVITE_GROUP,
    CAN_REMOVE_USER,
    permission,
    ROLES,
} from "../../utils/permission";
import { serviceWrapper, services } from "../../services";
import CanPerformAction from "../../components/CanPerformAction";
import "./groupusermanagement.scss";
import { Popper } from "react-popper";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/pro-solid-svg-icons/faUserPlus";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import { faUserCircle as fasUserCircle } from "@fortawesome/pro-solid-svg-icons/faUserCircle";
import { faUserCircle as farUserCircle } from "@fortawesome/pro-regular-svg-icons/faUserCircle";
import SuccessNotifer from "../../components/Success/SuccessNotifier";
import { EventEmitter } from "../../components/EventEmitter";

const TestDropdownMenuComponent = ({ tag: Tag, tabIndex, role, attributes, aria, d_key, children }) => (
    <Tag
        data-test="dropdown-menu-component"
        tabIndex={tabIndex}
        role={role}
        {...attributes}
        aria-hidden={aria}
        key={d_key}
    >
        {children}
    </Tag>
);

class MDBDropdownMenuWithoutEvents extends MDBDropdownMenu {
    render() {
        const { basic, children, className, color, flip, modifiers, right, tag, ...attrs } = this.props;

        const { isOpen, dropup, dropright, dropleft } = this.context;

        const classes = classNames(
            {
                "dropdown-menu-right": right,
                [`dropdown-${color}`]: color,
                show: isOpen,
                basic,
            },
            "dropdown-menu",
            className
        );

        const Tag = tag;

        if (isOpen) {
            const position1 = dropup ? "top" : dropright ? "right" : dropleft ? "left" : "bottom";

            const position2 = right ? "end" : "start";

            attrs.placement = `${position1}-${position2}`;
            attrs.component = tag;
        }

        return (
            <Popper
                modifiers={modifiers || (!flip && { flip: { enabled: false } })}
                eventsEnabled={false}
                positionFixed={false}
                placement={attrs.placement}
                data-test="dropdown-menu"
            >
                {({ placement, ref, style }) => (
                    <Tag ref={ref} style={style} data-placement={placement} className={classes}>
                        <TestDropdownMenuComponent
                            isOpen={isOpen}
                            tag={Tag}
                            tabIndex="-1"
                            role="menu"
                            attributes={attrs}
                            aria={!isOpen}
                            d_key="dropDownMenu"
                            color={color}
                        >
                            {children}
                        </TestDropdownMenuComponent>
                    </Tag>
                )}
            </Popper>
        );
    }
}

const GroupUserManagement = ({ group, currentUser, currUserPrivilegeForGroup, onUserRemoved, onUserAdded }) => {
    const { groupService } = services;
    const { register, handleSubmit, errors } = useForm();

    const groupId = group[ID_ATTRIBUTE];

    const [data, setData] = useState({});

    useEffect(() => {
        getGroupDetail();
    }, []);

    async function handleRemoveUserFromGroup(userId, userEmail, groupId) {
        const updatedGroup = await serviceWrapper(
            { model: null },
            {
                instance: groupService,
                name: "removeUserFromGroup",
                params: [
                    {
                        group: {
                            [ID_ATTRIBUTE]: groupId,
                        },
                        member: {
                            username: userEmail,
                        },
                    },
                ],
            }
        );

        if (updatedGroup) {
            setData(updatedGroup);

            onUserRemoved({ groupId, userId });
        }
    }

    const getGroupDetail = async () => {
        const groupDetailResult = await serviceWrapper(
            { model: null },
            {
                instance: groupService,
                name: "getGroupDetailByGroupId",
                params: [groupId],
            }
        );
        // Dispatch to redux store;
        if (groupDetailResult) {
            setData(groupDetailResult);
        }
    };

    const generateUsername = ({ firstname, lastname, invite_pending, email }) => {
        if (invite_pending) {
            return email;
        } else if (firstname && lastname) {
            return `${firstname}.${lastname}`;
        }

        return email;
    };

    const submitForm = async (e, data, extraParams) => {
        const newUserInGroupResult = await serviceWrapper(
            {
                successChannel: SUCCESS_CHANNEL.INVITE_GROUP_USER_SUCCESS,
                errorChannel: ERROR_CHANNEL.INVITE_GROUP_USER,
            },
            {
                instance: groupService,
                name: "addUserToGroup",
                params: [
                    {
                        group: {
                            [ID_ATTRIBUTE]: groupId,
                        },
                        invitee: {
                            email: data.email,
                            privilege: extraParams.privilege,
                            message: `Please join to the group ${group.name}`,
                        },
                    },
                ],
            }
        );

        if (newUserInGroupResult) {
            setData(newUserInGroupResult);

            const addedUser = newUserInGroupResult.users.find((u) => u.email === data.email);

            onUserAdded({ groupId, userId: addedUser._id, privilege: extraParams.privilege });
        }
    };

    const promptUserToRemoveAnotherUser = (userId, userEmail, groupId) => {
        return () => {
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_REMOVE_GROUP_MEMBER_MODAL, {
                payload: {
                    userEmail,
                    callback: () => handleRemoveUserFromGroup(userId, userEmail, groupId),
                },
            });
        };
    };

    if (!data.users) {
        return <div className="w-100">Loading data...</div>;
    }

    const users = data?.users.map((user, index) => {
        const username = generateUsername(user);

        // default options for users...
        let icon = fasUserCircle;
        let iconClass = "";
        let title = "Group user";

        // blue solid = registered, admin/inviter
        // grey hollow = invited, user
        // blue hollow = invited, admin/inviter
        // grey solid = registered, user

        if (permission[CAN_EDIT_GROUP](user.role, user.privilege)) {
            iconClass = "higher-perms";
            title = "Group admin";
        }

        // if root or app admin users don't have any group privileges
        // then give them a 'Can manage users' title so other root
        // and app admin users can see
        if ([ROLES.ROOT, ROLES.APP_ADMIN].includes(user.role) && user.privilege === PRIVILEGE.CAN_MANAGE_USERS) {
            iconClass = "higher-perms";
            title = "Can manage users";
        }

        // check if the user is a root/app admin that has a privilege
        // higher than their inherited 1 (can manage only)
        // in this case, they've been invited as a group admin or user
        // so we need to show them as such...
        if ([ROLES.ROOT, ROLES.APP_ADMIN].includes(user.role) && user.privilege === PRIVILEGE.CAN_USE) {
            iconClass = "";
            title = "Group user";

            // if a root or app admin user has a higher privilege
            // than can use, they are already marked as 'Group admin'
            // because they've already passed the 'CAN_EDIT_GROUP'
            // permission check...
        }

        // mark invited users (whom are still pending) with a special icon
        if (user.invite_pending) {
            icon = farUserCircle;
        }

        return (
            <div className="col-auto" key={index}>
                <FontAwesomeIcon icon={icon} className={`group-manage-users ${iconClass}`} title={title} />
                <span className={`group-member ${user.invite_pending ? "pending-member" : ""}`}>
                    {user[ID_ATTRIBUTE] === currentUser.id ? <strong>{username}</strong> : username}
                </span>

                {user[ID_ATTRIBUTE] !== currentUser.id && (
                    <CanPerformAction
                        action={CAN_REMOVE_USER}
                        extraParams={[user.role, user.privilege, currUserPrivilegeForGroup]}
                    >
                        <FontAwesomeIcon
                            className="remove-user-icon"
                            title={`Remove ${username} from ${group.name}`}
                            icon={faTrashAlt}
                            onClick={promptUserToRemoveAnotherUser(user[ID_ATTRIBUTE], user.email, data[ID_ATTRIBUTE])}
                        />
                    </CanPerformAction>
                )}
            </div>
        );
    });

    return (
        <div className="user-list">
            <CanPerformAction action={CAN_INVITE_GROUP} extraParams={[currUserPrivilegeForGroup]}>
                <div className="float-right user-invite-container">
                    <div className="input-group user-invite-container">
                        <input
                            type="text"
                            name="email"
                            defaultValue=""
                            ref={register({
                                required: { value: true, message: "Please enter your email address" },
                                validate: {
                                    validEmail: (value) =>
                                        /^.+@.+[\.].+$/.test(value) ||
                                        "That's not an email address. It must be like something@something.com",
                                },
                            })}
                            className="form-control user-invite-input"
                            selected="Please selected a funding provider"
                            label="Example label"
                            placeholder="Enter email of new user"
                            aria-label="Enter email of new user"
                        />
                        <div className="input-group-append">
                            <MDBDropdown className="">
                                <MDBDropdownToggle className="invite-user-trail-btn dropstart">
                                    <FontAwesomeIcon className="invite-user-trail-btn-icon" icon={faUserPlus} />
                                </MDBDropdownToggle>
                                <MDBDropdownMenuWithoutEvents className="invite-user-dropdown-menu" basic>
                                    <CanPerformAction
                                        action={CAN_INVITE_AS}
                                        extraParams={[PRIVILEGE.CAN_BE_GROUP_ADMIN, currUserPrivilegeForGroup]}
                                    >
                                        <MDBDropdownItem
                                            onClick={(e) =>
                                                handleSubmit((data) =>
                                                    submitForm(e, data, { privilege: PRIVILEGE.CAN_BE_GROUP_ADMIN })
                                                )()
                                            }
                                        >
                                            <MDBTooltip domElement placement="top" tag="span">
                                                <span>Group admin</span>
                                                <div>Can manage users and use the app</div>
                                            </MDBTooltip>
                                        </MDBDropdownItem>
                                    </CanPerformAction>
                                    <CanPerformAction
                                        action={CAN_INVITE_AS}
                                        extraParams={[PRIVILEGE.CAN_USE, currUserPrivilegeForGroup]}
                                    >
                                        <MDBDropdownItem
                                            onClick={(e) =>
                                                handleSubmit((data) =>
                                                    submitForm(e, data, { privilege: PRIVILEGE.CAN_USE })
                                                )()
                                            }
                                        >
                                            <MDBTooltip domElement placement="top" tag="span">
                                                <span>Group user</span>
                                                <div>Can use the app</div>
                                            </MDBTooltip>
                                        </MDBDropdownItem>
                                    </CanPerformAction>
                                </MDBDropdownMenuWithoutEvents>
                            </MDBDropdown>
                        </div>
                    </div>
                    {errors.email && <div className="help-block with-errors">{errors.email.message}</div>}
                    <SuccessNotifer
                        successChannel={SUCCESS_CHANNEL.INVITE_GROUP_USER_SUCCESS}
                        errorChannel={ERROR_CHANNEL.INVITE_GROUP_USER}
                        successFn={() => <div className="help-block">The user has been invited to this group</div>}
                        errorFn={() => (
                            <div className="help-block with-errors">
                                Couldn't invite the user to the group. Something went wrong.
                            </div>
                        )}
                    />
                </div>
            </CanPerformAction>

            <div className="row">{users}</div>

            <div style={{ clear: "both" }}></div>
        </div>
    );
};

export default React.memo(GroupUserManagement);
