import { useP2RAuth } from '../AuthContextProvider/authContext';
import { permission } from '../../utils/permission';


const CanPerform = ({ action = undefined, children = null, extraParams = [] }) => {
    const { profile } = useP2RAuth();
    if (action in permission && permission[action](profile.role, ...extraParams)) {
        return children;
    }

    return null;
};

export default CanPerform;