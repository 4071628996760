import React from "react";
import SurveyMapper from "./SurveyMapper";
import { useForm, Controller } from "react-hook-form";
import { EventEmitter } from "../EventEmitter";
import { SURVEY_ANSWER_BEFORE_AFTER, SURVEY_QUESTION_TYPES, ID_ATTRIBUTE, INDUSTRY } from "../../utils/constants";
import FormHelper from "../FormWizard/helper";
import PropTypes from "prop-types";
import { keyValueStringContent } from "../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons/faTimesCircle";
import merge from "lodash/merge";
import "./surveyQuestionSet.scss";
import { useFormWizard } from "../FormWizard/FormWizardContext";
import GrazingStreambankSpecificQuestions from "./GrazingStreambankSpecificQuestions";
import { KNOWN_SURVEY_CATEGORIES } from "../../utils/miscellaneous";

const {
    BEFORE: { label: beforeLabel, key: beforeKey },
    AFTER: { label: afterLabel, key: afterKey },
} = SURVEY_ANSWER_BEFORE_AFTER;

const DEF_SCHEMA = {};

const SurveyMapperCreator = ({ type, onChange, onBlur, question, beforeOrAfterVisibility, value }) => {
    const { type: qType, id: questionId } = question;

    const formattedValueMap = {
        [beforeKey]: value[`q${questionId}_${beforeKey}`],
        [afterKey]: value[`q${questionId}_${afterKey}`],
    };

    if (!(qType in SurveyMapper)) {
        return <p>Unknown type {qType}</p>;
    }

    const handleAnswerChange = (onChange) => (answerMap) => {
        let formattedAnswer = {};

        Object.keys(answerMap).forEach((ansBeforeOrAfter) => {
            formattedAnswer[`q${questionId}_${ansBeforeOrAfter}`] = answerMap[ansBeforeOrAfter] ?? null;
        });

        onChange(formattedAnswer);
    };

    const handleAnswerBlur = (onBlur) => (answerMap) => {
        let formattedAnswer = {};

        Object.keys(answerMap).forEach((ansBeforeOrAfter) => {
            formattedAnswer[`q${questionId}_${ansBeforeOrAfter}`] = answerMap[ansBeforeOrAfter] ?? null;
        });

        onBlur(formattedAnswer);
    };

    return React.createElement(SurveyMapper[type], {
        question,
        onAnswerChange: handleAnswerChange(onChange),
        onAnswerBlur: handleAnswerBlur(onBlur),
        selectedAnswerMap: formattedValueMap,
        beforeOrAfterVisibility,
    });
};

const SurveyQuestionSet = ({
    questionSet,
    schemaFields = DEF_SCHEMA,
    survey,
    projectId,
    projectTitle,
    saveChannel,
    nextText,
    industry,
}) => {
    const { cancelMethod, displayHelperText, displayCloseButton } = useFormWizard();
    const { questions = [], description: questionSetTitle = "" } = questionSet;
    const { control, getValues, setValue } = useForm();

    /** 
     Utility function that updates the redux store to the saveChannel using the data from the form
     
     @param {answer}
     @param {survey} NOTE: passed survey in to get the latest state, not sure why it can't pull from props
     
     @returns {void} 
     */
    const updateProject = () => {
        let answers = getValues() ?? {};

        answers = Object.values(answers).reduce((accumulator, current) => {
            return {
                ...(accumulator ?? {}),
                ...(current ?? {}),
            };
        }, {});

        let payload = {
            data: {
                [ID_ATTRIBUTE]: projectId,
                survey: {
                    ...answers,
                },
            },
        };

        EventEmitter.dispatch(saveChannel, {
            payload,
        });
    };

    /**
     * A utility function that determines whether to display a question based on dependency array
     * @param questionId - object containing information of the new groud
     * @returns boolean
     */
    const decideToDisplayQuestion = (questionId) => {
        const result = {
            [beforeKey]: true,
            [afterKey]: true,
        };

        if (!schemaFields[`q${questionId}_${beforeKey}`]) result[beforeKey] = false;

        if (!schemaFields[`q${questionId}_${afterKey}`]) result[afterKey] = false;

        return result;
    };

    const renderSurveyInput = (question, beforeOrAfterVisibility) => {
        const { type, id: questionId } = question;
        let defaultValue = {};

        [beforeKey, afterKey].forEach((beforeOrAfter) => {
            if ((survey ?? {})[`q${questionId}_${beforeOrAfter}`]) {
                defaultValue[`q${questionId}_${beforeOrAfter}`] = survey[`q${questionId}_${beforeOrAfter}`];
            }
        });

        return (
            <Controller
                render={({ onChange, onBlur, value, name }) => {
                    return (
                        <SurveyMapperCreator
                            onBlur={(ansMap) => {
                                const currentQValueMap = getValues(`q${questionId}`);
                                const newValueMap = merge({}, currentQValueMap, ansMap);
                                setValue(`q${questionId}`, newValueMap);
                                updateProject();
                            }}
                            question={question}
                            type={type}
                            onChange={(ansMap) => {
                                const previousValue = getValues(`q${questionId}`);
                                setValue(`q${questionId}`, {
                                    ...previousValue,
                                    ...ansMap,
                                });
                                updateProject();
                            }}
                            beforeOrAfterVisibility={beforeOrAfterVisibility}
                            value={value}
                        />
                    );
                }}
                name={`q${questionId}`}
                control={control}
                defaultValue={defaultValue}
            />
        );
    };

    const renderSpecificQuestions = (survey) => {
        if (industry !== INDUSTRY.GRAZING.key || !questionSet) {
            return null;
        }

        switch (questionSet.class) {
            case KNOWN_SURVEY_CATEGORIES.STREAMBANK:
                return (
                    <GrazingStreambankSpecificQuestions
                        projectId={projectId}
                        survey={survey}
                        saveChannel={saveChannel}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            {displayCloseButton && (
                <FontAwesomeIcon
                    className="close-button"
                    onClick={cancelMethod}
                    size="1x"
                    className={"ml-auto mr-1 modal-close"}
                    icon={faTimesCircle}
                />
            )}

            <h1 className="text-center">
                {projectTitle || "No name"}
                <span className="font-weight-bold">
                    {questionSetTitle && ` - ${questionSetTitle.charAt(0).toUpperCase() + questionSetTitle.slice(1)}`}
                </span>
            </h1>

            {questions.map((question) => {
                const { description, id } = question;
                const beforeOrAfterVisibility = decideToDisplayQuestion(id);

                if (beforeOrAfterVisibility[beforeKey] || beforeOrAfterVisibility[afterKey]) {
                    return (
                        <div className="question-set-container" key={id}>
                            <div className="font-weight-bold">
                                {id}.&nbsp;{description}
                            </div>
                            <div className="question-container">
                                {renderSurveyInput(question, beforeOrAfterVisibility)}
                            </div>
                        </div>
                    );
                }
                return null;
            })}

            {renderSpecificQuestions(survey)}

            {displayHelperText && <FormHelper nextText={nextText} />}
        </form>
    );
};

SurveyQuestionSet.propTypes = {
    questionSet: PropTypes.shape({
        class: PropTypes.string,
        description: PropTypes.string,
        question: PropTypes.arrayOf(
            PropTypes.shape({
                description: PropTypes.string,
                id: PropTypes.string,
                responses: PropTypes.arrayOf(
                    PropTypes.shape({
                        description: PropTypes.string,
                        id: PropTypes.string,
                        weight: PropTypes.number,
                    })
                ),
                type: PropTypes.oneOf([SURVEY_QUESTION_TYPES.NUMERIC, SURVEY_QUESTION_TYPES.MULTIPLECHOICE]),
                visible: PropTypes.bool,
            })
        ),
    }),
    survey: PropTypes.object, // From redux
    projectTitle: PropTypes.string,
    projectId: PropTypes.string,
    saveChannel: PropTypes.string.isRequired,
    nextText: PropTypes.string,
    industry: PropTypes.string,
};

function areEquals(prevProps, nextProps) {
    const { survey: prevSurvey, projectTitle: prevProjectTitle, questionSet: prevQS } = prevProps;
    const { survey: nextSurvey, projectTitle: nextProjectTitle, questionSet: nextQS } = nextProps;

    return (
        keyValueStringContent(prevSurvey) === keyValueStringContent(nextSurvey) && prevProjectTitle === nextProjectTitle
    );
}

export default React.memo(SurveyQuestionSet, areEquals);
