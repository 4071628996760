export const urlQueryParse = (queryString, queryModel) => {
    const queryModelDefault = {
        group: null,
        collection: null,
        project: null
    };
    const queryModelToUse = queryModel ? queryModel : queryModelDefault;
    if (queryString) {
        const parsedQueryString = queryString.slice(1).split("&").reduce((acc, curr) => {
            const [queryKey, queryValue] = curr.split("=");
            if (Object.hasOwnProperty.call(queryModelToUse, queryKey)) {
                acc[queryKey] = queryValue;
            }
            return acc;
        }, {});
        return parsedQueryString;
    }
}