import UserGroup from './UserGroup';
import ReduxModel from './ReduxModel';
import { ACTIONS, COLLECTION_SLICE, CREATE_ACTION, ID_ATTRIBUTE } from '../utils/constants';

const slice = COLLECTION_SLICE;
export default class Collection extends ReduxModel {

    constructor({ id, dateCreated, dateUpdated, industry, title, _v }) {

        super();
        this.id = id;
        this.industry = industry;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
        this.title = title;
        this._v = _v;
        this.slice = COLLECTION_SLICE;
    }

    static deserialise({
        [ID_ATTRIBUTE]: collectionId,
        industry,
        dateCreated,
        dateUpdated,
        title,
        _v
    }) {
        return new Collection({
            id: collectionId,
            industry,
            dateCreated,
            dateUpdated,
            title,
            _v
        });
    }

    toReduxStore(store) {

        return {
            id: this.id,
            industry: this.industry,
            title: this.title,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            _v: this._v
        };
    }

    _reduxPersist(data, { store }) {
        store.dispatch({
            type: ACTIONS[COLLECTION_SLICE][CREATE_ACTION],
            payload: data ?? {}
        });
    }

    static fromReduxStore(d) {

        if (!d) return null;

        const { id, industry, title, dateCreated, dateUpdated, _v } = d;
        return new Collection({
            id, industry, title, dateCreated, dateUpdated, _v
        });
    }

    static reduxGetAll({ state }) {
        const arr = (state[COLLECTION_SLICE] ?? []);
        return arr.map(element => this.fromReduxStore(element));
    }

    static _reduxGetById(id, { state } = {}) {
        const d = (state[COLLECTION_SLICE] ?? {})[id];
        return this.fromReduxStore(d);
    }

    static reduxDeleteAll() {
        super.reduxDeleteAll({ slice });
    }

    static reduxGetAll({ state }) {
        const data = super.reduxGetAll({ state, slice });
        return (data ?? []).filter(d => d).map(d => this.fromReduxStore(d));
    }

    // static reduxUpdateBatch() {
    //     data = (data ?? []).filter(d => d && d instanceof Collection).map(d => d.toReduxStore());
    //     super.reduxUpdateBatch({ slice, data });
    //     return data;
    // }

    // static reduxDeleteBatch() {
    //     data = (data ?? []).filter(d => d && d instanceof Collection).map(d => d.toReduxStore());
    //     super.reduxDeleteBatch({ slice, data });
    //     return data;
    // }

    static reduxCreateBatch(data) {
        data = (data ?? []).filter(d => d && d instanceof Collection).map(d => d.toReduxStore());
        super.reduxCreateBatch({ slice: COLLECTION_SLICE, data });
        return data;
    }

    clone() {
        return new Collection({
            id: this.id,
            industry: this.industry,
            title: this.title,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
            _v: this._v
        });
    }
}