import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    ERROR_CHANNEL,
    MAX_FIELD_LENGTH,
    VALIDATION_MESSAGES
} from '../../utils/constants';
import { ErrorNotifier } from '../../components/Error';
import { services, serviceWrapper } from '../../services';
import { useP2RAuth } from '../../components/AuthContextProvider/authContext';
import Footer from '../../components/Footer';
import { useLocation, useHistory } from 'react-router-dom';
import { urlQueryParse } from '../../utils/urlParser';
import './register.scss';
import { Config } from '../../utils/config';

// type PropsOf<Tag> =
//     Tag extends keyof JSX.IntrinsicElements ? JSX.IntrinsicElements[Tag] :
//     Tag extends React.ComponentType<infer Props> ? Props & JSX.IntrinsicAttributes :
//     any;

// // list of possible fieldNames
// type FieldNames = "firstName" | "lastName" | "password" | "confirmPassword";

// interface QueryModel {
//     t: string // UserToken from url params... i.e. /register?t=5ee2d694ac46960032b9c5c5
// }

// interface Validation {
//     name: string // name of the validation for understanding purposes
//     message: string // message to display when field has an error for the validator below
//     validator(value: any, otherValues?: InputValues): boolean // a function that has the value of the field and otherValues (optional). Return true if validator passes otherwise false
// }

// interface Field {
//     htmlID: string, // id name to be used when creating the html element of the field
//     type: string, // the type of input that is required, i.e. <input type="text" />  
//     Tag: PropsOf<string>, // the html tag you want to use when creating the element i.e. p = <p> span = <span>
//     placeholder: string // placeholder attribute for <input>
//     class: Array<string>  // Array of class names
//     validations: Array<Validation>
// }

// // type FieldTypeValuedKeys = { [K in FieldNames]?: any; };
// // interface DictionaryField extends FieldTypeValuedKeys { }

// type Dictionary<K extends keyof any, T> = { [P in K]?: T } //generic type

// interface RegisterPayload {
//     email: string
//     firstname: string
//     lastname: string
//     password: string
//     username: string
// }

// type Fields = Dictionary<FieldNames, Field>;

// type Errors = Dictionary<FieldNames, string>;

// type InputValues = Dictionary<FieldNames, string>;

const { userService } = services;

const Register = () => {
    const { register, handleSubmit, errors, watch } = useForm();
    const { search } = useLocation();
    const { getPublicToken, profile } = useP2RAuth();
    const [userToRegister, setUserToRegister] = useState(undefined);

    const history = useHistory();
    const queryToReceive = { t: "" };
    const userToken = urlQueryParse(search, queryToReceive);

    const getUserRegisterDetail = async (token) => {
        if (token) {
            await getPublicToken();
            const userToBeRegistered = await serviceWrapper({ model: null, errorChannel: ERROR_CHANNEL.REGISTER }, {
                instance: userService,
                name: "authDecodeToken",
                params: [token]
            });
            if (userToBeRegistered) {
                setUserToRegister(userToBeRegistered.data.user);
            } else {
                history.push("/");
            }
        }
    };

    const onSubmit = async (data) => {
        await getPublicToken();
        const payload = {
            email: userToRegister?.email,
            firstname: data.firstname,
            lastname: data.lastname,
            password: data.password,
            username: userToRegister.email,
        };
        const registerResponse = await serviceWrapper({ model: null }, {
            instance: userService,
            name: "registerUser",
            params: [payload]
        });
        if (registerResponse) {
            history.push("/login");
        }
    };

    useEffect(() => {
        if (!userToken) {
            history.push("/");
        } else {
            getUserRegisterDetail(userToken.t);
        }
    }, []);

    if (!userToRegister) {
        return <p>Loading user ...</p>;
    }

    return (
        <div className="register account">
            <main>
                <div className="container">
                    <h1>P2R Projector </h1>
                    <h2>(Paddock to Reef Project Selector) </h2>
                    <p>{userToRegister.email}</p>
                    <form
                        // @ts-ignore 
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(onSubmit)();
                        }}
                        name="form" className="login-form">

                        <div className="form-group">
                            <input
                                name="firstname"
                                className="form-control"
                                defaultValue=""
                                placeholder="First name"
                                ref={register({
                                    required: { value: true, message: "Please enter your first name" },
                                    maxLength: { value: MAX_FIELD_LENGTH, message: "First name must be less then 100 characters" }
                                })} />

                            {errors.firstname &&
                                <div className="help-block with-errors">{errors.firstname.message}</div>}
                        </div>

                        <div className="form-group">
                            <input
                                name="lastname"
                                className="form-control"
                                defaultValue=""
                                placeholder="Last name"
                                ref={register({
                                    required: { value: true, message: "Please enter your last name" },
                                    maxLength: { value: MAX_FIELD_LENGTH, message: "Last name must be less then 100 characters" }
                                })} />

                            {errors.lastname &&
                                <div className="help-block with-errors">{errors.lastname.message}</div>}
                        </div>

                        <div className="form-group">
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                defaultValue=""
                                placeholder="Password"
                                ref={register({
                                    required: { value: true, message: "Please enter your password" },
                                    validate: {
                                        passRequirement: value => (/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/).test(value) ||
                                            "Password must be 6 to 30 characters and contain at least one each of - uppercase, lowercase, number and special character"
                                    }
                                })} />

                            {errors.password &&
                                <div className="help-block with-errors">{errors.password.message}</div>}
                        </div>

                        <div className="form-group">
                            <input
                                type="password"
                                name="confirmPassword"
                                className="form-control"
                                defaultValue=""
                                placeholder="Confirm password"
                                ref={register({
                                    required: { value: true, message: "Confirm password cannot be empty" },
                                    validate: {
                                        confirmPassCheck: value => value === watch("password") || "Confirm password does not match your new password"
                                    }
                                })} />

                            {errors.confirmPassword &&
                                <div className="help-block with-errors">{errors.confirmPassword.message}</div>}
                        </div>

                        <input
                            type="checkbox"
                            name="terms"
                            className="app-checkbox"
                            ref={register({
                                required: { value: true, message: VALIDATION_MESSAGES.TERMS_PRIVACY_POLICY_REQUIRED },
                                validate: value => value === true || VALIDATION_MESSAGES.TERMS_PRIVACY_POLICY_REQUIRED
                            })}
                        />
                        <label htmlFor="terms" className="ml-2 mt-2">
                            I agree to the <a href={Config.PUBLIC_APP_TERMS_OF_SERVICE_URL} target="blank">Terms of Service</a> and <a href={Config.PUBLIC_APP_PRIVACY_POLICY_URL} target="blank">Privacy Policy</a> of this application.
                        </label>
                        <div className="help-block with-rrors mb-4">
                            {errors.terms && errors.terms.message}
                        </div>

                        <button type="submit" className="btn btn-color w-100">Register</button>

                    </form>
                    <ErrorNotifier
                        errorChannel={ERROR_CHANNEL.REGISTER}
                        useToast={false}
                        errorFn={(error) => {
                            return <div className="help-block with-errors">{error.message}</div>;
                        }}
                    />
                    <div className="row">
                        <div className="col-sm">
                            <p>
                                <a href={Config.PUBLIC_APP_URL} target="_blank">Learn more about this tool</a>
                            </p>
                        </div>
                        <div className="col-sm">
                            <p>
                                <a href="#">Reset your password</a>
                            </p>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div >
    );
};

export default Register;