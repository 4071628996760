import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons/faSpinnerThird";
import { EventEmitter } from "../EventEmitter";
import { useP2RAuth } from "../AuthContextProvider/authContext";
import { serviceWrapper, services } from "../../services";
import generateORMActionName from "../../redux/reducers/orm.action.gen";
import Group from "../../models/group";
import {
    ORM_COLLECTION_SLICE,
    ORM_WORKSPACE_SLICE,
    UPDATE_ACTION,
    EVENT_CHANNEL,
    ID_ATTRIBUTE,
    ERROR_CHANNEL,
    WORKSPACE_ID,
} from "../../utils/constants";
import { guid } from "../../utils/functions";
import { store } from "../../redux";

import "./SubmitCollection.scss";

const { groupService, collectionService } = services;

const SubmitCollection = ({ selectedCollection, collections = [], setModalClose, workspaceCollection }) => {
    const {
        profile: { selectedGroup, groups },
    } = useP2RAuth();

    const [groupReceivers, setGroupReceivers] = useState([]);
    const [isSending, setSending] = useState(false);

    const fetchGroupReceivers = async () => {
        // fetch the groups receivers from the api
        const receivers = await serviceWrapper(
            { model: Group },
            {
                instance: groupService,
                name: "getGroupReceiversByGroupId",
                params: [selectedGroup.id],
            }
        );
        setGroupReceivers(receivers ?? []);
    };

    const showSubmitError = () => {
        const contents = (
            <p className="help-block">
                <strong>Couldn't submit the collection to the parent group.</strong>
                <br />
                Something went wrong...
            </p>
        );
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, {
            contents,
            id: guid(),
        });
    };

    useEffect(() => {
        fetchGroupReceivers();

        // subscribe to errors for submitting
        EventEmitter.subscribe(ERROR_CHANNEL.COLLECTION_SUBMITTED, showSubmitError);
        return () => EventEmitter.off(ERROR_CHANNEL.COLLECTION_SUBMITTED, showSubmitError);
    }, []);

    const { register, handleSubmit, errors, setError } = useForm();

    const submitCollection = async (data) => {
        const { collection, group: groupId } = data;

        // check if data has been set
        if (collection === "") {
            // tell the user that no collection has been selected
            setError("collection", {
                type: "manual",
                message: "A collection must be selected",
            });
            return;
        }

        if (groupId === "") {
            // tell the user that no group has been selected
            setError("group", {
                type: "manual",
                message: "A group must be selected",
            });
            return;
        }

        const payload = {
            groupId,
            originGroupId: selectedGroup.id,
        };
        setSending(true);

        // submit the collection to this group's parent
        const col = await serviceWrapper(
            {
                errorChannel: ERROR_CHANNEL.COLLECTION_SUBMITTED,
                fireErrorOnSuccess: false,
            },
            {
                instance: collectionService,
                name: "submitCollection",
                params: [collection, payload],
            }
        );
        setSending(false);

        if (col === undefined) {
            // the request failed
            return;
        }

        const matchingGroup = groupReceivers.find((group) => group.id === groupId);
        const contents = (
            <span>
                Submitted
                <strong> '{col.title}'</strong> collection to
                <strong> '{matchingGroup.name}'</strong> group
            </span>
        );
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, {
            contents,
            id: guid(),
        });

        // update the collection in the redux store
        store.dispatch({
            type: generateORMActionName({ slice: ORM_COLLECTION_SLICE, actionName: UPDATE_ACTION }),
            payload: col,
        });

        if (workspaceCollection[ID_ATTRIBUTE] === col[ID_ATTRIBUTE]) {
            //If the submitted collection is the current workspace collection (the one the user is viewing),
            //flag the workspace as readonly
            store.dispatch({
                type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
                payload: {
                    [ID_ATTRIBUTE]: WORKSPACE_ID,
                    collectionReadOnly: true,
                },
            });
        }

        // exit the modal now that we've submitted the collection
        setModalClose();
    };

    // deconstruct the group parents from the profile
    // create select options for each one
    const groupSelectOptions = groupReceivers.map((parent) => (
        <option key={parent.id} value={parent.id}>
            {parent.name}
        </option>
    ));

    const defaultSelectedCollection = (
        <option value={selectedCollection[ID_ATTRIBUTE]}>{selectedCollection.title}</option>
    );

    // filter out the already selected collection from the total list
    // we're already creating a separate option for it above
    const filteredCols = collections.filter((c) => c[ID_ATTRIBUTE] !== selectedCollection[ID_ATTRIBUTE]);
    const collectionSelectOptions = filteredCols.map((c, index) => (
        <option key={index} value={c[ID_ATTRIBUTE]}>
            {c.title}
        </option>
    ));

    const hasErrors = Object.keys(errors ?? {}).length > 0;

    const colFormControl = (
        <select className="browser-default sub-col custom-select" name="collection" ref={register}>
            {defaultSelectedCollection}
            {collectionSelectOptions}
        </select>
    );

    const groupFormControl = (
        <select className="browser-default sub-col custom-select" name="group" ref={register}>
            {groupSelectOptions}
        </select>
    );

    return (
        <div className="submit-collection-modal-container">
            <div className="submit-form">
                <form onSubmit={handleSubmit(submitCollection)}>
                    <div className="description">
                        <p>
                            When you submit a collection, you will{" "}
                            <strong>send a read only version to the approving organisation</strong>'s group and{" "}
                            <strong>your copy will become read only</strong>.
                        </p>
                        <p>
                            To continue editing the submitted collection, you must create a new version using the copy
                            function in the collections dropdown menu.
                        </p>
                    </div>
                    <div className="mt-4 mb-5">
                        <label>Collection to submit</label>
                        {colFormControl}
                        {errors.collection && <div className="help-block">{errors.collection.message}</div>}
                    </div>

                    <div>
                        <label>Group to submit to</label>
                        {groupFormControl}
                        {errors.group && <div className="help-block">{errors.group.message}</div>}
                    </div>

                    <div className="footer-section">
                        <div className="btn-container">
                            <button
                                type="button"
                                title="Cancel submitting a collection"
                                className="btn btn-default"
                                onClick={setModalClose}
                                disabled={isSending}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                title="Submit collection"
                                className="btn btn-primary"
                                disabled={isSending || hasErrors}
                            >
                                {isSending ? (
                                    <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
                                ) : (
                                    "Submit collection"
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapORMStateToProps = (state) => ({});

export default connect(mapORMStateToProps)(SubmitCollection);
