import React, { useEffect, useState, useRef } from 'react';
import BlockCreatingStep from "../BlockCreatingStep";
import { ProjectEditMap } from '../Map';
import { ID_ATTRIBUTE, PAGE_STATUSES } from '../../utils/constants';
import { getProjectMapBoundsFieldName } from '../../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { useFormWizard } from '../FormWizard/FormWizardContext';

const DEF_MODAL_CLOSE = () => { };

const SurveyBlock = ({ project, onValidChange = (hasError) => { }, setModalClose }) => {

    return <>
        <FontAwesomeIcon
            className="close-button"
            onClick={setModalClose}
            size="1x"
            className={"ml-auto mr-1 modal-close"}
            icon={faTimesCircle}
        />
        <h1 className="text-center">Draw project area</h1>
        <div className={`row map-chart-wrapper m-0`}>

            <div className="col-lg-8 left-panel">
                <ProjectEditMap projectId={(project ?? {})[ID_ATTRIBUTE]} mainMapBoundsWindowPropName={getProjectMapBoundsFieldName(project[ID_ATTRIBUTE])}
                />
            </div>
            <div className={`col-lg-4 draw-project`} >
                <BlockCreatingStep projectId={project[ID_ATTRIBUTE]} onValidChange={onValidChange}
                />
            </div >
        </div>
    </>;
};

const MapStatusController = ({ groupId, pageId, displayForm, project, setModalClose = DEF_MODAL_CLOSE }) => {
    const { updatePageStatus } = useFormWizard();

    return displayForm ? <>
        <SurveyBlock
            project={project}
            setModalClose={setModalClose}
            onValidChange={(hasError) => {
                // updatePageStatus(groupId, pageId, hasError ? PAGE_STATUSES.OPTIONAL : PAGE_STATUSES.COMPLETE);

                // set the map page's status as incomplete rather than optional (if there are no blocks)
                // because we want to force the user to create blocks when they make the project
                // (otherwise the project will be invisible on the frontend to the user!)
                updatePageStatus(groupId, pageId, hasError ? PAGE_STATUSES.INCOMPLETE : PAGE_STATUSES.COMPLETE);
            }}
        />
    </> : null;
};

export default MapStatusController;