import BaseReducerHandler from "./BaseReducerHandler";
import {
    ERROR_CHANNEL, ACTIONS, CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION, CREATE_BATCH_ACTION,
    UPDATE_BATCH_ACTION, DELETE_BATCH_ACTION, DELETE_ALL_ACTION
} from "../../utils/constants";

import generateActionName from './action.gen';

import _ from "lodash";

export default class BaseCRUDReducerHandler extends BaseReducerHandler {
    /*
    
        { initState, slice, createAction, updateAction, deleteAction, deleteAllAction }
        slice is required
    */
    constructor({ initState = {}, slice,
        errorChannel = ERROR_CHANNEL.GENERAL,
        idAttribute = "id",
        fireErrorOnSuccess = true,
        createAction = CREATE_ACTION,
        updateAction = UPDATE_ACTION,
        deleteAction = DELETE_ACTION,
        createBatchAction = CREATE_BATCH_ACTION,
        updateBatchAction = UPDATE_BATCH_ACTION,
        deleteBatchAction = DELETE_BATCH_ACTION,

        deleteAllAction = DELETE_ALL_ACTION,
    }) {
        super({ initState, slice, errorChannel, fireErrorOnSuccess });
        this.idAttribute = idAttribute;

        // [createAction, updateAction, deleteAction, deleteAllAction,
        //     createBatchAction, updateBatchAction, deleteBatchAction].forEach(action => {
        //         if (!ACTIONS[slice][action])
        //             throw new Error(`Action definition ${action} within slice ${this.slice} does not exist`);
        //     });

        // action data requires type and payload
        this.registerAction({
            // actionName: createAction,
            actionFn: (state, action, next) => {

                state = this.create({ state, action, actionName: createAction, next });
                state = this.update({ state, action, actionName: updateAction, next });
                state = this.delete({ state, action, actionName: deleteAction, next });
                state = this.deleteAll({ state, action, actionName: deleteAllAction, next });
                state = this.createBatch({ state, action, actionName: createBatchAction, next });
                state = this.updateBatch({ state, action, actionName: updateBatchAction, next });
                state = this.deleteBatch({ state, action, actionName: deleteBatchAction, next });

                return state;
            }
        });

        // this.registerAction({
        //     actionName: createBatchAction,
        //     actionFn: (state, action, next) => {

        //         const actions = action.payload ?? [];
        //         let newState = state;

        //         if (action.type === generateActionName({ slice: this.slice, actionName: createBatchAction })) {
        //             actions.forEach(act => {
        //                 newState = this.create({
        //                     state: newState ?? {}, action: {
        //                         type: action.type,
        //                         payload: act
        //                     }, actionName: createBatchAction, next
        //                 });
        //             });

        //             return newState;
        //         }

        //         return next();
        //     }
        // });

        // this.registerAction({
        //     actionName: updateAction,
        //     actionFn: (state, action, next) => {
        //         return this.update({ state, action, actionName: updateAction, next });
        //     }
        // });

        // this.registerAction({
        //     actionName: updateBatchAction,
        //     actionFn: (state, action, next) => {

        //         const actions = action.payload ?? [];
        //         let newState = state;

        //         if (action.type === generateActionName({ slice: this.slice, actionName: updateBatchAction })) {
        //             actions.forEach(act => {
        //                 newState = this.update({
        //                     state: newState ?? {}, action: {
        //                         type: action.type,
        //                         payload: act
        //                     }, actionName: updateBatchAction, next
        //                 });
        //             });

        //             return newState;
        //         }

        //         return next();
        //     }
        // });

        // this.registerAction({
        //     actionName: deleteAction,
        //     actionFn: (state, action, next) => {
        //         return this.delete({ state, action, actionName: deleteAction, next });
        //     }
        // });


        // this.registerAction({
        //     actionName: deleteBatchAction,
        //     actionFn: (state, action, next) => {

        //         const actions = action.payload ?? [];
        //         let newState = state;

        //         if (action.type === generateActionName({ slice: this.slice, actionName: deleteBatchAction })) {
        //             actions.forEach(act => {
        //                 newState = this.delete({
        //                     state: newState ?? {}, action: {
        //                         type: action.type,
        //                         payload: act
        //                     }, actionName: deleteBatchAction, next
        //                 });
        //             });

        //             return newState;
        //         }

        //         return next();
        //     }
        // });

        // this.registerAction({
        //     actionName: deleteAllAction,
        //     actionFn: (state, action, next) => {

        //         if (action.type === generateActionName({ slice: this.slice, actionName: deleteAllAction })) {
        //             return this.initState;
        //         }

        //         return next();
        //     }
        // });

    }

    create({ state, action, actionName, next }) {
        if (action.type === generateActionName({ slice: this.slice, actionName })) {

            if ((action.payload ?? {})[this.idAttribute] !== null || (action.payload ?? {})[this.idAttribute] !== undefined) {
                return {
                    ...(state ?? {}),
                    [action.payload[this.idAttribute]]: {
                        ...(action.payload ?? {})
                    }
                };
            }

            return {
                ...(state ?? {})
            };
        }

        // const b = next();
        // return b;

        return state;
    }

    update({ state, action, actionName, next }) {
        if (action.type === generateActionName({ slice: this.slice, actionName })) {

            if ((action.payload ?? {})[this.idAttribute] !== null || (action.payload ?? {})[this.idAttribute] !== undefined) {
                const prev = ((state ?? {})[(action.payload ?? {})[this.idAttribute]]) ?? {};

                return {
                    ...(state ?? {}),
                    [action.payload[this.idAttribute]]: {
                        ...(prev ?? {}),
                        ...(action.payload ?? {})
                    }
                };
            }

            return {
                ...(state ?? {})
            };
        }

        // return next();

        return state;
    }

    createBatch({ state, action, actionName, next }) {
        const actions = action.payload ?? [];

        if (action.type === generateActionName({ slice: this.slice, actionName })) {

            let newState = state;

            actions.forEach(act => {
                newState = this.create({
                    state: newState ?? {}, action: {
                        type: action.type,
                        payload: act
                    }, actionName, next
                });
            });

            return newState;
        }

        return state;
    }


    updateBatch({ state, action, actionName, next }) {
        const actions = action.payload ?? [];

        if (action.type === generateActionName({ slice: this.slice, actionName })) {

            let newState = state;
            actions.forEach(act => {
                newState = this.update({
                    state: newState ?? {}, action: {
                        type: action.type,
                        payload: act
                    }, actionName, next
                });
            });

            return newState;
        }

        return state;
    }

    deleteBatch({ state, action, actionName, next }) {
        const actions = action.payload ?? [];

        if (action.type === generateActionName({ slice: this.slice, actionName })) {

            let newState = state;
            actions.forEach(act => {
                newState = this.delete({
                    state: newState ?? {}, action: {
                        type: action.type,
                        payload: act
                    }, actionName, next
                });
            });

            return newState;
        }

        return state;
    }

    deleteAll({ state, action, actionName, next }) {
        if (action.type === generateActionName({ slice: this.slice, actionName })) {
            return {
                ...(this.initState ?? {})
            };
        }

        return state;
    }

    delete({ state, action, actionName, next }) {
        if (action.type === generateActionName({ slice: this.slice, actionName })) {

            if ((action.payload ?? {})[this.idAttribute] !== null || (action.payload ?? {})[this.idAttribute] !== undefined) {
                state = _.omit((state ?? {}), [action.payload[this.idAttribute]]);
                return {
                    ...(state ?? {})
                };
            }


            return {
                ...(state ?? {})
            };
        }

        // return next();

        return state;
    }
}