const EVENT_CHANNEL = {
    // Group
    EVENT_GROUP_SELECTED: "EVENT_GROUP_SELECTED",
    EVENT_GROUP_UPDATED: "EVENT_GROUP_UPDATED",

    // Funding
    EVENT_CMD_FUNDING_LOADING: "EVENT_CMD_FUNDING_LOADING",

    // Collection
    EVENT_CMD_COLLECTION_API_DELETE: "EVENT_CMD_COLLECTION_API_DELETE",
    EVENT_CMD_COLLECTION_API_CREATE: "EVENT_CMD_COLLECTION_API_CREATE",
    EVENT_CMD_COLLECTION_CREATING: "EVENT_CMD_COLLECTION_CREATING",
    EVENT_CMD_COLLECTION_DELETING: "EVENT_CMD_COLLECTION_DELETING",
    EVENT_CMD_COLLECTION_TITLE_EDITING: "EVENT_CMD_COLLECTION_TITLE_EDITING",
    EVENT_COLLECTION_SELECTED: "EVENT_COLLECTION_SELECTED",
    EVENT_CMD_COLLECTION_LOADING: "EVENT_CMD_COLLECTION_LOADING",
    EVENT_CMD_COLLECTION_IMPORT_RESULT: "EVENT_CMD_COLLECTION_IMPORT_RESULT",
    EVENT_CMD_COLLECTION_UPLOAD: "EVENT_CMD_COLLECTION_UPLOAD",
    EVENT_CMD_COMPLETE_COLLECTION_UPLOAD: "EVENT_CMD_COMPLETE_COLLECTION_UPLOAD",
    EVENT_CMD_COMPUTE_COLLECTION: "EVENT_CMD_COMPUTE_COLLECTION",
    EVENT_CMD_MIGRATE_COLLECTION: "EVENT_CMD_MIGRATE_COLLECTION",
    // Auth
    EVENT_CMD_RESET_PASS_WITH_EMAIL: "EVENT_CMD_RESET_PASS_WITH_EMAIL",
    EVENT_CMD_CONFIRM_PASS_RESET: "EVENT_CMD_CONFIRM_PASS_RESET",

    // Project
    EVENT_CMD_CREATE_OR_EDIT_PROJECT: "EVENT_CMD_CREATE_OR_EDIT_PROJECT",
    EVENT_CMD_PROJECT_CANCEL: "EVENT_CMD_PROJECT_CANCEL",
    EVENT_CMD_PROJECT_SAVE: "EVENT_CMD_PROJECT_SAVE",
    EVENT_CMD_PROJECT_SAVE_NEW: "EVENT_CMD_PROJECT_SAVE_NEW",
    EVENT_CMD_PROJECT_CLEAR_SURVEY: "EVENT_CMD_PROJECT_CLEAR_SURVEY",

    EVENT_CMD_PROJECT_FORM_PROJECT_DETAIL_SAVE: "EVENT_CMD_PROJECT_FORM_PROJECT_DETAIL_SAVE",
    EVENT_CMD_PROJECT_FORM_FUNDING_DETAIL_SAVE: "EVENT_CMD_PROJECT_FORM_FUNDING_DETAIL_SAVE",
    EVENT_CMD_PROJECT_FORM_SURVEY_SAVE: "EVENT_CMD_PROJECT_FORM_SURVEY_SAVE",
    EVENT_CMD_PROJECT_FORM_RESET_SURVEY_QUESTIONS: "EVENT_CMD_PROJECT_FORM_RESET_SURVEY_QUESTIONS",
    EVENT_CMD_PROJECT_BATCH_HIGHLIGHT: "EVENT_CMD_PROJECT_BATCH_HIGHLIGHT",
    EVENT_CMD_PROJECT_BLOCK_BATCH_HIGHLIGHT: "EVENT_CMD_PROJECT_BLOCK_BATCH_HIGHLIGHT",
    EVENT_CMD_PROJECT_BATCH_SELECT: "EVENT_CMD_PROJECT_BATCH_SELECT",
    EVENT_CMD_PROJECT_BLOCK_SET_EXTENT: "EVENT_CMD_PROJECT_BLOCK_SET_EXTENT",
    EVENT_CMD_PROJECT_PATCH_SURVEY: "EVENT_CMD_PROJECT_PATCH_SURVEY",
    EVENT_CMD_PROJECTS_DELETE: "EVENT_CMD_PROJECTS_DELETE",
    EVENT_CMD_PROJECT_FORCE_COMPUTE: "EVENT_CMD_PROJECT_FORCE_COMPUTE",

    // Block
    EVENT_CMD_BLOCK_BATCH_HIGHLIGHT: "EVENT_CMD_BLOCK_BATCH_HIGHLIGHT",
    EVENT_CMD_BLOCK_BATCH_DELETE: "EVENT_CMD_BLOCK_BATCH_DELETE",
    EVENT_CMD_BLOCK_DELETE_HIGHLIGHTED: "EVENT_CMD_BLOCK_DELETE_HIGHLIGHTED",
    EVENT_CMD_BLOCK_BATCH_UPDATE: "EVENT_CMD_BLOCK_BATCH_UPDATE",
    EVENT_CMD_BLOCK_CREATE: "EVENT_CMD_BLOCK_CREATE",
    EVENT_CMD_BLOCK_SET_EXTENT: "EVENT_CMD_BLOCK_SET_EXTENT",

    // Map
    EVENT_CMD_MAP_SET_EXTENT: "EVENT_CMD_MAP_SET_EXTENT",

    // Modals
    EVENT_CMD_POP_UP_TEST: "EVENT_CMD_POP_UP_TEST",
    EVENT_CMD_MANAGE_ACCOUNT_MODAL: "EVENT_CMD_MANAGE_ACCOUNT_MODAL",
    EVENT_CMD_CREATE_GROUP_MODAL: "EVENT_CMD_CREATE_GROUP_MODAL",
    EVENT_CMD_EDIT_GROUP_MODAL: "EVENT_CMD_EDIT_GROUP_MODAL",
    EVENT_CMD_REMOVE_GROUP_MEMBER_MODAL: "EVENT_CMD_REMOVE_GROUP_MEMBER_MODAL",
    EVENT_CMD_FUNDING_BODY_MODAL: "EVENT_CMD_FUNDING_BODY_MODAL",
    EVENT_CMD_FUNDING_PROGRAM_MODAL: "EVENT_CMD_FUNDING_PROGRAM_MODAL",
    EVENT_CMD_COLLECTION_ADD_PROJECT: "EVENT_CMD_COLLECTION_ADD_PROJECT",
    EVENT_CMD_SUBMIT_COLLECTION_MODAL: "EVENT_CMD_SUBMIT_COLLECTION_MODAL",
    EVENT_CMD_PROGRESS_DISPLAY_MODAL: "EVENT_CMD_PROGRESS_DISPLAY_MODAL",
    EVENT_CMD_PROGRESS_REPORT_VIA_MODAL: "EVENT_CMD_PROGRESS_REPORT_VIA_MODAL",
    EVENT_CMD_PROJECT_BLOCK_CREATE_MODAL: "EVENT_CMD_PROJECT_BLOCK_CREATE_MODAL",
    EVENT_CMD_TERMS_SERVICE_ACCEPT_MODAL: "EVENT_CMD_TERMS_SERVICE_ACCEPT_MODAL",
    EVENT_CMD_SHOW_COLLECTION_MIGRATION_MODAL: "EVENT_CMD_SHOW_COLLECTION_MIGRATION_MODAL",

    EVENT_SHOW_NOTIFICATION: "EVENT_SHOW_NOTIFICATION",
    EVENT_CMD_COMPUTATION: "EVENT_CMD_COMPUTATION",
};

export default EVENT_CHANNEL;
