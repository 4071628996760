/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styles from "./LeafletMap2.module.scss";
import { TileLayer } from "react-leaflet";

export const LeafletBaseLayers = ({}) => {
    return (
        <>
            <TileLayer
                key={"app-map-satellite"}
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, onEachFeature, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                miniZoom={5}
            />
            <TileLayer
                key={"app-map-satellite-label"}
                url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, onEachFeature, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                miniZoom={5}
            />
        </>
    );
};
