import React from "react";
import { useHistory } from "react-router-dom";
import { useP2RAuth } from "../../components/AuthContextProvider/authContext";
import Footer from "../../components/Footer";
import { services, serviceWrapper } from "../../services";
import { ERROR_CHANNEL, SUCCESS_CHANNEL, EVENT_CHANNEL } from "../../utils/constants";
import { useForm } from "react-hook-form";
import routes from "../../utils/routes";
import SuccessNotifier from "../../components/Success/SuccessNotifier";
import schema from "../../components/FormWizard/formSchema";
import { yupResolver } from "@hookform/resolvers";
import "./login.scss";
import { Config } from "../../utils/config";
import { EventEmitter } from '../../components/EventEmitter';

const Login = () => {
    const { getPublicToken, setUserToken } = useP2RAuth();
    const { register, errors, handleSubmit } = useForm({ resolver: yupResolver(schema.login) });
    const { userService } = services;
    const history = useHistory();

    const handleSubmitForm = async (inputValues, e) => {
        e.preventDefault();
        // Acquire public token
        await getPublicToken();
        setUserToken(null);
        const loginResult = await serviceWrapper(
            { model: null, successChannel: SUCCESS_CHANNEL.LOGIN, errorChannel: ERROR_CHANNEL.LOGIN },
            {
                instance: userService,
                name: "authenticateUser",
                params: [
                    {
                        username: inputValues["username"],
                        password: inputValues["password"],
                    },
                ],
            }
        );
        if (loginResult) {
            setUserToken(loginResult.token);
            history.push(routes.Home.Route);

            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_TERMS_SERVICE_ACCEPT_MODAL, {
                payload: {}
            });
        }
    };

    return (
        <div className="account login">
            <main>
                <div className="container">
                    <h1>P2R Projector</h1>
                    <h2>(Paddock to Reef Project Selector)</h2>

                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <input
                            type="text"
                            name="username"
                            className="form-control"
                            placeholder="Email"
                            ref={register({})}
                        />
                        <div className="help-block with-errors mb-4">{errors.username && errors.username.message}</div>

                        <input
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            ref={register({})}
                        />
                        <div className="help-block with-errors mb-4">{errors.password && errors.password.message}</div>

                        <button className="btn btn-color ml-0 w-100" type="submit">
                            Login
                        </button>
                    </form>

                    <SuccessNotifier
                        successChannel={SUCCESS_CHANNEL.LOGIN}
                        errorChannel={ERROR_CHANNEL.LOGIN}
                        startFn={() => {
                            return <div className="help-block with-errors">Please wait...</div>;
                        }}
                        successFn={() => {
                            return null;
                        }}
                        errorFn={(error) => {
                            return <div className="help-block with-errors">{error.message}</div>;
                        }}
                    />

                    <div className="row actions">
                        <div className="col-sm">
                            <p>
                                <a href={Config.PUBLIC_APP_URL} target="_blank">Learn more about this tool</a>
                            </p>
                        </div>
                        <div className="col-sm">
                            <p>
                                <a href="/resetPassword">Reset your password</a>
                            </p>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default React.memo(Login);
