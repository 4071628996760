const PUBLIC_APP_URL = 'https://p2rprojector.net.au';

export const Config = {
    PORT: parseInt(`${process.env.PORT}`),

    API_ENDPOINT: `${process.env.REACT_APP_API_ENDPOINT}`,
    CAL_ENDPOINT: `${process.env.REACT_APP_CAL_ENDPOINT}`,
    AUTH_ENDPOINT: `${process.env.REACT_APP_AUTH_ENDPOINT}`,
    LB_ENDPOINT: `${process.env.REACT_APP_LB_ENDPOINT}`,

    WS_ENDPOINT: `${process.env.REACT_APP_WS_ENDPOINT}`,
    WS_PATH: `${process.env.REACT_APP_WS_PATH}`,
    VERSION: `${process.env.REACT_APP_VERSION}`,

    PUBLIC_APP_URL,
    PUBLIC_APP_TERMS_OF_SERVICE_URL: `${PUBLIC_APP_URL}/terms-of-service/`,
    PUBLIC_APP_PRIVACY_POLICY_URL: `${PUBLIC_APP_URL}/privacy-policy/`
};