import BaseCRUDReducerHandler from "./BaseCRUDReducerHandler";
import {
    COLLECTION_SLICE, PROJECT_SLICE, BLOCK_SLICE,
    GROUP_SLICE, ID_ATTRIBUTES,
    ERROR_CHANNEL,
    ORM_SLICE
} from "../../utils/constants";
import generateActionName from './action.gen';
import BaseORMReducerHandler from "./BaseORMReducerHandler";
import orm from "../../models/orm.register";

// import ExampleReducerHandler from "./ExampleReducerHandler";
// export const COLLECTION_SLICE = "collection";
// export const PROJECT_SLICE = "project";
// export const BLOCK_SLICE = "block";
// export const UPDATE_ACTION = "UPDATE_ACTION";
// export const CREATE_ACTION = "CREATE_ACTION";
// export const DELETE_ACTION = "DELETE_ACTION";
// export const CREATE_BATCH_ACTION = "CREATE_BATCH_ACTION";
// export const UPDATE_BATCH_ACTION = "UPDATE_BATCH_ACTION";
// export const DELETE_BATCH_ACTION = "DELETE_BATCH_ACTION";
// export const DELETE_ALL_ACTION = "DELETE_ALL_ACTION";


// slices is an array of strings
export const generateCRUDReducers = (initState = {}, slices = []) => {
    slices = slices ?? [];
    // slices.forEach(slice => {
    //     ACTIONS[slice] = {
    //         UPDATE_ACTION: generateActionName({
    //             slice,
    //             actionName: UPDATE_ACTION
    //         }),
    //         CREATE_ACTION: generateActionName({
    //             slice,
    //             actionName: CREATE_ACTION
    //         }),
    //         DELETE_ACTION: generateActionName({
    //             slice,
    //             actionName: DELETE_ACTION
    //         }),
    //         CREATE_BATCH_ACTION: generateActionName({
    //             slice,
    //             actionName: CREATE_BATCH_ACTION
    //         }),
    //         UPDATE_BATCH_ACTION: generateActionName({
    //             slice,
    //             actionName: UPDATE_BATCH_ACTION
    //         }),
    //         DELETE_BATCH_ACTION: generateActionName({
    //             slice,
    //             actionName: DELETE_BATCH_ACTION
    //         }),
    //         DELETE_ALL_ACTION: generateActionName({
    //             slice,
    //             actionName: DELETE_ALL_ACTION
    //         }),
    //     };
    // });

    return slices.map(slice => {
        return new BaseCRUDReducerHandler({
            idAttribute: ID_ATTRIBUTES,
            slice,
            initState: {}
        });
    });
};

let _reducers = [];
_reducers = _reducers.concat(generateCRUDReducers({}, [
    COLLECTION_SLICE,
    PROJECT_SLICE,
    BLOCK_SLICE,
    GROUP_SLICE,
]));

_reducers = _reducers.concat([new BaseORMReducerHandler({
    slice: ORM_SLICE,
    orm: orm,
    initState: orm.getEmptyState()
})]);

// ACTIONS[USER_TEST_SLICE] = {
//     USER_CREATE_ACTION: generateActionName({
//         USER_TEST_SLICE,
//         actionName: USER_CREATE_ACTION
//     }),
// };

// _reducers.push(
//     new ExampleReducerHandler({
//         slice: USER_TEST_SLICE,
//         initState: {}
//     }));


export const reducers = _reducers;
