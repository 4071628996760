import React, { useState, useEffect } from "react";
import Select from "react-select";
import { services, serviceWrapper } from "../../services";
import SoilClimateCascadingSelect from "./SoilClimateCascadingSelect";
import { ID_ATTRIBUTE } from "../../utils/constants";

const { soilClimateDataService } = services;

const defaultSelectorState = {
    region: null,
    catchment: null,
    climate: null,
    soil: null,
};

const SoilTypeSelector = (props) => {
    const { code, codeChanged, readOnly } = props;
    const [regions, setRegions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(defaultSelectorState);

    const getRegions = async () => {
        setLoading(true);

        const regionData = await serviceWrapper(
            {},
            {
                instance: soilClimateDataService,
                name: "getRegions",
                params: [],
            }
        );

        let selectedRegion = null;

        const options = regionData.map((region) => {
            const option = {
                label: region.region.name,
                value: region.nodes[0][ID_ATTRIBUTE],
                meta: region,
                code: region.region.code,
            };

            if (option.value === code.regionNodeId) {
                selectedRegion = option;
            }

            return option;
        });

        setRegions(options);

        if (selectedRegion) {
            selectRegion(selectedRegion);
        }

        setLoading(false);
    };

    const selectRegion = (region) => {
        const next = {
            ...state,
            region: {
                ...region,
            },
            catchment: null,
            climate: null,
            soil: null,
        };

        setState(next);
        codeChanged({ code: "" });
    };

    const selectCatchment = (catchment) => {
        const next = {
            ...state,
            catchment: {
                ...catchment,
            },
            climate: null,
            soil: null,
        };

        setState(next);
        codeChanged({ code: "" });
    };

    const selectClimate = (climate) => {
        const next = {
            ...state,
            climate: {
                ...climate,
            },
            soil: null,
        };

        setState(next);
        codeChanged({ code: "" });
    };

    const selectSoil = (soil) => {
        const next = {
            ...state,
            soil: {
                ...soil,
            },
        };

        setState(next);

        const code = {
            code: `${next.region.code}.${next.catchment.code}${next.climate.code}${next.soil.code}`,
            regionNodeId: next.region.value,
            catchmentNodeId: next.catchment.value,
            climateNodeId: next.climate.value,
            soilNodeId: next.soil.value,
        };

        codeChanged(code);
    };

    useEffect(() => {
        getRegions();
    }, []);

    return (
        <div className="d-flex flex-column">
            <div className="py-1">
                <label htmlFor="region-region">Region</label>

                <Select
                    value={state.region}
                    isLoading={loading}
                    isDisabled={loading || readOnly}
                    options={regions}
                    onChange={selectRegion}
                    classNamePrefix="multiselect"
                    className="select-region"
                />
            </div>

            <SoilClimateCascadingSelect
                label="Catchment"
                parentId={state.region?.value}
                nodeType="catchment"
                selectedNodeId={code.catchmentNodeId}
                onChange={selectCatchment}
                readOnly={readOnly}
        
                className="dependent-selector select-catchment"
            />

            <SoilClimateCascadingSelect
                label="Climate"
                parentId={state.catchment?.value}
                nodeType="climate"
                selectedNodeId={code.climateNodeId}
                onChange={selectClimate}
                readOnly={readOnly}
                className="dependent-selector select-climate"
            />

            <SoilClimateCascadingSelect
                label="Soil"
                parentId={state.climate?.value}
                nodeType="soil"
                selectedNodeId={code.soilNodeId}
                onChange={selectSoil}
                readOnly={readOnly}
                className="dependent-selector select-soil"

            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

        </div>
    );
};

export default SoilTypeSelector;
