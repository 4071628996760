import * as yup from "yup";
import { INDUSTRY, DEFAULT_SELECTED_OPTION, VALIDATION_MESSAGES } from "../../utils/constants";

const {
    BANANAS: { key: bananaKey, label: bananaLabel },
    CANE: { key: caneKey, label: caneLabel },
    GRAIN: { key: grainKey, label: grainLabel },
    GRAZING: { key: grazingKey, label: grazingLabel },
} = INDUSTRY;

const NON_DIGIT_REGEX = /[^0-9]/g;
const PASSWORD_REGEX = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
const PROJECT_TITLE_REGEX = /^[-_'!@#$\\\/&a-zA-Z0-9\s]{6,}$/;

const removeNonDigit = (value, originalValue) => {
    if (isNaN(originalValue)) return Number(originalValue.replace(NON_DIGIT_REGEX, ""));

    return Number(originalValue);
};

const schema = {
    login: yup.object().shape({
        username: yup
            .string()
            .required(VALIDATION_MESSAGES.EMAIL_ADDRESS_REQUIRED)
            .email(VALIDATION_MESSAGES.EMAIL_ADDRESS_INVALID),
        password: yup
            .string()
            .required(VALIDATION_MESSAGES.PASSWORD_REQUIRED)
            .matches(PASSWORD_REGEX, VALIDATION_MESSAGES.PASSWORD_INVALID("Password")),
    }),
    forgotPassword: yup.object().shape({
        password: yup
            .string()
            .required(VALIDATION_MESSAGES.CURRENT_PASSWORD_REQUIRED)
            .matches(PASSWORD_REGEX, VALIDATION_MESSAGES.PASSWORD_INVALID("Current password")),
        newPassword: yup
            .string()
            .required(VALIDATION_MESSAGES.NEW_PASSWORD_REQUIRED)
            .notOneOf([yup.ref("password")], VALIDATION_MESSAGES.CANT_SET_NEW_PASS_AS_CURRENT)
            .matches(PASSWORD_REGEX, VALIDATION_MESSAGES.PASSWORD_INVALID("Password")),
        confirmPassword: yup
            .string()
            .required(VALIDATION_MESSAGES.CONFIRM_PASSWORD_REQUIRED)
            .oneOf([yup.ref("newPassword")], VALIDATION_MESSAGES.CONFIRM_PASS_NOT_MATCH_NEW_PASS),
    }),
    project: yup.object().shape({
        project_title: yup
            .string()
            .required(VALIDATION_MESSAGES.PROJECT_TITLE_REQUIRED)
            .matches(PROJECT_TITLE_REGEX, VALIDATION_MESSAGES.PROJECT_TITLE_INVALID),
        description: yup.string().optional().nullable(),
        industry: yup.string().required(VALIDATION_MESSAGES.INDUSTRY_REQUIRED),
    }),
    funding: yup.object().shape({
        organisationCash: yup
            .number(VALIDATION_MESSAGES.NUMERIC_CHARACTER_ONLY)
            .transform(removeNonDigit)
            .required(VALIDATION_MESSAGES.NUMERIC_AND_COMMA_ONLY("an organisation cash")),
        organisationDays: yup
            .number(VALIDATION_MESSAGES.NUMERIC_CHARACTER_ONLY)
            .transform(removeNonDigit)
            .required(VALIDATION_MESSAGES.NUMERIC_AND_COMMA_ONLY("an organisation day")),
        landholderCash: yup
            .number(VALIDATION_MESSAGES.NUMERIC_CHARACTER_ONLY)
            .transform(removeNonDigit)
            .required(VALIDATION_MESSAGES.NUMERIC_AND_COMMA_ONLY("a landholder cash")),
        landholderDays: yup
            .number(VALIDATION_MESSAGES.NUMERIC_CHARACTER_ONLY)
            .transform(removeNonDigit)
            .required(VALIDATION_MESSAGES.NUMERIC_AND_COMMA_ONLY("a landholder days")),
        funding: yup.object().shape({
            // This is needed to validate the form since we are using a controller with the funding;

            fundingBody: yup
                .string(VALIDATION_MESSAGES.FUNDING_BODY_REQUIRED)
                .test(
                    "isNotDefaultOption",
                    VALIDATION_MESSAGES.FUNDING_BODY_REQUIRED,
                    (value) => value !== DEFAULT_SELECTED_OPTION
                ),

            fundingProgram: yup
                .string(VALIDATION_MESSAGES.FUNDING_PROGRAM_REQUIRED)
                .test(
                    "isNotDefaultOption",
                    VALIDATION_MESSAGES.FUNDING_PROGRAM_REQUIRED,
                    (value) => value !== DEFAULT_SELECTED_OPTION
                ),
        }),
    }),
    survey: {}, // Dynamically generated in SurveyPageGroup component
};

export default schema;
