/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { MDBDropdownItem } from "mdbreact";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "../SearchBox";
import "./collection.menu.scss";
import BaseORMModel from "../../models/BaseORMModel";
import _ from "lodash";
import {
    ORM_COLLECTION_SLICE,
    ORM_COLLECTION_EDITING_STATE_SLICE,
    UPDATE_ACTION,
    CREATE_ACTION,
    DELETE_ACTION,
    EVENT_CHANNEL,
    ID_ATTRIBUTE,
    DATE_CREATED,
} from "../../utils/constants";
import { store } from "../../redux";
import generateORMActionName from "../../redux/reducers/orm.action.gen";
import { serviceWrapper, services } from "../../services";
import { EventEmitter, EventHanlders } from "../EventEmitter";
import CollectionDropdownItem from "./CollectionDropdownItem";
import { COLLECTION_ACTION, COLLECTION_CATEGORY } from "./collectionMenuConstant";
import collectionSelector from "./collectionSelector";
import { useP2RAuth } from "../AuthContextProvider/authContext";
import ImportCollectionMenuItem from "./ImportCollectionMenuItem";
import { isCollectionReadOnly } from "../../utils/collection.utils";

const SEPARATOR = "_____";

const { collectionService } = services;

const __CollectionDropdownContent = ({
    collections = [],
    highlightSelection = false,
    couldSearch = false,
    couldCreate = false,
    couldImport = false,
    couldShowDetail = false,
    couldShowCategories = true,
    category,
    actions,
    onCategoryChange = () => {},
    onCollectionSelected = () => {},
    preventDefaultDismiss = false,
    overrideSelectedCollection = null,
}) => {
    const { profile } = useP2RAuth();

    collections = collections ?? [];

    const [searchKeyword, setKeyword] = useState("");

    const changeCollectionTitle = (collection, title) => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COLLECTION_TITLE_EDITING, {
            payload: {
                [ID_ATTRIBUTE]: collection[ID_ATTRIBUTE],
                title,
                oldTitle: collection.title,
            },
        });
    };

    const eventHandlers = useRef(new EventHanlders());

    useEffect(() => {
        eventHandlers.current.addEvents(EVENT_CHANNEL.EVENT_GROUP_SELECTED, async () => {
            setKeyword("");
        });

        EventEmitter.subscribe(
            EVENT_CHANNEL.EVENT_GROUP_SELECTED,
            eventHandlers.current.events[EVENT_CHANNEL.EVENT_GROUP_SELECTED]
        );

        return () => {
            EventEmitter.off(
                EVENT_CHANNEL.EVENT_GROUP_SELECTED,
                eventHandlers.current.events[EVENT_CHANNEL.EVENT_GROUP_SELECTED]
            );
        };
    }, []);

    const filteredCols = (collections ?? []).filter((d) => {
        let myRe = new RegExp(`.*${searchKeyword.toLowerCase()}.*`, "g");
        if (!d.title) return false;
        var found = myRe.exec(d.title.toLowerCase());
        return found != null && myRe.lastIndex > -1;
    });

    let selectedCol = (collections ?? []).filter((col) => col.selectedCollectionWorkspace)[0];

    if (overrideSelectedCollection !== null) {
        selectedCol = (collections ?? []).find((col) => col[ID_ATTRIBUTE] === overrideSelectedCollection);
    }

    const copyCollection = (collection) => async ($event) => {
        $event.stopPropagation();

        const collectionId = collection[ID_ATTRIBUTE];
        const { isCopying = false } = collection.editingState ?? {};
        // use the collection id as the notification id
        // that way we can update it when the user clicks the button multiple times
        const notifId = collectionId;

        if (isCopying) {
            // alert the user that copying is currently disabled as another collection
            // is currently being copied, and must finish first
            const contents = <span>Already copying this collection, please wait...</span>;
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, {
                contents,
                type: "error",
                id: notifId + "-error",
            });
            return;
        }

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
            payload: {
                progress: 0,
                message: "Copying collection",
                scale: 100,
            },
        });

        // update the store to show that the collection is currently being copied
        store.dispatch({
            type: generateORMActionName({ slice: ORM_COLLECTION_EDITING_STATE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: `${collectionId}-editing`,
                collectionId,
                isCopying: true,
            },
        });

        const col = collections.find((c) => c[ID_ATTRIBUTE] === collectionId) ?? {};

        // call the service to copy the collection
        // we don't specify a model in the first argument
        // as the id property in the model does not match what redux wants...
        const updatedCollection = await serviceWrapper(
            {},
            {
                instance: collectionService,
                name: "copyCollection",
                params: [collectionId, profile.selectedGroup.id],
            }
        );

        // and add the collection to the store
        store.dispatch({
            type: generateORMActionName({ slice: ORM_COLLECTION_SLICE, actionName: CREATE_ACTION }),
            payload: updatedCollection,
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_COLLECTION_EDITING_STATE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: `${collectionId}-editing`,
                isCopying: false,
            },
        });

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
            payload: {
                progress: 100,
                message: "Finished",
                scale: 100,
            },
        });

        const contents = (
            <span>
                Made a copy of
                <strong> '{col.title}'</strong> collection
            </span>
        );

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, {
            contents,
            autoClose: true,
            id: notifId,
        });
    };

    const submitCollection = (collection) => async () => {
        // show a modal to configure how to submit the collection
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_SUBMIT_COLLECTION_MODAL, {
            payload: {
                selectedCollection: collection,
                collections,
                workspaceCollection: selectedCol,
            },
        });
    };

    const colComp = useMemo(() => {
        return (filteredCols ?? []).map(
            (collection) => {
                return (
                    <CollectionDropdownItem
                        key={collection[ID_ATTRIBUTE]}
                        collection={collection}
                        isSelected={
                            highlightSelection && selectedCol && selectedCol[ID_ATTRIBUTE] === collection[ID_ATTRIBUTE]
                        }
                        preventDefaultDismiss={preventDefaultDismiss}
                        actions={actions}
                        onTitleChanged={(title) => {
                            store.dispatch({
                                type: generateORMActionName({
                                    slice: ORM_COLLECTION_EDITING_STATE_SLICE,
                                    actionName: CREATE_ACTION,
                                }),
                                payload: {
                                    [ID_ATTRIBUTE]: `${collection[ID_ATTRIBUTE]}-editing`,
                                    collectionId: collection[ID_ATTRIBUTE],
                                    title,
                                },
                            });
                        }}
                        onEditingEntered={() => {
                            store.dispatch({
                                type: generateORMActionName({
                                    slice: ORM_COLLECTION_EDITING_STATE_SLICE,
                                    actionName: UPDATE_ACTION,
                                }),
                                payload: {
                                    [ID_ATTRIBUTE]: `${collection[ID_ATTRIBUTE]}-editing`,
                                    collectionId: collection[ID_ATTRIBUTE],
                                    editing: true,
                                    title: collection.title,
                                },
                            });
                        }}
                        onEditingSaved={async () => {
                            changeCollectionTitle(collection, (collection.editingState ?? {}).title);
                        }}
                        onCollectionSelected={onCollectionSelected}
                        onCopyTriggered={copyCollection(collection)}
                        onSubmitTriggered={submitCollection(collection)}
                        onEditingCanceled={() => {
                            store.dispatch({
                                type: generateORMActionName({
                                    slice: ORM_COLLECTION_EDITING_STATE_SLICE,
                                    actionName: DELETE_ACTION,
                                }),
                                payload: {
                                    [ID_ATTRIBUTE]: `${collection[ID_ATTRIBUTE]}-editing`,
                                },
                            });
                        }}
                        onDeleteRequested={() => {
                            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COLLECTION_DELETING, {
                                payload: collection,
                            });
                        }}
                    />
                );
            },
            [BaseORMModel.getVersionId(filteredCols ?? [])]
        );
    });

    const _onCategoryChange = (filterMode) => () => {
        let { filter } = COLLECTION_CATEGORY.ALL;

        if (filterMode === COLLECTION_CATEGORY.SUBMISSION.state) {
            ({ filter } = COLLECTION_CATEGORY.SUBMISSION);
        }

        if (filterMode === COLLECTION_CATEGORY.SUBMISSION_RECEIVING.state) {
            ({ filter } = COLLECTION_CATEGORY.SUBMISSION_RECEIVING);
        }

        onCategoryChange({
            category: filterMode,
            filter,
        });
    };

    return (
        <>
            {couldSearch && (
                <MDBDropdownItem header>
                    <SearchBox
                        keyword={searchKeyword}
                        autoFocus={true}
                        onChange={(e) => {
                            setKeyword(e);
                        }}
                    />
                </MDBDropdownItem>
            )}

            {couldCreate && (
                <MDBDropdownItem header>
                    <div
                        className="grey-header"
                        title={"Create a new collection"}
                        onClick={() => {
                            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COLLECTION_CREATING, {});
                        }}
                    >
                        <span>Create a new collection</span>
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                </MDBDropdownItem>
            )}

            {couldImport && (
                <div className="dropdown-header">
                    <ImportCollectionMenuItem
                        selectedColId={selectedCol?.[ID_ATTRIBUTE]}
                        selectedGroupId={profile?.selectedGroup?.id}
                    />
                </div>
            )}

            {couldShowDetail && (
                <MDBDropdownItem header>
                    Create a collection from spatial file <br /> (coming soon)
                </MDBDropdownItem>
            )}

            {(couldSearch || couldCreate || couldImport) && <MDBDropdownItem divider />}

            {couldShowCategories && (
                <MDBDropdownItem className="collection-filter-btns" header>
                    {/* filter buttons */}
                    <div className="btn-group" role="group">
                        <button
                            type="button"
                            className={`btn filter-cols ${category === COLLECTION_CATEGORY.ALL.state ? "active" : ""}`}
                            onClick={_onCategoryChange(COLLECTION_CATEGORY.ALL.state)}
                        >
                            {COLLECTION_CATEGORY.ALL.label}
                        </button>

                        <button
                            type="button"
                            className={`btn filter-cols ${
                                category === COLLECTION_CATEGORY.SUBMISSION.state ? "active" : ""
                            }`}
                            onClick={_onCategoryChange(COLLECTION_CATEGORY.SUBMISSION.state)}
                        >
                            {COLLECTION_CATEGORY.SUBMISSION.label}
                        </button>

                        <button
                            type="button"
                            className={`btn filter-cols ${
                                category === COLLECTION_CATEGORY.SUBMISSION_RECEIVING.state ? "active" : ""
                            }`}
                            onClick={_onCategoryChange(COLLECTION_CATEGORY.SUBMISSION_RECEIVING.state)}
                        >
                            {COLLECTION_CATEGORY.SUBMISSION_RECEIVING.label}
                        </button>
                    </div>
                </MDBDropdownItem>
            )}

            <div className="scrollable-menu custom-scrollbar">{colComp}</div>
        </>
    );
};

export const _CollectionDropdownContent = React.memo(
    __CollectionDropdownContent,
    (
        {
            collections: prevCollections,
            category: prevCategory,
            couldSearch: prevCouldSearch,
            couldCreate: prevCouldCreate,
            couldImport: prevCouldImport,
            highlightSelection: prevHighlightSelection,
            actions: prevActions,
            onCollectionSelected: prevOnCollectionSelected,
            overrideSelectedCollection: prevOverrideSelectedCollection,
        },
        {
            collections: nextCollections,
            category: nextCategory,
            couldSearch: nextCouldSearch,
            couldCreate: nextCouldCreate,
            couldImport: nextCouldImport,
            highlightSelection: nextHighlightSelection,
            actions: nextActions,
            onCollectionSelected: nextOnCollectionSelected,
            overrideSelectedCollection: nextOverrideSelectedCollection,
        }
    ) => {
        return (
            BaseORMModel.getVersionId(prevCollections ?? []) === BaseORMModel.getVersionId(nextCollections ?? []) &&
            prevCategory === nextCategory &&
            prevCouldSearch === nextCouldSearch &&
            prevCouldCreate === nextCouldCreate &&
            prevCouldImport === nextCouldImport &&
            prevHighlightSelection === nextHighlightSelection &&
            _.join(prevActions ?? [], SEPARATOR) === _.join(nextActions ?? [], SEPARATOR) &&
            prevOnCollectionSelected === nextOnCollectionSelected &&
            prevOverrideSelectedCollection === nextOverrideSelectedCollection
        );
    }
);

const CollectionDropdownContent = ({
    defaultCategory,
    couldCreate = false,
    couldImport = false,
    couldSearch = false,
    couldShowDetail = false,
    couldShowCategories = true,
    highlightSelection = false,
    actions = [],
    onCollectionSelected = () => {},
    excludeCollectionIds = [],
    preventDefaultDismiss = false,
    overrideSelectedCollection = null,
    showReadOnlyCollections = true,
}) => {
    const [categoryDef, setCategoryDef] = useState(
        defaultCategory
            ? defaultCategory
            : {
                  category: COLLECTION_CATEGORY.ALL.state,
                  filter: COLLECTION_CATEGORY.ALL.filter,
              }
    );

    const couldEdit = (actions ?? []).filter((action) => action === COLLECTION_ACTION.EDIT.state).length > 0;

    const include = [
        couldEdit ? "editingState" : null,
        highlightSelection ? "selectedCollectionWorkspace" : null,
    ].filter((d) => d);

    let { collections } = useSelector(
        collectionSelector({
            filter: categoryDef.filter,
            include,
        })
    );

    // filter the collections, excluding everything from the exclude array
    collections = collections.filter((c) => {
        if (excludeCollectionIds.includes(c[ID_ATTRIBUTE])) {
            return false;
        }

        if (!showReadOnlyCollections && isCollectionReadOnly(c)) {
            return false;
        }

        return true;
    });

    collections = _.orderBy(collections ?? [], (d) => d[DATE_CREATED], "desc");

    return (
        <_CollectionDropdownContent
            collections={collections}
            highlightSelection={highlightSelection}
            category={categoryDef.category}
            couldCreate={couldCreate}
            couldImport={couldImport}
            couldSearch={couldSearch}
            couldShowDetail={couldShowDetail}
            couldShowCategories={couldShowCategories}
            actions={actions}
            onCategoryChange={({ category, filter }) => {
                setCategoryDef({
                    category,
                    filter,
                });
            }}
            onCollectionSelected={onCollectionSelected}
            preventDefaultDismiss={preventDefaultDismiss}
            overrideSelectedCollection={overrideSelectedCollection}
        />
    );
};

export default CollectionDropdownContent;
