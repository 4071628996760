const ENDPOINTS = Object.freeze({
    // User
    AdminToken: "/users/auth",
    User: "/private/users",
    PublicTokenPath: "/tokens/public",
    RegisterUser: "/users/register",
    RequestResetPassword: "/users/requestResetPassword/{}",
    ResetPassWithUserToken: "/private/users/resetPass",
    ResetPassword: "/users/resetPass",
    AuthTokenDecode: "/users/tokens/{}",

    // Collection
    // GetCollectionsByGroupIdPath: '/collection/{}',
    GetCollectionsById: "/collection/{}",
    GetCollectionsMetadataByGroupIdPath: "/group/{}/collection",
    CopyCollectionByGroupIdPath: "/group/{}/collection/{}/clone",
    CollectionById: "/collection/{}",
    SubmitCollection: "/collection/{}/submit",

    // Groups
    GetGroupsByUserIdPath: "/group/user/{}",
    Group: "/group",
    GroupDetailById: "/private/groups/{}",
    PutGroupDetailById: "/group/{}",
    GroupInvite: "/private/groups/invite",
    RemoveUserFromGroup: "/private/groups/removeMember",
    AddReceiverstoGroup: "/group/{}/receivers",
    GetGroupReceiversByGroupId: "/group/{}/receivers",

    // Funding
    Funding: "/funding",
    ProgramForFundingBody: "/funding/{}/fundingPrograms",
    FundingProgram: "/fundingProgram/{}",

    // Project
    SaveProjects: "/project/save-projects",
    NewProject: "/collection/{}/project",
    CopyProjects: "/project/copy-projects",
    MoveProjects: "/project/move-projects",
    DeleteProjects: "/project/delete-projects",

    // Block
    SaveBlocks: "/project/save-blocks",
    DeleteBlocks: "/block/delete-blocks",

    //SoilClimateCode
    SaveSoilClimateCodes: "/project/soil-climate-codes",
    GetSoilClimateCode: "/project/soil-climate-code/{}",

    // Surveys
    Survey: "/run/questions/{}",

    // comp
    Run: "/run/data",

    // Azure
    Connector: "/azure/connector",
    Inspect: "/azure/inspect",
    Complete: "/azure/complete",

    GetNodeTypes: "/api/nodetypes",
    GetNodeChildren: "/api/nodes/{}/children",

    //Map
    GetMask: "/map/mask/{}",
});

export default ENDPOINTS;
