import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { ORM_FUNDING_BODY_SLICE, CREATE_ACTION, MAX_FIELD_LENGTH } from '../../utils/constants';
import { services, serviceWrapper } from '../../services';
import generateORMActionName from '../../redux/reducers/orm.action.gen';

const { fundingService } = services;

const FundingBodyModal = ({ setModalClose }) => {
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();

    const onSubmit = async data => {
        const newFundingBody = await serviceWrapper({ model: null }, {
            instance: fundingService,
            name: "addFundingBody",
            params: [{ fundingBody: data.fundingProviderName }]
        });

        if (newFundingBody) {
            dispatch({
                type: generateORMActionName({ slice: ORM_FUNDING_BODY_SLICE, actionName: CREATE_ACTION }),
                payload: newFundingBody
            });
            setModalClose && setModalClose();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="form-group">
                <label>Name</label>
                <input name="fundingProviderName" placeholder="Add the name of the funding provider" className="form-control" defaultValue="" ref={register({
                    required: { value: true, message: "You must enter a name of the funding provider" },
                    maxLength: { value: MAX_FIELD_LENGTH, message: "Provider name can only consist of 100 characters" }
                })} />

                {errors.fundingProviderName &&
                    <div className="help-block with-errors">{errors.fundingProviderName.message}</div>}
            </div>
            <div className="form-button-container d-flex justify-content-center">
                <button type="button" className="btn btn-default" onClick={(e) => setModalClose && setModalClose()}>Cancel</button>
                <button type="submit" className="btn btn-primary">Add provider</button>
            </div>
        </form>
    );
};

export default FundingBodyModal;