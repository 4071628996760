import BaseModal from './BaseModal';
import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EventHanlders, EventEmitter } from '../EventEmitter';
import { MODAL_OPEN_CLOSE_STATE } from '../../utils/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons/faTimesCircle";
import "./CollectionDeletor.scss";
import { eventModalMapper } from './mapper';
import _ from 'lodash';
import "./AppModal.scss";

const AppModal = ({ } = {}) => {
    const eventHandlers = useRef(new EventHanlders());
    const [modalState, setModalState] = useState({});
    const history = useHistory();

    useEffect(() => {

        _.keys(eventModalMapper ?? {}).forEach(event => {
            eventHandlers.current.addEvents(event, async (eventData) => {

                const openState = eventData[MODAL_OPEN_CLOSE_STATE] ?? true;
                eventData = _.omit(eventData, MODAL_OPEN_CLOSE_STATE);

                const tEvent = (eventModalMapper[event] ?? {}).toCompProps ?
                    eventModalMapper[event].toCompProps(eventData) : eventData;

                setModalState(state => {
                    return {
                        ...(state ?? {}),
                        [event]: {
                            ...({ event: tEvent }),
                            ...({
                                state: {
                                    isOpen: openState
                                }
                            })
                        }
                    };
                });
            });

            EventEmitter.subscribe(event, eventHandlers.current.events[event]);
        });

        return () => {

            _.keys(eventModalMapper ?? {}).forEach(event => {
                EventEmitter.off(event, eventHandlers.current.events[event]);
            });
        };
    }, []);

    const modals = _.keys(eventModalMapper ?? {}).map(event => {

        let { content, modalProps, modal, onClosed } = eventModalMapper[event] ?? {};
        modal = modal ?? BaseModal;
        modalProps = {
            ...(modalProps ?? {}),
            ...({
                className: `app-modal ${modalProps.className ?? ''}`
            })
        };

        const eventData = ((modalState ?? {})[event] ?? {}).event ?? {};
        const stateData = ((modalState ?? {})[event] ?? {}).state ?? {};

        const contentComponent = <>
            {modalProps.title !== undefined && (
                <div className="modal-title-container">
                    <h1 className="modal-title">
                        {modalProps.title ?? ''}
                    </h1>

                    {modalProps.closeBtnDisplay && <FontAwesomeIcon
                        className="close-button"
                        onClick={() => {
                            setModalState(state => {
                                return {
                                    ...(state ?? {}),
                                    [event]: {
                                        ...({ event: ((state ?? {})[event] ?? {}).event }),
                                        ...({
                                            state: {
                                                isOpen: false
                                            }
                                        })
                                    }
                                };
                            });
                            onClosed && onClosed(history, eventData ?? {});
                        }}
                        size="1x"
                        className={"mr-1 modal-close"}
                        icon={faTimesCircle}
                    />}
                </div>
            )}
            {
                !content ? null : (
                    React.createElement(content, {
                        ...(eventData ?? {})
                        , ...{
                            setModalClose: () => {
                                setModalState(state => {
                                    return {
                                        ...(state ?? {}),
                                        [event]: {
                                            ...({ event: ((state ?? {})[event] ?? {}).event }),
                                            ...({
                                                state: {
                                                    isOpen: false
                                                }
                                            })
                                        }
                                    };
                                });

                                onClosed && onClosed(history, eventData);
                            }
                        }
                    })
                )
            }
        </>;

        const modalComp = React.createElement(modal, {
            ...(modalProps ?? {}),
            ...({
                isOpen: (stateData).isOpen ?? false
            }),
            key: event
        }, contentComponent);

        return modalComp;
    });

    const openedModalCount = _.keys(eventModalMapper ?? {}).map(event => {
        const eventData = ((modalState ?? {})[event] ?? {}).event ?? {};
        const stateData = ((modalState ?? {})[event] ?? {}).state ?? {};

        return (stateData).isOpen ?? false;
    }).filter(d => d).length;

    useEffect(() => {
        if (openedModalCount) {
            document.body.classList.add('hasOpenedModals');
        } else {
            document.body.classList.remove('hasOpenedModals');
        }
    }, [openedModalCount]);

    return modals;
};

export default AppModal;