import React, { useState, useMemo, useRef } from "react";
import { FormWizardContextProvider } from "./FormWizardContext";
import FormStepper from "./FormStepper";
import { PAGE_STATUSES, INCOMPLETE_SUVEY_MESSAGE } from "../../utils/constants";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Separator from "./Separator";
import flattenDeep from "lodash/flattenDeep";
import _ from "lodash";

/**
 * A component that is responsible for controlling which Page group and which page within the group to display.
 * Wraps all child components around FormWizardContextProvider which provides the following utility constant/functions
 * -------
 * canNext - Boolean that indicates if the next operation can occur
 * canPrev - Boolean that indicates if the prev operation can occur
 * next - Function that increments page group or page within a group
 * prev - Function that decrements page group or page within a group
 * setPageGroupMap - 
 * pageGroupMap - 
 * currentStep - 
 * currentPageMap -
 * setCurrentStep - 
 * --------
 * Structure of data
 * order - Array<String>- 
 * @param {Array<String>} order - Ordering of page groups. i.e. ["PROJECT","SURVEY"]
 * @param {Array<React.Child> | React.Child}
 * @param {Object} pageGroupMap - Object that templates all the possible pageGroups. i.e. 

 * 
 *                       
 */

const DEF_PAGE_GROUP_MAP = {};
const DEF_CANCEL_METHOD = () => {};
const FIRST_PAGE_INDEX = 0;
/** 
    React component that deals with the navigation layout on the bottom of the wizard
    @param {}
    
    @returns {React.Component}
*/
const FormWizardStepper = ({ setCurrentStep, currentPageMap, currentStep, formSteppersToDisplay }) => {
    return (
        <div className="d-flex align-items-center">
            {formSteppersToDisplay.map(
                ({ groupId, pageId, page: { index: pageIndex, status, saveChannel } }, stepNumber) => {
                    return (
                        <React.Fragment key={`${groupId}_${pageId}`}>
                            <FormStepper
                                stepNumber={stepNumber + 1}
                                pageGroupIndex={pageIndex}
                                status={status}
                                pageGroupName={groupId}
                                setCurrentStep={setCurrentStep}
                                saveChannel={saveChannel}
                                currentPageMap={currentPageMap}
                                currentStep={currentStep}
                            />
                            {stepNumber !== formSteppersToDisplay.length - 1 && (
                                <Separator groupId={groupId} pageId={pageId} />
                            )}
                        </React.Fragment>
                    );
                }
            )}
        </div>
    );
};

const FormWizard = ({
    children,
    pageGroupMap = DEF_PAGE_GROUP_MAP,
    onSave,
    readOnly = false,
    displayDefNav = true,
    displayHelperText = true,
    displayCloseButton = true,
    cancelMethod = DEF_CANCEL_METHOD,
    className = "",
}) => {
    // TODO make currentStep dynamic based on props
    className = className ?? "";
    const order = Object.keys(pageGroupMap);
    const [currentStep, setCurrentStep] = useState({
        //TODO set default if pagegroupName doesn't exist
        pageGroup: order[0] ?? "", // currentPageGroup
        index: 0, // Index of the currentPageGroup
    });
    const [_pageGroupMap, _setPageGroupMap] = useState(pageGroupMap);
    const scrollContainerRef = useRef(null);

    // Flatten out the pageGroup map to an array to create a better structure to work with
    const pageMaps = useMemo(() => {
        return flattenDeep(
            order.map((pageGroupName, groupIndex) => {
                return Object.keys(_pageGroupMap[pageGroupName] ?? {}).map((pageName) => {
                    const page = _pageGroupMap[pageGroupName][pageName] ?? {};

                    return {
                        page,
                        groupId: pageGroupName,
                        pageId: pageName,
                    };
                });
            })
        );
    }, [_pageGroupMap]);

    const currentPageNumber = useMemo(() => {
        return pageMaps.findIndex(({ page: { index: pageIndex }, groupId: pageGroup }) => {
            const { index: currentPageIndex, pageGroup: currentPageGroup } = currentStep;
            return pageGroup === currentPageGroup && pageIndex === currentPageIndex;
        });
    }, [currentStep, pageMaps]);

    const currentPageMap = pageMaps[currentPageNumber];

    /** 
        Utility function that updates the status of a page within a pagegroup
        @param {String} groupId - The groupId that requires a page to be registered
        @param {Object<pageObject>} pageToAddToGroup - Pages that need to be registered for the groupId
        @param {pageObject} index - Index of the page within the page group
        @param {PAGE_STATUSES} status - Status of grops from PAGE_STATUSES inside constant
 
        @returns {void} 
    */
    const updatePageGroupMap = (groupId, pageToAddToGroup) => {
        if (groupId && pageToAddToGroup) {
            _setPageGroupMap((prevState) => {
                return {
                    ...prevState,
                    [groupId]: {
                        // ...prevState[groupId] ?? {},
                        ...pageToAddToGroup,
                    },
                };
            });
        }
    };

    const removePageGroupMap = (groupId) => {
        if (groupId) {
            _setPageGroupMap((prev) => {
                const next = _.omit(prev, groupId);
                return next;
            });
        }
    };

    /** 
        Utility function that updates the status of a page within a pagegroup
        @param {String} groupId - The groupId of the page that needs the status update 
        @param {String} pageId - The pageId of the page that needs the status update 
        @param {PAGE_STATUSES} status - Status of grops from PAGE_STATUSES inside constant
        
        @returns {void} 
    */
    const updatePageStatus = (groupId, pageId, status) => {
        if (groupId && pageId && status) {
            _setPageGroupMap((prevState) => {
                const newState = { ...prevState };
                ((newState[groupId] ?? {})[pageId] ?? {}).status = status;
                return newState;
            });
        }
    };

    const scrollToTop = () => {
        if (scrollContainerRef && scrollContainerRef.current) {
            scrollContainerRef.current.scroll({ top: 0 });
        }
    };

    /** 
        Utility function that moves the cursor to the next page.
        @param {} 
        
        @returns {void} 
    */
    const next = () => {
        const nextIndex = currentPageNumber + 1;

        if (nextIndex >= pageMaps.length) return;

        const nextPageMap = pageMaps[nextIndex];

        const {
            groupId: pageGroup,
            page: { index },
        } = nextPageMap;

        scrollToTop();

        setCurrentStep({
            pageGroup,
            index,
        });
    };

    /** 
        Utility function that moves the cursor to the prev page.
        @param {} 
        
        @returns {void} 
    */
    const prev = () => {
        const prevIndex = currentPageNumber - 1;

        if (prevIndex < FIRST_PAGE_INDEX) return;

        const prevPageMap = pageMaps[prevIndex];

        const {
            groupId: pageGroup,
            page: { index },
        } = prevPageMap;

        scrollToTop();

        setCurrentStep({
            pageGroup,
            index,
        });
    };

    /**
     *  Checks pageMaps to see if there are any pages marked as INCOMPLETE
     */
    const isThereIncompletePages = pageMaps.some(
        (pageMap) => !pageMap.page.status || pageMap.page.status === PAGE_STATUSES.INCOMPLETE
    );

    const canNext = currentPageNumber !== pageMaps.length - 1;
    const canPrev = currentPageNumber > FIRST_PAGE_INDEX;

    const disableScroll = currentStep && currentStep.pageGroup === "SOIL_TYPE_DECTION";

    return (
        <>
            <FormWizardContextProvider
                value={{
                    registerPageGroupMap: updatePageGroupMap,
                    pageGroupMap: _pageGroupMap,
                    currentStep,
                    pages: pageMaps,
                    currentPageNumber,
                    setCurrentStep,
                    next,
                    canNext,
                    prev,
                    canPrev,
                    updatePageStatus,
                    readOnly,
                    cancelMethod,
                    displayHelperText,
                    displayCloseButton,
                    removePageGroupMap,
                }}
            >
                <div className="flex-grow-1 d-flex flex-column">
                    <div id="modal-parent" className="survey-container">
                        <div
                            ref={scrollContainerRef}
                            // style={{
                            //     overflow: disableScroll ? "unset" : "auto",
                            // }}
                            className={`survey-content-container ${className}`}
                        >
                            {children}
                        </div>
                    </div>
                    {displayDefNav && (
                        <div className="survey-control-container">
                            <div className="row justify-content-center p-2">
                                <div className="col-12 d-flex justify-content-center form-wizard">
                                    <button className="btn btn-primary  mr-2" disabled={!canPrev} onClick={prev}>
                                        <span>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                            &nbsp;Prev
                                        </span>
                                    </button>
                                    <FormWizardStepper
                                        setCurrentStep={setCurrentStep}
                                        currentPageMap={currentPageMap}
                                        formSteppersToDisplay={pageMaps}
                                        currentStep={currentStep}
                                    />
                                    <button className="btn btn-primary ml-2" disabled={!canNext} onClick={next}>
                                        <span>
                                            Next&nbsp;
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </span>
                                    </button>
                                    <button
                                        className="btn btn-outline-primary ml-2"
                                        disabled={isThereIncompletePages}
                                        onClick={onSave && onSave}
                                    >
                                        Save And Exit
                                    </button>
                                </div>
                            </div>

                            {!readOnly && (
                                <>
                                    <div className="d-flex mt-2 justify-content-center"></div>
                                    {isThereIncompletePages && (
                                        <p className="help-block">
                                            <strong>
                                                *&nbsp;
                                                {INCOMPLETE_SUVEY_MESSAGE}
                                            </strong>
                                        </p>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </FormWizardContextProvider>
        </>
    );
};

export default FormWizard;
