import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { EVENT_CHANNEL } from "../../utils/constants";
import { getProjectsAndBlocksFromBlocks } from "../../utils/functions";
import { EventEmitter } from "../EventEmitter";
import { mapStateToPropsOfActions } from "./projectBlockSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBTooltip } from "mdbreact";
import BaseORMModel from "../../models/BaseORMModel";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

const TOOLTIP_TITLE = "Delete the highlighted projects or blocks";

const __BatchDelete = ({ blocks = [], readOnly = false, selectedProjectMap = {} }) => {
    blocks = blocks ?? [];

    let shouldEnabled = false;

    if (blocks && Array.isArray(blocks) && blocks.length > 0) {
        shouldEnabled = true;
    }

    shouldEnabled = shouldEnabled && !readOnly;

    // select projects where all blocks are selected and remaing blocks
    let { projects, blocks: validBlocks } = getProjectsAndBlocksFromBlocks(blocks, selectedProjectMap ?? {});

    const deleteProjectsFromCollection = async () => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECTS_DELETE, {
            payload: { projects, blocks },
        });
    };

    return (
        <MDBDropdown className={`batch-action-dropdown ${!shouldEnabled ? "disabled" : ""}`}>
            <MDBDropdownToggle tag="span" disabled={!shouldEnabled}>
                <span>
                    <MDBTooltip
                        domElement
                        placement="bottom"
                        style={{ position: "absolute", width: "10rem", marginTop: "-1rem" }}
                    >
                        <span>
                            <FontAwesomeIcon icon={faTrash} className={`icon ${!shouldEnabled ? "disabled" : ""}`} />
                        </span>
                        <div>{TOOLTIP_TITLE}</div>
                    </MDBTooltip>
                </span>
            </MDBDropdownToggle>

            <MDBDropdownMenu basic className="z-depth-3 mini-project-action-popup batch-action-delete">
                <h5 className="mini-collection-menu-title copy-project-title">
                    Delete&nbsp;
                    <strong>
                        {projects.length} projects&nbsp; ({blocks.length} blocks)
                    </strong>
                </h5>

                <p>
                    This action cannot be undone.
                    <br />
                    <br />
                    <strong>Are you sure you wish to continue?</strong>
                </p>

                <MDBDropdownItem tag="div" className="mb-2 hide-hover">
                    <div className="row no-gutters justify-content-end">
                        <div className="col-4">
                            <button className="btn btn-default">Cancel</button>
                        </div>
                        <div className="col-5">
                            <button
                                className="btn btn-primary"
                                onClick={deleteProjectsFromCollection}
                            >
                                Yes, delete
                            </button>
                        </div>
                    </div>
                </MDBDropdownItem>
            </MDBDropdownMenu>
        </MDBDropdown>
    );
};

export const _BatchDelete = React.memo(
    __BatchDelete,
    (
        { projects: prevProjects, selectedCollection: prevCollection, readOnly: prevReadonly, blocks: prevBlocks },
        { projects: nextProjects, selectedCollection: nextCollection, readOnly: nextReadonly, blocks: nextBlocks }
    ) => {
        return (
            BaseORMModel.getVersionId(prevProjects ?? []) === BaseORMModel.getVersionId(nextProjects ?? []) &&
            BaseORMModel.getVersionId(prevBlocks ?? []) === BaseORMModel.getVersionId(nextBlocks ?? []) &&
            BaseORMModel.getVersionId(prevCollection ?? {}) === BaseORMModel.getVersionId(nextCollection ?? {}) &&
            prevReadonly === nextReadonly
        );
    }
);

const BatchDelete = connect(mapStateToPropsOfActions)(_BatchDelete);

export default BatchDelete;
