import React, { isValidElement } from 'react';
import { useFormWizard } from './FormWizardContext';
import PropTypes from 'prop-types';
import { PAGE_STATUSES, SURVEY_QUESTION_TYPES } from '../../utils/constants';

class BasePageGroup extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const pages = {};
        
        React.Children.map(this.props.children, (child, index) => {
            if (isValidElement(child) && child.props.pageId) {
                pages[child.props.pageId] = { index };
            }
        });

        this.props.registerPageGroupMap && this.props.registerPageGroupMap(this.props.groupId, pages);
    }

    componentWillUnmount(){
        this.props.removePageGroupMap && this.props.removePageGroupMap(this.props.groupId);
    }

    // Only update page group if the currentStep changes from FormWizard...
    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.currentStep.pageGroup !== this.props.currentStep.pageGroup ||
    //         nextProps.currentStep.index !== this.props.currentStep.index ||
    //         nextProps.project !== this.props.project;
    // }

    // Clone child and pass down displayForm prop to let child decide whether to display itself
    render() {
        const { pageGroup, index } = this.props.currentStep;
        return React.Children.map(this.props.children, (child, childIndex) => {
            if (isValidElement(child)) {
                return React.cloneElement(
                    child,
                    {
                        groupId: this.props.groupId,
                        displayForm: childIndex === index && pageGroup === this.props.groupId,
                    }
                );
            }
        });
    }
}

BasePageGroup.propTypes = {
    project: PropTypes.object, // From Redux

    /* Below keys are from FormWizard Context .... 
        @@@@@@@@ START @@@@@@@
    */
    currentPageMap: PropTypes.shape({ // From FormWizard Context
        index: PropTypes.number,
        status: PropTypes.oneOf([PAGE_STATUSES.COMPLETE, PAGE_STATUSES.INCOMPLETE, PAGE_STATUSES.OPTIONAL])
    }),
    currentStep: PropTypes.shape({
        pageGroup: PropTypes.string,
        index: PropTypes.number
    }),
    dependencyForQuestions: function (props, propName, componentName) {
        if (/[0-9]+[a-z]?/i.test(props['dependencyForQuestions'])) {
            return new Error('Validation failed!');
        }
    },
    canNext: PropTypes.bool,
    canPrev: PropTypes.bool,
    next: PropTypes.func,
    prev: PropTypes.func,
    updatePageStatus: PropTypes.func,
    setCurrentStep: PropTypes.func,
    /* 
        @@@@@@@@ END @@@@@@@
    */
    questionSets: PropTypes.arrayOf(PropTypes.shape({
        class: PropTypes.string,
        description: PropTypes.string,
        question: PropTypes.arrayOf(PropTypes.shape({
            description: PropTypes.string,
            id: PropTypes.string,
            responses: PropTypes.arrayOf(PropTypes.shape({
                description: PropTypes.string,
                id: PropTypes.string,
                weight: PropTypes.number
            })),
            type: PropTypes.oneOf([SURVEY_QUESTION_TYPES.NUMERIC, SURVEY_QUESTION_TYPES.MULTIPLECHOICE]),
            visible: PropTypes.bool
        }))
    })),
    groupId: PropTypes.string.isRequired,
};

const PageGroup = ({ children, groupId, ...props }) => {
    return <BasePageGroup
        {...props}
        children={children}
        groupId={groupId}
        {...useFormWizard()}
    />;
};

export { BasePageGroup, PageGroup };