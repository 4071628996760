import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Multiselect } from 'multiselect-react-dropdown';
import Select from 'react-select';
import { ID_ATTRIBUTE } from '../../utils/constants';

import './AutocompleteMultiSelect.scss';

const AutocompleteMultiSelect = ({ selectedValues, options, onChange }) => {
    const [value, setValue] = useState([]);

    const _onChange = (values) => {
        setValue(values);
        onChange(values);
    };

    useEffect(() => {
        const formatted = selectedValues.map(sV => ({ label: sV.name, value: sV[ID_ATTRIBUTE] }));
        setValue(formatted);
    }, [selectedValues]);

    const values = options.map(o => ({ label: o.name, value: o[ID_ATTRIBUTE] }));

    return (
        <Select
            value={value}
            onChange={_onChange}
            options={values}
            classNamePrefix="multiselect"
            isMulti
        />
    );

    // return (
    //     <Multiselect
    //         options={options} // Options to display in the dropdown
    //         placeholder="+ Add parent"
    //         selectedValues={selectedValues}
    //         onSelect={onSelect} // Function will trigger on select event
    //         onRemove={onRemove} // Function will trigger on remove event
    //         displayValue="name"
    //     />
    // );
};
AutocompleteMultiSelect.propTypes = {
    selectedValues: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func
};
AutocompleteMultiSelect.defaultProps = {
    selectedValues: [],
    options: [],
    onChange: () => false
};

export default AutocompleteMultiSelect; 