import React from 'react';
import { EventHanlders, EventEmitter } from "../EventEmitter";
import { useRef, useState, useEffect } from "react";
import { SUCCESS_CHANNEL, ERROR_CHANNEL } from "../../utils/constants";
import { Toast } from "../Toast";

const SuccessNotifer = ({ successChannel,
    errorChannel,
    onStart = null,
    onSuccess = null,
    onError = null,
    successFn = null,
    startFn = null,
    errorFn = null
    // isError = null,
}) => {

    let [success, setSuccess] = useState(null);
    let [error, setError] = useState(null);

    // useEffect(()=>{
    //     if (isError !== null && isError !== undefined) {

    //     }
    // }, [isError]);
    const eventHandlers = useRef(new EventHanlders());

    useEffect(() => {

        if (SUCCESS_CHANNEL[successChannel]) {
            eventHandlers.current.addEvents(SUCCESS_CHANNEL[successChannel], async (event) => {
                // event && onSuccess && onSuccess(event);
                event && setSuccess(event);
            });

            // console.log(SUCCESS_CHANNEL[successChannel]);

            EventEmitter.subscribe(SUCCESS_CHANNEL[successChannel], eventHandlers.current.events[SUCCESS_CHANNEL[successChannel]]);

            return () => {
                EventEmitter.off(SUCCESS_CHANNEL[successChannel], eventHandlers.current.events[SUCCESS_CHANNEL[successChannel]]);
            };
        }

        return () => {
        };
    }, [successChannel]);

    useEffect(() => {

        if (ERROR_CHANNEL[errorChannel]) {
            eventHandlers.current.addEvents(ERROR_CHANNEL[errorChannel], async (event) => {
                // event && onSuccess && onSuccess(event);

                setError(event);
            });

            // console.log(ERROR_CHANNEL[errorChannel]);

            EventEmitter.subscribe(ERROR_CHANNEL[errorChannel], eventHandlers.current.events[ERROR_CHANNEL[errorChannel]]);

            return () => {
                EventEmitter.off(SUCCESS_CHANNEL[errorChannel], eventHandlers.current.events[ERROR_CHANNEL[errorChannel]]);
            };
        }

        return () => {
        };
    }, [errorChannel]);


    useEffect(() => {
        if (success && success.type === "success") {
            setError(null);
            onSuccess && onSuccess(success.data);
        } else if (success && success.type === "start") {
            setError(null);
            onStart && onStart();
        }

    }, [success]);

    useEffect(() => {
        if (error) {
            onError && onError(error);
        }

    }, [error]);

    const isSuccess = (success ?? {}).type === "success";
    const isStart = (success ?? {}).type === "start";
    const isError = error != null;

    return (<>
        {
            isError && errorFn && errorFn(error)
        }
        {
            !isError && isStart && startFn && startFn()
        }
        {
            !isError && (isSuccess) && successFn && successFn((success ?? {}).data)
        }
    </>
    );
};

export default SuccessNotifer;