import React from 'react';
import { MDBTooltip } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';

const IssueDisplay = ({ issues = [] }) => {
    return issues.map((issue, index) => {

        const { message, detailedMessage, meta, render } = issue;

        return (
            <MDBTooltip
                domElement
                tag="div"
                key={index}
                style={{}}
            >
                <span>
                    <span className="error-block">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        &nbsp;{message && message(meta)}
                    </span>
                    {render && render()}
                    <br />
                </span>

                <span>{detailedMessage && detailedMessage(meta)}</span>
            </MDBTooltip>
        );
    });
};

export default IssueDisplay;