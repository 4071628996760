import { HttpMethods, ServerType, Url } from '../utils/constants';
import { UrlBuilder, https } from '../api';
import BaseService from './base.service';

class FundingService extends BaseService {
    constructor(props) {
        super(props);
    }

    /**
     * 
     * @param {*} program 
     */
    async getFunding(program = false) {
        const query = program ? { expand: "fundingPrograms" } : {};
        let url = UrlBuilder(ServerType.Data, Url.Funding, [], query);
        const funding = await https({ url, method: HttpMethods.Get });
        return funding;
    }

    /**
     * 
     * @param {*} data 
     */
    async addFundingBody(data) {
        let url = UrlBuilder(ServerType.Data, Url.Funding);
        const fundingBody = await https({ url, method: HttpMethods.Post, data });
        return fundingBody;
    }

    /**
     * 
     * @param {*} id 
     * @param {*} data 
     */
    async addFundingProgram(id, data) {
        let url = UrlBuilder(ServerType.Data, Url.ProgramForFundingBody, [id]);
        const fundingProgram = await https({ url, method: HttpMethods.Post, data });
        return fundingProgram;
    }

    /**
     * 
     * @param {*} data 
     */
    async updateFundingBody(data) {
        let url = UrlBuilder(ServerType.Data, Url.Funding);
        const fundingBody = await https({ url, method: HttpMethods.Post, data });
        return fundingBody;
    }

    /**
     * 
     * @param {*} id 
     * @param {*} data 
     */
    async updateFundingProgram(id, data) {
        let url = UrlBuilder(ServerType.Data, Url.FundingProgram, [id]);
        const fundingProgram = await https({ url, method: HttpMethods.Put, data });
        return fundingProgram;
    }

}

export default FundingService;