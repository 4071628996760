import React, { useRef, useEffect, useState } from 'react';
import "./ToggleButton.scss";
import { ID_ATTRIBUTE } from '../../utils/constants';

const selectItem = ({ items, selectedItemId, setSelected }) => {
    const def = (items ?? []).filter(item => item.default)[0];
    const selected = (items ?? []).filter(item => item[ID_ATTRIBUTE] === selectedItemId)[0];
    setSelected(selected ?? def);
};

const ToggleButton = ({ items = [],

    selectedItemId = null,

    className = null,

    onItemSelected = null
} = {}) => {

    const [selected, setSelected] = useState(undefined);

    // useEffect(() => {
    //     selectItem({ items, selectedItemId, setSelected });
    // }, [selectedItemId]);

    useEffect(() => {
        selectItem({ items, selectedItemId, setSelected });
    }, []);

    useEffect(() => {
        onItemSelected && onItemSelected(selected);
    }, [selected]);

    return <div className={`btn-group toggle-btn ${className ?? ''}`}>
        {
            (items ?? []).map(item => {
                return <button type="button"
                    key={item[ID_ATTRIBUTE]}
                    className={`btn btn-default ${(selected ?? {})[ID_ATTRIBUTE] === item[ID_ATTRIBUTE] ? 'selected' : 'non-selected'} ${item.className ?? ''}`}
                    disabled={item.disabled}
                    onClick={() => {
                        selectItem({ items, selectedItemId: item[ID_ATTRIBUTE], setSelected });
                    }}
                >{
                        item.label
                    }</button>;
            })
        }
    </div>;
};

export default ToggleButton;