import _ from "lodash";
import { ID_ATTRIBUTE, LATEST_SURVEY_VERSION } from "./constants";
import numeral from "numeral";
import { store } from "../redux";
import orm from "../models/orm.register";
import { ORM_SLICE, ORM_COLLECTION_SLICE, ORM_PROJECT_SLICE } from "../utils/constants";

const SEPARATOR = "____";

export const guid = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

export const getProjectMapBoundsFieldName = (projectId) => {
    return `${projectId}-mapBounds`;
};

export const keyValueStringContent = (obj) => {
    const sortedKeys = _.sortBy(Object.keys(obj ?? {}), (d) => d);

    const pairs = sortedKeys.map((key) => {
        try {
            return `${key}:${JSON.stringify(obj[key])}`;
        } catch (ex) {
            console.log(ex);
            return `${key}:null`;
        }
    });

    return _.join(pairs, SEPARATOR);
};

export const boundValue = (value, range) => {
    let min = (range ?? {}).min;
    let max = (range ?? {}).max;

    let boundedValue = value !== null && value !== undefined ? parseFloat(`${numeral(value).value()}`) : null;
    if (min !== null && min !== undefined && min !== "inf" && boundedValue < parseFloat(`${min}`)) {
        boundedValue = parseFloat(`${min}`);
    }

    if (max !== null && max !== undefined && max !== "inf" && boundedValue > parseFloat(`${max}`)) {
        boundedValue = parseFloat(`${max}`);
    }
    return boundedValue;
};

export const extractCSSVar = (variables, name) => {
    const _styles = {};
    variables = variables ?? {};
    Object.keys(variables)
        .filter((key) => {
            return key.indexOf(name) > -1;
        })
        .forEach((key) => {
            const value = variables[key];
            key = key.replace(`${name}-`, "");
            _styles[key] = value;
        });

    return _styles;
};

export const getProjectsBasedOnFilter = (filter) => {
    const state = store.getState();
    const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());
    const projects = session[ORM_PROJECT_SLICE].select(session, {
        filter: filter,
    });

    if (!projects) return [];

    return projects;
};

/**
 * Check if the project contains blocks with survey version that are not
 * the latest for the project industry
 * @param {Object} project The project to check for conflict
 * @returns Whether the project has survey version mismatch
 */
const hasSurveyVersionMismatchConflict = (project) => {
    if (!project || !project.blocks || project.blocks.length === 0) {
        return false;
    }

    const latestVersionForIndustry = LATEST_SURVEY_VERSION[project.industry];

    if (!latestVersionForIndustry) {
        return true;
    }

    const hasMismatched = project.blocks.some((b) => b.surveyVersion !== latestVersionForIndustry);

    return hasMismatched;
};

export const generateProjectIssues = (project) => {
    const {
        survey = {},
        conflictProperties: { lookupFails, outsideOfRegion, erosionRateProviderError },
    } = project;

    return {
        incompleteSurveyConflict: {
            status: survey.incompleteSurveyConflict,
            meta: {},
        },
        customSoilClimatePermissionConflict: {
            status: survey.customSoilClimatePermissionConflict,
            meta: {},
        },
        hasLookupFails: {
            status: lookupFails,
            meta: {},
        },
        outsideOfRegion: {
            status: outsideOfRegion,
            meta: {},
        },
        surveyVersionMismatchConflict: {
            status: hasSurveyVersionMismatchConflict(project),
            meta: {},
        },
        erosionRateProviderError: {
            status: erosionRateProviderError,
            meta: {},
        },
    };
};

export const generateBlockIssues = (block) => {
    const {
        conflictProperties: {
            conflict,
            lookupFails,
            lookupFailureImpactAreas,
            lookupFailureImpactRatio,
            outsideOfRegion,
            paddockCodesFailsToResolve,
            erosionRateProviderError,
        },
    } = block;

    return {
        hasLookupFails: {
            status: lookupFails,
            meta: {
                percentage: lookupFailureImpactRatio >= 1 ? 100 : lookupFailureImpactRatio * 100,
            },
        },
        outsideOfRegion: {
            status: outsideOfRegion,
            meta: {},
        },
        erosionRateProviderError: {
            status: erosionRateProviderError,
            meta: {},
        },
    };
};

export const generateProjectSkeleton = (projectId, collectionId, isNew) => {
    // skeleton project data

    const state = store.getState();

    const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

    const collection = session[ORM_COLLECTION_SLICE].select(session, {
        filter: {
            [ID_ATTRIBUTE]: collectionId,
        },
    })[0];

    if (!collection) {
        throw new Error("Collection doesn't exist");
    }

    let project = {};

    if (projectId) {
        const projectToClone = session[ORM_PROJECT_SLICE].select(session, {
            filter: {
                [ID_ATTRIBUTE]: projectId,
            },
        })[0];

        if (!projectToClone) throw new Error("Project does not exist");

        project = {
            industry: null,
            fundingBody: null,
            fundingProgram: null,
            landholderCash: null,
            landholderDays: null,
            organisationCash: null,
            organisationDays: null,
            project_title: null,

            survey: {},
            ...(projectToClone ?? {}),
        };

        if (isNew) {
            project.dateCreated = null;
        }
    }

    return {
        industry: collection.industry,
        fundingBody: collection.fundingBody,
        fundingProgram: collection.fundingProgram,
        landholderCash: null,
        landholderDays: null,
        organisationCash: null,
        organisationDays: null,
        project_title: "",
        survey: {},

        // if project exists, replace the attributes
        ...(project ?? {}),
        [ID_ATTRIBUTE]: guid(),
    };
};

/**
 * The function should return result object (not sure if @output is proper keyword)
 * @param {Array<BlockORMModel} blocks - an array of block instances
 * @param {{[measureClass]: {label, state}}} measureMap - a constant instance of different measure classes
 * @param {{[measureUnitClass]: {label, state}}} measureUnitMap - a constant instance of different measure unit classes
 * @return {{[class]: {before, after, reduction, area, measure, unit, class}}} - report instance
 */
export const reportResultsOfBlocks = (blocks, measureMap = {}, measureUnitMap = {}) => {
    measureMap = measureMap ?? {};
    measureUnitMap = measureUnitMap ?? {};
    blocks = blocks ?? [];

    let results = {};
    blocks.forEach((block) => {
        const { results: blkResults } = block;

        _.values(blkResults ?? {}).forEach((blkResult) => {
            if (!results[blkResult.class]) {
                results[blkResult.class] = {
                    before: 0,
                    after: 0,
                    reduction: 0,
                    area: 0,
                    // measure: (MEASURE_MAP[blkResult.class] ?? {}).label ?? blkResult.measure,
                    // unit: (MEASURE_UNIT_MAP[blkResult.unit] ?? {}).label ?? "unit",
                    measure: (measureMap[blkResult.class] ?? {}).label ?? blkResult.measure,
                    unit: (measureUnitMap[blkResult.unit] ?? {}).label ?? "unit",

                    unitClass: blkResult.unit,

                    // class is the measure class
                    class: blkResult.class,
                };
            }

            // assuming all block units are the same to do the calculation in here
            results[blkResult.class].before += blkResult.before ?? 0;
            results[blkResult.class].after += blkResult.after ?? 0;
            results[blkResult.class].area += blkResult.area ?? 0;
            results[blkResult.class].reduction +=
                Math.round((blkResult.before - blkResult.after) * blkResult.area * 100) / 100;
        });
    });

    return results;
};

export const reportConflictsOfBlocks = (blocks, project) => {
    if (!project) {
        project = {};
    }

    if (!blocks) {
        blocks = [];
    }

    const incompleteSurveyConflict = project.survey?.incompleteSurveyConflict ?? false;
    const customSoilClimatePermissionConflict = project.survey?.customSoilClimatePermissionConflict ?? false;
    const conflictBlocks = blocks.map((block) => block.conflictProperties?.conflict).filter((d) => d === true);

    const lookupFailsConflictBlocks = blocks
        .map((block) => block.conflictProperties?.lookupFails)
        .filter((d) => d === true);
    const outsideOfRegionConflictBlocks = blocks
        .map((block) => block.conflictProperties?.outsideOfRegion)
        .filter((d) => d === true);
    const erosionRateProviderErrorConflictBlocks = blocks
        .map((block) => block.conflictProperties?.erosionRateProviderError)
        .filter((d) => d === true);

    const hasConflict = incompleteSurveyConflict || customSoilClimatePermissionConflict || conflictBlocks.length !== 0;
    const hasLookupFails = lookupFailsConflictBlocks.length !== 0;
    const hasOutsideOfRegion = outsideOfRegionConflictBlocks.length !== 0;
    const hasErosionRateProviderError = erosionRateProviderErrorConflictBlocks.length !== 0;

    return {
        conflict: hasConflict,
        lookupFails: hasLookupFails,
        outsideOfRegion: hasOutsideOfRegion,
        erosionRateProviderError: hasErosionRateProviderError,
        customSoilClimatePermissionConflict: customSoilClimatePermissionConflict,
    };
};

export const getProjectsAndBlocksFromBlocks = (blocks, selectedProjectMap) => {
    blocks = blocks ?? [];

    const blockMap = _.keyBy(blocks, (block) => block[ID_ATTRIBUTE]);

    // delete a project if all blocks in the projects selected
    // finding blocks where every block in the project of blocks is selected
    let projects = [];
    blocks
        .filter((block) => selectedProjectMap[block.projectId])
        .filter((block) => {
            // const blockCount = ((block.project ?? {}).blocks ?? []).length;
            // const selectedBlockCount = ((block.project ?? {}).blocks ?? []).filter(projectBlock => {
            //     return blockMap[projectBlock[ID_ATTRIBUTE]] ? true : false
            // }).length;

            const blockCount = ((selectedProjectMap[block.projectId] ?? {}).blocks ?? []).length;
            const selectedBlockCount = ((selectedProjectMap[block.projectId] ?? {}).blocks ?? []).filter(
                (projectBlock) => {
                    return blockMap[projectBlock[ID_ATTRIBUTE]] ? true : false;
                }
            ).length;

            if (blockCount === selectedBlockCount && selectedProjectMap[block.projectId]) {
                projects.push({
                    ...(selectedProjectMap[block.projectId] ?? {} ?? {}),
                    blocks: null,
                });
            }

            return blockCount !== selectedBlockCount;
        });
    projects = _.uniqBy(projects, (d) => d[ID_ATTRIBUTE]);

    const projectMap = _.keyBy(projects, (project) => project[ID_ATTRIBUTE]);
    const validBlocks = blocks.filter((block) => !projectMap[block.projectId]);
    return {
        blocks: validBlocks,
        projects,
    };
};
