import React, { useEffect } from 'react';
import * as yup from "yup";
import { useFormWizard } from './FormWizardContext';
import { PAGE_STATUSES } from '../../utils/constants';
import PropTypes from 'prop-types';

const { COMPLETE, INCOMPLETE, OPTIONAL } = PAGE_STATUSES;

const DEF_FORM_DATA = {};
const DEF_SCHEMA = {};

const FormStatusController = ({
    pageId,
    groupId,
    displayForm,
    children,
    schema = DEF_SCHEMA,
    formData = DEF_FORM_DATA,
    isFormOptional = false
}) => {

    /** 
        Utility function that generates a object to run the validation against a YupSchema
        @param {YupSchema} schemaForController - Schema to run validation on 
        
        @returns {void} 
    */
    const validateFormAndUpdateStatus = async (schemaForController) => {
        const answers = {};
        if (schemaForController && schemaForController.fields) {
            Object.keys(schemaForController.fields).forEach(fieldName => {
                if (schemaForController.fields[fieldName] && !schemaForController.fields[fieldName].ignoreInController)
                    answers[fieldName] = formData[fieldName];
            });
            const isFormClean = await schemaForController.isValid(answers);
            updatePageStatus(groupId, pageId, (isFormOptional && !isFormClean) ? OPTIONAL : isFormClean ? COMPLETE : INCOMPLETE);
        }
    };

    const props = { pageId, groupId, displayForm, schema };
    const { updatePageStatus } = useFormWizard();
    const childrenWithNewProps = React.Children.map((children), (child) => {
        return React.cloneElement(child, props);
    });

    useEffect(() => {
        if (formData && schema) {
            validateFormAndUpdateStatus(schema);
        }
    }, [formData, schema]);

    return displayForm ? childrenWithNewProps : null;
};

FormStatusController.propTypes = {
    pageId: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    displayForm: PropTypes.bool,
    // children: PropTypes,
    schema: PropTypes.object, // Yup validation (i.e. formSchema.js)
    formData: PropTypes.object, // Pulled from redux 
    isFormOptional: PropTypes.bool // Is this form required to be completed ?
};

function areEquals(prevProps, nextProps) {
    const { displayForm: prevDisplayForm, formData: prevFormData, schema: prevSchema } = prevProps;
    const { displayForm: nextDisplayForm, formData: nextFormData, schema: nextSchema } = nextProps;
    return prevDisplayForm === nextDisplayForm &&
        prevFormData === nextFormData &&
        prevSchema === nextSchema;
}

export default React.memo(FormStatusController, areEquals);