import { Model, ORM, fk, many, attr, oneToOne } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_PROJECT_SLICE,
    ID_ATTRIBUTE,
    ORM_COLLECTION_SLICE,
    ORM_FUNDING_BODY_SLICE,
    ORM_FUNDING_PROGRAM_SLICE,
} from "../utils/constants";

export default class ProjectORMModel extends BaseORMModel {}

ProjectORMModel.modelName = ORM_PROJECT_SLICE;

ProjectORMModel.fields = {
    [ID_ATTRIBUTE]: attr(),
    collectionId: fk({
        to: ORM_COLLECTION_SLICE,
        as: "collection",
        relatedName: "projects",
    }),

    dateCreated: attr(),
    dateUpdated: attr(),

    description: attr(),
    fundingBody: fk({
        to: ORM_FUNDING_BODY_SLICE,
        as: "fundingBody",
        relatedName: "projects",
    }),
    fundingProgram: fk({
        to: ORM_FUNDING_PROGRAM_SLICE,
        as: "fundingProgram",
        relatedName: "projects",
    }),

    industry: attr(),
    landholderCash: attr(),
    landholderDays: attr(),
    organisationCash: attr(),
    organisationDays: attr(),
    project_title: attr(),
    survey: attr(),

    /**
     * {
     *      _id
     *      code
     *      regionNodeId
     *      catchmentNodeId
     *      climateNodeId
     *      soilNodeId
     * }
     */
    soilClimateCode: attr(),

    // incompleteSurveyConflict: false
    // q1_after: "1_1"
    // q1_before: "1_2"
    // q2_after: "2_3"
    // q2_before: "2_1"
    // q3_after: "3_3"
    // q3_before: "3_1"
    // q4_after: "4_3"
    // q4_before: "4_1"
    // q5_after: "5_3"
    // q5_before: "5_1"
    // q6_after: "6_3"
    // q6_before: "6_1"
    // q7_after: "7_3"
    // q7_before: "7_1"
    // q8_after: "8_3"
    // q8_before: "8_1"
    // q9_after: "9_3"
    // q9_before: "9_1"
    // q10_after: "10_3"
    // q10_before: "10_1"
    // q11_after: "11_3"
    // q11_before: "11_1"
    // q12_after: "12_3"
    // q12_before: "12_1"
    // q13_after: "13_3"
    // q13_before: "13_1"
    // qConflicts: {}
    // qMissing: {}

    __v: attr(),
};
