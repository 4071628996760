import React, { useEffect, useState, useContext, useRef } from "react";
import { Doughnut, Bar, HorizontalBar, Line } from 'react-chartjs-2';
import _ from 'lodash';
import styles from './ComboSeriesChart.module.scss';

export const ComboSeriesChart = ({ data: chart, className, title, ...others }) => {
    // const chart = {
    //     "datasets":
    //         [
    //             {
    //                 "data":
    //                     [30, 77.607, 94.214, 127.419, 14.322, 156.083, 165, 17, 21.364, 23.78],
    //                 "label": "Export weight at point of loading (t)", "pointRadius": 4, "lineTension": 0, "fill": false, "spanGaps": true, "borderWidth": 5,
    //                 "backgroundColor": "green",
    //                 "borderColor": "green",
    //                 "pointBackgroundColor": 'green',
    //                 "type": "line",
    //                 yAxisID: 'right',
    //             },
    //             {
    //                 "data":
    //                     [7789648.606, 7604900.607, 9363593.214, 11736528.419, 13121208.322, 14603884.083, 15535049.669, 16695611.909, 20055324.364, 22603424.78],
    //                 "label": "Export weight at point of loading (t)", "pointRadius": 4, "lineTension": 0, "fill": false, "spanGaps": true, "borderWidth": 5,
    //                 "backgroundColor": "#DE9B9E",
    //                 "borderColor": "#DE9B9E",
    //                 "pointBackgroundColor": '#DE9B9E',
    //                 yAxisID: 'left',
    //             }

    //         ], "labels": ["2008-09", "2009-10", "2010-11", "2011-12", "2012-13", "2013-14", "2014-15", "2015-16", "2016-17", "2017-18"]
    // };


    // bar with line
    // const chart = {
    //     "data": {
    //         "datasets":
    //             [{
    //                 "pointRadius": 4, "lineTension": 0.1, "fill": false, "spanGaps": true,
    //                 "borderWidth": 3, "type": "line", "yAxisID": "right",
    //                 "data": [71.3, 72.3, 82.3, 79.6, 80.5, 76.1, 76.9, 72.2, 76, 74.5, 76.9, 85.7, 84.1, 83, 82.5, 75, 76.7, 73.3, 75.2, 71.9, 78.5, 81.4, 79.8, 92.4, 86.7, 85.7, 79.8, 77.3, 76.7, 77.1, 75.4, 71.9],
    //                 "label": "Mean late dry season ground cover (%)", "backgroundColor": "#13498c", "borderColor": "#13498c"
    //             },
    //             { "yAxisID": "left", "data": [669.57, 804.64, 1040.83, 862.34, 934.21, 627.19, 578.13, 648.68, 586.6, 774.46, 822.64, 911.46, 1036.56, 964.59, 850.38, 650.55, 561.53, 676.64, 710.56, 778.14, 754.74, 1030.95, 900.65, 955.37, 1514.57, 1014.62, 736.17, 714.64, 675.03, 780.17, 692.17, 732.99], "label": "Rainfall (mm)", "backgroundColor": "#189cc6", "borderColor": "#189cc6" }
    //             ], "labels": [1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018]
    //     },
    //     "options": { "tooltips": { "enabled": true, "intersect": false, "mode": "index", "titleFontFamily": "'Lato', sans-serif", "bodyFontFamily": "'Lato', sans-serif", "footerFontFamily": "'Lato', sans-serif", "titleFontSize": 14.4, "bodyFontSize": 14.4, "footerFontSize": 14.4, "callbacks": {} }, "hover": { "mode": "index", "intersect": false }, "legend": { "display": true, "onClick": false, "labels": { "fontFamily": "'Lato', sans-serif", "fontSize": 13, "boxWidth": 13 } }, "maintainAspectRatio": false, "scales": { "yAxes": [{ "id": "left", "position": "left", "scaleLabel": { "display": true, "labelString": "Rainfall (mm)" }, "display": true, "stacked": false, "gridLines": { "display": false, "drawBorder": true }, "ticks": { "beginAtZero": true } }, { "id": "right", "position": "right", "scaleLabel": { "display": true, "labelString": "Ground cover (%)" }, "display": true, "stacked": false, "gridLines": { "display": false, "drawBorder": true }, "ticks": { "beginAtZero": true } }], "xAxes": [{ "display": true, "ticks": { "fontFamily": "'Lato', sans-serif", "fontSize": 12.8 }, "gridLines": { "display": false, "drawBorder": true } }] }, "plugins": { "flexiTickArea": false, "datalabels": false, "stacked100": false, "doughnutlabel": false, "labels": false, "deferred": { "yOffset": "30%", "delay": 500 } } }
    // };

    // bar chart
    // chart = {
    //     "data": {
    //         "datasets":
    //             [
    //                 barSeriesStyles({
    //                     data: [669.57, 804.64, 1040.83, 862.34, 934.21, 627.19, 578.13, 648.68, 586.6, 774.46, 822.64, 911.46, 1036.56, 964.59, 850.38, 650.55, 561.53, 676.64, 710.56, 778.14, 754.74, 1030.95, 900.65, 955.37, 1514.57, 1014.62, 736.17, 714.64, 675.03, 780.17, 692.17, 732.99],
    //                     label: "Rainfall (mm)"
    //                 })
    //             ], "labels": [1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018]
    //     },
    //     "options": globalTwoAxisStyles({ leftLabel: "aaa", leftMax: 1800 })
    // };

    // bar two column chart
    // chart = {
    //     "data": {
    //         "datasets":
    //             [
    //                 barSeriesStyles({
    //                     data: [669.57, 804.64, 1040.83, 862.34, 934.21],
    //                     label: "Total nitrogen"
    //                 }),
    //                 barSeriesStyles({
    //                     data: [669.57, 804.64, 1040.83, 862.34, 934.21],
    //                     label: "Total phosphorus"
    //                 })
    //             ], "labels": ["Base line 1987", 1988, 1989, 1990, 1991]
    //     },
    //     "options": globalTwoAxisStyles({ leftLabel: "aaa", leftMax: 1800 })
    // };

    // bar flow rule chart
    // chart = {
    //     "data": {
    //         "datasets":
    //             [
    //                 barSeriesStyles({
    //                     data: [100, 90, 0, 81, 82],
    //                     label: "Total nitrogen",
    //                     backgroundColor: ['blue', 'blue', 'blue', 'blue', 'yellow']
    //                 }),
    //                 barSeriesStyles({
    //                     data: [100, 90, 80, 81, 82],
    //                     label: "Total nitrogen 2",
    //                     backgroundColor: ['blue', 'blue', 'blue', 'blue', 'yellow']
    //                 }),
    //                 barSeriesStyles({
    //                     data: [100, 90, 100, 81, 82],
    //                     label: "Total nitrogen 3",
    //                     backgroundColor: ['blue', 'blue', 'blue', 'blue', 'yellow']
    //                 }),
    //                 barSeriesStyles({
    //                     data: [100, 90, 0, 81, 82],
    //                     label: "Total nitrogen 4",
    //                     backgroundColor: ['blue', 'blue', 'blue', 'blue', 'yellow']
    //                 }),
    //             ], "labels": ["2018-19", "2019-20", "2020-21", "2021-22", "2022-23"]
    //     },
    //     "options": globalTwoAxisStyles({ leftLabel: "aaa", leftMax: 8 })
    // };

    // console.log(chart);

    // vertical stack
    // chart = {
    //     "data": {
    //         "datasets":
    //             [
    //                 barSeriesStyles({
    //                     data: [100, 90, 0, 81, 82],
    //                     label: "Very low",
    //                     backgroundColor: 'red'
    //                 }),
    //                 barSeriesStyles({
    //                     data: [100, 90, 0, 81, 82],
    //                     label: "Low",
    //                     backgroundColor: 'orange'
    //                 }),

    //             ], "labels": ["2018-19", "2019-20"]
    //     },
    //     "options": globalTwoAxisStyles({ leftLabel: "aaa", legendDisplay: false, leftMax: 3, stacked: true })
    // };


    // horizontal stack
    // const stacked100Converter = (datasets) => {
    //     const v = [];

    //     (datasets ?? []).forEach(({ data = [] }, index) => {
    //         data.forEach((d, dIndex) => {
    //             v[dIndex] = d + (v[dIndex] ?? 0);
    //         });
    //     });

    //     return (datasets ?? []).map((dataset, index) => {

    //         let { data = [] } = dataset;

    //         data = data.map((d, pieceIndex) => {
    //             if (v[pieceIndex] == null || v[pieceIndex] === 0)
    //                 return 0;
    //             return d / v[pieceIndex] * 100;
    //         });

    //         return {
    //             ...dataset ?? {},
    //             dataRaw: dataset.data,
    //             data
    //         };
    //     });
    // };

    // chart = {
    //     // type: 'bar',
    //     type: 'horizontalBar',
    //     "data": {
    //         "datasets":
    //             stacked100Converter([

    //                 baseSeriesStyles({
    //                     data: [100, 90, 0, 81, 82],
    //                     label: "Very low",
    //                     backgroundColor: 'red'
    //                 }),
    //                 baseSeriesStyles({
    //                     data: [100, 90, 0, 81, 82],
    //                     label: "Low",
    //                     backgroundColor: 'orange'
    //                 }),
    //                 baseSeriesStyles({
    //                     data: [100, 90, 0, 81, 82],
    //                     label: "VLow",
    //                     backgroundColor: 'orange'
    //                 }),

    //             ])

    //         , "labels": ["2018-19", "2019-20", "2018-19", "2019-20"]
    //     },
    //     // options: {
    //     //     plugins: {
    //     //         stacked100: { enable: true }
    //     //     }
    //     // }
    //     "options": globalTwoAxisStyles({
    //         leftLabel: '', legendDisplay: false, leftMax: 0, stacked: true
    //     })
    // };

    // (((chart ?? {}).options ?? {}).scales ?? {}).xAxes = [
    //     {
    //         display: false,
    //         stacked: true,
    //         ticks: {
    //             display: false,
    //         },
    //         gridLines: {
    //             display: false
    //         }
    //     }
    // ]

    // console.log(chart);

    if (!chart || !chart.data || !chart.options)
        return null;

    const chartType = {
        'bar': Bar,
        'horizontalBar': HorizontalBar
    }
    return <>
        <div className={`context-chart combo-series ${className}`}>
            {
                title != null && <div className="col-12 p-0 title">{title}</div>
            }
            {
                chart && chart.topContent
            }
            <div className={"col-12 chart"}>


                <div className="chart-area">
                    {
                        // React.createElement(Bar, chart, null)
                        React.createElement(chartType[chart.type ?? 'bar'] ?? Bar, chart, null)
                    }
                </div>

            </div>
            {
                chart && chart.bottomContent
            }
        </div>
    </>
};