import React, { useEffect, useState } from "react";
import { EventEmitter } from "../EventEmitter";
import { EVENT_CHANNEL, SUCCESS_CHANNEL, INDUSTRY, ERROR_CHANNEL, ID_ATTRIBUTE } from "../../utils/constants";
import { createSelector } from "redux-orm";
import orm from "../../models/orm.register";
import { connect } from "react-redux";
import { SuccessNotifier } from "../Success";

import "./CollectionNewer.module.scss";
import { FundingSelector } from "../FundingSelector";
import { ToggleButton } from "../ToggleButton";
import { useForm, Controller } from "react-hook-form";
import { useP2RAuth } from "../AuthContextProvider/authContext";
import _ from "lodash";
import BaseORMModel from "../../models/BaseORMModel";

const CollectionNewer = ({ fundingBodies, setModalClose } = {}) => {
    const { profile } = useP2RAuth();

    const { register, handleSubmit, watch, errors, control, trigger } = useForm({
        mode: "onChange",
    });

    const onSubmit = (data) => {
        const funding = data.funding ?? {};

        data = _.omit(data ?? {}, "funding");

        const collection = {
            ...(data ?? {}),
            ...(funding ?? {}),
            requiresComputationCode: null,
        };

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COLLECTION_API_CREATE, {
            payload: {
                group: profile.selectedGroup,
                collection,
            },
            errorController: {
                successChannel: SUCCESS_CHANNEL.COLLECTION_CREATE_SUCCESS,
                errorChannel: ERROR_CHANNEL.COLLECTION_CREATE_ERROR,
            },
        });
    };

    const hasError = _.keys(errors ?? {}).length !== 0;

    useEffect(() => {
        trigger();
    }, [BaseORMModel.getVersionId(fundingBodies ?? [])]);

    if (!profile.selectedGroup) {
        return null;
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex-grow-1 main-section collection-newer-content">
                    <input
                        name="title"
                        type="text"
                        placeholder="Type your collection name here (required)"
                        className=""
                        ref={register({
                            required: {
                                value: true,
                                message: "You must give your collection a name",
                            },
                            pattern: {
                                value: /^[-_'!@#$\\\/&a-zA-Z0-9\s]{6,}$/i,
                                message:
                                    "Enter a collection name between 6 and 100 characters (allow !, @, #, $, &, -, _, , / characters)",
                            },
                        })}
                    ></input>

                    {errors.title && <div className="help-block">{errors.title.message}</div>}

                    <h2 className="big form-element">Select the industry this collection relates to</h2>

                    <Controller
                        control={control}
                        name="industry"
                        render={({ onChange, onBlur, value }) => (
                            <ToggleButton
                                items={[
                                    {
                                        [ID_ATTRIBUTE]: INDUSTRY.CANE.key,
                                        className: "btn ",
                                        label: "Cane",
                                        default: true,
                                    },
                                    { [ID_ATTRIBUTE]: INDUSTRY.BANANAS.key, className: "btn ", label: "Bananas" },
                                    {
                                        [ID_ATTRIBUTE]: INDUSTRY.GRAIN.key,
                                        className: "btn ",
                                        label: "Grain",
                                    },
                                    {
                                        [ID_ATTRIBUTE]: INDUSTRY.GRAZING.key,
                                        className: "btn ",
                                        label: "Grazing",
                                    },
                                ]}
                                onItemSelected={(industry) => {
                                    onChange((industry ?? {})[ID_ATTRIBUTE]);
                                }}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: "You must select an industry",
                            },
                        }}
                    />

                    {errors.industry && <div className="help-block">{errors.industry.message}</div>}

                    <Controller
                        control={control}
                        name="funding"
                        render={({ onChange, onBlur, value }) => (
                            <FundingSelector
                                onFundingChanged={({ fundingBody, fundingProgram }) => {
                                    onChange({
                                        fundingBody: (fundingBody ?? {})[ID_ATTRIBUTE],
                                        fundingProgram: (fundingProgram ?? {})[ID_ATTRIBUTE],
                                    });
                                }}
                                preFundingBodyFn={() => {
                                    return <h2 className="">Funding provider</h2>;
                                }}
                                preFundingProgramFn={() => {
                                    return <h2 className="">Funding program</h2>;
                                }}
                                selectedFundingBodyId={null}
                                selectedFundingProgramId={null}
                            />
                        )}
                    />

                    {errors.funding && <div>{errors.funding.message}</div>}

                    <SuccessNotifier
                        key={"sucessNotifierId"}
                        successChannel={SUCCESS_CHANNEL.COLLECTION_CREATE_SUCCESS}
                        errorChannel={ERROR_CHANNEL.COLLECTION_CREATE_ERROR}
                        startFn={() => {
                            return <div className="help-block">{"The collection is being created"}</div>;
                        }}
                        successFn={() => {
                            return <div className="help-block">{"The collection is successfully created"}</div>;
                        }}
                        errorFn={(error) => {
                            return <div className="help-block">{error.message}</div>;
                        }}
                        onSuccess={(result) => {
                            setModalClose && setModalClose();
                        }}
                    />
                </div>

                <div className="footer-section">
                    <div className="btn-container">
                        <button
                            type="button"
                            title="Cancel create new collection"
                            className="btn btn-default btn-lg"
                            onClick={() => {
                                setModalClose && setModalClose();
                            }}
                        >
                            Cancel
                        </button>

                        <button
                            type="submit"
                            title="Create a collection"
                            className="btn btn-primary btn-lg"
                            disabled={hasError}
                            onClick={() => {}}
                        >
                            Create collection
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

const getFundingData = createSelector(orm, (session) => {
    const { FundingBody } = session;

    // many to many
    const fundingBodies = FundingBody.select(session, {
        include: ["fundingPrograms"],
        filter: { deleted: false },
    });

    return fundingBodies;
});

function mapORMStateToProps(state) {
    return {
        fundingBodies: getFundingData(state),
    };
}
export default connect(mapORMStateToProps)(CollectionNewer);
