import React from "react";
import _ from "lodash";

import "./ProjectTable.module.scss";

const BatchActionGroup = ({ actions }) => {
    return (actions ?? []).map(({ render }, index) => {
        return <React.Fragment key={index}>{render()}</React.Fragment>;
    });
};

export default BatchActionGroup;
