import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
// last, important
import '@fortawesome/fontawesome-pro/css/all.css';
// import '@fortawesome/fontawesome-free/js/all';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './styles/styles.scss';

import App from './App';
import { AuthContextProvider } from './components/AuthContextProvider/authContext';
import { store } from './redux';

import * as serviceWorker from './serviceWorker';

// import polyfills
import './polyfill';


ReactDOM.render(
    <Provider store={store}>
        <AuthContextProvider>
            <Router>
                <App />
            </Router>
        </AuthContextProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
