export function formatDuration(time) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    let ret = "";

    if (hours > 0) {
        ret += `${hours} hours `;
    }

    if (minutes > 0) {
        ret += `${minutes} minutes `;
    }

    if (seconds > 0) {
        ret += `${seconds} seconds`;
    }

    return ret;
}
