import React, { useEffect, useState } from "react";

const Checkbox = ({ checked: checkedProp, onChange = (e) => { }, ...rest }) => {

    const [checked, setChecked] = useState(checkedProp);
    useEffect(() => {
        setChecked(checkedProp);
    }, [checkedProp]);


    return <input type="checkbox" checked={checked} onChange={(e) => {
        setChecked(e.target.checked);
        onChange(e);
    }} {...rest} />;
};

export default Checkbox;