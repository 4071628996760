import React, { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { ID_ATTRIBUTE, ORM_PROJECT_SLICE, DELETE_BATCH_ACTION, EVENT_CHANNEL } from "../../utils/constants";
import "./ProjectTable.module.scss";
import { store } from "../../redux";
import generateORMActionName from "../../redux/reducers/orm.action.gen";
import { EventEmitter } from "../EventEmitter";
import { mapStateToPropsOfActions } from "./projectBlockSelector";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBTooltip } from "mdbreact";
import { faExchangeAlt } from "@fortawesome/pro-solid-svg-icons/faExchangeAlt";
import BaseORMModel from "../../models/BaseORMModel";
import { COLLECTION_CATEGORY, COLLECTION_WAS_SUBMITTED_PROP } from "../CollectionMenu/collectionMenuConstant";
import ComponentWillUnmountSupport from "../ComponentWillUnmountSupport";
import CollectionDropdownContent from "../CollectionMenu/CollectionDropdownContent";
import { serviceWrapper, services } from "../../services";

const { projectService } = services;

const TOOLTIP_TITLE = "Move the highlighted projects to a new collection";

const __BatchProjectMove = ({ projects = [], readOnly = false, selectedCollection = null }) => {
    const excludeCollectionIds = [(selectedCollection ?? {})[ID_ATTRIBUTE]];
    const [showCollection, setShowCollection] = useState(false);
    const [selectedCol, setSelectedCol] = useState(null);

    projects = projects ?? [];

    let shouldEnabled = false;

    if (projects && Array.isArray(projects) && projects.length > 0) {
        shouldEnabled = true;
    }

    shouldEnabled = shouldEnabled && !readOnly;

    const defaultCollectionMenuCategory = {
        category: COLLECTION_CATEGORY.ALL.state,
        filter: (col) => (col[COLLECTION_WAS_SUBMITTED_PROP] ?? "") === "",
    };

    const moveProjectsIntoSelectedCollection = async () => {
        const { [ID_ATTRIBUTE]: collectionId, title } = selectedCol;
        const projectIds = projects.map((p) => p[ID_ATTRIBUTE]);
        const originCollectionId = excludeCollectionIds[0];

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
            payload: {
                progress: 0,
                message: "Moving projects",
                scale: 100,
            },
        });

        // call the api to move the projects to another collection
        await serviceWrapper(
            {},
            {
                instance: projectService,
                name: "moveProjects",
                params: [collectionId, projectIds, originCollectionId],
            }
        );

        // now update the store to remove the projects
        // they are no longer in our current collection
        // (and the store keeps tracks of our current collection' assets)
        const payload = projectIds.map((pId) => ({ [ID_ATTRIBUTE]: pId }));

        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: DELETE_BATCH_ACTION }),
            payload,
        });

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
            payload: {
                progress: 100,
                message: "Finished",
                scale: 100,
            },
        });

        // trigger a notification to say we're done moving projects over
        EventEmitter.dispatch(
            EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION,
            <span>
                Moved selected projects to
                <strong> {title}</strong>
            </span>
        );
    };

    return (
        <MDBDropdown className={`batch-action-dropdown ${!shouldEnabled ? "disabled" : ""}`}>
            <MDBDropdownToggle tag="span" disabled={!shouldEnabled}>
                <span>
                    <MDBTooltip
                        domElement
                        placement="bottom"
                        style={{ position: "absolute", width: "10rem", marginTop: "-1rem" }}
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faExchangeAlt}
                                className={`icon ${!shouldEnabled ? "disabled" : ""}`}
                            />
                        </span>
                        <div>{TOOLTIP_TITLE}</div>
                    </MDBTooltip>
                </span>
            </MDBDropdownToggle>

            <MDBDropdownMenu
                basic
                className="z-depth-3 collection-menu-content mini-collection-menu mini-project-action-popup batch-action-move"
            >
                <h5 className="mini-collection-menu-title copy-project-title">
                    Move&nbsp;
                    <strong>{projects.length} projects</strong>
                </h5>

                <p>Choose destination collection:</p>

                <ComponentWillUnmountSupport
                    willUnmount={() => {
                        setShowCollection(false);
                        setSelectedCol(null);
                    }}
                >
                    <CollectionDropdownContent
                        highlightSelection={selectedCol !== null}
                        couldCreate={false}
                        couldImport={false}
                        couldSearch={true}
                        couldShowCategories={false}
                        defaultCategory={defaultCollectionMenuCategory}
                        actions={[]}
                        onCollectionSelected={(col) => setSelectedCol(col)}
                        preventDefaultDismiss={true}
                        excludeCollectionIds={excludeCollectionIds}
                        overrideSelectedCollection={(selectedCol ?? {})[ID_ATTRIBUTE]}
                        showReadOnlyCollections={false}
                    />
                </ComponentWillUnmountSupport>

                <MDBDropdownItem divider="true" />

                <p className="warning-label">
                    <strong>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        &nbsp;Warning:&nbsp;
                    </strong>
                    This action cannot be undone
                </p>

                <MDBDropdownItem tag="div" className="mb-2 hide-hover">
                    <div className="row justify-content-end">
                        <div className="col-5">
                            <button className="btn btn-default">Cancel</button>
                        </div>

                        <div className="col-5">
                            <button
                                className="btn btn-primary"
                                onClick={moveProjectsIntoSelectedCollection}
                                disabled={selectedCol === null}
                            >
                                Move
                            </button>
                        </div>
                    </div>
                </MDBDropdownItem>
            </MDBDropdownMenu>
        </MDBDropdown>
    );
};

export const _BatchProjectMove = React.memo(
    __BatchProjectMove,
    (
        { projects: prevProjects, selectedCollection: prevCollection, readOnly: prevReadonly },
        { projects: nextProjects, selectedCollection: nextCollection, readOnly: nextReadonly }
    ) => {
        return (
            BaseORMModel.getVersionId(prevProjects ?? []) === BaseORMModel.getVersionId(nextProjects ?? []) &&
            BaseORMModel.getVersionId(prevCollection ?? {}) === BaseORMModel.getVersionId(nextCollection ?? {}) &&
            prevReadonly === nextReadonly
        );
    }
);

const BatchProjectMove = connect(mapStateToPropsOfActions)(_BatchProjectMove);

export default BatchProjectMove;
