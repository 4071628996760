import React from 'react';
import toaster from "toasted-notes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import 'toasted-notes/src/styles.css';

export class Toast {
	static success(text) {
		toaster.notify(
			<>
				<span><FontAwesomeIcon style={{ color: "green" }} icon={faCheckCircle} />&nbsp;</span>
				<span dangerouslySetInnerHTML={{ __html: text }} />
			</>, {
			position: 'top-right'
		});
	}

	static error(text) {
		toaster.notify(
			<>
				<span><FontAwesomeIcon style={{ color: "red" }} icon={faCheckCircle} />&nbsp;</span>
				<span dangerouslySetInnerHTML={{ __html: text }} />
			</>, {
			position: 'top-right'
		});
	}

	static warn(text) {
		toaster.notify(<span><FontAwesomeIcon style={{ color: "yellow" }} icon={faExclamationTriangle} />&nbsp;{text} </span>, {
			position: 'top-right'
		});
	}

}