import React, { useState } from 'react';
import { services, serviceWrapper } from '../../services';
import { SuccessNotifier } from '../../components/Success';
import { ErrorNotifier } from '../../components/Error';
import { EventEmitter } from '../../components/EventEmitter';
import { ERROR_CHANNEL, SUCCESS_CHANNEL, EVENT_CHANNEL } from '../../utils/constants';
import { useForm } from 'react-hook-form';
import { useP2RAuth } from '../../components/AuthContextProvider/authContext';
import Footer from '../../components/Footer/index';


const { userService } = services;

const ResetPassword = () => {
    const { register, handleSubmit, errors } = useForm();
    const { getPublicToken } = useP2RAuth();

    const onSubmit = async (data) => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_RESET_PASS_WITH_EMAIL, {
            payload: data,
            getPublicToken
        });
    };

    return (
        <div className="account reset">
            <main>
                <div className="container">
                    <h1>P2R Projector </h1>
                    <h2>(Paddock to Reef Project Selector) </h2>
                    <p className="msg">Forgotten your password hey? Don't worry, we'll sort you out.
                        Just enter your email address below and we'll send you an email with a reset link.
                        You'll be selecting projects again in no time :)
                    </p>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(onSubmit)();
                        }}
                        className="login-form">
                        <div className="form-group">
                            <input
                                name="email"
                                className="form-control"
                                defaultValue=""
                                placeholder="Email"
                                ref={register({
                                    required: { value: true, message: "Please enter your email address" },
                                    validate: {
                                        emailCheck: value => (/^.+@.+[\.].+$/).test(value) || "That's not an email address. It must be like something@something.com"
                                    }
                                })} />

                            {errors.email &&
                                <div className="help-block with-errors">{errors.email.message}</div>}

                            {
                                <SuccessNotifier
                                    successChannel={SUCCESS_CHANNEL.USER_RESET_PASS_BY_EMAIL_SUCCESS}
                                    errorChannel={ERROR_CHANNEL.RESET_PASSWORD_BY_EMAIL}
                                    startFn={
                                        () => {
                                            return <div className="help-block with-errors">Please wait...</div>;
                                        }
                                    }

                                    successFn={
                                        () => {
                                            return <div className="help-block with-errors">An email has been sent to email to reset the password</div>;
                                        }
                                    }

                                    errorFn={
                                        (error) => {
                                            return <div className="help-block with-errors">{error.message}</div>;
                                        }
                                    }
                                />
                            }

                        </div>

                        <button type="submit" className="btn btn-color ml-0 mt-4 w-100">Reset password</button>

                    </form>
                    <div className="row actions">
                        <div className="col"><a href="/login">Back to login</a></div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default ResetPassword;