import CollectionDeletor from "./CollectionDeletor";
import CollectionNewer from "./CollectionNewer";
import CollectionImport from "./CollectionImport";
import PopupTest from "./PopupTest";
import ManageAccount from "./ManageAccount";
import SubmitCollection from "./SubmitCollection";
import { AddGroupModal, EditGroupModal, RemoveGroupMemberModal } from "../../scenes/Groups";
import { FundingBodyModal, FundingProgramModal } from "../../scenes/FundingSource";
import AddProjectModal from "./AddProjectModal";
import { EVENT_CHANNEL } from "../../utils/constants";
import { EventEmitter } from "../EventEmitter";
import { ProgressDisplay } from "../ProgressDisplay";
import CollectionMigrationModal from "./CollectionMigrationModal";
import TermsServiceModal from "./TermsServiceModal";

export const eventModalMapper = {
    [EVENT_CHANNEL.EVENT_CMD_COLLECTION_DELETING]: {
        content: CollectionDeletor,
        modalProps: {
            size: "md",
            position: "top",
            frame: true,
            closeBtnDisplay: true,
            className: "mini-modal collection-delete-modal",
            title: "Delete collection",
        },
        toCompProps: ({ payload } = {}) => {
            return {
                collection: payload,
            };
        },
        // modal: BaseModal
    },
    [EVENT_CHANNEL.EVENT_CMD_COLLECTION_CREATING]: {
        content: CollectionNewer,
        modalProps: {
            size: "lg",
            position: "top",
            frame: true,
            closeBtnDisplay: true,
            className: "full-modal collection-new-modal",
            title: "New collection",
        },
    },
    [EVENT_CHANNEL.EVENT_CMD_MANAGE_ACCOUNT_MODAL]: {
        content: ManageAccount,
        modalProps: {
            size: "lg",
            position: "top",
            frame: true,
            closeBtnDisplay: true,
            className: "full-modal manage-account-modal",
            title: "Manage account",
        },
        toCompProps: ({ payload } = {}) => {
            return {
                person: payload,
            };
        },
        onClosed: (history) => {
            history && history.push("/home");
            console.log("on closed");
        },
        // modal: BaseModal
    },
    [EVENT_CHANNEL.EVENT_CMD_CREATE_GROUP_MODAL]: {
        content: AddGroupModal,
        modalProps: {
            size: "md",
            position: "top",
            frame: false,
            closeBtnDisplay: true,
            backdropClassName: "backdrop-group-modal",
            className: "mini-modal",
            title: "Create a group",
        },
        toCompProps: ({ payload } = {}) => {
            return {
                person: payload,
            };
        },
        onClosed: (history) => {},
        // modal: BaseModal
    },
    [EVENT_CHANNEL.EVENT_CMD_REMOVE_GROUP_MEMBER_MODAL]: {
        content: RemoveGroupMemberModal,
        modalProps: {
            size: "md",
            frame: false,
            closeBtnDisplay: true,
            backdropClassName: "backdrop-group-modal",
            className: "mini-modal",
            title: "Remove group member",
        },
        toCompProps: (props) => props.payload,
    },
    [EVENT_CHANNEL.EVENT_CMD_EDIT_GROUP_MODAL]: {
        content: EditGroupModal,
        modalProps: {
            size: "md",
            // position: "middle",
            frame: false,
            closeBtnDisplay: true,
            backdropClassName: "backdrop-group-modal",
            className: "mini-modal group-modal",
            wrapClassName: "group-modal-wrapper",
            title: "Edit group",
        },
        onClosed: (history) => {
            // history && history.push('/home');
        },
        toCompProps: ({ payload } = {}) => {
            // SelectedGroup = {
            //     id: "5f0428ea2fe5f11fe0b3cd39",
            //     name: "Group name"
            // }
            // console.log(payload, "PAYLAOD")
            return {
                selectedGroup: payload,
            };
        },
        // modal: BaseModal
    },
    [EVENT_CHANNEL.EVENT_CMD_FUNDING_BODY_MODAL]: {
        content: FundingBodyModal,
        modalProps: {
            size: "md",
            position: "top",
            frame: false,
            closeBtnDisplay: true,
            className: "mini-modal",
            title: "Add funding provider",
        },
    },
    [EVENT_CHANNEL.EVENT_CMD_FUNDING_PROGRAM_MODAL]: {
        content: FundingProgramModal,
        modalProps: {
            size: "md",
            position: "top",
            frame: false,
            closeBtnDisplay: true,
            className: "mini-modal",
            title: "Add funding program",
        },
        toCompProps: ({ payload } = {}) => {
            // SelectedGroup = {
            //     id: "5f0428ea2fe5f11fe0b3cd39",
            //     name: "Group name"
            // }
            return {
                edit: payload.edit,
                fundingBodies: payload.fundingBodies,
            };
        },
    },
    [EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL]: {
        content: ProgressDisplay,
        modalProps: {
            size: "md",
            position: "top",
            frame: true,
            closeBtnDisplay: false,
            className: "progress-modal",
            centered: true,
        },
        toCompProps: ({ payload = {} } = {}) => {
            // SelectedGroup = {
            //     id: "5f0428ea2fe5f11fe0b3cd39",
            //     name: "Group name"
            // }
            // return {
            //     edit: payload.edit,
            //     fundingBodies: payload.fundingBodies
            // };

            return payload;
        },
    },
    [EVENT_CHANNEL.EVENT_CMD_COLLECTION_ADD_PROJECT]: {
        content: AddProjectModal,
        modalProps: {
            size: "lg",
            position: "top",
            frame: true,
            closeBtnDisplay: false,
            className: "full-modal",
            // title: ""
        },
        toCompProps: ({ payload } = {}) => {
            const { projectId, collectionId, sourceProjectId, collectionReadOnly, isNew, isCopy = false } = payload;
            return {
                projectId,
                collectionId,
                sourceProjectId,
                readOnly: collectionReadOnly,
                isNew,
                isCopy,
            };
        },
        onClosed: (history, props) => {
            const { projectId } = props ?? {};
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_CANCEL, {
                payload: {
                    projectId,
                },
            });
        },
    },

    [EVENT_CHANNEL.EVENT_CMD_SHOW_COLLECTION_MIGRATION_MODAL]: {
        content: CollectionMigrationModal,
        modalProps: {
            size: "lg",
            position: "top",
            frame: false,
            closeBtnDisplay: true,
            className: "mini-modal",
            title: "Recalculation required",
        },
        toCompProps: ({ payload } = {}) => {
            return payload;
        },
    },

    [EVENT_CHANNEL.EVENT_CMD_SUBMIT_COLLECTION_MODAL]: {
        content: SubmitCollection,
        modalProps: {
            size: "md",
            position: "top",
            frame: true,
            closeBtnDisplay: true,
            className: "mini-modal manage-account-modal",
            title: "Submit collection",
        },
        toCompProps: ({ payload } = {}) => ({
            ...payload,
        }),
    },
    [EVENT_CHANNEL.EVENT_CMD_COLLECTION_IMPORT_RESULT]: {
        content: CollectionImport,
        modalProps: {
            size: "md",
            position: "top",
            frame: true,
            closeBtnDisplay: true,
            className: "mini-modal",
            title: "Import collection from file",
        },
        toCompProps: ({ payload } = {}) => ({
            ...payload,
        }),
    },
    [EVENT_CHANNEL.EVENT_CMD_TERMS_SERVICE_ACCEPT_MODAL]: {
        content: TermsServiceModal,
        modalProps: {
            size: "md",
            frame: false,
            closeBtnDisplay: false,
            // className: 'full-modal',
            title: "",
        },
        toCompProps: ({ payload } = {}) => ({
            ...payload,
        }),
    },
};
