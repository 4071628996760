export const HttpMethods = Object.freeze({
    Get: 'GET',
    Post: 'POST',
    Put: 'PUT',
    Delete: 'DELETE'
});

export const ServerType = Object.freeze({
    Authentication: 'auth',
    Data: 'data',
    Computation: 'comp',
    LB: 'lb',
});