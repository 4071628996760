import React, { useState, useEffect } from "react";
import { MDBModal, MDBModalBody } from "mdbreact";

const BaseModal = ({ ...props }) => {

    const { className, isOpen = false, onOpened = null } = props ?? {};
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(open => isOpen);
    }, [{ isOpen }]);

    // if (!open) {
    //     onClosed && onClosed();
    // }
    return <MDBModal
        {...(props ?? {})}
        className={`${className ?? ''}`} isOpen={open}
        backdrop={true} keyboard={true}
        onOpened={() => {
            onOpened && onOpened();
        }}

        toggle={() => {
            return true;
            // setOpen(open => !open);
        }}
    >
        <MDBModalBody>
            {
                props.children
            }
        </MDBModalBody>
    </MDBModal>;
};

export default BaseModal;