import React, { useEffect } from "react";
import _ from "lodash";
import { faSortUp } from "@fortawesome/pro-solid-svg-icons/faSortUp";
import { faSortDown } from "@fortawesome/pro-solid-svg-icons/faSortDown";
import { ID_ATTRIBUTE } from "../../utils/constants";
import { useTable, usePagination, useSortBy, useExpanded } from "react-table";

import "./ProjectTable.module.scss";
import BaseORMModel from "../../models/BaseORMModel";
import { SEPARATOR, TABLE_CUSTOM_VERSION } from "./tableConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PAGE_SIZE = 20;

const ReactRow = ({ row, className, colSpanOnEmpty = 1 }) => {
    if (!row)
        return (
            <tr className={`table-border no-projects ${className}`}>
                <td colSpan={colSpanOnEmpty}>No projects selected</td>
            </tr>
        );

    return (
        <tr className={`table-border ${row.canExpand ? "can-expand" : ""} ${className}`}>
            {(row.cells ?? []).map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
            })}
        </tr>
    );
};

const ReactRow1 = React.memo(ReactRow, ({ row: prevRow }, { row: nextRow }) => {
    if (!prevRow || !nextRow) return false;

    const { generateVersion: prevVersion } = prevRow.getRowProps();
    const { generateVersion: nextVersion } = nextRow.getRowProps();

    if (!prevVersion || !nextVersion) return false;

    return prevVersion() === nextVersion();
});

/**
 * The project react table should have those properties
 * @property {number} pageIndex - default page index
 */

const ProjectReactTable = ({
    className,
    data,
    columns,
    pageIndex: pageIndexFromProp = 0,
    resetPage = {},
    sortBy = [],
    getRowProps = () => {},
}) => {
    pageIndexFromProp = pageIndexFromProp ?? 0;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

        state,
        dispatch,

        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            sortBy,

            isMultiSortEvent: () => {
                return false;
            },
            getSubRows: (projectOrBlock) => {
                return projectOrBlock.blocks;
            },
            getRowId: (projectOrBlock, index) => {
                // should change to get version id
                // return _.join([row.id, row.version], '###');
                // return row.id;
                return projectOrBlock[ID_ATTRIBUTE];
            },

            initialState: {
                pageSize: PAGE_SIZE,
                pageIndex: pageIndexFromProp,
            },
            stateReducer: (state, action, prevState) => {
                switch (action.type) {
                    case "resetPage":
                        return {
                            ...(state ?? {}),
                            pageIndex: 0,
                        };
                    case "resetSort":
                        return {
                            ...(state ?? {}),
                            sortBy: action.payload,
                        };
                    default:
                        return state;
                }
            },

            autoResetPage: false,
            autoResetExpanded: false,
            paginateExpandedRows: false,
        },
        useSortBy,
        useExpanded,
        usePagination,
        (hooks) => {
            hooks.getRowProps.push((rowProps, { instance, row, userProps }) => {
                return {
                    ...(rowProps ?? {}),

                    ...(getRowProps ? getRowProps(rowProps, { instance, row, userProps }) ?? {} : {}),

                    generateVersion: () => {
                        return _.join(
                            [
                                BaseORMModel.getVersionId(row.original ?? {}),
                                `isExpanded:${row.isExpanded}`,
                                `extraVersionInfo:${((row ?? {}).original ?? {}).extraVersionInfo}`,

                                `${TABLE_CUSTOM_VERSION}:${((row ?? {}).original ?? {})[TABLE_CUSTOM_VERSION]}`,
                            ],
                            SEPARATOR
                        );
                    },
                };
            });
        }
    );

    const { pageIndex, pageSize } = state;

    useEffect(() => {
        if (resetPage === true || (resetPage ?? {}).reset === true)
            dispatch({
                type: "resetPage",
                payload: {},
            });
    }, [resetPage]);

    useEffect(() => {
        dispatch({
            type: "resetSort",
            payload: sortBy,
        });
    }, [sortBy]);

    return (
        <div className={className}>
            <table {...getTableProps()}>
                {data && data.length !== 0 && (
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    return (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className={`${column.canSort ? "sortable" : ""} ${column.className ?? ""}`}
                                            title={`${
                                                column.canSort
                                                    ? "Toggle the column to sort the table by the column values"
                                                    : ""
                                            }`}
                                        >
                                            <span>
                                                {column.render("Header")}
                                                {column.canSort &&
                                                    (column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <span className="fa-stack icon">
                                                                <FontAwesomeIcon
                                                                    icon={faSortDown}
                                                                    className={`fa-stack-1x ml-0 sort-down active`}
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={faSortUp}
                                                                    className={`fa-stack-1x ml-0 sort-up`}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span className="fa-stack icon">
                                                                <FontAwesomeIcon
                                                                    icon={faSortDown}
                                                                    className={`fa-stack-1x ml-0 sort-down`}
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={faSortUp}
                                                                    className={`fa-stack-1x ml-0 sort-up active`}
                                                                />
                                                            </span>
                                                        )
                                                    ) : (
                                                        <span className="fa-stack icon">
                                                            <FontAwesomeIcon
                                                                icon={faSortDown}
                                                                className={`fa-stack-1x ml-0 sort-down`}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faSortUp}
                                                                className={`fa-stack-1x ml-0 sort-up`}
                                                            />
                                                        </span>
                                                    ))}
                                            </span>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                )}
                <tbody {...getTableBodyProps()}>
                    {(!data || data.length === 0) && <ReactRow row={null} colSpanOnEmpty={columns.length} />}
                    {data &&
                        data.length !== 0 &&
                        (page ?? []).map((row, i) => {
                            prepareRow(row);

                            return <ReactRow1 {...row.getRowProps()} row={row} />;
                        })}
                </tbody>

                {data && data.length !== 0 && pageCount > 1 && (
                    <tbody>
                        <tr className="pagination">
                            <td colSpan="12">
                                <a onClick={() => gotoPage(0)} className={`${!canPreviousPage ? "disabled" : ""}`}>
                                    First
                                </a>
                                <a onClick={() => previousPage()} className={`${!canPreviousPage ? "disabled" : ""}`}>
                                    Previous
                                </a>

                                <span>
                                    {pageIndex + 1} / {pageOptions.length}
                                </span>
                                <a
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                    className={`${!canNextPage ? "disabled" : ""}`}
                                >
                                    Next
                                </a>
                                <a
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                    className={`${!canNextPage ? "disabled" : ""}`}
                                >
                                    Last
                                </a>
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>
        </div>
    );
};

export default ProjectReactTable;
