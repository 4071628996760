import { Model, ORM, fk, many, attr } from "redux-orm";
// import generateActionName from "../redux/reducers/action.gen";

import BaseORMModel from "./BaseORMModel";
import { ORM_COLLECTION_SLICE, ORM_PROJECT_SLICE, ID_ATTRIBUTE } from "../utils/constants";

export default class CollectionORMModel extends BaseORMModel { }
CollectionORMModel.modelName = ORM_COLLECTION_SLICE;
CollectionORMModel.fields = {
    [ID_ATTRIBUTE]: attr(),
    title: attr(),
    industry: attr(),
    dateCreated: attr(),
    dateUpdated: attr(),

    isSubmitted: attr(),
    // true or false when user submits the project

    isReceivedFromSubmission: attr(),
    // true or false when user receives the submitted project

    __v: attr()
};