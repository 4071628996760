import React, { useState, useEffect } from "react";
import orm from "../../models/orm.register";
import { connect } from "react-redux";
import _ from "lodash";
import {
    ID_ATTRIBUTE,
    ORM_WORKSPACE_SLICE,
    ORM_PROJECT_SLICE,
    WORKSPACE_ID,
    UPDATE_ACTION,
    ORM_BLOCK_SLICE,
    UPDATE_BATCH_ACTION,
    EVENT_CHANNEL,
    ORM_SLICE,
} from "../../utils/constants";
import { COLLECTION_CATEGORY } from "../CollectionMenu/collectionMenuConstant";

import "./ProjectTable.module.scss";
import { store } from "../../redux";
import generateORMActionName from "../../redux/reducers/orm.action.gen";
import { EventEmitter } from "../EventEmitter";
import { mapStateToPropsOfActions } from "./projectBlockSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBTooltip } from "mdbreact";
import { faCopy } from "@fortawesome/pro-solid-svg-icons";
import BaseORMModel from "../../models/BaseORMModel";
import CollectionDropdownContent from "../CollectionMenu/CollectionDropdownContent";
import { COLLECTION_WAS_SUBMITTED_PROP } from "../CollectionMenu/collectionMenuConstant";
import ComponentWillUnmountSupport from "../ComponentWillUnmountSupport";
import { serviceWrapper, services } from "../../services";

const { projectService } = services;

const TOOLTIP_TITLE = "Copy the highlighted projects to a new collection";

const __BatchProjectCopy = ({ projects = [], readOnly = false, selectedCollection = null, selectedGroupId }) => {
    projects = projects ?? [];

    const [, setShowCollection] = useState(false);
    const [selectedCol, setSelectedCol] = useState(null);

    useEffect(() => {
        setShowCollection(false);
    }, [BaseORMModel.getVersionId(projects ?? [])]);

    let shouldEnabled = false;

    if (projects && Array.isArray(projects) && projects.length > 0) {
        shouldEnabled = true;
    }

    shouldEnabled = shouldEnabled && !readOnly;

    const defaultCollectionMenuCategory = {
        category: COLLECTION_CATEGORY.ALL.state,
        filter: (col) => {
            return (col[COLLECTION_WAS_SUBMITTED_PROP] ?? "") === "";
        },
    };

    const copyProjectsIntoSelectedCatchment = async () => {
        const { [ID_ATTRIBUTE]: collectionId, title } = selectedCol;
        const { [ID_ATTRIBUTE]: originCollectionId } = selectedCollection;
        const projectIds = projects.map((p) => p[ID_ATTRIBUTE]);
        const numBlocks = projects.reduce((a, b) => b.blocks.length + a, 0);

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
            payload: {
                progress: 0,
                message: "Copying projects",
                scale: 100,
            },
        });

        // call the api and have it copy all our projects and blocks to new ids
        // then add some information into the project's blocks so they have an id
        // back to their parent. this is required for redux to link properly...
        // TODO: add proper error handling here...
        let projs = await serviceWrapper(
            {},
            {
                instance: projectService,
                name: "copyProjects",
                params: [collectionId, projectIds, originCollectionId, selectedGroupId],
            }
        );

        const blocks = projs.reduce((list, p) => {
            p.blocks.forEach((b) => (b.projectId = p[ID_ATTRIBUTE]));
            return list.concat(p.blocks);
        }, []);

        // add the new projects and blocks to the store so they can show up in the map
        projs = projs.map((p) => {
            const matchingProject = _.find(
                projects,
                (x) => x.soilClimateCodeId && x.soilClimateCodeId === p.soilClimateCodeId
            );

            return {
                ...p,
                blocks: undefined,
                collectionId,
                soilClimateCode: matchingProject && matchingProject.soilClimateCode,
            };
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: UPDATE_BATCH_ACTION }),
            payload: blocks,
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: UPDATE_BATCH_ACTION }),
            payload: projs,
        });

        // add the project to the selected project ids in the workspace
        // if the current destination collection is the one we're viewing right now
        if (collectionId === originCollectionId) {
            const state = store.getState();
            const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());
            const workspace = session[ORM_WORKSPACE_SLICE].select(session, {
                include: [],
                filter: {
                    [ID_ATTRIBUTE]: WORKSPACE_ID,
                },
            })[0];

            const { selectedProjectIds = {} } = workspace ?? {};
            projs.forEach((p) => (selectedProjectIds[p[ID_ATTRIBUTE]] = 1));
            store.dispatch({
                type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
                payload: {
                    [ID_ATTRIBUTE]: WORKSPACE_ID,
                    selectedProjectIds,
                },
            });
        }

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
            payload: {
                progress: 100,
                message: "Finished",
                scale: 100,
            },
        });

        // trigger a notification to say we're done copying projects
        let contents = (
            <span>
                <strong>{projects.length} project(s) </strong>({numBlocks} blocks) copied to
                <strong> '{title}'</strong> collection
            </span>
        );
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, {
            contents,
            autoClose: true,
        });
    };

    return (
        <MDBDropdown className={`batch-action-dropdown ${!shouldEnabled ? "disabled" : ""}`}>
            <MDBDropdownToggle tag="span" disabled={!shouldEnabled}>
                <span>
                    <MDBTooltip
                        domElement
                        placement="bottom"
                        style={{ position: "absolute", width: "10rem", marginTop: "-1rem" }}
                    >
                        <span>
                            <FontAwesomeIcon icon={faCopy} className={`icon ${!shouldEnabled ? "disabled" : ""}`} />
                        </span>
                        <div>{TOOLTIP_TITLE}</div>
                    </MDBTooltip>
                </span>
            </MDBDropdownToggle>

            <>
                {
                    <MDBDropdownMenu
                        basic
                        className="z-depth-3 collection-menu-content mini-collection-menu mini-project-action-popup"
                    >
                        <h5 className="mini-collection-menu-title copy-project-title">
                            Copy&nbsp;
                            <strong>{projects.length} projects</strong>
                        </h5>
                        <p>Choose destination collection:</p>

                        <ComponentWillUnmountSupport
                            willUnmount={() => {
                                setShowCollection(false);
                                setSelectedCol(null);
                            }}
                        >
                            <CollectionDropdownContent
                                highlightSelection={selectedCol !== null}
                                couldCreate={false}
                                couldImport={false}
                                couldSearch={true}
                                couldShowCategories={false}
                                defaultCategory={defaultCollectionMenuCategory}
                                actions={[]}
                                onCollectionSelected={(col) => setSelectedCol(col)}
                                preventDefaultDismiss={true}
                                overrideSelectedCollection={(selectedCol ?? {})[ID_ATTRIBUTE]}
                                showReadOnlyCollections={false}
                            />
                        </ComponentWillUnmountSupport>

                        <MDBDropdownItem divider="true" />

                        <MDBDropdownItem
                            tag="div"
                            className="mb-2 hide-hover"
                            onClick={($event) => $event.preventDefault()}
                        >
                            <div className="row justify-content-end">
                                <div className="col-5">
                                    <button className="btn btn-default">
                                        Cancel
                                    </button>
                                </div>
                                <div className="col-5">
                                    <button
                                        className="btn btn-primary"
                                        onClick={copyProjectsIntoSelectedCatchment}
                                        disabled={selectedCol === null}
                                    >
                                        Copy
                                    </button>
                                </div>
                            </div>
                        </MDBDropdownItem>
                    </MDBDropdownMenu>
                }
            </>
        </MDBDropdown>
    );
};

export const _BatchProjectCopy = React.memo(
    __BatchProjectCopy,
    (
        { projects: prevProjects, selectedCollection: prevCollection, readOnly: prevReadonly },
        { projects: nextProjects, selectedCollection: nextCollection, readOnly: nextReadonly }
    ) => {
        return (
            BaseORMModel.getVersionId(prevProjects ?? []) === BaseORMModel.getVersionId(nextProjects ?? []) &&
            BaseORMModel.getVersionId(prevCollection ?? {}) === BaseORMModel.getVersionId(nextCollection ?? {}) &&
            prevReadonly === nextReadonly
        );
    }
);

const BatchProjectCopy = connect(mapStateToPropsOfActions)(_BatchProjectCopy);

export default BatchProjectCopy;
