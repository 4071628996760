import React, { useState, useEffect } from 'react';

export default class ComponentWillUnmountSupport extends React.Component {
    constructor(props) {
        super(props)
    }

    componentWillUnmount() {
        this.props.willUnmount && this.props.willUnmount();
    }

    render() {
        return this.props.children;
    }
}