import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useTable, useExpanded, useSortBy } from "react-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/pro-solid-svg-icons/faUniversity";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons/faPencilAlt";
import { faUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { faSortUp } from "@fortawesome/pro-duotone-svg-icons/faSortUp";
import { faSortDown } from "@fortawesome/pro-duotone-svg-icons/faSortDown";
import { faSort } from "@fortawesome/pro-duotone-svg-icons/faSort";
import { DETAIL_DISPLAY_GROUP_NUM, EVENT_CHANNEL } from "../../utils/constants";
import CanPerformAction from "../CanPerformAction";
import { CAN_ADD_GROUP } from "../../utils/permission";
import { EventEmitter } from "../EventEmitter";

const GroupTableColumns = [
    {
        Header: "Group name",
        accessor: "name",
        Cell: ({ value: groupName }) => (
            <span className="table-group-name-header">
                <FontAwesomeIcon className="group-name-icon icon-text-right-spacing" icon={faUniversity} />
                {groupName}
            </span>
        ),
        className: "table-group-name",
        width: "60%",
    },
    {
        Header: () => (
            <>
                <FontAwesomeIcon className="icon-text-right-spacing" icon={faUsers} />
                Members
            </>
        ),
        accessor: "members",
        headerClassName: "text-center",
        className: "text-center table-group-member-count",
    },
    {
        id: "edit-group",
        disableSortBy: true,
        Cell: ({ row }) => (
            <CanPerformAction action={CAN_ADD_GROUP} extraParams={[row.original.privilege]}>
                <span className="table-group-actions-icons">
                    <FontAwesomeIcon
                        icon={faPencilAlt}
                        onClick={(e) => {
                            e.stopPropagation();
                            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_EDIT_GROUP_MODAL, {
                                payload: row.original,
                            });
                        }}
                    />
                </span>
            </CanPerformAction>
        ),
    },
];

const GroupTable = ({ data, renderRowSubComponent = () => null }) => {
    const columns = useMemo(() => GroupTableColumns, []);

    const expanded = useMemo(() => {
        let obj = {};

        if (data.length <= DETAIL_DISPLAY_GROUP_NUM) {
            obj = data.reduce((obj, _, index) => ({ ...obj, [index]: true }), {});
        }

        return obj;
    }, [data]);

    const initialState = { expanded };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        toggleAllRowsExpanded,
        state: { sortBy },
    } = useTable({ columns, data, initialState, autoResetExpanded: false }, useSortBy, useExpanded);

    useEffect(() => {
        if (sortBy.length === 0) {
            return;
        }

        // close all the expanded rows if the sorting changes
        toggleAllRowsExpanded(false);
    }, [sortBy]);

    const groups = headerGroups.map((group, gIdx) => {
        const headers = group.headers.map((column, cIdx) => {
            const { headerClassName, width } = column;
            return (
                <th
                    key={cIdx}
                    {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        className: headerClassName,
                        width,
                    })}
                >
                    {column.render("Header")}

                    {/* sort by direction indicator */}
                    <span>
                        {column.canSort ? (
                            column.isSorted ? (
                                column.isSortedDesc ? (
                                    <FontAwesomeIcon icon={faSortDown} className="icon-text-left-spacing" />
                                ) : (
                                    <FontAwesomeIcon icon={faSortUp} className="icon-text-left-spacing" />
                                )
                            ) : (
                                <FontAwesomeIcon icon={faSort} className="icon-text-left-spacing" />
                            )
                        ) : null}
                    </span>
                </th>
            );
        });

        return (
            <tr key={gIdx} {...group.getHeaderGroupProps()}>
                {headers}
            </tr>
        );
    });

    const _rows = rows.map((row, rIdx) => {
        prepareRow(row);

        const cells = row.cells.map((cell, cIdx) => {
            const { className } = cell.column;
            return (
                <td key={cIdx} {...cell.getCellProps({ className })}>
                    {cell.render("Cell")}
                </td>
            );
        });

        let subRow = null;

        if (row.isExpanded) {
            subRow = (
                <tr role="expanded">
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent(row)}</td>
                </tr>
            );
        }

        return (
            <React.Fragment key={rIdx}>
                <tr {...row.getRowProps()} {...row.getToggleRowExpandedProps()}>
                    {cells}
                </tr>
                {subRow}
            </React.Fragment>
        );
    });

    return (
        <table {...getTableProps()}>
            <thead>{groups}</thead>
            <tbody {...getTableBodyProps()}>{_rows}</tbody>
        </table>
    );
};
GroupTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GroupTable;
