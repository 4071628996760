import React, { useMemo } from "react";
import { connect } from "react-redux";
import { createSelector } from "redux-orm";
import { LeafletMap } from "./LeafletMap4";
import { ORM_PROJECT_SLICE, ID_ATTRIBUTE, EVENT_CHANNEL } from "../../utils/constants";
import orm from "../../models/orm.register";
import {
    MAP_OPERATION_MODE,
    SELECT_ALL_TOOLBAR,
    MAP_BOUNDS_WINDOW_PROPS_NAME,
    REPAINT_TILES_ON_UPDATE,
} from "./mapConstants";
import { EventEmitter } from "../EventEmitter";
import { SHARED_SELECTOR } from "../../selectors/appSelector";

const _AppMap = ({
    projects,
    selectedProjectIds,
    highlightedBlockIds,
    readOnly,
    selectionIgnore = false,
    collectionId,
    couldDeleteProjects = false,
    mainMapBoundsWindowPropName = MAP_BOUNDS_WINDOW_PROPS_NAME,
    repaintTilesOnUpdate = REPAINT_TILES_ON_UPDATE,
}) => {
    const mode = readOnly ? MAP_OPERATION_MODE.READ_ONLY : MAP_OPERATION_MODE.EDIT;
    const selectAllMode = selectionIgnore || !collectionId ? SELECT_ALL_TOOLBAR.OFF : SELECT_ALL_TOOLBAR.ON;

    const onProjectSelection = useMemo(() => {
        return (projectIds, blockIds, status) => {
            projectIds &&
                EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_BATCH_SELECT, {
                    payload: {
                        selected: status,
                        projects: (projectIds ?? []).map((projectId) => {
                            return {
                                [ID_ATTRIBUTE]: projectId,
                            };
                        }),
                        blocks: (blockIds ?? []).map((blockId) => {
                            return {
                                [ID_ATTRIBUTE]: blockId,
                            };
                        }),
                    },
                });
        };
    }, []);

    const onBlockEdit = useMemo(() => {
        return (blocks) => {
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_BLOCK_BATCH_UPDATE, {
                payload: {
                    blocks: blocks ?? [],
                },
            });
        };
    }, []);

    const onBlockDelete = useMemo(() => {
        return (blocks) => {
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_BLOCK_BATCH_DELETE, {
                payload: {
                    blocks: blocks ?? [],
                    couldDeleteProjects,
                },
            });
        };
    }, [couldDeleteProjects]);

    const onBlockCreate = useMemo(() => {
        return (projectId, block) => {
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_BLOCK_CREATE, {
                payload: {
                    block,
                    projectId,
                },
            });
        };
    }, []);

    return (
        <>
            <LeafletMap
                projects={projects}
                repaintTilesOnUpdate={repaintTilesOnUpdate}
                selectedProjectIds={selectedProjectIds}
                highlightedBlockIds={highlightedBlockIds}
                mode={mode}
                selectAllMode={selectAllMode}
                mainMapBoundsWindowPropName={mainMapBoundsWindowPropName}
                onSelectedProjectsChange={onProjectSelection}
                onBlockEdit={onBlockEdit}
                onBlockDelete={onBlockDelete}
                onBlockCreate={onBlockCreate}
            />
            <div className="map-legend">
                <p>
                    <span className="shading"></span> Unselected
                </p>
                <p>
                    <span className="shading selected"></span> Selected
                </p>
                <p>
                    <span className="shading conflict"></span> Contains errors
                </p>
            </div>
        </>
    );
};

const singleProjectSelector = (projectId) => {
    return createSelector(orm, (session) => {
        const { [ORM_PROJECT_SLICE]: Project } = session;

        const project = (Project.select(session, {
            include: [
                {
                    blocks: [],
                },
            ],
            filter: { [ID_ATTRIBUTE]: projectId },
        }) ?? [])[0];

        return project;
    });
};

const mapStateToProps = (state, props) => {
    const { projectId } = props;

    const workspace = SHARED_SELECTOR.EVERY_THING(state);
    const readOnly = !workspace.selectedCollection || workspace.collectionReadOnly;

    let highlightedBlockIds = Object.keys(workspace.highlightedBlockIds ?? {});
    let projects = (workspace.selectedCollection ?? {}).projects ?? [];
    let selectedProjectIds = (workspace.selectedProjects ?? []).map((d) => d[ID_ATTRIBUTE]);
    let selectionIgnore = false;
    let couldDeleteProjects = true;

    let project = null;

    if (projectId) {
        project = singleProjectSelector(projectId)(state);
    }

    if (project) {
        // if one single project is required, check if the source project exists in the workspace to remove it
        const sourceProjectId = workspace.editingSourceProjectId;

        if (sourceProjectId) {
            let nosourceProjects = projects.filter((project) => project[ID_ATTRIBUTE] !== sourceProjectId);
            projects = nosourceProjects;
        }

        projects = projects.concat(project);
        selectedProjectIds = [project[ID_ATTRIBUTE]];
        selectionIgnore = true;
        couldDeleteProjects = false;
    }

    return {
        collectionId: (workspace.selectedCollection ?? {})[ID_ATTRIBUTE],
        projects,
        selectedProjectIds,
        highlightedBlockIds,
        readOnly,
        selectionIgnore,
        couldDeleteProjects,
    };
};

const AppMap = connect(mapStateToProps)(_AppMap);

export { AppMap };
