import { Config } from "../utils/config";
import socketIOClient from "socket.io-client";

export class WS {
    constructor({ endpoint = "", onConnect = (e, socket) => { },
        onReconnect = (e, socket) => { }, onDisconnect = (e, socket) => { },
        onError = (e, socket) => { }

    }) {

        this.endpoint = endpoint;
        this.onConnect = onConnect;
        this.onReconnect = onReconnect;
        this.onDisconnect = onDisconnect;
        this.onError = onError;
    }

    open() {
        let socket = socketIOClient(`${Config.WS_ENDPOINT}/${this.endpoint}`, {
            path: `${Config.WS_PATH}`,
            transports: ['polling', 'websocket']
        });

        socket.on('connect', (e) => {
            this.onConnect(e, socket);
        });

        socket.on('reconnect', (e) => {
            this.onReconnect(e, socket);
        });

        socket.on('disconnect', (e) => {
            this.onDisconnect(e, socket);
        });

        socket.on("connect_error", this.onError);
        socket.on("connect_timeout", this.onError);
        socket.on("error", this.onError);
        socket.on("reconnect_error", this.onError);
        socket.on("reconnect_failed", this.onError);

        return socket;
    }


}