import React from "react";
import { downloadGeoJson } from "../../utils/export";
import { EVENT_CHANNEL, ORM_PROJECT_SLICE, ORM_BLOCK_SLICE, ID_ATTRIBUTE } from "../../utils/constants";
import { EventEmitter } from "../EventEmitter";
import { connect } from "react-redux";
import { createSelector } from "redux-orm";
import { SHARED_SELECTOR } from "../../selectors/appSelector";
import orm from "../../models/orm.register";
import { formatDuration } from "../../utils/time.utils";
import { isCollectionSubmittedOrReceived } from "../../utils/collection.utils";

import "./CollectionMigrationModal.scss";

const MAX_AVERAGE_ESTIMATED_COMP_RUN_TIME_IN_MS = 1153;

const CollectionMigrationHeader = (props) => {
    const { code, estimateComputationDuration } = props;

    const getDescriptionFromCode = (code) => {
        switch (code) {
            case "CAL-RSDR":
                return "The new version applies a delivery ratio to estimate the water quality load change at the reef.";
            default:
                return "";
        }
    };

    const description = getDescriptionFromCode(code);

    return (
        <div className="col">
            <p>This collection was created with an earlier version of P2R Projector.</p>
            <p>{description && <>{description}</>}</p>
            <p>We estimate that it will take approximately {formatDuration(estimateComputationDuration)}.</p>
        </div>
    );
};

const CollectionMigrationBackupButton = () => {
    return (
        <div className="migration-modal-backup col">
            <p>
                <strong>You can download a copy of the collection for your records.</strong>
            </p>

            <button className="btn btn-outline-secondary ml-0 w-100" onClick={() => downloadGeoJson()}>
                Download
            </button>
        </div>
    );
};

const CollectionMigrationButtons = (props) => {
    const { collectionId, onClose, keepReadonly } = props;

    const migrateCollection = () => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_MIGRATE_COLLECTION, {
            payload: {
                collectionId: collectionId,
                collectionReadOnly: keepReadonly,
            },
        });

        onClose();
    };

    return (
        <div className="migration-modal-actions">
            <button className="btn btn-default" onClick={onClose}>
                Do it later
            </button>
            <button className="btn btn-primary" onClick={migrateCollection}>
                Recalculate
            </button>
        </div>
    );
};

const CollectionMigrationModal = (props) => {
    const { collection, setModalClose, estimateComputationDuration } = props;

    const keepReadonly = isCollectionSubmittedOrReceived(collection);

    return (
        <div className="migration-modal">
            <CollectionMigrationHeader
                code={collection.requiresComputationCode}
                estimateComputationDuration={estimateComputationDuration}
            />

            <CollectionMigrationBackupButton />

            <CollectionMigrationButtons
                keepReadonly={keepReadonly}
                collectionId={collection._id}
                onClose={setModalClose}
            />
        </div>
    );
};

const getEstimateComputationDuration = (collectionId) =>
    createSelector(orm, (session) => {
        const projects = session[ORM_PROJECT_SLICE].select(session, {
            include: [],
            filter: (project) => {
                return project.collectionId === collectionId;
            },
        });

        const projectIds = projects.map((project) => project[ID_ATTRIBUTE]);

        const blocks = session[ORM_BLOCK_SLICE].select(session, {
            include: [],
            filter: (block) => projectIds.includes(block.projectId),
        });

        const averageBlockComputeMS = MAX_AVERAGE_ESTIMATED_COMP_RUN_TIME_IN_MS;
        const numberOfBlocks = blocks.length;

        return Math.ceil((numberOfBlocks * averageBlockComputeMS) / 1000);
    });

function mapORMStateToProps(state) {
    const workspace = SHARED_SELECTOR.EVERY_THING(state);

    return {
        estimateComputationDuration: getEstimateComputationDuration(workspace.selectedCollectionId)(state),
    };
}

export default connect(mapORMStateToProps)(CollectionMigrationModal);
