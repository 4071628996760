import React, { useEffect, useState } from "react";
import BarChart from "../../components/BarChart/index";
import { faHandPointer as farHandPointer } from "@fortawesome/pro-regular-svg-icons/faHandPointer";
import { faCircle } from "@fortawesome/pro-light-svg-icons/faCircle";
import { faLayerPlus } from "@fortawesome/pro-regular-svg-icons/faLayerPlus";
import { faChevronCircleDown } from "@fortawesome/pro-solid-svg-icons/faChevronCircleDown";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {
    MEASURE_VALS,
    MEASURE_MAP,
    ORM_WORKSPACE_SLICE,
    ORM_PROJECT_SLICE,
    ID_ATTRIBUTE,
    WORKSPACE_ID,
    MEASURE_UNIT_MAP,
    REDUCTION_PRINT_FORMAT,
} from "../../utils/constants";
import { connect, useSelector } from "react-redux";
import { createSelector } from "redux-orm";
import numeral from "numeral";

import orm from "../../models/orm.register";

import "./VisualReport.module.scss";
import BaseORMModel from "../../models/BaseORMModel";
import { reportResultsOfBlocks } from "../../utils/functions";
import { toSignificantDigit } from "../../utils/math.utils";

const MetricReport = ({ projects }) => {
    // const blocks = _.flattenDeep((projects ?? []).map(project => project.blocks ?? []))
    //     .filter(block => ((block ?? {}).conflictProperties ?? {}).conflict === false && block.results);

    const blocks = _.flattenDeep((projects ?? []).map((project) => project.blocks ?? [])).filter(
        (block) => block.results
    );

    let results = reportResultsOfBlocks(blocks, MEASURE_MAP, MEASURE_UNIT_MAP);

    const chartLabels = (MEASURE_VALS ?? []).filter((val) => results[val.state]).map((val) => val.label);
    const chartValues = (MEASURE_VALS ?? [])
        .filter((val) => results[val.state])
        .map((val) =>
            results[val.state].before < 0 ||
            results[val.state].after < 0 ||
            // when it generates some DIN or PEST, show 0 rather than a negative number
            results[val.state].before <= results[val.state].after
                ? 0
                : ((results[val.state].before - results[val.state].after) * 100) / results[val.state].before
        )
        .map((val) => Math.round(val));

    // console.log(results);

    results = (MEASURE_VALS ?? [])
        .filter((val) => results[val.state])
        .map((mClass) => {
            const { reduction, measure, unit } = results[mClass.state];
            return {
                measure,

                // if it generates some DIN or PEST, user doesn't want to show them (or show 0 for now)
                reduction:
                    results[mClass.state].before <= results[mClass.state].after
                        ? 0
                        : Math.round((reduction ?? 0) * 100) / 100,
                unit,
            };
        });

    const title = (projects ?? []).length === 1 ? projects[0].project_title : `${projects.length} projects selected`;

    return (
        <div className="row report">
            {projects && projects.length !== 0 && <h1 className="col-12 instruction">{title}</h1>}
            <div className="col-12">
                <h2 className="cost-header">Percent improvement</h2>
                {(!projects || projects.length === 0) && <p>No projects selected</p>}
                {projects && projects.length !== 0 && (!results || results.length === 0) && <p>No results</p>}
                {projects && projects.length !== 0 && results && results.length !== 0 && (
                    <BarChart
                        className="improvement-chart"
                        data={{ data: chartValues, label: chartLabels }}
                        color="#B08EA2"
                    />
                )}
            </div>
            <div className="col-12">
                <h2 className="cost-header">Savings</h2>
                {
                    <>
                        {(!projects || projects.length === 0) && <p>No projects selected</p>}
                        {projects && projects.length !== 0 && (!results || results.length === 0) && <p>No results</p>}
                        {projects && projects.length !== 0 && results && results.length !== 0 && (
                            <div>
                                {results.map(({ measure, reduction, unit }, index) => {
                                    const theReduction = toSignificantDigit(reduction, 3);
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="row costs">
                                                <div className="col-4 measure">{measure}</div>
                                                <div
                                                    className={`col-4 amount ${reduction !== 0 ? "has-result" : ""}`}
                                                    title={
                                                        reduction === 0
                                                            ? "No change"
                                                            : numeral(theReduction).format(REDUCTION_PRINT_FORMAT)
                                                    }
                                                >
                                                    {reduction === 0
                                                        ? "No change"
                                                        : numeral(theReduction).format(REDUCTION_PRINT_FORMAT)}
                                                </div>
                                                <div className="col-4  unit">{unit}</div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        )}
                    </>
                }
            </div>
        </div>
    );
};

export default MetricReport;
