import MiddleWare from "../../utils/middleware";
import { EventEmitter } from "../../components/EventEmitter";
import { ERROR_CHANNEL, ACTIONS } from "../../utils/constants";

export default class BaseReducerHandler {
    /*
    
        { initState, slice }
        slice is required
    */
    constructor({ initState
        , slice, errorChannel = ERROR_CHANNEL.GENERAL, fireErrorOnSuccess = true }) {
        // if (!ACTIONS[slice])
        //     throw new Error(`Slice definition ${slice} does not exist`);

        this.initState = initState;
        this.slice = slice;
        this.errorChannel = errorChannel ?? ERROR_CHANNEL.GENERAL;
        this.fireErrorOnSuccess = fireErrorOnSuccess ?? true;

        // this.middleware = new MiddleWare({});
        this.pipes = [];
    }

    // actionParams should contain state and action
    generatePipe({ actionName, actionFn, actionParams }) {

        actionParams = actionParams ?? [];

        return (d, error, next) => {
            if (error) {
                throw error;
            }

            if (!actionFn)
                throw new Error("Action function does not exist");

            const pipeRet = actionFn(...actionParams, next);
            // console.log("pipeRet");
            // console.log(pipeRet);
            return pipeRet;
        };
    }

    generateErrorPipe({ errorChannel, fireErrorOnSuccess }) {
        return (d, error, next) => {
            if (error) {

                EventEmitter.dispatch(errorChannel, {
                    message: error.message
                });
            } else {
                fireErrorOnSuccess && EventEmitter.dispatch(errorChannel ?? ERROR_CHANNEL.GENERAL, null);
            }

            return this.initState;
        };
    };

    registerAction({ actionName, actionFn }) {
        this.pipes.push({
            actionName,
            actionFn,
            actionParams: []
        });
    }

    reducer() {
        return (state = this.initState, action) => {
            let middleware = new MiddleWare({});

            const actionDataInjectedPipes = this.pipes.map(pipe => {

                let { actionParams } = pipe;
                actionParams = actionParams ?? [];


                // actionParams.push(action);

                pipe.actionParams = [state, action];

                return pipe;
            });

            // data flows through the pipe here
            (actionDataInjectedPipes ?? []).forEach(pipe => {
                middleware.use(this.generatePipe(pipe));
            });

            // default case
            middleware.use((d, error, next) => {
                if (error) {
                    throw error;
                }

                return this.initState;
            });

            middleware.use(this.generateErrorPipe({ errorChannel: this.errorChannel, fireErrorOnSuccess: this.fireErrorOnSuccess }));
            const ret = middleware.execSync();

            // console.log("slice");
            // console.log(this.slice);
            // console.log(action);

            // console.log(ret);

            return ret;
        };
    }
}