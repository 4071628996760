export const areGrazingProjectGeometriesValid = (project) => {
    const pastureManagement = project.survey?.q0_1 ?? false;
    const streambankManagement = project.survey?.q0_2 ?? false;
    const gulliesManagement = project.survey?.q0_3 ?? false;

    if (!pastureManagement && !streambankManagement && !gulliesManagement) {
        return false;
    }

    if (pastureManagement && project.blocks.filter((x) => x.geometry.type === "Polygon").length === 0) {
        return false;
    }

    if (streambankManagement && project.blocks.filter((x) => x.geometry.type === "LineString").length === 0) {
        return false;
    }

    if (gulliesManagement && project.blocks.filter((x) => x.geometry.type === "Point").length === 0) {
        return false;
    }
    return true;
};
