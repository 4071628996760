import {
    HttpMethods,
    ServerType,
    Url,
    EVENT_CHANNEL,
    ID_ATTRIBUTE,
    ORM_SLICE,
    ORM_PROJECT_SLICE,
    ORM_BLOCK_SLICE,
    UPDATE_BATCH_ACTION,
    LATEST_SURVEY_VERSION,
    GRAZING_PRACTICE_FLAG_QUESTION_ID,
    GRAZING_STREAMBANK_SPECIFIC_QUESTIONS,
} from "../utils/constants";
import { UrlBuilder, https } from "../api";
import BaseService from "./base.service";
import { WS } from "../api/ws";
import { EventEmitter } from "../components/EventEmitter";
import _ from "lodash";
import { store } from "../redux";
import generateORMActionName from "../redux/reducers/orm.action.gen";
import ProgressController from "../components/ProgressDisplay/progressController";
import orm from "../models/orm.register";
import { Config } from "../utils/config";
import { keyValueStringContent } from "../utils/functions";

class ComputationService extends BaseService {
    constructor(props) {
        super(props);

        const ws = new WS({
            onConnect: () => {},
            onReconnect: () => {},
            onDisconnect: () => {},
            onError: () => {},
        });

        // start to listen on the ws
        this.ws = ws.open();
        this.ws.on("progress_update", (event) => {
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COMPUTATION, {
                payload: event,
            });
        });
    }

    pullSurvey(properties) {
        let surveyData = {};

        if (!properties) {
            return surveyData;
        }

        const knownProperties = [
            ...Object.values(GRAZING_PRACTICE_FLAG_QUESTION_ID),
            ...Object.values(GRAZING_STREAMBANK_SPECIFIC_QUESTIONS),
        ];

        Object.keys(properties)
            .filter((key) => {
                return (
                    RegExp(/q([0-9a-zA-Z]+)_([0-9a-zA-Z]*_)*(after|before)/g).test(key) || knownProperties.includes(key)
                );
            })
            .forEach((qId) => {
                surveyData[qId] = properties[qId];
            });

        return surveyData;
    }

    pullComputationData(blocks) {
        const projects = [];
        const blockMap = _.keyBy(blocks ?? [], (block) => block[ID_ATTRIBUTE]);

        blocks = (blocks ?? [])
            .map((block) => {
                const { projectId, appVersion, surveyVersion, [ID_ATTRIBUTE]: blockId, geometry, project } = block;

                if (!project) return null;

                projects.push(project);

                const { survey, industry, soilClimateCode } = project;

                let surveyData = this.pullSurvey(survey);

                return {
                    type: "Feature",
                    // each feature requires
                    // industry, project_id (try projectId), [ID_ATTRIBUTE], q1_after,...., surveyTemplate,
                    // might pass appVersion if we need to
                    properties: {
                        [ID_ATTRIBUTE]: blockId,
                        projectId,
                        appVersion,
                        surveyTemplate: surveyVersion,
                        industry,
                        soilClimateCode,
                        ...surveyData,
                    },
                    id: blockId,
                    geometry,
                };
            })
            .filter((d) => d);

        const indBlocks = _.groupBy(blocks, (block) => block.properties.industry);

        const projectMap = _.keyBy(projects, (project) => project[ID_ATTRIBUTE]);

        return {
            projects,
            blockMap,
            projectMap,
            compData: Object.keys(indBlocks ?? []).map((industry) => {
                return {
                    meta: {
                        industry,
                    },
                    features: indBlocks[industry] ?? [],
                };
            }),
        };
    }

    async saveProjects(projects) {
        projects = (projects ?? []).map((project) => {
            const { collectionId, ...rest } = project;
            return {
                ...rest,
            };
        });

        const url = UrlBuilder(ServerType.Data, Url.SaveProjects);

        return await https({ url, method: HttpMethods.Put, data: projects ?? [], userRequest: true });
    }

    async saveBlocks(blocks) {
        blocks = (blocks ?? []).map((block) => {
            const { projectId, ...rest } = block;
            return {
                ...(rest ?? {}),
                projectId,
                existing_block: block.dateCreated != null,
            };
        });

        const url = UrlBuilder(ServerType.Data, Url.SaveBlocks);

        return await https({ url, method: HttpMethods.Put, data: blocks ?? [], userRequest: true });
    }

    async compute(blocks, projects, onProgress = ({}) => {}) {
        const _projectMap = _.keyBy(projects ?? [], (project) => project[ID_ATTRIBUTE]);

        blocks = (blocks ?? []).map((block) => {
            const { projectId, ...rest } = block;

            let _project = _projectMap[projectId] ?? {};
            _project = _.omit(_project, "blocks");

            const project = {
                ...(block.project ?? {}),
                ...(_project ?? {}),
            };

            return {
                projectId,
                ...rest,
                project,
            };
        });

        // prepare data for computation
        const { blockMap, projectMap, compData } = this.pullComputationData(blocks);

        const url = UrlBuilder(ServerType.Computation, Url.Run);

        const eventHandler = {
            ref: null,
        };

        return await new Promise(async (resolve) => {
            let jobId = null;

            const tasks = {};
            const taskBuffer = {};
            const jobBuffer = {};

            // resolve data
            const _resolve = () => {
                // dump data into tasks
                Object.keys(tasks).forEach((task) => {
                    if (taskBuffer[task]) {
                        tasks[task] = taskBuffer[task];
                    }
                });

                // clean up task results
                let blockResults = _.flattenDeep(Object.values(tasks));

                blockResults = blockResults
                    .filter((d) => d)
                    .map((result) => {
                        const {
                            // block fields
                            codes = [],
                            extraResults,
                            results,
                            scores,
                            area,
                            conflict = null,
                            lookupFails = null,
                            lookupFailureImpactAreas = null,
                            lookupFailureImpactRatio = null,
                            outsideOfRegion = null,
                            paddockCodesFailsToResolve = null,
                            erosionRateProviderError = null,
                            surveyVersion,
                            appVersion,
                            projectId,
                            [ID_ATTRIBUTE]: blockId,
                        } = result ?? {};

                        return {
                            [ID_ATTRIBUTE]: blockId,
                            projectId,
                            appVersion,
                            area,
                            codes,
                            conflictProperties: {
                                conflict,
                                lookupFails,
                                lookupFailureImpactAreas,
                                lookupFailureImpactRatio,
                                outsideOfRegion,
                                paddockCodesFailsToResolve,
                                erosionRateProviderError,
                            },
                            extraResults,
                            results,
                            scores,
                            surveyVersion,
                            geometry: blockMap[blockId].geometry,
                        };
                    });

                let projectResults = _.flattenDeep(jobBuffer[jobId] ?? []);

                projectResults = projectResults
                    .filter((d) => d)
                    .map((result) => {
                        const {
                            incompleteSurveyConflict,
                            customSoilClimatePermissionConflict,
                            qConflicts,
                            qMissing,
                            industry,
                            projectId,
                            soilClimateCode,
                            ...rest
                        } = result;

                        let surveyData = this.pullSurvey(rest);

                        return {
                            ...(projectMap[projectId] ?? {}),
                            survey: {
                                incompleteSurveyConflict,
                                customSoilClimatePermissionConflict,
                                qConflicts,
                                qMissing,
                                ...(surveyData ?? {}),
                            },
                            industry,
                            [ID_ATTRIBUTE]: projectId,
                            soilClimateCodeId: soilClimateCode ? soilClimateCode[ID_ATTRIBUTE] : null,
                        };
                    });

                onProgress({
                    progress: 1,
                });

                resolve({
                    blocks: blockResults,
                    projects: projectResults,
                });
            };

            const _progress = () => {
                if (!Object.keys(tasks).length) return;

                const completedTasks = Object.keys(tasks).filter((task) => taskBuffer[task]).length;
                const totalTasks = Object.keys(tasks).length;

                const percentage = parseFloat(`${completedTasks}`) / totalTasks;

                onProgress({
                    progress: percentage,
                });
            };

            const progressUpdate = (event) => {
                if (!event || !event.payload) return;

                event = JSON.parse(event.payload);

                const { job, tasks, event: name, data: eventData } = event;

                if (name === "taskDone") {
                    const {
                        id: taskId,
                        data: { results },
                    } = eventData ?? {};
                    taskBuffer[taskId] = results ?? [];
                }

                if (name === "jobDone") {
                    jobBuffer[jobId] = eventData ?? [];
                }

                const completedTasks = tasks.filter((task) => taskBuffer[task] != null);
                const totalTasks = tasks;

                if (job === jobId && jobBuffer[jobId] && completedTasks.length === totalTasks.length) {
                    _resolve();
                } else {
                    _progress();
                }
            };

            eventHandler.ref = progressUpdate;

            EventEmitter.subscribe(EVENT_CHANNEL.EVENT_CMD_COMPUTATION, progressUpdate);

            // trigger computation
            const { id, tasks: _tasks } = await https({
                url,
                method: HttpMethods.Put,
                data: {
                    data: compData,
                },
                userRequest: true,
            });

            // watching progress and results (could be an issue when results are given before watching command is sent)
            this.ws.emit(
                "job.cmd.watch",
                JSON.stringify({
                    watch: id,
                })
            );

            (_tasks ?? []).forEach((task) => (tasks[task] = null));
            jobId = id;

            if (jobBuffer[jobId]) {
                _resolve();
            }
        }).finally((d) => {
            EventEmitter.off(EVENT_CHANNEL.EVENT_CMD_COMPUTATION, eventHandler.ref);
            return d;
        });
    }

    async saveSoilClimateCodes(projects) {
        const projectService = this.getService("projectService");

        const codesToSave = projects.filter((x) => x.soilClimateCode).map((x) => x.soilClimateCode);

        if (codesToSave.length === 0) {
            return;
        }

        const savedCodes = await projectService.saveSoilAndClimateCodes(codesToSave);

        projects
            .filter((project) => project.soilClimateCode)
            .forEach((project) => {
                const matchingCode = _.find(savedCodes, (x) => x.code === project.soilClimateCode.code);

                if (matchingCode) {
                    project.soilClimateCode = matchingCode;
                    project.soilClimateCodeId = matchingCode[ID_ATTRIBUTE];
                }
            });
    }

    async save(_blocks, _projects, onProgress = ({}) => {}, onBlockSave = () => {}, onProjectSave = () => {}) {
        if (!_projects || !_projects.length) throw new Error("Require project data");

        const blockMap = _.keyBy(_blocks ?? [], (block) => block[ID_ATTRIBUTE]);

        const { projects, blocks } = await this.compute(_blocks, _projects, onProgress);

        const projectService = this.getService("projectService");

        await this.saveSoilClimateCodes(projects);

        await projectService.saveProjects(projects);

        onProjectSave();

        const retBlocks = await this.saveBlocks(
            (blocks ?? []).map((block) => {
                return {
                    ...(blockMap[block[ID_ATTRIBUTE]] ?? {}),
                    ...(block ?? {}),
                };
            })
        );

        onBlockSave();

        return { projects: projects, blocks: retBlocks };
    }

    collectTaskResults(job, onProgress = ({}) => {}) {
        const { id, tasks: _tasks } = job;

        const eventHandler = {
            ref: null,
        };

        return new Promise((resolve) => {
            let jobId = null;
            const tasks = {};
            const jobBuffer = {};

            // resolve data
            const _resolve = () => {
                const result = jobBuffer[jobId];

                resolve(result);
                onProgress({
                    progress: 1,
                });
            };

            const _progress = (event) => {
                if (!_tasks.length) return;

                const completedTasks = _tasks.filter((task) => tasks[task] !== undefined).length;
                const totalTasks = _tasks.length;

                const percentage = parseFloat(`${completedTasks}`) / totalTasks;
                if (jobId === event.job)
                    onProgress({
                        progress: percentage,
                    });
            };

            const progressUpdate = (event) => {
                if (!event || !event.payload) return;

                event = JSON.parse(event.payload);

                const { job, event: name, data: eventData } = event;

                if (name === "taskDone") {
                    const { id: taskId } = eventData ?? {};

                    if (taskId in tasks) tasks[taskId] = eventData;
                }

                if (name === "jobDone" && job === jobId) {
                    jobBuffer[jobId] = eventData;
                }

                if (jobBuffer[jobId] !== undefined) {
                    _resolve();
                } else {
                    _progress(event);
                }
            };

            eventHandler.ref = progressUpdate;

            (_tasks ?? []).forEach((task) => (tasks[task] = undefined));
            jobId = id;
            jobBuffer[jobId] = undefined;

            EventEmitter.subscribe(EVENT_CHANNEL.EVENT_CMD_COMPUTATION, eventHandler.ref);
            // Hook to job
            this.ws.emit(
                "job.cmd.watch",
                JSON.stringify({
                    watch: id,
                })
            );

            if (jobBuffer[jobId] !== undefined) {
                _resolve();
            }
        }).finally((d) => {
            EventEmitter.off(EVENT_CHANNEL.EVENT_CMD_COMPUTATION, eventHandler.ref);
            return d;
        });
    }

    async saveAndUpdateCollection(tasksAndJobs) {
        const STAGE = {
            COMP: {
                label: "Calculating data",
                state: "Computation",
                contribution: 0.95,
            },

            IMPORT: {
                label: "Saving data",
                state: "Importing",
                contribution: 0.05,
            },
        };

        const stages = [STAGE.COMP, STAGE.IMPORT];

        const progress = new ProgressController({
            stages,
        });

        progress.report(STAGE.COMP.state, 0, true);

        const arrOfPromiseCompJobs = [
            this.collectTaskResults(tasksAndJobs.calculationJob, ({ progress: _p }) => {
                progress.report(STAGE.COMP.state, _p, true);
            }),
            this.collectTaskResults(tasksAndJobs.importJob, ({ progress: _p }) => {
                progress.report(STAGE.IMPORT.state, _p, true);
            }),
        ];

        return await Promise.all(arrOfPromiseCompJobs).then(([calJobResults, importJobResults]) => {
            const blockResults = _.flattenDeep((calJobResults ?? []).map((d) => d.results ?? []));
            const collection = (importJobResults ?? [])[0]?.collection;

            return [blockResults, collection];
        });
    }

    // blocks require projectId
    // for block creation, block editing,
    // not block delete
    // not project creation, not project editing
    async saveAndUpdateStore(blocks, projects = null) {
        const STAGE = {
            INIT: {
                label: "Init",
                state: "init",
                contribution: 0.1,
            },

            COMP: {
                label: "Calculating",
                state: "Computation",
                contribution: 0.6,
            },

            BLOCK_SAVING: {
                label: "Saving",
                state: "blockSaving",
                contribution: 0.1,
            },

            PROJECT_SAVING: {
                label: "Saving",
                state: "projectSaving",
                contribution: 0.1,
            },

            DRAWING: {
                label: "Drawing",
                state: "draw",
                contribution: 0.05,
            },

            FINISHED: {
                label: "Finished",
                state: "finish",
                contribution: 0.05,
            },
        };

        const stages = [
            STAGE.INIT,
            STAGE.COMP,
            STAGE.BLOCK_SAVING,
            STAGE.PROJECT_SAVING,
            STAGE.DRAWING,
            STAGE.FINISHED,
        ];

        const progress = new ProgressController({
            stages,
        });

        progress.report(STAGE.INIT.state, 0, true);

        await new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 200);
        });

        // get projects from blocks, query redux
        blocks = (blocks ?? []).filter((block) => block.projectId);

        const blockMap = _.keyBy(blocks, (block) => block[ID_ATTRIBUTE]);
        const projectIds = _.uniq((blocks ?? []).map((block) => block.projectId));
        const projectIdMap = _.keyBy(
            projectIds.map((id) => {
                return {
                    [ID_ATTRIBUTE]: id,
                };
            }),
            (project) => project[ID_ATTRIBUTE]
        );

        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        if (!projects) {
            projects = session[ORM_PROJECT_SLICE].select(session, {
                include: [],
                filter: (project) => {
                    return projectIdMap[project[ID_ATTRIBUTE]] != null;
                },
            });
        }

        const projectMap = _.keyBy(projects, (project) => project[ID_ATTRIBUTE]);

        blocks = blocks.filter((block) => projectMap[block.projectId] != null);

        progress.report(STAGE.COMP.state, 0, true);

        if (!blocks.length || !projects.length) {
            progress.report(STAGE.FINISHED.state, 1, true);
            return {
                projects: [],
                blocks: [],
            };
        }

        const { projects: savedProjects, blocks: savedBlocks } = await this.save(
            blocks.map((block) => {
                return {
                    ...block,
                    appVersion: Config.VERSION,
                    surveyVersion: LATEST_SURVEY_VERSION[projectMap[block.projectId].industry],
                };
            }),
            projects,
            ({ progress: _p }) => {
                progress.report(STAGE.COMP.state, _p, true);
            },
            () => {
                // block save
                progress.report(STAGE.BLOCK_SAVING.state, 1, true);
            },
            () => {
                // project save
                progress.report(STAGE.PROJECT_SAVING.state, 1, true);
            }
        );

        progress.report(STAGE.DRAWING.state, 0, true);

        store.dispatch({
            type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: UPDATE_BATCH_ACTION }),
            payload: (savedBlocks ?? []).map((block) => {
                return {
                    ...block,
                    projectId: (blockMap[block[ID_ATTRIBUTE]] ?? {}).projectId ?? (projectIds ?? [])[0],
                };
            }),
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: UPDATE_BATCH_ACTION }),
            payload: (savedProjects ?? [])
                .filter((project) => projectMap[project[ID_ATTRIBUTE]] != null)
                .map((project) => {
                    project = _.omit(project, "blocks");

                    return {
                        ...project,
                        collectionId: projectMap[project[ID_ATTRIBUTE]].collectionId,
                    };
                }),
        });

        progress.report(STAGE.DRAWING.state, 1, true);
        progress.report(STAGE.FINISHED.state, 1, true);

        return { projects: savedProjects, blocks: savedBlocks };
    }

    async createProjectAndUpdateStore(projectId, collectionId) {
        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        let project = (session[ORM_PROJECT_SLICE].select(session, {
            include: [
                {
                    blocks: [],
                },
            ],
            filter: {
                [ID_ATTRIBUTE]: projectId,
            },
        }) ?? [])[0];

        if (!project) throw new Error("The project does not exist");

        let blocks = project.blocks ?? [];

        if (project.dateCreated) throw new Error("The project was created");

        const projectService = this.getService("projectService");

        await this.saveSoilClimateCodes([project]);

        const result = await projectService.saveProject(collectionId, {
            ...project,
            blocks: [],
        });

        project = {
            ...project,
            ...result,
        };

        project = _.omit(project, "blocks");

        project.collectionId = collectionId;

        blocks = (blocks ?? []).map((block) => {
            return {
                ...block,
                projectId: project[ID_ATTRIBUTE],
            };
        });

        return await this.saveAndUpdateStore(blocks, [project]);
    }

    didUpdateSoilClimateCode(sourceProject, editingProject) {
        if (!sourceProject.soilClimateCode && !editingProject.soilClimateCode) {
            return false;
        }

        if (!sourceProject.soilClimateCode && editingProject.soilClimateCode) {
            return editingProject.soilClimateCode.code.length > 0;
        }

        if (sourceProject.soilClimateCode && !editingProject.soilClimateCode) {
            return true;
        }

        return sourceProject.soilClimateCode.code !== editingProject.soilClimateCode.code;
    }

    async updateProjectAndUpdateStore(projectId, sourceProjectId) {
        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        let projects =
            session[ORM_PROJECT_SLICE].select(session, {
                include: [
                    {
                        blocks: [],
                    },
                ],
                filter: (project) => {
                    return project[ID_ATTRIBUTE] == projectId || project[ID_ATTRIBUTE] == sourceProjectId;
                },
            }) ?? [];

        let sourceProject = projects.find((project) => project[ID_ATTRIBUTE] === sourceProjectId);
        let editingProject = projects.find((project) => project[ID_ATTRIBUTE] === projectId);

        if (!sourceProject || !editingProject) throw new Error("The project does not exist");

        if (!sourceProject.dateCreated) throw new Error("The project needs to exist");

        // only apply for existing project
        // if survey is the same, only update data
        const sourceSurveys = this.pullSurvey(sourceProject.survey ?? {});
        const editingSurveys = this.pullSurvey(editingProject.survey ?? {});

        const sSurveyContent = keyValueStringContent(sourceSurveys);
        const eSurveyContent = keyValueStringContent(editingSurveys);

        if (sSurveyContent === eSurveyContent && !this.didUpdateSoilClimateCode(sourceProject, editingProject)) {
            const projectService = this.getService("projectService");

            const savedProjects = await projectService.saveProjectsAndUpdateStore([
                {
                    ...(editingProject ?? {}),
                    [ID_ATTRIBUTE]: sourceProject[ID_ATTRIBUTE],
                    collectionId: sourceProject.collectionId,
                },
            ]);

            return { projects: savedProjects, blocks: [] };
        }

        // if survey is different run comp
        editingProject = _.omit(editingProject, ["blocks"]);
        editingProject[ID_ATTRIBUTE] = sourceProject[ID_ATTRIBUTE];
        editingProject.collectionId = sourceProject.collectionId;

        let blocks = sourceProject.blocks ?? [];

        return await this.saveAndUpdateStore(blocks, [editingProject]);
    }
}

export default ComputationService;
