import React, { useEffect, useState } from "react";
import BarChart from "../../components/BarChart/index";
import { faLayerPlus } from "@fortawesome/pro-solid-svg-icons/faLayerPlus";
import { faChevronCircleDown } from "@fortawesome/pro-solid-svg-icons/faChevronCircleDown";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import { MEASURE_VALS, MEASURE_MAP, ORM_WORKSPACE_SLICE, ORM_PROJECT_SLICE, ID_ATTRIBUTE, WORKSPACE_ID, MEASURE_UNIT_MAP } from '../../utils/constants';
import { connect, useSelector } from "react-redux";
import { createSelector } from 'redux-orm';

import orm from '../../models/orm.register';

import "./VisualReport.module.scss";
import BaseORMModel from "../../models/BaseORMModel";
import { faHandPointer } from "@fortawesome/pro-solid-svg-icons";

const ProjectSelectionInstruction = () => {

    return <div className="report instruction">
                <h1 className="header">
                    <span className="icon-container"> <FontAwesomeIcon
                    className="icon"
                    icon={faHandPointer}/></span>
                   
                Select a project</h1>
        <h2>Select projects from the map to show their improvement summary</h2>
    </div>;
};

export default ProjectSelectionInstruction;

