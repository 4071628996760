import ModelConverter from './model.converter';

class BaseService extends ModelConverter {
    constructor(props) {
        super(props);
    }

    assignService(serviceMap) {
        Object.keys(serviceMap).forEach(name => {
            const instance = serviceMap[name];
            if (instance !== this) {
                this[name] = instance;
            }
        });
    }

    getService(name) {
        if (!this[name])
            throw new Error(`This service ${name} does not exist`);

        return this[name];
    }

    static cast(instance, constructor) {
        if (instance instanceof constructor) {
            return instance;
        }

        throw new Error("Cannot case the type");
    }
}

export default BaseService;