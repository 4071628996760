// Reducer slices
export const COLLECTION_SLICE = "collection";
export const PROJECT_SLICE = "project";
export const BLOCK_SLICE = "block";
export const GROUP_SLICE = "group";

// ORM slices
export const ORM_PROJECT_SLICE = "Project";
export const ORM_COLLECTION_SLICE = "Collection";
export const ORM_BLOCK_SLICE = "Block";
export const ORM_COLLECTION_EDITING_STATE_SLICE = "CollectionEditingState";
export const ORM_WORKSPACE_SLICE = "Workspace";
export const ORM_FUNDING_BODY_SLICE = "FundingBody";
export const ORM_FUNDING_PROGRAM_SLICE = "FundingProgram";
export const ORM_SLICE = "orm";