import { AppGUID, STATUS_ERROR, HttpMethods } from '../utils/constants';
import fetchProgress from 'fetch-progress';
const VALID_RESPONSE = 200;

export class AppResponseError extends Error {
    constructor(message) {
        super(message);
    }
}

// the name says https but the implementation does not force or error out if non http call is being called
// SEMANTIC ISSUE
export const https = async ({ url, method, header = {}, data: body = null, userRequest = true }) => {
    const userToken = localStorage.getItem(`${AppGUID}_user_token`) ?? "";
    const publicToken = localStorage.getItem(`${AppGUID}_public_token`) ?? "";
    let config = {
        method, // * GET, POST, PUT, DELETE. These methods are available (HttpMethods).
        // mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        // url: url,
        // headers: {'Authorization':CONFIG.AuthenCode},
        headers: {
            ...(header ?? {}),

            "Content-Type": "application/json",

            Authorization: userRequest ? `Bearer ${userToken}` : `Basic ${publicToken}`
        },
        // Only add the body object if method is not Get.
        ...(method !== HttpMethods.Get ? {
            body: JSON.stringify(body ?? {})
        } : {})
    };

    let response = null;

    try {
        response = await fetch(url, config);
        const data = await response.json();

        // v1 backend error response
        if (data && "message" in data) {
            throw new AppResponseError(data.message);
        }

        // v2 backend error response
        if (data && "error" in data) {
            throw new AppResponseError(data.error.message);
        }

        // To catch responses from back-end where the payload is empty
        if (!response || (!data && response.status === VALID_RESPONSE)) {
            throw new AppResponseError(`Success BUT please go refactor the endpoint ${response.config.url} to return a valid response.`);
        }
        
        return data;
    } catch (error) {
        if (response.status === VALID_RESPONSE) {
            return null;
        }

        console.log(error);
        throw new AppResponseError(error.message);
    }
};

export const httpsLoopbackQuery = async ({url, method, headers = {}, filters = {}, userRequest = true }) => {
    const userToken = localStorage.getItem(`${AppGUID}_user_token`) ?? "";
    const publicToken = localStorage.getItem(`${AppGUID}_public_token`) ?? "";

    let config = {
        method,
        headers: {
            ...headers,
            Authorization: userRequest ? `Bearer ${userToken}` : `Basic ${publicToken}`
        }
    };

    const jsonFilters = JSON.stringify(filters);

    const finalUrl = `${url}?filter=${encodeURIComponent(jsonFilters)}`;
    
    let response = null;

    try {
        response = await fetch(finalUrl, config);

        const data = await response.json();

        // v1 backend error response
        if (data && "message" in data) {
            throw new AppResponseError(data.message);
        }

        // v2 backend error response
        if (data && "error" in data) {
            throw new AppResponseError(data.error.message);
        }

        // To catch responses from back-end where the payload is empty
        if (!response || (!data && response.status === VALID_RESPONSE)) {
            throw new AppResponseError(`Success BUT please go refactor the endpoint ${response.config.url} to return a valid response.`);
        }
        
        return data;
    } catch (error) {
        if (response.status === VALID_RESPONSE) {
            return null;
        }

        console.log(error);
        throw new AppResponseError(error.message);
    }
};

export const httpsWithProgress = async ({ url, method, body, header = {}, userRequest = true, onProgress = () => { } }) => {

    const userToken = localStorage.getItem(`${AppGUID}_user_token`) ?? "";
    const publicToken = localStorage.getItem(`${AppGUID}_public_token`) ?? "";

    const response = fetch(url, {
        method,
        headers: {
            ...(header ?? {}),
            Authorization: userRequest ? `Bearer ${userToken}` : `Basic ${publicToken}`
        },
        ...(method !== HttpMethods.Get ? {
            body: JSON.stringify(body ?? {})
        } : {})

    });

    let responseData = await new Promise((resolve, reject) => {

        response.then(fetchProgress({
            onProgress,
            onError: (err) => {
                reject(err);
            }
        })).then(r => {
            const json = r.json();
            json.then(d => {
                resolve(d);
            }).catch(err => {
                reject(err);
            });
        }).catch(err => {
            reject(err);
        });

    });

    return responseData;
}