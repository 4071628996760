import { EventHanlders } from '../../components/EventEmitter';
import { EVENT_CHANNEL, ORM_FUNDING_BODY_SLICE, CREATE_BATCH_ACTION } from '../../utils/constants';
import { serviceWrapper, services } from '../../services';
import generateORMActionName from '../../redux/reducers/orm.action.gen';
import { store } from '../../redux';


const { fundingService } = services;

// event logic
const fundingLoading = async (event) => {

    console.log("loading request");
    let bodies = await serviceWrapper({}, {
        instance: fundingService,
        name: "getFunding",
        params: [
            true
        ]
    });

    if ((bodies ?? {}).fundingBodies) {
        store.dispatch({
            type: generateORMActionName({ slice: ORM_FUNDING_BODY_SLICE, actionName: CREATE_BATCH_ACTION }),
            payload: ((bodies ?? {}).fundingBodies ?? [])
        });
    }
};

// register events
const fundingEventsDesc = [{
    event: EVENT_CHANNEL.EVENT_CMD_FUNDING_LOADING,
    handler: fundingLoading
}];

// create an event handler
const fundingSourceHandlers = new EventHanlders();
fundingSourceHandlers.addEventsViaDescriptor(fundingEventsDesc);

export default fundingSourceHandlers;