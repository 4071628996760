import { HttpMethods, ServerType, Url, ID_ATTRIBUTE, ORM_PROJECT_SLICE, UPDATE_BATCH_ACTION } from '../utils/constants';
import { UrlBuilder, https } from '../api';
import BaseService from './base.service';
import ProgressController from '../components/ProgressDisplay/progressController';
import { store } from '../redux';
import generateORMActionName from '../redux/reducers/orm.action.gen';
import _ from 'lodash';

class ProjectService extends BaseService {
    constructor(props) {
        super(props);
    }

    async copyProjects(collectionId, projectIds, originCollectionId, groupId) {
        const url = UrlBuilder(ServerType.Data, Url.CopyProjects, []);
        
        const data = {
            collectionId,
            projectsCopiedIds: projectIds,
            originCollectionId,
            groupId
        };

        return await https({ url, method: HttpMethods.Post, data });
    }

    async moveProjects(collectionId, projectIds, originCollectionId) {
        const url = UrlBuilder(ServerType.Data, Url.MoveProjects, []);
        const data = {
            collectionId,
            projectsMovedIds: projectIds,
            originCollectionId
        };

        return await https({ url, method: HttpMethods.Put, data });
    }

    async deleteProjects(projectIds) {
        const url = UrlBuilder(ServerType.Data, Url.DeleteProjects, []);
        const data = {
            projectsDeletedIds: projectIds
        };

        return await https({ url, method: HttpMethods.Put, data });
    }

    async saveProject(collectionId, data) {
        const url = UrlBuilder(ServerType.Data, Url.NewProject, [collectionId]);
        const newProject = await https({ url, method: HttpMethods.Post, data });
        return newProject;
    }

    async getSurveyByType(surveyType) {
        const url = UrlBuilder(ServerType.Computation, Url.Survey, [surveyType]);
        const survey = await https({ url, method: HttpMethods.Get });
        return survey;
    }

    async saveProjects(projects) {
        projects = (projects ?? []).map(project => {
            const { collectionId, [ID_ATTRIBUTE]: projectId, blocks = [], ...rest } = project;

            return {
                ...rest,
                ...((project.dateCreated) ? { [ID_ATTRIBUTE]: projectId } : {})
            };
        });

        const url = UrlBuilder(ServerType.Data, Url.SaveProjects);

        return await https({ url, method: HttpMethods.Put, data: projects, userRequest: true });
    }

    async saveSoilAndClimateCodes(codes) {
        const url = UrlBuilder(ServerType.Data, Url.SaveSoilClimateCodes);
        
        return await https({ url, method: HttpMethods.Post, data: codes, userRequest: true });
    }

    
    async getSoilClimateCode(codeId) {
        const url = UrlBuilder(ServerType.Data, Url.GetSoilClimateCode, [codeId]);
        const code = await https({ url, method: HttpMethods.Get });
        return code;
    }


    // expecting collectionId in projects
    async saveProjectsAndUpdateStore(projects) {
        if (!projects || !projects.length) {
            return [];
        }

        const projectMap = _.keyBy(projects, project => project[ID_ATTRIBUTE]);

        const STAGE = {
            SAVING: {
                label: 'Saving',
                state: 'saving',
                contribution: .95
            },
            DRAWING: {
                label: 'Drawing',
                state: 'draw',
                contribution: .05
            }
        };
        const stages = [STAGE.SAVING, STAGE.DRAWING];

        const progress = new ProgressController({
            stages
        });

        // saving data
        progress.report(STAGE.SAVING.state, 0, true);
        
        await new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 200);
        });

        const savedProjects = await this.saveProjects(projects);
        
        progress.report(STAGE.SAVING.state, 1, true);

        // update redux store
        progress.report(STAGE.DRAWING.state, 0, true);

        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: UPDATE_BATCH_ACTION }),
            payload: (savedProjects ?? []).filter(project => projectMap[project[ID_ATTRIBUTE]] != null)
                .map(project => {
                    project = _.omit(project, "blocks");

                    return {
                        ...project,
                        collectionId: projectMap[project[ID_ATTRIBUTE]].collectionId
                    };
                })
        });
        progress.report(STAGE.DRAWING.state, 1, true);
    }
}

export default ProjectService;