import { saveAs } from "file-saver";
import JSZip from "jszip";
import replace from "lodash/replace";
import transform from "lodash/transform";
import merge from "lodash/merge";
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import sum from "lodash/sum";
import sortBy from "lodash/sortBy";
import round from "lodash/round";
import orm from "../models/orm.register";
import { store } from "../redux";
import { ID_ATTRIBUTE, ORM_SLICE, ORM_WORKSPACE_SLICE, INDUSTRY, ORM_VERSION_CONTROL_FIELDS } from "../utils/constants";
import {
    INDUSTRY_BY_KEY,
    CANE_SURVEY_VERSION,
    BANANA_SURVEY_VERSION,
    GRAIN_SURVEY_VERSION,
    GRAZING_SURVEY_VERSION,
} from "./miscellaneous";
import { ORM_BLOCK_SLICE } from "./reduxSlices";
import { toSignificantDigit } from "./math.utils";

export const splitFeaturesByIndustry = (blocks) => {
    if (!blocks) {
        blocks = [];
    }

    let split = Object.keys(INDUSTRY_BY_KEY).map((industry) => {
        return {
            industry,
            features: blocks.filter((block) => block.properties.industry === industry),
        };
    });

    return split;
};

export const formatProjectDescriptionForCSV = (description) => {
    if (!description) {
        return "";
    }

    return description.replaceAll('"', "");
};

export const replaceSnakeCaseToDot = (properties, propertyName) => {
    if (
        !properties ||
        properties[propertyName.toLowerCase().trim()] === null ||
        properties[propertyName.toLowerCase().trim()] === undefined
    ) {
        return properties || {};
    }

    properties[propertyName.toLowerCase().trim()] = replace(properties[propertyName.toLowerCase().trim()], "_", ".");

    return properties;
};

export const renameProperty = (properties, keyMappings, keyOmitting = {}) => {
    if (!properties) {
        return properties || {};
    }

    return transform(
        properties,
        function (result, value, key) {
            if (keyMappings[key] !== null && keyMappings[key] !== undefined) {
                result[keyMappings[key]] = value;
            } else if (keyOmitting[key] === 1) {
                // ignore key that is in the omitting list
            } else {
                result[key] = value;
            }
        },
        {}
    );
};

// split numeric options, assume that propertyName has the format q[questionId]_[stage: before|after]
// the value of the property is an object {[questionId]_[option]: value}
export const numericOptionsToFlatQuestions = (properties, propertyName) => {
    const sanitisedPropertyName = propertyName.toLowerCase().trim();

    if (!properties || properties[sanitisedPropertyName] === null || properties[sanitisedPropertyName] === undefined) {
        return properties || {};
    }

    const [__, stage] = propertyName.split("_");

    let propertyKeys = Object.keys(properties[sanitisedPropertyName]);
    const splitProps = propertyKeys.reduce((allProperties, key) => {
        let value = properties[sanitisedPropertyName][key];
        // key shoud have the format [questionId]_[option]
        if (value !== null && value !== undefined && key.indexOf("_") > -1) {
            let [questionId, optionId] = key.split("_");

            allProperties[`q${questionId}_${optionId}_${stage}`] = value;
        }

        return allProperties;
    }, {});

    Object.keys(splitProps).forEach((key) => {
        properties[key] = splitProps[key];
    });

    properties = renameProperty(
        properties,
        {},
        {
            [propertyName]: 1,
        }
    );

    return properties;
};

export const caneV2RawToV2Standard = (features) => {
    features = features.map((feature) => {
        try {
            // feature = JSON.parse(JSON.stringify(feature));
            // convert snakecase answers to dot based answers
            // [question]_[options] to [question].[options]

            // TODO Tom - please update to this pull from industry template
            [
                "q1_before",
                "q1_after",
                "q2_before",
                "q2_after",
                "q3_before",
                "q3_after",
                "q4_before",
                "q4_after",
                "q6_before",
                "q6_after",
                "q7_before",
                "q7_after",
                "q8_before",
                "q8_after",
                "q9_before",
                "q9_after",
                "q10_before",
                "q10_after",
                "q11_before",
                "q11_after",
                "q12_before",
                "q12_after",
                "q13_before",
                "q13_after",
                "q14_before",
                "q14_after",
                "q15_before",
                "q15_after",
                "q16_before",
                "q16_after",
                "q17a_before",
                "q17a_after",
                "q18_before",
                "q18_after",
                "q19_before",
                "q19_after",
                "q20_before",
                "q20_after",
            ].forEach((propertyName) => {
                feature.properties = replaceSnakeCaseToDot(feature.properties || {}, propertyName);
            });

            ["q7a_before", "q7a_after", "q7b_before", "q7b_after"].forEach((propertyName) => {
                feature.properties = numericOptionsToFlatQuestions(feature.properties || {}, propertyName);
            });

            return feature;
        } catch (ex) {
            console.log(ex);
            return null;
        }
    });

    return features.filter((feature) => feature !== null && feature !== undefined);
};

export const bananaV2RawToV2Standard = (features) => {
    features = features.map((feature) => {
        try {
            // convert snakecase answers to dot based answers
            // [question]_[options] to [question].[options]
            [
                "q1_before",
                "q1_after",
                "q2_before",
                "q2_after",
                "q3_before",
                "q3_after",
                "q4_before",
                "q4_after",
                "q6_before",
                "q6_after",
                "q7_before",
                "q7_after",
                "q8_before",
                "q8_after",
                "q9_before",
                "q9_after",
                "q10_before",
                "q10_after",
                "q11_before",
                "q11_after",
                "q12_before",
                "q12_after",
                "q13_before",
                "q13_after",
            ].forEach((propertyName) => {
                feature.properties = replaceSnakeCaseToDot(feature.properties || {}, propertyName);
            });

            feature.properties = renameProperty(
                feature.properties || {},
                {
                    q1_before: `q1_before`,
                    q1_after: `q1_after`,
                    q2_before: `q2_before`,
                    q2_after: `q2_after`,
                    q3_before: `q3_before`,
                    q3_after: `q3_after`,
                    q4_before: `q4_before`,
                    q4_after: `q4_after`,
                    q5_before: `q5_before`,
                    q5_after: `q5_after`,
                    q6_before: `q6_before`,
                    q6_after: `q6_after`,
                    q7_before: `q7_before`,
                    q7_after: `q7_after`,
                    q8_before: `q8_before`,
                    q8_after: `q8_after`,
                    q9_before: `q9_before`,
                    q9_after: `q9_after`,
                    q10_before: `q10_before`,
                    q10_after: `q10_after`,
                    q11_before: `q11_before`,
                    q11_after: `q11_after`,
                    q12_before: `q12_before`,
                    q12_after: `q12_after`,
                    q13_before: `q13_before`,
                    q13_after: `q13_after`,

                    project_id: `project_id`,
                    surveyVersion: `surveyVersion`,
                    appVersion: `appVersion`,
                    industry: `industry`,
                },
                {
                    region: 1,
                }
            );

            return feature;
        } catch (ex) {
            return null;
        }
    });

    return features.filter((feature) => feature !== null && feature !== undefined);
};

export const createZip = (descriptor) => {
    let zip = new JSZip();

    let createElement = function (parent, desc) {
        let { name, type, data, options } = desc;
        if (type === "folder") {
            let folder = parent.folder(name);
            (data || []).forEach((element) => {
                createElement(folder, element);
            });
        } else if (type === "file") {
            parent.file(name, data, options);
        }
    };

    descriptor.forEach((desc) => {
        createElement(zip, desc);
    });

    return zip;
};

export const saveZip = (zip, name) => {
    zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, name);
    });
};

export const blocksToGeoJson = (blocks) => {
    /* 
    { 
        type: "Feature",
        id: project_id,
        geometry: { ...block.geometry }
        properties: {...blocks, ...project}
    }

    */
    return blocks.map((block) => {
        const {
            [ID_ATTRIBUTE]: blockId,

            // Properties below need to move to parent level
            project: { [ID_ATTRIBUTE]: projectId, survey = {}, ...restOfProject } = {},
            geometry = {},
            conflictProperties = {},
            ...restOfBlocks
        } = block;

        const properties = {
            block_id: blockId,
            b_id: blockId,
            ...(restOfProject ?? {}),
            ...restOfBlocks,
            ...survey,
            ...conflictProperties,
        };

        const blockAsGeoJson = {
            type: "Feature",
            id: projectId,
            geometry,
            properties: renameProperty(properties, { collectionId: "collection_id", projectId: "project_id" }, {}),
        };

        return blockAsGeoJson;
    });
};

const createFeatureCollection = (collection, blocks) => {
    let features = blocksToGeoJson(blocks);
    let split = splitFeaturesByIndustry(features);

    split = split.map((data) => {
        let { industry } = data;
        let { features } = data;

        if (industry === "cane") {
            features = caneV2RawToV2Standard(features);
        } else if (industry === "banana") {
            features = bananaV2RawToV2Standard(features);
        }

        return {
            industry,
            features,
        };
    });

    return transform(
        split,
        (result, item) => {
            ((item || {}).features || []).forEach((feature) => {
                if (collection.requiresComputationCode) {
                    feature.properties.requiresComputationCode = collection.requiresComputationCode;
                }

                ((result || {}).features || []).push(feature);
            });
        },
        {
            type: "FeatureCollection",
            features: [],
        }
    );
};

export const arrayToCSV = (data) => {
    let csvContent = "";

    data.forEach(function (infoArray, index) {
        let dataString = infoArray.join(",");
        csvContent += index < data.length ? dataString + "\n" : dataString;
    });

    return csvContent;
};

export const detailedResults = (features) => {
    const fProps = (features ?? []).map((feature) => {
        let properties = (feature ?? {}).properties;

        try {
            const industry = properties.industry;

            if (properties.description) {
                properties.description = formatProjectDescriptionForCSV(properties.description);
            }

            // pull off scores
            properties = merge(properties, (properties || {}).scores || {});

            // pull off codes only for cane, banana and grain since grazing does not have any soil / climate codes
            const codes = industry !== INDUSTRY.GRAZING.key ? (properties || {}).codes || [] : [];

            const codeExported = codes.reduce((result, code, index) => {
                result[`code_${index}`] = code.code;
                result[`code_${index}_climate`] = code.climate;
                result[`code_${index}_soil`] = code.soil;
                result[`code_${index}_area`] = code.area;
                result[`code_${index}_soil_ids`] = (code.soil_id || []).join(",");
                result[`code_${index}_climate_ids`] = (code.climate_id || []).join(",");

                return result;
            }, {});

            properties = merge(properties, codeExported);

            // pull off caneRegions in extraResults
            properties = merge(properties, ((properties || {}).extraResults || {}).caneRegions || {});

            // tear apart results
            let detailedRes = transform(
                (properties || {}).results || {},
                function (result, value, key) {
                    // key shoud have the format [questionId]_[option]
                    if (value) {
                        result[`${value.class}_before_PerHa`] = toSignificantDigit(value.before, 3);
                        result[`${value.class}_after_PerHa`] = toSignificantDigit(value.after, 3);
                        result[`${value.class}_reduction_PerHa`] = toSignificantDigit(value.reduction, 3);
                        result[`${value.class}_delivery_ratio`] = toSignificantDigit(value.rsdrRatio || 1, 3);
                        result[`${value.class}_area`] = value.area;
                        result[`${value.class}_unit`] = value.unit;
                    }
                },
                {}
            );

            properties = merge(properties, detailedRes);

            // Use renameProperty to omit keys specified in last parameter
            properties = renameProperty(
                properties || {},
                {},
                {
                    results: 1,
                    codes: 1,
                    scores: 1,
                    extraResults: 1,
                }
            );

            return properties;
        } catch (ex) {
            return null;
        }
    });

    return fProps.filter((properties) => properties !== null && properties !== undefined);
};

export const generateDetailedResults = (features) => {
    let fsProps = detailedResults(features);

    let content = [];
    let headers = [];

    let propsSet = {};

    // build headers
    fsProps.forEach((properties) => {
        for (const prop in properties) {
            if (propsSet[prop] == null) {
                headers.push(prop);
                propsSet[prop] = headers.length - 1;
            }
        }
    });

    content.push(headers);

    fsProps.forEach((properties) => {
        let row = [];

        for (const prop in properties) {
            const propIndex = propsSet[prop];
            row[propIndex] = properties[prop];
        }

        row = row.map((b) => {
            if (typeof b == "string") {
                return `"=""${b}"""`;
            }

            if (!(b instanceof Array) && !(b instanceof Object)) {
                return b;
            }

            return `"=""${JSON.stringify(b).replace(new RegExp('"', "g"), '""')}"""`;
        });

        content.push(row);
    });

    return content;
};

export const generateProjectSummary = (fs) => {
    let seed = null;
    if (fs != null && fs.length > 0) seed = fs[0];

    if (seed == null) return null;

    const ret = {};

    fs.forEach((f) => {
        for (const measure in f.properties.results) {
            if (!ret[measure]) {
                ret[measure] = {
                    before: 0,
                    after: 0,
                    reduction: 0,
                    area: 0,
                    measure: measure,
                };
            }

            // if (f.properties.conflict && f.properties.lookupFails == false)
            //     continue;

            ret[measure].before +=
                Math.round(f.properties.results[measure].before * f.properties.results[measure].area * 100) / 100;

            ret[measure].after +=
                Math.round(f.properties.results[measure].after * f.properties.results[measure].area * 100) / 100;

            ret[measure].area += f.properties.results[measure].area;

            ret[measure].reduction +=
                Math.round(
                    (f.properties.results[measure].before - f.properties.results[measure].after) *
                        f.properties.results[measure].area *
                        100
                ) / 100;

            ret[measure].unit =
                f.properties.results[measure].unit == null ? "unit" : f.properties.results[measure].unit;
            ret[measure].measure = measure;
            ret[measure].class = f.properties.results[measure].class;
        }
    });

    return ret;
};

export const summarise = (features) => {
    let resFeatures = features.filter(
        (d) => d != null && d.properties != null
        // && d.properties.results != null
    );

    let rets = [];
    // Projects by id
    let fGroups = groupBy(resFeatures, (f) => f.id);
    for (let group in fGroups) {
        let seed = null;

        if (fGroups[group] != null && fGroups[group].length > 0) seed = fGroups[group][0];

        if (seed != null) {
            let project = {};
            let conflictFs = fGroups[group].filter((f) => f.properties.conflict == true);

            let lookFailsConflictFs = fGroups[group].filter(
                (f) => f.properties.conflict == true && f.properties.lookupFails == true
            );

            let outsideOfRegionConflictFs = fGroups[group].filter(
                (f) => f.properties.conflict == true && f.properties.outsideOfRegion == true
            );

            let erosionRateProviderErrorConflictsFs = fGroups[group].filter(
                (f) => f.properties.conflict == true && f.properties.erosionRateProviderError == true
            );

            let inCompleteSurveyFs = fGroups[group].filter((f) => f.properties.incompleteSurveyConflict == true);

            let _industry = uniq(fGroups[group].map((f) => f.properties.industry));

            let industry = null;

            if (!_industry || _industry.length > 1) {
                industry = null;
            } else {
                industry = _industry[0];
            }
            project.industry = industry;

            let surveyVersion = null;

            let _surveyVersion = uniq(
                fGroups[group].map(
                    (f) =>
                        f.properties.surveyVersion ||
                        (f.properties.industry === INDUSTRY.CANE.key
                            ? CANE_SURVEY_VERSION.VERSION_3_0
                            : f.properties.industry === INDUSTRY.BANANA.key
                            ? BANANA_SURVEY_VERSION.VERSION_3_0
                            : f.properties.industry === INDUSTRY.GRAIN.key
                            ? GRAIN_SURVEY_VERSION.VERSION_1_0
                            : f.properties.industry === INDUSTRY.GRAZING.key
                            ? GRAZING_SURVEY_VERSION.VERSION_1_0
                            : null)
                )
            );

            if (!_surveyVersion || _surveyVersion.length > 1) {
                surveyVersion = null;
            } else {
                surveyVersion = _surveyVersion[0];
            }

            project.surveyVersion = surveyVersion;

            let allConflict = conflictFs.length == fGroups[group].length ? true : false;
            let hasConflict = conflictFs.length == 0 ? false : true;
            let incompleteSurveyConflict = inCompleteSurveyFs.length == 0 ? false : true;
            let hasLookupFails = lookFailsConflictFs.length == 0 ? false : true;
            let outsideOfRegion = outsideOfRegionConflictFs.length == 0 ? false : true;
            let erosionRateProviderError = erosionRateProviderErrorConflictsFs.length == 0 ? false : true;

            project.id = seed.id;
            project.project_title = seed.properties.project_title;
            project.description = seed.properties.description;
            project.seedProperties = seed.properties;
            project.properties = fGroups[group].map((f) => f.properties);

            project.conflict = allConflict;
            project.hasConflict = hasConflict;
            project.incompleteSurveyConflict = incompleteSurveyConflict;
            project.hasLookupFails = hasLookupFails;
            project.outsideOfRegion = outsideOfRegion;
            project.erosionRateProviderError = erosionRateProviderError;

            project.unknownSurveyConflict =
                (project.industry !== INDUSTRY.CANE &&
                    project.industry !== INDUSTRY.BANANA &&
                    project.industry !== INDUSTRY.GRAIN &&
                    project.industry !== INDUSTRY.GRAZING) ||
                (project.surveyVersion !== CANE_SURVEY_VERSION.VERSION_1 &&
                    project.surveyVersion !== CANE_SURVEY_VERSION.VERSION_2 &&
                    project.surveyVersion !== CANE_SURVEY_VERSION.VERSION_3_0 &&
                    project.surveyVersion !== BANANA_SURVEY_VERSION.VERSION_2 &&
                    project.surveyVersion !== BANANA_SURVEY_VERSION.VERSION_3_0 &&
                    project.surveyVersion !== GRAIN_SURVEY_VERSION.VERSION_1_0 &&
                    project.surveyVersion !== GRAZING_SURVEY_VERSION.VERSION_1_0);

            project.area = sum((fGroups[group] || []).map((feature) => ((feature || {}).properties || {}).area || 0));

            project.results = generateProjectSummary(fGroups[group]);
            rets.push(project);
        }
    }

    rets = sortBy(rets, (project) => project.properties.project_title);
    return rets;
};

export const getMeasures = (features, displayClasses) => {
    const resFeatures = features.filter((d) => d != null && d.properties != null && d.properties.results != null);

    const sumResults = {};

    const classMeasureMap = {};

    resFeatures.forEach((d) => {
        for (const measure in d.properties.results) {
            if (!sumResults[measure]) {
                sumResults[measure] = {
                    before: 0,
                    after: 0,
                    reduction: 0,
                    area: 0,
                    measure: measure,
                    // budget: 0
                };
            }

            sumResults[measure].before += d.properties.results[measure].before;
            sumResults[measure].after += d.properties.results[measure].after;
            sumResults[measure].area += d.properties.results[measure].area;
            sumResults[measure].reduction += d.properties.results[measure].reduction;
            sumResults[measure].unit =
                d.properties.results[measure].unit == null ? "unit" : d.properties.results[measure].unit;

            // sumResults[measure].budget +=
            //     d.properties.budget == null
            //         ? 0
            //         : parseFloat(d.properties.budget.toString());

            sumResults[measure].measure = measure;
            sumResults[measure].class = d.properties.results[measure].class;
            classMeasureMap[sumResults[measure].class] = sumResults[measure];
        }
    });

    const rets = [];

    displayClasses.forEach((_class) => {
        if (classMeasureMap[_class] != null) rets.push(classMeasureMap[_class]);
    });

    return rets;
};

export const generateSummary = (features, type) => {
    let content = [];
    let displayClasses = ["total_din", "soil", "pest"];

    let projects = summarise(features);
    let measures = getMeasures(features, displayClasses);

    let headers = [];

    if (type === "project")
        headers = [
            "Project Id",
            "Project title",
            "Description",
            // "Budget",
            "Area (Ha)",
        ];
    else if (type === "block")
        headers = [
            "Project Id",
            "Project title",
            "Description",
            // "Budget",
            "Area (Ha)",
            "Block id",
        ];

    content.push(headers);
    measures.forEach((measure) => {
        headers.push(measure.measure + " saved " + measure.unit + "s");
    });

    headers.push("Issues");

    projects.forEach((project) => {
        if (type === "project") {
            let row = [];
            content.push(row);

            row.push(project.id);
            row.push(project.project_title);
            row.push(`"${formatProjectDescriptionForCSV(project.description)}"`);
            // row.push(project.budget);
            row.push(project.area);

            measures.forEach((measure) => {
                if (
                    project != null &&
                    (project.hasConflict == false || (project.hasConflict == true && project.hasLookupFails == true)) &&
                    project.results != null &&
                    project.results[measure.measure] != null
                ) {
                    row.push(toSignificantDigit(project.results[measure.measure].reduction, 3));
                } else row.push("");
            });

            if (project != null && project.hasConflict == true) row.push("Yes");
            else row.push("No");
        }

        if (project != null && project.properties != null && type === "block") {
            project.properties.forEach((paddock) => {
                let r = [];
                content.push(r);

                r.push(project.id);
                r.push(project.project_title);
                r.push(`"${formatProjectDescriptionForCSV(project.description)}"`);
                r.push(paddock.area);
                r.push(paddock.block_id);

                measures.forEach((measure) => {
                    if (
                        paddock != null &&
                        (paddock.conflict == false ||
                            (paddock.conflict == true &&
                                paddock.lookupFails == true &&
                                paddock.lookupFailureImpactRatio < 1.0)) &&
                        paddock.results != null &&
                        paddock.results[measure.measure] != null
                    ) {
                        r.push(
                            toSignificantDigit(
                                paddock.results[measure.measure].reduction * paddock.results[measure.measure].area,
                                3
                            )
                        );
                    } else {
                        r.push("");
                    }
                });

                const issues = [];
                if (paddock != null && paddock.conflict == false) issues.push("None");

                if (paddock != null && paddock.conflict == true && paddock.outsideOfRegion == true)
                    issues.push("Outside of region");

                if (paddock && paddock.conflict == true && paddock.erosionRateProviderError == true) {
                    issues.push("Erosion Rate Provider Error");
                }

                if (paddock != null && paddock.conflict == true && paddock.incompleteSurveyConflict == true)
                    issues.push("Survey incomplete");

                if (paddock != null && paddock.conflict == true && paddock.lookupFails == true)
                    issues.push(
                        "Climate & Soil combination not modelled for " +
                            (paddock.lookupFailureImpactRatio >= 1
                                ? 100
                                : round(paddock.lookupFailureImpactRatio * 100, 2)) +
                            "% of the area"
                    );

                if (paddock != null && paddock.conflict == true && paddock.paddockCodesFailsToResolve == true)
                    issues.push("Practice change not modelled");

                if (
                    paddock != null &&
                    paddock.conflict == true &&
                    paddock.outsideOfRegion == false &&
                    paddock.erosionRateProviderError == false &&
                    paddock.incompleteSurveyConflict == false &&
                    paddock.lookupFails == false &&
                    paddock.paddockCodesFailsToResolve == false
                )
                    issues.push("Unspecified");

                r.push(issues.join(" and "));
            });
        }
    });

    return content;
};

const getWorkspace = () => {
    const state = store.getState();
    const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

    // Get workspace with selectedCollection
    const workspace = session[ORM_WORKSPACE_SLICE].select(session, {
        include: ["selectedCollection"],
        filter: {},
    })[0];

    return workspace;
};

const getAllAndSelectedBlocks = (selectedProjectIds) => {
    const state = store.getState();
    const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

    // Grab all blocks
    const allBlocks = session[ORM_BLOCK_SLICE].select(session, {
        include: ["project"],
        filterDbOpts: ORM_VERSION_CONTROL_FIELDS,
    });

    const selectedBlocks = allBlocks.filter((block) => selectedProjectIds[block.projectId]);

    return {
        allBlocks,
        selectedBlocks,
    };
};

export const downloadGeoJson = async () => {
    const { selectedProjectIds, selectedCollection } = getWorkspace();

    const title = selectedCollection.title;

    const { allBlocks, selectedBlocks } = getAllAndSelectedBlocks(selectedProjectIds);
    const prefixFileName = `${title}_spatial_data`;

    const v2SCollectionData = createFeatureCollection(selectedCollection, allBlocks);
    const v2SSelectedBlocks = createFeatureCollection(selectedCollection, selectedBlocks);

    const zipMap = transform(
        [
            {
                type: "all",
                data: v2SCollectionData,
            },
            {
                type: "selected",
                data: v2SSelectedBlocks,
            },
        ].map(({ type, data: fCol }) => {
            // [{industry, features: []}]
            return {
                type,
                data: splitFeaturesByIndustry(fCol.features),
            };
        }),
        (result, item) => {
            let { type = "all", data = null } = item || {};

            if (data) {
                data.forEach(({ industry, features }) => {
                    if (!result[industry]) {
                        result[industry] = {
                            type: "folder",
                            name: industry,
                            data: [],
                        };
                    }

                    // Move project object to feature
                    const featureReconfig = features.map((feature) => {
                        const { project = {}, ...rest } = feature;

                        return {
                            ...rest,
                            ...project,
                        };
                    });

                    let industryRes = result[industry];
                    industryRes.data.push({
                        type: "file",
                        name: type === "all" ? `${industry}_${title}.geojson` : `${industry}_${title}_${type}.geojson`,
                        data: new Blob(
                            [
                                JSON.stringify({
                                    type: "FeatureCollection",
                                    features: featureReconfig,
                                }),
                            ],
                            {
                                type: "text/json",
                            }
                        ),
                    });
                });
            }
        },
        {}
    );

    const zipData = Object.values(zipMap || {});

    zipData.push({
        type: "file",
        name: `${prefixFileName}.geojson`,
        data: new Blob([JSON.stringify(v2SCollectionData)], {
            type: "text/json",
        }),
    });
    zipData.push({
        type: "file",
        name: `${prefixFileName}_selected.geojson`,
        data: new Blob([JSON.stringify(v2SSelectedBlocks)], {
            type: "text/json",
        }),
    });

    const zip = createZip(zipData);

    saveZip(zip, `${title}_combined.zip`);
};

export const downloadDetailedResults = () => {
    const { selectedProjectIds, selectedCollection } = getWorkspace();

    const title = selectedCollection.title;

    const { allBlocks, selectedBlocks } = getAllAndSelectedBlocks(selectedProjectIds);
    const prefixFileName = `${title}_detailed_results`;

    const v2SCollectionData = createFeatureCollection(selectedCollection, allBlocks);
    const v2SSelectedBlocks = createFeatureCollection(selectedCollection, selectedBlocks);

    const zipMap = transform(
        [
            {
                type: "all",
                data: v2SCollectionData,
            },
            {
                type: "selected",
                data: v2SSelectedBlocks,
            },
        ].map(({ type, data: fCol }) => {
            // [{industry, features: []}]
            return {
                type,
                data: splitFeaturesByIndustry(fCol.features),
            };
        }),
        (result, item) => {
            const { type = "all", data = null } = item || {};

            if (data) {
                data.forEach(({ industry, features }) => {
                    if (!result[industry]) {
                        result[industry] = {
                            type: "folder",
                            name: industry,
                            data: [],
                        };
                    }

                    let industryRes = result[industry];

                    let report = generateDetailedResults(features);
                    industryRes.data.push({
                        type: "file",
                        name: type === "all" ? `${industry}_${title}.csv` : `${industry}_${title}_${type}.csv`,
                        data: new Blob([arrayToCSV(report)], {
                            type: "text/csv",
                        }),
                    });
                });
            }
        },
        {}
    );

    const zipData = Object.values(zipMap || {});

    zipData.push({
        type: "file",
        name: `${title}.csv`,
        data: new Blob([arrayToCSV(generateDetailedResults((v2SCollectionData || {}).features || []))], {
            type: "text/csv",
        }),
    });
    zipData.push({
        type: "file",
        name: `${title}_selected.csv`,
        data: new Blob([arrayToCSV(generateDetailedResults((v2SSelectedBlocks || {}).features || []))], {
            type: "text/csv",
        }),
    });

    const zip = createZip(zipData);

    saveZip(zip, `${prefixFileName}_combined.zip`);
};

export const downloadSummary = (summaryReportType) => {
    const { selectedProjectIds, selectedCollection } = getWorkspace();

    const title = selectedCollection.title;

    const { allBlocks, selectedBlocks } = getAllAndSelectedBlocks(selectedProjectIds);
    const prefixFileName = `${title}_${summaryReportType}_summary`;

    const v2SCollectionData = createFeatureCollection(selectedCollection, allBlocks);
    const v2SSelectedBlocks = createFeatureCollection(selectedCollection, selectedBlocks);

    let zipMap = transform(
        [
            {
                type: "all",
                data: v2SCollectionData,
            },
            {
                type: "selected",
                data: v2SSelectedBlocks,
            },
        ].map(({ type, data: fCol }) => {
            // [{industry, features: []}]
            return {
                type,
                data: splitFeaturesByIndustry(fCol.features),
            };
        }),
        (result, item) => {
            const { type = "all", data = null } = item || {};

            if (data) {
                data.forEach(({ industry, features }) => {
                    if (!result[industry]) {
                        result[industry] = {
                            type: "folder",
                            name: industry,
                            data: [],
                        };
                    }

                    let industryRes = result[industry];

                    let report = generateSummary(features, summaryReportType);
                    industryRes.data.push({
                        type: "file",
                        name: type === "all" ? `${industry}_${title}.csv` : `${industry}_${title}_${type}.csv`,
                        data: new Blob([arrayToCSV(report)], {
                            type: "text/plain",
                        }),
                    });
                });
            }
        },
        {}
    );

    const zipData = Object.values(zipMap || {});

    zipData.push({
        type: "file",
        name: `${title}.csv`,
        data: new Blob([arrayToCSV(generateSummary((v2SCollectionData || {}).features || [], summaryReportType))], {
            type: "text/plain",
        }),
    });
    zipData.push({
        type: "file",
        name: `${title}_selected.csv`,
        data: new Blob([arrayToCSV(generateSummary((v2SSelectedBlocks || {}).features || [], summaryReportType))], {
            type: "text/plain",
        }),
    });

    const zip = createZip(zipData);

    saveZip(zip, `${prefixFileName}_combined.zip`);
};
