import React, { useState } from 'react';
import CanPerformAction from '../CanPerformAction';
import ChangePassword from '../../scenes/ChangePassword';
import { FundingSource } from '../../scenes/FundingSource';
import { Groups } from '../../scenes/Groups';
import { CAN_ACCESS_FUNDING, CAN_ACCESS_RESET_PASSWORD, CAN_ACCESS_GROUPS } from "../../utils/permission";
import { MDBTabPane, MDBTabContent, MDBNav, MDBNavItem } from 'mdbreact';

const tabs = {
    Password: {
        id: "1",
        label: "Change password",
        Scene: ChangePassword,
        PermissionAction: CAN_ACCESS_RESET_PASSWORD

    },
    Groups: {
        id: "2",
        label: "Your groups",
        Scene: Groups,
        PermissionAction: CAN_ACCESS_GROUPS
    },
    Metadata: {
        id: "3",
        label: "Manage metadata",
        Scene: FundingSource,
        PermissionAction: CAN_ACCESS_FUNDING
    }
};

const ManageAccount = () => {
    const [activeTab, setActiveTab] = useState(tabs.Password.id);
    
    const handleTabToggle = (tabId) => ($event) => {
        setActiveTab(tabId);
    };

    const navItems = Object.keys(tabs).map((key) => {
        const tab = tabs[key];
        const activeClassName = activeTab === tab.id ? 'active': '';

        return (
            <CanPerformAction key={tab.id} action={tab.PermissionAction}>
                <MDBNavItem
                    className={`${activeClassName} manage-account-tab col`}
                    onClick={handleTabToggle(tab.id)}
                    role="tab"
                >
                    { tab.label }
                </MDBNavItem>
            </CanPerformAction>
        );
    });

    const tabPanes = Object.keys(tabs).map((key) => {
        const tab = tabs[key];

        return (
            <MDBTabPane
                key={`${tab.id}-tab`}
                className="tab-panel"
                tabId={tab.id}
                role="tabpanel"
            >
                <tab.Scene />
            </MDBTabPane>
        );
    });

    return (
        <>
            <MDBNav tabs className="nav-justified">
                { navItems }
            </MDBNav>
            <MDBTabContent className="card" activeItem={activeTab}>
                { tabPanes }
            </MDBTabContent>
        </>
    );
};

export default React.memo(ManageAccount);