import React, { useEffect, useRef } from "react";
import { Polygon, Polyline, CircleMarker, Marker } from "react-leaflet";
import styles from "./LeafletMap2.module.scss";
import BaseORMModel from "../../models/BaseORMModel";
import { ID_ATTRIBUTE } from "../../utils/constants";
import _ from "lodash";
import { GEOMETRY_LINE_STRING_TYPE, GEOMETRY_POINT, GEOMETRY_POLYGON_TYPE } from "./mapConstants";

const _BlockPolygon = ({
    block,
    onClick = () => {},
    isSelected = false,
    isHighlighted = false,
    draggable = false,
    isHovered = false,
    isExtentStatusSet = false,
    onMouseOut = () => {},
    onMouseOver = () => {},
    onMouseMove = () => {},
    geometryType = "Polygon",
}) => {
    const blockRef = useRef(null);
    const isError = ((block ?? {}).conflictProperties ?? {}).conflict ?? false;

    useEffect(() => {
        if (!blockRef.current) {
            console.log("the polygon should be available");
            return;
        }

        const ref = blockRef.current;
        if (ref && ref.leafletElement && ref.leafletElement._path && ref.leafletElement._path.classList) {
            const path = ref.leafletElement._path;

            // looks stupid in here when double adding the classes, but the leaflet does not update class names
            // when new props are provided
            if (isSelected) {
                path.classList.add("selected");
            } else {
                path.classList.remove("selected");
            }

            if (isHovered) {
                path.classList.add("hovered");
            } else {
                path.classList.remove("hovered");
            }

            if (isHighlighted) {
                path.classList.add("highlighted");
            } else {
                path.classList.remove("highlighted");
            }

            if (isError) {
                path.classList.add("error");
            } else {
                path.classList.remove("error");
            }

            if (isExtentStatusSet) {
                path.classList.add("extent-enabled");
            } else {
                path.classList.remove("extent-enabled");
            }
        }
    });

    const getGeometryCompoment = (geometryType) => {
        switch (geometryType) {
            case GEOMETRY_POLYGON_TYPE:
                return Polygon;
            case GEOMETRY_LINE_STRING_TYPE:
                return Polyline;
            case GEOMETRY_POINT:
                return CircleMarker;
        }
    };

    if (!block) {
        return null;
    }

    const { id, geometry, ...rest } = block;

    const coordinates = geometry.coordinates;

    const Geometry = getGeometryCompoment(geometryType);

    return (
        <Geometry
            onAdd={() => {}}
            onRemove={() => {
                if (isSelected) {
                    onMouseOut && onMouseOut(null, block);
                }
            }}
            ref={blockRef}
            draggable={draggable}
            onClick={(e) => {
                onClick && onClick(e, block);
            }}
            onMouseOver={(e) => {
                onMouseOver && onMouseOver(e, block);
            }}
            onMouseMove={(e) => {
                onMouseOver && onMouseMove(e, block);
            }}
            onMouseOut={(e) => {
                onMouseOut && onMouseOut(e, block);
            }}
            id={block[ID_ATTRIBUTE]}
            metadata={{ id, ...(rest ?? {}) }}
            className={`block 
            ${isSelected ? "selected" : ""} 
            ${isHovered ? "hovered" : ""} 
            ${isHighlighted ? "highlighted" : ""} 
            ${isExtentStatusSet ? "extent-enabled" : ""} 
            ${isError ? "error" : ""}
            ${geometryType === GEOMETRY_LINE_STRING_TYPE ? "line-string" : ""}
        `}
            positions={coordinates}
            position={coordinates}
            center={coordinates}
            radius={10}
        />
    );
};

export const BlockPolygon = React.memo(
    _BlockPolygon,
    (
        {
            block: prevBlock,
            isSelected: prevIsSelected,
            isHovered: prevIsHovered,
            draggable: prevDraggable,
            isHighlighted: prevIsHighlighted,
            isExtentStatusSet: prevIsExtentStatusSet,
        },
        {
            block: nextBlock,
            isSelected: nextIsSelected,
            isHovered: nextIsHovered,
            draggable: nextDraggable,
            isHighlighted: nextIsHighlighted,
            isExtentStatusSet: nextIsExtentStatusSet,
        }
    ) => {
        return (
            BaseORMModel.getVersionId(prevBlock ?? {}) === BaseORMModel.getVersionId(nextBlock ?? {}) &&
            prevIsSelected === nextIsSelected &&
            prevIsHovered === nextIsHovered &&
            prevDraggable === nextDraggable &&
            prevIsHighlighted === nextIsHighlighted &&
            prevIsExtentStatusSet === nextIsExtentStatusSet
        );
    }
);
