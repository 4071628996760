import React from "react";
import "./about.scss";
import AUSTRALIAN_GOVERNMENT from "../../styles/images/gov-logo-au-blk.png";
import QUEENSLAND_GOVERNMENT from "../../styles/images/gov-logo-qld-blk.png";

const About = () => {
    return (
        <div className="container">
            <h1>About the Paddock to Reef Project Selector (P2R Projector)</h1>
            <h2>Background</h2>
            <p>
                The P2R Projector is a prioritisation tool created to support groups who are working with farmers to
                improve their water quality. It assists with the process of assessing and prioritising projects for
                funding. The projects propose changes in agricultural management practices for key industries in the
                Great Barrier Reef region.
            </p>
            <h2>Where does it apply?</h2>
            <p>
                The P2R Projector is only for the Great Barrier Reef region. It only applies for cane, banana, grain and
                grazing industries.
            </p>
            <h2>How does it work?</h2>
            <p>
                The P2R Projector is driven by the modelling approach developed by the Queensland Government Office of
                the Great Barrier Reef’s
                <a
                    href="https://www.reefplan.qld.gov.au/measuring-success/paddock-to-reef/management-practices/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Paddock to Reef modelling program
                </a>
                .
            </p>
            <p>
                For each project created in the P2R Projector, the resulting sediment, nutrient and pesticide (for some
                industries) loads are estimated for both the current conditions and the conditions following the
                implementation of proposed change management practices. Estimates are based on the underlying soil type,
                climatic conditions and the management practices before and after the project (see the{" "}
                <a
                    href="https://www.reefplan.qld.gov.au/__data/assets/pdf_file/0028/82918/report-card-2017-2018-methods-agricultural-mpa.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Great Barrier Reef Report Card - Management Practice Methods
                </a>
                .
            </p>
            <h2>Why don’t the estimates match the annual Reef Water Quality Report Card?</h2>
            <p>
                The estimates generated by the P2R Projector will not match those generated by the{" "}
                <a href="https://reportcard.reefplan.qld.gov.au/" rel="noopener noreferrer" target="_blank">
                    annual Reef Water Quality Report Card
                </a>{" "}
                process because the P2R Projector takes a simpler modelling approach.
            </p>
            <p>
                The P2R Projector provides an estimate of on-farm generation rates, but does not route those changes to
                a delivery on the reef. The tool allows users to assess the relative benefit of different projects. The
                P2R Projector does not attempt to replicate the Reef Water Quality Report Card reporting process.
                However, because it uses the same data and similar approaches, those projects which perform well in the
                Projector are also likely to contribute to the protection of the Great Barrier Reef well in the annual
                reporting process.
            </p>
            <h2>How do I use it?</h2>
            <p>Use the links below to view video tutorials on YouTube:</p>
            <ul>
                <li>
                    <a href="https://youtu.be/7swSVY2CqSQ" rel="noopener noreferrer" target="_blank">
                        Create a new collection of projects
                    </a>
                </li>
                <li>
                    <a href="https://youtu.be/dZNdLjP3sOE" rel="noopener noreferrer" target="_blank">
                        Import and export existing project collections
                    </a>
                </li>
                <li>
                    <a href="https://youtu.be/xLdgEEmlSTA" rel="noopener noreferrer" target="_blank">
                        Add a new project
                    </a>
                </li>
                <li>
                    <a href="https://youtu.be/aWPDGOQ4njc" rel="noopener noreferrer" target="_blank">
                        Edit a project
                    </a>
                </li>
                <li>
                    <a href="https://youtu.be/lqEax9ogfeI" rel="noopener noreferrer" target="_blank">
                        Delete a project
                    </a>
                </li>
                <li>
                    <a href="https://youtu.be/thnR8iuSSxM" rel="noopener noreferrer" target="_blank">
                        Add another block to a selected project
                    </a>
                </li>
                <li>
                    <a href="https://youtu.be/3J0WZHCxZoA" rel="noopener noreferrer" target="_blank">
                        Compare multiple projects
                    </a>
                </li>
                <li>
                    <a href="https://youtu.be/6dw_c9fkOs8" rel="noopener noreferrer" target="_blank">
                        Manage your group (for administrators)
                    </a>
                </li>
            </ul>
            <h2>Support</h2>
            <p>
                The tool has been built by Truii and they will continue to support it. If you find any bugs, odd
                behaviour, or have any feedback at all about the tool please email them directly ({" "}
                <a href="mailto:support@truii.com">support@truii.com</a> ) and CC Adam Northey ({" "}
                <a href="mailto:adam.northey@daf.qld.gov.au">adam.northey@daf.qld.gov.au</a> ).
            </p>
            <h2>Who created the Projector?</h2>
            <p>
                <strong>Funding:</strong> Queensland Government Office of the Great Barrier Reef.
            </p>
            <p>
                <strong>Modelling approach:</strong> Queensland Department of Natural Resources, Mines and Energy.
            </p>
            <p>
                <strong>Conceptual design and project management:</strong> Queensland Department of Agriculture and
                Fisheries
            </p>
            <p>
                <strong>Application development:</strong>{" "}
                <a href="https://truii.com" rel="noopener noreferrer" target="_blank">
                    Truii
                </a>
            </p>
            <div className="row logos d-flex">
                <div className="col-sm">
                    <a href="https://www.australia.gov.au/">
                        <img alt="Australian Government" src={AUSTRALIAN_GOVERNMENT} />
                    </a>
                </div>
                <div className="col-sm">
                    <a href="https://www.qld.gov.au/">
                        <img alt="Queensland Government" src={QUEENSLAND_GOVERNMENT} />
                    </a>
                </div>
            </div>
            <h2>For more information, contact</h2>
            <p>
                <strong>Paddock to Reef (OGBR)</strong>
            </p>
            <p>
                <strong>Email: </strong>p2r@nrmrq.org.au
            </p>
            <p>
                <strong>Phone: </strong>(07) 3330 6109
            </p>
        </div>
    );
};

export default React.memo(About);
