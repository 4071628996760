import React from "react";
import * as yup from "yup";
import { useFormWizard } from "./FormWizardContext";
import { BasePageGroup } from "./BasePageGroup";
import { SURVEY_QUESTION_TYPES, SURVEY_ANSWER_BEFORE_AFTER, PAGE_STATUSES, ID_ATTRIBUTE } from "../../utils/constants";
import SurveyQuestionSet from "../Survey/SurveyQuestionSet";
import FormStatusController from "./FormStatusController";
import BaseORMModel from "../../models/BaseORMModel";
import PropTypes from "prop-types";
import { keyValueStringContent } from "../../utils/functions";

const yStr = yup.string();
const yNum = yup.number();

const ySetOfCreator = (setOfValidValues, required) => {
    let ySetOf = yup.mixed().oneOf(setOfValidValues ?? []);

    if (required) ySetOf = ySetOf.required();

    return ySetOf;
};

const yNumWithRangeCreator = (minValue, maxValue, required) => {
    let yNumWithRange = yNum;

    if (minValue !== "inf") yNumWithRange = yNumWithRange.min(minValue);

    if (maxValue !== "inf") yNumWithRange = yNumWithRange.max(maxValue);

    if (required) yNumWithRange = yNumWithRange.required();

    return yNumWithRange;
};

const {
    BEFORE: { label: beforeLabel, key: beforeKey },
    AFTER: { label: afterLabel, key: afterKey },
} = SURVEY_ANSWER_BEFORE_AFTER;
const { MULTIPLECHOICE, NUMERIC } = SURVEY_QUESTION_TYPES;

/**
 * A utility function that performs a union of two sets.
   @param {Set} setA - First set of numbers
   @param {Set} setB - Second set of numbers
  
   @returns {Set} setA ∪ setB
*/
function union(setA, setB) {
    let _union = new Set(setA);
    for (let elem of setB) {
        _union.add(elem);
    }
    return _union;
}

class SurveyBaseGroup extends BasePageGroup {
    constructor(props) {
        super(props);
        this.nextVisibleQuestionArray = [];
    }

    /**
     * A utility function that determines whether to display a question set based on dependency array and given question set.
       @param {Array<{class: String, description: String, questions: Array<Question>} } questionSet - Array containing information of the question
       @param {Array<Question>{{description: String, id: String, responses: Array, type: "mc | numeric", visible: Boolean}}} 
      
       @returns boolean
    */
    decideToDisplayQuestionSet = (questionSet) => {
        const { questions } = questionSet;
        const { survey = {} } = this.props.project;
        // array to test displaying a question set (cane - nut class);

        const dependency = this.props.dependencyForQuestions ?? {};

        // const questionIdsWithDependency = Object.keys(testDependencyforQuestion);
        const questionIdsWithDependency = Object.keys(dependency);

        // return true / false if all questions id are keys of the dependency array...
        const questionSetDependOnOtherQuestion = questions.every((question) =>
            questionIdsWithDependency.includes(question.id)
        );
        // All questions of a set is in the dependency array
        if (questionSetDependOnOtherQuestion) {
            let requiredAnswerSet = new Set();

            // Create a set of all required answers for the question set
            questions.forEach((question) => {
                const answerSetForQuestion = new Set(dependency[question.id]);
                requiredAnswerSet = union(requiredAnswerSet, answerSetForQuestion);
            });

            const displayQuestionSet = Array.from(requiredAnswerSet).some((requiredAnswer) => {
                const requiredQuestion = `q${requiredAnswer.split("_")[0]}`;
                // initialise just incase the question hasn't been answered
                return (
                    survey[`${requiredQuestion}_${beforeKey}`] === requiredAnswer ||
                    survey[`${requiredQuestion}_${afterKey}`] === requiredAnswer ||
                    this.props.industryCanShowQuestions(
                        this.props.project.industry,
                        survey,
                        requiredQuestion,
                        requiredAnswer
                    )
                );
            });

            return displayQuestionSet;
        }

        // At least one question in the set was not apart of the dependency array
        return !questionSetDependOnOtherQuestion;
    };

    /** 
        Utility function sets the page group map of the formwizard from formwizard's React.Context. It will
        also update the nextVisibleQuestionArray to aid the Helper with the next text.
        @param {}
        
        @returns {void} 
    */
    updatePageGroupMap() {
        const questionSetPageGroupMap = {};
        const questionsToReset = [];

        this.nextVisibleQuestionArray = [];

        this.props.questionSets.forEach((questionSet, index) => {
            if (this.decideToDisplayQuestionSet(questionSet)) {
                questionSetPageGroupMap[`${questionSet.class}_question_set`] = {
                    index,
                };

                // Update nextVisibleQuestion to be used in the helper component
                this.nextVisibleQuestionArray.push(index);
            } else {
                questionsToReset.push(...questionSet.questions);
            }
        });

        this.props.registerPageGroupMap && this.props.registerPageGroupMap(this.props.groupId, questionSetPageGroupMap);

        if (questionsToReset.length > 0 && this.props.resetQuestions) {
            this.props.resetQuestions(questionsToReset);
        }
    }

    /** 
        Utility function that dynamically creates the Yup schema (https://github.com/jquense/yup) a questionset. 
        The schema is used for validation purposes.
       
        @param {Array<QuestionSet>}
        
        @returns {YupSchema} 
    */
    generateSurveySchema(questionSet = []) {
        const fields = {};

        /** 
            Utility function that dynamically creates a yup field that is either required or not required. 
        
            @param {question} - Object with question data
            @property {question.type} - "mc" || "numeric"
            @property {question.responses Array<response>} - Array of response objects
            
            @typedef {response} - Object with response data
            @property {response.description} - response 
            @property {response._id} -
            @property {response.weight} - number used for computation 
            
            @returns {YupSchema} 
        */
        const declareYup = (question, required = false, before = true, after = true) => {
            const { type, responses } = question;
            const responseIds = responses.map((response) => response.id);
            const beforeQName = `q${question.id}_${beforeKey}`;
            const afterQName = `q${question.id}_${afterKey}`;

            const fieldNames = [];

            if (before) fieldNames.push(beforeQName);
            if (after) fieldNames.push(afterQName);

            fieldNames.forEach((fieldName) => {
                if (type === MULTIPLECHOICE) {
                    fields[fieldName] = ySetOfCreator(responseIds, required);
                } else if (question.type === NUMERIC) {
                    const {
                        range: { min, max },
                    } = question.meta ?? {};

                    fields[fieldName] = yNumWithRangeCreator(min, max, required);

                    if (responses.length) {
                        const responseSchema = {};
                        responses.forEach((response) => {
                            responseSchema[response.id] = yNumWithRangeCreator(min, max, required);
                        });

                        const yResponseSchema = yup.object(responseSchema);
                        fields[fieldName] = required ? yResponseSchema.required() : yResponseSchema;
                    }
                }
            });
        };

        // Loop through depedency list for each question
        // of before and after and decide the visibility if one of answers of the question are given
        questionSet
            .filter((d) => d)
            .forEach((question) => {
                const { id: questionId } = question;

                // if question is independent, then always add to schema (validation is required)
                if (!(questionId in this.props.dependencyForQuestions)) {
                    declareYup(question, true, true, true);
                    return;
                }

                Object.values(SURVEY_ANSWER_BEFORE_AFTER).forEach(({ key: beforeOrAfter }) => {
                    // Leverage some's early break when one of answers are given
                    this.props.dependencyForQuestions[questionId].some((requiredAnswer) => {
                        const questionId = requiredAnswer.split("_")[0];
                        const qIdBefOrAftSurveyField = `q${questionId}_${beforeOrAfter}`;

                        // initialise just incase the question hasn't been answered
                        const answer = this.props.project.survey[qIdBefOrAftSurveyField] ?? { [`q${questionId}`]: {} };

                        const hasBeenAnswered =
                            answer === requiredAnswer ||
                            this.props.industryCanShowQuestions(
                                this.props.project.industry,
                                this.props.project.survey,
                                questionId,
                                requiredAnswer
                            );

                        if (hasBeenAnswered) {
                            declareYup(
                                question,
                                true,
                                beforeOrAfter === SURVEY_ANSWER_BEFORE_AFTER.BEFORE.key,
                                beforeOrAfter === SURVEY_ANSWER_BEFORE_AFTER.AFTER.key
                            );
                        }

                        return hasBeenAnswered;
                    });
                });
            });

        // For optional questions
        questionSet
            .filter((d) => false)
            .forEach((question) => {
                declareYup(question, false);
            });
        return yup.object().shape(fields);
    }

    componentDidMount() {
        this.updatePageGroupMap();
    }

    // Only update page group if the currentStep changes from FormWizard or project.survey updates
    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.currentStep.pageGroup !== this.props.currentStep.pageGroup ||
            nextProps.currentStep.index !== this.props.currentStep.index ||
            keyValueStringContent(nextProps.project.survey) !== keyValueStringContent(this.props.project.survey)
        );
    }

    // Re-register page groups if the project orm slice update it's version
    componentDidUpdate(prevProps) {
        const prevProjectOrmVersion = BaseORMModel.getVersionId(prevProps.project);
        const currentProjectORMVersion = BaseORMModel.getVersionId(this.props.project);

        if (prevProjectOrmVersion !== currentProjectORMVersion) {
            this.updatePageGroupMap();
        }
    }

    render() {
        const { pageGroup, index } = this.props.currentStep;

        return this.props.questionSets.map((questionSet, childIndex) => {
            const { class: pageIdPrefix } = questionSet;
            const schema = this.generateSurveySchema(questionSet.questions);

            // Constants for nextText
            const currentQsIdx = this.nextVisibleQuestionArray.findIndex((nextQIdx) => nextQIdx === childIndex);
            const nextPossibleQsIdx = this.nextVisibleQuestionArray[currentQsIdx + 1] ?? -1;
            const nextText = (this.props.questionSets[nextPossibleQsIdx] ?? {}).description ?? undefined;
            const nextTextFormatted = nextText ? nextText.charAt(0).toUpperCase() + nextText.slice(1) : undefined;

            const statusControllerProps = {
                groupId: this.props.groupId,
                pageId: `${pageIdPrefix}_question_set`,
                displayForm: childIndex === index && pageGroup === this.props.groupId,
                formData: this.props.project.survey,
                schema,
                isFormOptional: true,
                key: `${pageIdPrefix}_question_set`,
            };

            const surveyQsProps = {
                survey: this.props.project.survey,
                projectTitle: this.props.project.project_title,
                saveChannel: this.props.saveChannel,
                schemaFields: schema.fields,
                questionSet,
                nextText:
                    childIndex === this.props.questionSets.length - 1
                        ? this.props.nextInstruction ?? nextTextFormatted
                        : nextTextFormatted,
                projectId: this.props.project[ID_ATTRIBUTE],
                industry: this.props.project.industry,
            };

            return (
                <FormStatusController {...statusControllerProps}>
                    <SurveyQuestionSet {...surveyQsProps} />
                </FormStatusController>
            );
        });
    }
}

SurveyBaseGroup.propTypes = {
    project: PropTypes.object, // From redux
    /* Below keys are from FormWizard Context .... 
        @@@@@@@@ START @@@@@@@
    */
    currentPageMap: PropTypes.shape({
        // From FormWizard Context
        index: PropTypes.number,
        status: PropTypes.oneOf([PAGE_STATUSES.COMPLETE, PAGE_STATUSES.INCOMPLETE, PAGE_STATUSES.OPTIONAL]),
    }),
    currentStep: PropTypes.shape({
        pageGroup: PropTypes.string,
        index: PropTypes.number,
    }),

    canNext: PropTypes.bool,
    canPrev: PropTypes.bool,
    next: PropTypes.func,
    prev: PropTypes.func,
    updatePageStatus: PropTypes.func,
    setCurrentStep: PropTypes.func,
    /* 
        @@@@@@@@ END @@@@@@@
    */
    saveChannel: PropTypes.string.isRequired,
    questionSets: PropTypes.arrayOf(
        PropTypes.shape({
            class: PropTypes.string,
            description: PropTypes.string,
            question: PropTypes.arrayOf(
                PropTypes.shape({
                    description: PropTypes.string,
                    id: PropTypes.string,
                    responses: PropTypes.arrayOf(
                        PropTypes.shape({
                            description: PropTypes.string,
                            id: PropTypes.string,
                            weight: PropTypes.number,
                        })
                    ),
                    type: PropTypes.oneOf([SURVEY_QUESTION_TYPES.NUMERIC, SURVEY_QUESTION_TYPES.MULTIPLECHOICE]),
                    visible: PropTypes.bool,
                })
            ),
        })
    ),
    groupId: PropTypes.string.isRequired,
    nextInstruction: PropTypes.string,
};

// Proxy component to use react context
const PageGroup = (props) => {
    return <SurveyBaseGroup {...props} {...useFormWizard()} />;
};

export default PageGroup;
