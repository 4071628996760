import React from 'react';
import { Config } from '../../utils/config';
import { useP2RAuth } from '../AuthContextProvider/authContext';

import './TermsServiceModal.scss';

const TermsServiceModal = ({ setModalClose } = {}) => {
    const { profile, acceptTermsForUser } = useP2RAuth();
    const { acceptedTerms = false } = profile;

    const accept = async () => {
        // update the user in the database
        // to set a flag to true if it's currently false...
        if (!acceptedTerms) {
            acceptTermsForUser();
        }

        setModalClose && setModalClose();
    };

    let terms = null;
    if (!acceptedTerms) {
        terms = (
            <p>
                And agree to the <a href={Config.PUBLIC_APP_TERMS_OF_SERVICE_URL} target="blank">Terms of Service</a> and <a href={Config.PUBLIC_APP_PRIVACY_POLICY_URL} target="blank">Privacy Policy</a> of this application.
            </p>
        );
    }

    return (
        <>
            <div className="row" style={{marginTop: '1rem'}}>
                <div className="col-lg-10 offset-lg-1">
                    <p>
                        <strong>
                            By clicking ‘accept’ you understand that:
                        </strong>
                    </p>
                    <ul>
                        <li>This is a decision support tool.</li>
                        <li>The outputs are selected from available modelling that enable estimation of the potential impact of changing farm practices.</li>
                        <li>The results do not directly represent my unique soils, climate, farming practices.</li>
                        <li>This tool accesses an existing database of model output, and due to the granularity of the simulation modelling it is possible the best match for practices both before and after a change will be the identical model output. In these cases, the management changes will not appear to generate an improvement.</li>
                    </ul>
                    
                    { terms }
                </div>
            </div>

            <div className="text-center" style={{marginBottom: '2rem'}}>
                <button
                    type="submit"
                    title="Accept terms and conditions"
                    className="btn btn-primary"
                    onClick={accept}
                >
                    Accept
                </button>
            </div>
        </>
    );
};

export default TermsServiceModal;