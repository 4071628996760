import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { EventEmitter } from "../EventEmitter";
import { EVENT_CHANNEL, ID_ATTRIBUTE } from "../../utils/constants";
import { connect } from "react-redux";
import { SHARED_SELECTOR } from "../../selectors/appSelector";
import { getProjectsBasedOnFilter } from "../../utils/functions";
import { MDBDropdownItem } from "mdbreact";
import "./surveySaveFixForm.scss";

const HELPER_MESSAGE_THIS_PROJECT = "For this project";
const HELPER_MESSAGE_SELECTED_PROJECT = "For the selected projects";
const HELPER_MESSAGE_COLLECTION_PROJECT = "For all projects in this collection";

const SurveySaveFixForm = ({
    displayForm,
    projectIdWithSurvey,
    editingProjectId,
    editingSourceProjectId,
    selectedProjectIds,
    collectionId,
    project,
}) => {
    const { handleSubmit, register, watch } = useForm({ mode: "onChange" });

    const saveMethodMap = useMemo(() => {
        return {
            "save-option-1": {
                id: "save-option-1", // Apply for this project only
                value: "save-option-1",
                message: HELPER_MESSAGE_THIS_PROJECT,
                saveMethod: () => {
                    EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_PATCH_SURVEY, {
                        payload: {
                            projectsToPatch: getProjectsBasedOnFilter({
                                [ID_ATTRIBUTE]: editingSourceProjectId,
                            }),
                            projectWithSurvey: project,
                        },
                    });
                },
            },
            "save-option-2": {
                id: "save-option-2", // Apply for this selected projects
                value: "save-option-2",
                message: HELPER_MESSAGE_SELECTED_PROJECT,
                saveMethod: () => {
                    const filteredSelectedProject = getProjectsBasedOnFilter((_project) => {
                        const {
                            [ID_ATTRIBUTE]: _id,
                            collectionId: _collectionId,
                            industry: _industry,
                            survey: { incompleteSurveyConflict },
                        } = _project;
                        return (
                            collectionId === _collectionId &&
                            _industry === project.industry &&
                            incompleteSurveyConflict === true &&
                            selectedProjectIds.hasOwnProperty(_id)
                        );
                    });

                    EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_PATCH_SURVEY, {
                        payload: {
                            projectsToPatch: filteredSelectedProject,
                            projectWithSurvey: project,
                        },
                    });
                },
            },
            "save-option-3": {
                id: "save-option-3", // Apply for every project in collection
                value: "save-option-3",
                message: HELPER_MESSAGE_COLLECTION_PROJECT,
                saveMethod: () => {
                    const allProjectsExcludeEditing = getProjectsBasedOnFilter((_project) => {
                        const {
                            [ID_ATTRIBUTE]: _id,
                            collectionId: _collectionId,
                            industry: _industry,
                            survey: { incompleteSurveyConflict },
                        } = _project;
                        return (
                            collectionId === _collectionId &&
                            _industry === project.industry &&
                            incompleteSurveyConflict === true &&
                            _id !== editingProjectId
                        );
                    });

                    EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_PATCH_SURVEY, {
                        payload: {
                            projectsToPatch: allProjectsExcludeEditing,
                            projectWithSurvey: project,
                        },
                    });
                },
            },
        };
    }, [projectIdWithSurvey, project]);

    const saveSurvey = (data, e) => {
        if (data.save_option && saveMethodMap[data.save_option]) {
            saveMethodMap[data.save_option].saveMethod && saveMethodMap[data.save_option].saveMethod();
        }
    };

    if (!displayForm) return null;

    return (
        <form className="survey-save-fix-form" onSubmit={handleSubmit(saveSurvey)}>
            {Object.values(saveMethodMap).map(({ id, value, message }) => (
                <div className="radio-container" key={id}>
                    <div className="custom-control custom-radio">
                        <input
                            ref={register}
                            type="radio"
                            className="custom-control-input"
                            id={id}
                            name="save_option"
                            value={value}
                        />
                        <label className="orange-check-form custom-control-label" htmlFor={id}>
                            {message}
                        </label>
                    </div>
                </div>
            ))}

            <div>
                {/* Use MDBDropdownItem to close survey fixer dropdown */}
                <MDBDropdownItem className="btn btn-primary" type="submit" disabled={!watch("save_option")}>
                    Save and Apply
                </MDBDropdownItem>
            </div>
        </form>
    );
};

function mapORMStateToProps(state) {
    const workspace = SHARED_SELECTOR.EVERY_THING(state);
    const {
        selectedCollectionId,
        collectionReadOnly,
        editingSourceProjectId,
        selectedProjectIds,
        editingProjectId,
    } = workspace;
    return {
        collectionId: selectedCollectionId,
        collectionReadOnly: collectionReadOnly,
        editingSourceProjectId: editingSourceProjectId,
        editingProjectId: editingProjectId,
        selectedProjectIds,
    };
}

export default connect(mapORMStateToProps)(SurveySaveFixForm);
