import React from "react";
import { PRIVILEGE } from "../../utils/constants";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { Link, useHistory } from "react-router-dom";
import Routes from "../../utils/routes";
import { useP2RAuth } from "../../components/AuthContextProvider/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faCaretDown } from "@fortawesome/pro-solid-svg-icons";

import "./usermenu.scss";

const UserMenu = () => {
    const { profile, selectGroup, logout } = useP2RAuth();

    const history = useHistory();

    const userSettings = [
        {
            id: 1,
            title: "Manage Account",
            route: Routes.ManageAccount.Route,
            internal: true,
        },
        {
            id: 2,
            title: "About",
            route: Routes.About.Route,
            internal: false,
        },
        {
            id: 3,
            title: "Logout",
            route: Routes.Logout.Route,
            internal: true,
            onClick: () => {
                logout();
                history.push(Routes.Login.Route);
            },
        },
    ];

    const handleGroupSelecting = async (id) => {
        selectGroup(id);
    };

    const groupItems = profile.getGroupsByMinPrivilege(PRIVILEGE.CAN_USE).map((g) => (
        <MDBDropdownItem
            href="#"
            className={profile.selectedGroup && profile.selectedGroup.id === g.id ? "active" : ""}
            key={g.id}
            onClick={() => {
                !g.active && handleGroupSelecting(g.id);
            }}
        >
            {g.name}
        </MDBDropdownItem>
    ));

    const settings = userSettings.map((x) => {
        return x.internal ?? true ? (
            <Link key={x.id} to={x.route} className="dropdown-item user-menu-item" onClick={x?.onClick}>
                {x.title}
            </Link>
        ) : (
            <a className="dropdown-item user-menu-item" key={x.id} target="_blank" href={x.route} onClick={x?.onClick}>
                {x.title}
            </a>
        );
    });

    return (
        <>
            <MDBDropdown className="user-menu">
                <MDBDropdownToggle color="color">
                    <div className="display-inline">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <span className="user-profile">
                            {profile.firstname}.{profile.lastname}
                        </span>
                        <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                </MDBDropdownToggle>
                <MDBDropdownMenu right basic className="z-depth-3">
                    <MDBDropdownItem header>YOUR GROUPS</MDBDropdownItem>
                    <div className="scrollable-menu custom-scrollbar">{groupItems}</div>
                    <div className="user-actions">{settings}</div>
                </MDBDropdownMenu>
            </MDBDropdown>
        </>
    );
};

export default UserMenu;
