import React, { useEffect, useState } from "react";
import { faWrench } from "@fortawesome/pro-solid-svg-icons/faWrench";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle } from "mdbreact";
import { services, serviceWrapper } from "../../services";
import { ERROR_CHANNEL, SUCCESS_CHANNEL } from "../../utils/constants";
import ComponentWillUnmountSupport from "../ComponentWillUnmountSupport";
import SuccessNotifier from "../Success/SuccessNotifier";
import MiniSurvey from "./MiniSurvey";
import "./SurveyFixer.scss";

const { projectService } = services;
const TOOLTIP_TITLE = "Fix survey";

const SurveyFixer = ({ project, readOnly }) => {
    const [showSurvey, setShowSurvey] = useState(false);

    useEffect(() => {
        if (showSurvey) {
            loadSurveyQuestionSets(project.industry);
        }
    }, [showSurvey]);

    const loadSurveyQuestionSets = async (industry) => {
        if (industry) {
            await serviceWrapper(
                {
                    successChannel: SUCCESS_CHANNEL.SURVEY_GET_INDUSTRY_SUCCESS,
                    errorChannel: ERROR_CHANNEL.SURVEY,
                },
                {
                    instance: projectService,
                    name: "getSurveyByType",
                    params: [industry],
                }
            );
        }
    };

    if (readOnly) {
        return null;
    }

    return (
        <MDBDropdown dropleft className={`survey-fixer-container`}>
            <MDBDropdownToggle tag="span" className="btn" onClick={() => setShowSurvey(true)}>
                <span title={TOOLTIP_TITLE}>
                    <FontAwesomeIcon icon={faWrench} className={`icon`} />
                </span>
            </MDBDropdownToggle>
            <MDBDropdownMenu className="issue-detail-wrapper">
                {showSurvey && (
                    <ComponentWillUnmountSupport
                        willUnmount={() => {
                            setShowSurvey(false);
                        }}
                    >
                        <SuccessNotifier
                            successChannel={SUCCESS_CHANNEL.SURVEY_GET_INDUSTRY_SUCCESS}
                            errorChannel={ERROR_CHANNEL.SURVEY}
                            startFn={() => {
                                return (
                                    <div className="help-block with-errors">Grabbing {project.industry} survey...</div>
                                );
                            }}
                            successFn={({ questions: questionSets, deps }) => {
                                const { survey = {} } = project;
                                const { qMissing, qConflicts } = survey;
                                const _questionSets = [];

                                // Transform each question in survey to be a questionset.
                                questionSets.forEach(({ questions, class: _class }) => {
                                    questions.forEach((question, idx) => {
                                        // Only grab missing questions
                                        if (qMissing[question.id] || qConflicts[question.id]) {
                                            _questionSets.push({
                                                class: `${_class}_${idx}`,
                                                questions: [question],
                                            });
                                        }
                                    });
                                });

                                return <MiniSurvey questionSets={_questionSets} project={project} deps={deps} />;
                            }}
                            errorFn={(error) => {
                                return <div className="help-block">{error.message}</div>;
                            }}
                        />
                    </ComponentWillUnmountSupport>
                )}
            </MDBDropdownMenu>
        </MDBDropdown>
    );
};

export default SurveyFixer;
