// import { ACTIONS, CREATE_ACTION } from "../redux/reducers/reducer.register";
import { store } from "../redux";
import {
    ACTIONS, CREATE_ACTION, DELETE_ALL_ACTION, CREATE_BATCH_ACTION, UPDATE_BATCH_ACTION, DELETE_BATCH_ACTION, UPDATE_ACTION,
    DELETE_ACTION
} from "../utils/constants";

export default class ReduxModel {
    constructor() { }

    toReduxStore() { }

    static fromReduxStore() { }

    clone() { }

    static deserialise() { }

    _reduxPersist(data, { store, slice }) { }

    reduxPersist({ store, slice } = {}) {
        if (!store || !store.dispatch)
            throw new Error("Store is not provided");

        const reduxData = this.toReduxStore();

        this._reduxPersist(reduxData, { store, slice });
    }

    static _reduxGetById(id, { state, slice } = {}) { }

    static reduxGetById(id, { state, slice } = {}) {
        if (!state)
            throw new Error("State is not provided");

        return this._reduxGetById(id, { state, slice });
    }

    static reduxGetAll({ state, slice }) {
        return state[slice];
    }

    static reduxDeleteAll({ slice }) {
        store.dispatch({
            type: ACTIONS[slice][DELETE_ALL_ACTION]
        });
    }

    static reduxUpdateBatch({ slice, data }) {
        store.dispatch({
            type: ACTIONS[slice][UPDATE_BATCH_ACTION],
            payload: data ?? []
        });
    }

    static reduxCreateBatch({ slice, data }) {
        store.dispatch({
            type: ACTIONS[slice][CREATE_BATCH_ACTION],
            payload: data ?? []
        });
    }

    static reduxDeleteBatch({ slice, data }) {
        store.dispatch({
            type: ACTIONS[slice][DELETE_BATCH_ACTION],
            payload: data ?? []
        });
    }

    reduxUpdate({ slice, data }) {
        store.dispatch({
            type: ACTIONS[slice][UPDATE_ACTION],
            payload: data ?? {}
        });
    }

    reduxCreate({ slice, data }) {
        store.dispatch({
            type: ACTIONS[slice][CREATE_ACTION],
            payload: data ?? {}
        });
    }

    reduxDelete({ slice, data }) {
        store.dispatch({
            type: ACTIONS[slice][DELETE_ACTION],
            payload: data ?? {}
        });
    }
}