import React, {  } from 'react';
import orm from '../../models/orm.register';
import { createSelector } from 'redux-orm';
import _ from 'lodash';
import { ID_ATTRIBUTE, ORM_PROJECT_SLICE, ORM_BLOCK_SLICE } from '../../utils/constants';
import { connect } from 'react-redux';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { SHARED_SELECTOR } from '../../selectors/appSelector';
import BaseORMModel from '../../models/BaseORMModel';

const projectBlockSelector = (state, { projectIds = [], blockIds = [], blockInclude = [], projectInclude = [] }) => {

    projectIds = projectIds ?? [];
    blockIds = blockIds ?? [];
    const projectMap = _.keyBy(projectIds.map(id => {
        return {
            [ID_ATTRIBUTE]: id
        };
    }), d => d[ID_ATTRIBUTE]);

    const blockMap = _.keyBy(blockIds.map(id => {
        return {
            [ID_ATTRIBUTE]: id
        };
    }), d => d[ID_ATTRIBUTE]);

    return createSelector(
        orm,
        session => {
            const { [ORM_PROJECT_SLICE]: Project, [ORM_BLOCK_SLICE]: Block } = session;

            let projects = [];
            let blocks = [];

            if (projectIds.length) {
                projects = Project.select(session, {
                    include: projectInclude ?? [],
                    filter: d => {
                        return projectMap[d[ID_ATTRIBUTE]] ? true : false;
                    }
                }) ?? [];
            }

            if (blockIds.length) {
                blocks = Block.select(session, {
                    include: blockInclude ?? [],
                    filter: d => {
                        return blockMap[d[ID_ATTRIBUTE]] ? true : false;
                    }
                }) ?? [];
            }

            return {
                projects,
                blocks
            };
        }
    )(state);
};


const _memoize = (func, argEqualityCheck) => {
    return defaultMemoize(func, argEqualityCheck);
};

const isEqual = (previousVal, currentVal) => {
    return previousVal === currentVal;
};

const createProjectBlockSelector = createSelectorCreator(
    _memoize,
    isEqual
);

const getProjectBlock = createProjectBlockSelector(
    [projectBlockSelector],
    (data) => {
        return data;
    }
);

const _ProjectBlockReduxHooker = ({ projects, blocks, children }) => {
    let _children = children;

    if (!_children)
        _children = [];
    else if (_children && !Array.isArray(children)) {
        _children = [children];
    }

    return _children.map((child, index) => {
        return <child.type {...child.props} {...{
            projects,
            blocks,
            key: index
        }} />;
    });
};

const mapStateToProps = (state, props) => {
    // memoize the selector to get data from cache if it's already selecteds
    const { projects, blocks } = getProjectBlock(state, props);

    return {
        projects,
        blocks
    };
};

const ProjectBlockReduxHooker = connect(mapStateToProps)(_ProjectBlockReduxHooker);

const blockInclude = [{ 'project': ['blocks'] }];
const projectInclude = [{ 'blocks': [] }];

const toMapMemo = () => {

    return () => {

        let prev = [];
        let prevMap = null;

        return (arr) => {
            if (BaseORMModel.getVersionId(arr ?? []) !== BaseORMModel.getVersionId(prev ?? [])) {
                const map = _.keyBy(arr ?? [], d => d[ID_ATTRIBUTE]);
                prevMap = map;
                prev = arr;
                return map;
            } else {

                prev = arr;

                if (!prevMap) {
                    const map = _.keyBy(arr ?? [], d => d[ID_ATTRIBUTE]);
                    prevMap = map;

                    return map;
                } else {
                    return prevMap;
                }
            }
        };
    };
};

const projectMap = toMapMemo()();
const blockMap = toMapMemo()();

const mapStateToPropsOfActions = (state, props) => {
    const workspace = SHARED_SELECTOR.EVERY_THING(state);
    
    const selProjMap = projectMap((workspace ?? {}).selectedProjects ?? []);
    const selBlockMap = blockMap((workspace ?? {}).selectedBlocks ?? []);

    const projects = (props.projectIds ?? []).map(projectId => selProjMap[projectId]).filter(d => d);
    const blocks = (props.blockIds ?? []).map(blockId => selBlockMap[blockId]).filter(d => d);
    
    return {
        projects,
        blocks,
        selectedProjectMap: selProjMap,
        selectedBlockMap: selBlockMap,
        readOnly: props.readOnly ?? ((workspace ?? {}).collectionReadOnly ?? false),
        selectedCollection: (workspace ?? {}).selectedCollection,
        selectedGroupId: workspace.selectedGroupId
    };
};

export { ProjectBlockReduxHooker, mapStateToPropsOfActions, projectBlockSelector, blockInclude, projectInclude };