import BaseReducerHandler from "./BaseReducerHandler";
import {
    ERROR_CHANNEL, ACTIONS, CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION, CREATE_BATCH_ACTION,
    UPDATE_BATCH_ACTION, DELETE_BATCH_ACTION, DELETE_ALL_ACTION
} from "../../utils/constants";

import generateActionName from './action.gen';

// import orm from "../../models/orm.register";

import _ from "lodash";

export default class BaseORMReducerHandler extends BaseReducerHandler {
    /*
    
        { initState, slice, createAction, updateAction, deleteAction, deleteAllAction }
        slice is required
    */
    constructor({ initState = {},
        slice,
        orm,
        errorChannel = ERROR_CHANNEL.GENERAL,
        fireErrorOnSuccess = true
    }) {

        super({ initState, slice, errorChannel, fireErrorOnSuccess });

        if (!orm)
            throw new Error("ORM definition does not exist");

        // [createAction, updateAction, deleteAction, deleteAllAction,
        //     createBatchAction, updateBatchAction, deleteBatchAction].forEach(action => {
        //         if (!ACTIONS[slice][action])
        //             throw new Error(`Action definition ${action} within slice ${this.slice} does not exist`);
        //     });

        this.orm = orm;

        // action data requires type and payload
        this.registerAction({
            // actionName: createAction,
            actionFn: (state, action, next) => {

                const session = this.orm.session(state || this.orm.getEmptyState());
                session.sessionBoundModels.forEach(modelClass => {
                    if (typeof modelClass.reducer === 'function') {
                        modelClass.reducer(action, modelClass, session);
                    }
                });

                // updater(session, action);

                return session.state;

                // state = this.create({ state, action, actionName: createAction, next });
                // state = this.update({ state, action, actionName: updateAction, next });
                // state = this.delete({ state, action, actionName: deleteAction, next });
                // state = this.deleteAll({ state, action, actionName: deleteAllAction, next });
                // state = this.createBatch({ state, action, actionName: createBatchAction, next });
                // state = this.updateBatch({ state, action, actionName: updateBatchAction, next });
                // state = this.deleteBatch({ state, action, actionName: deleteBatchAction, next });

                // return state;
            }
        });
    }

}