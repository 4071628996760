import { Model, ORM, fk, many, attr } from "redux-orm";
// import generateActionName from "../redux/reducers/action.gen";

import BaseORMModel from "./BaseORMModel";
import { ORM_PROJECT_SLICE, ORM_BLOCK_SLICE, ID_ATTRIBUTE } from "../utils/constants";

export default class BlockORMModel extends BaseORMModel { }
BlockORMModel.modelName = ORM_BLOCK_SLICE;
BlockORMModel.fields = {
    [ID_ATTRIBUTE]: attr(),
    projectId: fk({
        to: ORM_PROJECT_SLICE,
        as: 'project',
        relatedName: 'blocks',
    }),

    appVersion: attr(),
    area: attr(),
    codes: attr(),
    // [
    //     {
    //         area: 0.9505172153867858,
    //         climate: "1.811",
    //         soil: "8",
    //         climate_id: ["5d6f1b14e4fcf50728ffe078"],
    //         code: "1.811_8",
    //         soil_id: ["5d6f1b8ce4fcf50728006f5d"]
    //     }
    // ]

    conflictProperties: attr(),
    // conflict: false
    // lookupFails: false
    // lookupFailureImpactAreas: null
    // lookupFailureImpactRatio: null
    // outsideOfRegion: false
    // paddockCodesFailsToResolve: false


    dateCreated: attr(),
    dateUpdated: attr(),

    // for cane, cane regions of q7
    extraResults: attr(),
    // {
    //     caneRegions: {}
    // }

    geometry: attr(),
    // type
    // coordinates

    results: attr(),
    // Runoff DIN,
    // after: 0.16784087166687153
    // area: 0.9504853957260805
    // before: 0.36529585070016257
    // class: "total_din"
    // measure: "Total DIN"
    // reduction: 0.19745497903329104
    // unit: "kg"
    // Soil
    // Total DIN

    scores: attr(),
    // afterCode: "BfntB/C_IBB_FBB"
    // afterFallow: "BB"
    // afterInterrow: "BB"
    // afterSim: "BfntB/C"
    // beforeCode: "DmntD_IDD_FDD"
    // beforeFallow: "DD"
    // beforeInterrow: "DD"
    // beforeSim: "DmntD"
    // nutrient_a: "B"
    // nutrient_aScore: 100
    // nutrient_b: "D"
    // nutrient_bScore: 0
    // runoff_a: "B"
    // runoff_aScore: 100
    // runoff_b: "D"
    // runoff_bScore: 0
    // water_a: "B"
    // water_aScore: 100
    // water_b: "D"
    // water_bScore: 0

    surveyVersion: attr(),

    __v: attr()
};