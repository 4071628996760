import _ from "lodash";
import React from "react";

export const ID_ATTRIBUTES = "_id";

export const ID_ATTRIBUTE = "_id";
export const REDUX_ORM_VERSION = "__v";
export const VERSION_ATTRIBUTE = "_____version";
export const QUERY_VERSION_ATTRIBUTE = "_____query____version";
export const SOFT_DELETE_ATTRIBUTE = "deleted";
export const ORM_VERSION_CONTROL_FIELDS = [VERSION_ATTRIBUTE, QUERY_VERSION_ATTRIBUTE, SOFT_DELETE_ATTRIBUTE];

export const DETAIL_DISPLAY_GROUP_NUM = 5;
export const MAX_FIELD_LENGTH = 100;

export const INDUSTRY = {
    GRAIN: { key: "grain", label: "Grain" },
    CANE: { key: "cane", label: "Cane" },
    BANANAS: { key: "banana", label: "Banana" },
    GRAZING: { key: "grazing", label: "Grazing" },
};

export const INDUSTRY_BY_KEY = Object.values(INDUSTRY).reduce((industries, currentIndustry) => {
    return {
        ...industries,
        [currentIndustry.key]: currentIndustry,
    };
}, {});

export const GRAZING_PRACTICE_FLAG_QUESTION_ID = {
    Q0_1: "q0_1",
    Q0_2: "q0_2",
    Q0_3: "q0_3",
};

export const GRAZING_STREAMBANK_SPECIFIC_QUESTIONS = {
    Q0_4: "q0_4",
};

export const GRAZING_STREAMBANK_SIZES = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
};

export const KNOWN_SURVEY_CATEGORIES = {
    STREAMBANK: "streambank",
};

export const SURVEY_QUESTION_TYPES = {
    NUMERIC: "numeric",
    MULTIPLECHOICE: "mc",
};

export const SURVEY_ANSWER_BEFORE_AFTER = {
    BEFORE: { key: "before", label: "Before" },
    AFTER: { key: "after", label: "After" },
};

export const PAGE_STATUSES = {
    INCOMPLETE: "INCOMPLETE",
    OPTIONAL: "OPTIONAL",
    COMPLETE: "COMPLETE",
};

export const WORKSPACE_ID = "workspace";

export const MEASURE_UNITS = {
    KG: { state: "kg", label: "kilograms" },
    TONNE: { state: "tonne", label: "tonnes" },
    GRAM: { state: "gram", label: "grams" },
};

export const MEASURE_UNIT_MAP = _.keyBy(_.values(MEASURE_UNITS ?? {}) ?? [], (d) => d.state);

export const MEASURES = {
    SOIL: { label: "Fine sediment", state: "soil" },
    PEST: { label: "Pesticides", state: "pest" },
    TOTAL_DIN: { label: "Total DIN", state: "total_din" },
};

export const MEASURE_VALS = [MEASURES.TOTAL_DIN, MEASURES.SOIL, MEASURES.PEST];
export const MEASURE_MAP = _.keyBy(_.values(MEASURE_VALS ?? {}) ?? [], (d) => d.state);

export const NUMERIC_PRINT_FORMAT = "0,0[.]00";
export const REDUCTION_PRINT_FORMAT = NUMERIC_PRINT_FORMAT;
export const AREA_PRINT_FORMAT = NUMERIC_PRINT_FORMAT;
export const DATE_CREATED = "dateCreated";
export const DATE_UPDATED = "dateUpdated";

export const DEFAULT_SELECTED_OPTION = "-1";

export const MODAL_OPEN_CLOSE_STATE = "___modal___state";

export const DEF_SELECTED_ANS = {
    [SURVEY_ANSWER_BEFORE_AFTER.BEFORE.key]: null,
    [SURVEY_ANSWER_BEFORE_AFTER.AFTER.key]: null,
};

export const CANE_SURVEY_VERSION = {
    VERSION_1: "v1",
    VERSION_2: "v2",
    VERSION_3_0: "v3.0",
};

export const BANANA_SURVEY_VERSION = {
    VERSION_2: "v2",
    VERSION_3_0: "v3.0",
};

export const GRAIN_SURVEY_VERSION = {
    VERSION_1_0: "v1.0",
};

export const GRAZING_SURVEY_VERSION = {
    VERSION_1_0: "v1.0",
};

export const LATEST_SURVEY_VERSION = {
    [INDUSTRY.CANE.key]: CANE_SURVEY_VERSION.VERSION_3_0,
    [INDUSTRY.BANANAS.key]: BANANA_SURVEY_VERSION.VERSION_3_0,
    [INDUSTRY.GRAIN.key]: GRAIN_SURVEY_VERSION.VERSION_1_0,
    [INDUSTRY.GRAZING.key]: GRAZING_SURVEY_VERSION.VERSION_1_0,
};

export const INCOMPLETE_SUVEY_MESSAGE = "You must complete page 1 and the map (if applicable) to save";

const SURVEY_INCOMPLETE_TEXT = "Survey incomplete";
const PROJECT_HAS_SC_CONFLICT_TEXT = "Soil and climate permission conflict";
const PROJECT_HAS_SC_CONFLICT_DETAILED_TEXT =
    "Soil and climate detection for this project was created by a group with different permissions to yours ";
const PROJECT_HAS_LUF_TEXT = "Not modelled";
const PROJECT_HAS_LUF_DETAILED_TEXT = "Soil and climate combinations are not modelled for some areas in the project";
const OUTSIDE_REGION_TEXT = "Outside region";
const OUTSIDE_REGION_DETAILED_TEXT = "This project is located outside of the area covered by the model computation";
const SURVEY_VERSION_MISMATCH_CONFLICT_TEXT = "Survey Version Mismatch";
const SURVEY_VERSION_MISMATCH_CONFLICT_DETAILED_TEXT = "This project contains blocks with outdated surveys";
const EROSION_RATE_PROVIDER_ERROR_TEXT = "Erosion Rate Request Error";
const EROSION_RATE_PROVIDER_ERROR_DETAILED_TEXT =
    "There was an error while requesting erosion rates, please try again later";

export const PROJECT_ISSUES = {
    incompleteSurveyConflict: {
        message: (meta) => SURVEY_INCOMPLETE_TEXT,
        detailedMessage: (meta) => {
            if (meta.readOnly) {
                return <span>{SURVEY_INCOMPLETE_TEXT}</span>;
            }

            return (
                <>
                    To fix the issue, use the <span className="fa fa-wrench" /> &nbsp;'Fix issues' action
                </>
            );
        },
        type: "incompleteSurveyConflict",
    },
    customSoilClimatePermissionConflict: {
        message: (meta) => PROJECT_HAS_SC_CONFLICT_TEXT,
        detailedMessage: (meta) => {
            if (meta.readOnly) {
                return <span>{PROJECT_HAS_SC_CONFLICT_DETAILED_TEXT}</span>;
            }

            return (
                <>
                    To fix the issue, use the <span className="fa fa-wrench" /> &nbsp;'Fix issues' action
                </>
            );
        },
        type: "customSoilClimatePermissionConflict",
    },
    hasLookupFails: {
        message: (meta) => PROJECT_HAS_LUF_TEXT,
        detailedMessage: (meta) => PROJECT_HAS_LUF_DETAILED_TEXT,
        type: "lookupFails",
    },
    outsideOfRegion: {
        message: (meta) => OUTSIDE_REGION_TEXT,
        detailedMessage: (meta) => OUTSIDE_REGION_DETAILED_TEXT,
        type: "outsideOfRegion",
    },
    erosionRateProviderError: {
        message: (meta) => EROSION_RATE_PROVIDER_ERROR_TEXT,
        detailedMessage: (meta) => EROSION_RATE_PROVIDER_ERROR_DETAILED_TEXT,
        type: "erosionRateProviderError",
    },
    conflict: {
        message: (meta) => "",
        detailedMessage: (meta) => "",
        type: "",
    },
    hasConflict: {
        message: (meta) => "",
        detailedMessage: (meta) => "",
        type: "",
    },
    surveyVersionMismatchConflict: {
        message: (meta) => SURVEY_VERSION_MISMATCH_CONFLICT_TEXT,
        detailedMessage: (meta) => {
            if (meta.readOnly) {
                return <span>{SURVEY_VERSION_MISMATCH_CONFLICT_DETAILED_TEXT}</span>;
            }

            return (
                <>
                    To fix the issue, use the <span className="fa fa-wrench" /> &nbsp;'Fix issues' action
                </>
            );
        },
        type: "surveyVersionMismatchConflict",
    },
};

// LUF = look up failed
const BLOCK_LUF_PERCENTAGE_DECIMAL_PLACES = 2;
export const BLOCK_ISSUES = {
    hasLookupFails: {
        message: (meta) =>
            `Climate and soil combination not modelled for ${meta?.percentage?.toFixed(
                BLOCK_LUF_PERCENTAGE_DECIMAL_PLACES
            )}% of area`,
        detailedMessage: (meta) =>
            `Climate and soil combination not modelled for ${meta?.percentage?.toFixed(
                BLOCK_LUF_PERCENTAGE_DECIMAL_PLACES
            )}% of area`,
        type: "lookupFails",
    },
    outsideOfRegion: {
        message: (meta) => OUTSIDE_REGION_TEXT,
        detailedMessage: (meta) => OUTSIDE_REGION_DETAILED_TEXT,
        type: "outsideOfRegion",
    },
    erosionRateProviderError: {
        message: (meta) => EROSION_RATE_PROVIDER_ERROR_TEXT,
        detailedMessage: (meta) => EROSION_RATE_PROVIDER_ERROR_DETAILED_TEXT,
        type: "erosionRateProviderError",
    },
    conflict: {
        message: (meta) => "",
        detailedMessage: (meta) => "",
        type: "All Blocks with conflicts",
    },
    hasConflict: {
        message: (meta) => "",
        type: "",
    },
};
