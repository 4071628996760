import React from 'react';
import PropTypes from 'prop-types';

const Helper = ({ nextText = "", afterText = "" }) => {
    if (nextText || afterText) {
        return (
            <div className="form-helper">
                {nextText &&
                    <p>
                        <strong>Next:&nbsp;</strong>
                        {nextText}
                    </p>
                }
                {afterText &&
                    <p>
                        <strong>After:&nbsp;</strong>
                        {afterText}
                    </p>
                }
            </div>
        );
    }

    return null;
};

Helper.propTypes = {
    nextText: PropTypes.string,
    afterText: PropTypes.string
};

export default Helper;

