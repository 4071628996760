const validatonMessages = {
    EMAIL_ADDRESS_REQUIRED: "Please enter your email address",
    PASSWORD_REQUIRED: "Please enter your password",
    EMAIL_ADDRESS_INVALID: "That's not an email address. It must be like something@something.com",
    PASSWORD_INVALID: (type) => `${type} must be 6 to 30 characters and contain at least one each of - uppercase, lowercase, number and special character`,
    TERMS_PRIVACY_POLICY_REQUIRED: 'Please read and accept the Terms of Service and Privacy Policy of this application to be able to login.',

    CURRENT_PASSWORD_REQUIRED: "Please enter your current password",
    NEW_PASSWORD_REQUIRED: "Please enter your new password",
    CONFIRM_PASSWORD_REQUIRED: "Please confirm your password",
    CANT_SET_NEW_PASS_AS_CURRENT: "Can't set new password as your current password",
    CONFIRM_PASS_NOT_MATCH_NEW_PASS: "Confirm password does not match your new password",

    PROJECT_TITLE_REQUIRED: "Project title is required",
    PROJECT_TITLE_INVALID: "Enter a project title between 6 and 100 characters (allow !, @, #, $, &, -, _, \, / characters)",
    INDUSTRY_REQUIRED: "Industry is required",

    NUMERIC_CHARACTER_ONLY: "Numeric characters only",
    NUMERIC_AND_COMMA_ONLY: (type) => `You must enter ${type} for this project (numeric and commas only)`,

    FUNDING_BODY_REQUIRED: "You must select a funding body",
    FUNDING_PROGRAM_REQUIRED: "You must select a funding body",
};

export default validatonMessages;
