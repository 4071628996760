import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-regular-svg-icons/faSave';
import { useP2RAuth } from '../../components/AuthContextProvider/authContext';
import { ERROR_CHANNEL, SUCCESS_CHANNEL } from "../../utils/constants";
import { services, serviceWrapper } from '../../services';
import { SuccessNotifier } from '../../components/Success';
import { yupResolver } from '@hookform/resolvers';
import schema from '../../components/FormWizard/formSchema';
import './changepassword.scss';

const resetSuccessMessage = "Your password has been reset. Please check your email to get the new password";
const resetInProgressMessage = "A new password is being sent to your registered email address";
const resetFailedMessage = "Something went wrong when updating your password";

const newPassSuccess = "New password has been set";
const newPassInProgress = "A new password is being set...";
const newPassFailed = "Something went wrong when updating your password";

const ChangePassword = () => {
    const { userService } = services;
    const { setUserToken } = useP2RAuth();
    const { register, errors, handleSubmit, reset, trigger } = useForm({ resolver: yupResolver(schema.forgotPassword), mode: "onBlur" });
    const [disableResetOnSuccess, setDisableResetOnSuccess] = useState(false);
    const errorMessages = Object.values(errors);

    const resetPassword = async () => {
        setDisableResetOnSuccess(true);
        const newToken = await serviceWrapper(
            {
                successChannel: SUCCESS_CHANNEL.RESET_PASSWORD_SUCCESS,
                errorChannel: ERROR_CHANNEL.RESET_PASSWORD
            },
            {
                instance: userService,
                name: "resetPassWithUserToken",
                params: []
            });
        if (newToken) {
            setDisableResetOnSuccess(false);
            setUserToken(newToken.token);
        }
    };

    const handleOnSubmit = async (data, e) => {
        e.preventDefault();
        const changePasswordResult = await serviceWrapper(
            {
                successChannel: SUCCESS_CHANNEL.CHANGE_PASSWORD_SUCCESS,
                errorChannel: ERROR_CHANNEL.CHANGE_PASSWORD
            },
            {
                instance: userService,
                name: "changeUserPassword",
                params: [{
                    oldPassword: data['password'],
                    password: data['newPassword']
                }]
            });
        if (changePasswordResult) {
            setUserToken(changePasswordResult.token);
            reset();
        }
    };

    return (
        <div className="tab-content change-password">
            <form onSubmit={handleSubmit(handleOnSubmit)}>
                <div className="row">
                    <div className="col-4">
                        <input
                            name="password"
                            placeholder="Current password"
                            className="form-control"
                            type="password"
                            ref={register({})}
                        />
                        {/* hidden on request of nick, the password that is then emailed to you does not work to login to the app with... this will need to be fixed later down the track at some point... */}
                        {/* <div className="d-flex justify-content-end">
                            <button
                                type="button"
                                className="btn btn-as-text help-block forgot-password"
                                onClick={resetPassword}
                                disabled={disableResetOnSuccess}
                            >
                                Forgot your password?
                            </button>
                        </div> */}
                        <SuccessNotifier
                            successChannel={SUCCESS_CHANNEL.RESET_PASSWORD_SUCCESS}
                            errorChannel={ERROR_CHANNEL.RESET_PASSWORD}
                            startFn={
                                () => <div className="help-block with-errors">{resetInProgressMessage}</div>
                            }

                            successFn={
                                () => {
                                    return (
                                        <div className="help-block with-errors">{resetSuccessMessage}</div>
                                    );
                                }
                            }
                            errorFn={
                                () => {
                                    return (
                                        <div className="help-block with-errors">{resetFailedMessage}</div>
                                    );
                                }
                            }
                        />
                    </div>
                    <div className="col-4">
                        <input
                            name="newPassword"
                            placeholder="New password"
                            className="form-control"
                            type="password"
                            ref={register({})}
                            onBlur={() => trigger("confirmPassword")}
                        />
                    </div>
                    <div className="col-4">
                        <input
                            name="confirmPassword"
                            placeholder="Confirm new password"
                            className="form-control"
                            type="password"
                            ref={register({})}
                        />
                    </div>
                </div>

                <div className="">
                    {errorMessages.length > 0 &&
                        <p className="help-block">{(errorMessages[0] ?? {}).message}</p>
                    }
                    <SuccessNotifier
                        successChannel={SUCCESS_CHANNEL.CHANGE_PASSWORD_SUCCESS}
                        errorChannel={ERROR_CHANNEL.CHANGE_PASSWORD}
                        startFn={
                            () => <div className="help-block with-errors">{newPassInProgress}</div>
                        }

                        successFn={
                            () => {
                                return (
                                    <div className="help-block with-errors">{newPassSuccess}</div>
                                );
                            }
                        }
                        errorFn={
                            ({ message }) => {
                                return (
                                    <div className="help-block with-errors">{message ?? newPassFailed}</div>
                                );
                            }
                        }
                    />
                </div>

                <div className="row justify-content-end">
                    <div className="col-4">
                        <button className="btn btn-primary w-100 ml-0" type="submit">
                            Save new password
                            <FontAwesomeIcon className="icon-text-left-spacing" icon={faSave} />
                        </button>
                    </div>
                </div>

            </form>
        </div>
    );
};

export default ChangePassword;