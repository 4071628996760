
class MiddleWare {
    constructor(...props) {
        this.props = props;
        this.pipes = [];
        // this.params = [];
    }

    //use(pipe, ...params) {
    use(pipe) {
        this.pipes.push(pipe);
        // this.params.push(params || []);
    }

    async exec() {

        const next = async (index, err, fn) => {
            index++;
            return await fn(index, err);
        };

        const fn = async (index, err) => {
            if (index >= this.pipes.length)
                return;

            if (this.pipes[index] &&
                //typeof (this.pipes[index]) === "function" && this.pipes[index](...this.props, ...this.params[index], (err, ret) => {
                typeof (this.pipes[index]) === "function") {

                try {
                    return await this.pipes[index](...this.props, err, async (err, ret) => {

                        if (err) {
                            console.log(err);
                        }

                        return await next(index, err, fn);
                    });
                } catch (err) {


                    return await next(index, err, fn);
                }
            }
        };

        return await fn(0);
    }

    execSync() {

        const next = (index, err, fn) => {
            index++;

            // console.log("before");
            // console.log(index);


            const ret = fn(index, err);

            // console.log("after");
            // console.log(index);
            // // console.log(fn);
            // console.log(this.pipes[index]);
            // console.log(ret);
            return ret;
        };

        const fn = (index, err) => {
            if (index >= this.pipes.length)
                return;

            if (this.pipes[index] &&
                //typeof (this.pipes[index]) === "function" && this.pipes[index](...this.props, ...this.params[index], (err, ret) => {
                typeof (this.pipes[index]) === "function") {

                try {
                    // console.log(index);
                    const b = this.pipes[index](...this.props, err, (err, ret) => {

                        if (err) {
                            console.log(err);
                        }

                        return next(index, err, fn);
                    });

                    // console.log(b);
                    return b;
                } catch (err) {

                    console.log(err);
                    return next(index, err, fn);
                }
            }
        };

        return fn(0);
    }

    async execWithAsync() {

        const next = async (index, err, fn) => {
            index++;
            return await fn(index, err);
        };

        const fn = async (index, err) => {

            if (index >= this.pipes.length) {
                if (err)
                    throw err;

                return;
            }

            if (this.pipes[index] &&
                //typeof (this.pipes[index]) === "function" && this.pipes[index](...this.props, ...this.params[index], (err, ret) => {
                typeof (this.pipes[index]) === "function") {

                try {


                    return await this.pipes[index](...this.props, err, async (err, ret) => {

                        if (err) {
                            console.log(err);
                        }

                        return await next(index, err, fn);
                    });

                } catch (err) {

                    console.log(err);
                    return await next(index, err, fn);
                }
            }
        };

        return await fn(0);
    }
}
export default MiddleWare;