import React from "react";
import BaseService from "./base.service";
import TokenService from "./token.service";
import UserService from "./user.service";
import GroupService from "./group.service";
import CollectionService from "./collection.service";
import ProjectService from "./project.service";
import FundingService from "./funding.service";
import SoilClimateDataService from "./soil-climate-data.service";

import Middleware from "../utils/middleware";
import _ from "lodash";
import { EventEmitter } from "../components/EventEmitter";
import { ERROR_CHANNEL, VERSION_ATTRIBUTE, QUERY_VERSION_ATTRIBUTE, REDUX_ORM_VERSION } from "../utils/constants";
import ComputationService from "./computation.service";
import BlockService from "./block.service";
import MapService from "./map.service";

/**
    Utility function to remove redux related keys for object and array of objects (1 level depth only)
    @param {object | Array<object>}


    @returns {sanitised params}
*/
const sanitiseORMPayload = (params) => {
    return params.reduce((arrayOfSanitisedParams, param) => {
        if (Array.isArray(param)) {
            arrayOfSanitisedParams.push(sanitiseORMPayload(param));
        } else if (typeof param === "object" && param !== null) {
            const {
                [VERSION_ATTRIBUTE]: unwantedVer,
                [QUERY_VERSION_ATTRIBUTE]: unwatedQVer,
                [REDUX_ORM_VERSION]: unwantedReduxVer,
                ...sanitisedParams
            } = param; // remove unwanted redux keys

            arrayOfSanitisedParams.push(sanitisedParams);
        } else {
            arrayOfSanitisedParams.push(param);
        }

        return arrayOfSanitisedParams;
    }, []);
};

const generatePipe = (service, { name, params }) => {
    return async (d, error, next) => {
        const sanitisedParams = sanitiseORMPayload(params);

        if (error) {
            throw error;
        }

        if (!service) {
            throw new Error("Service instance is null");
        }

        const fn = service[name];

        if (fn && typeof fn === "function") {
            const ret = await fn.apply(service, sanitisedParams ?? []);

            d.result = ret ?? {};

            await next();
        } else {
            throw new Error(`Function ${name} within ${service} is not found`);
        }
    };
};

const generateErrorPipe = ({ errorChannel, successChannel, model, fireErrorOnSuccess = true }) => {
    return async (d, error, next) => {
        if (error) {
            console.log(error);

            EventEmitter.dispatch(errorChannel ?? ERROR_CHANNEL.GENERAL, {
                // error: {
                message: error.message,
                //}
            });
        } else {
            fireErrorOnSuccess && EventEmitter.dispatch(errorChannel ?? ERROR_CHANNEL.GENERAL, null);

            successChannel &&
                EventEmitter.dispatch(successChannel, {
                    type: "success",
                    data: (d ?? {}).result,
                });
            // successChannel && EventEmitter.dispatch(successChannel, (d ?? {}).result);
        }
    };
};

const modelConverter = (model) => {
    return async (d, error, next) => {
        if (error) {
            throw error;
        }

        d.result = services.baseService.modelConvert({ model, data: d.result || {} });
        await next();
    };
};

// execute it in a middleware routine to provide error handling and model serialisation and deserialisation
// TODO: explain fireErrorOnSuccess in future
export const serviceWrapper = async (params, ...methods) => {
    let { sharedParam, errorChannel, fireErrorOnSuccess = true, successChannel, model } = params ?? {};
    sharedParam = sharedParam ?? {};
    const pipes = new Middleware(sharedParam);

    methods = methods ?? [];

    successChannel &&
        EventEmitter.dispatch(successChannel, {
            type: "start",
        });

    (methods || []).forEach(({ name, params, instance }) => {
        pipes.use(
            generatePipe(instance, {
                name,
                params,
            })
        );
    });

    pipes.use(modelConverter(model));
    pipes.use(
        generateErrorPipe({
            errorChannel,
            successChannel,
            model,
            fireErrorOnSuccess,
        })
    );

    await pipes.exec();
    return sharedParam.result;
};

export const services = {
    baseService: new BaseService(),
    userService: new UserService(),
    tokenService: new TokenService(),
    groupService: new GroupService(),
    collectionService: new CollectionService(),
    fundingService: new FundingService(),
    projectService: new ProjectService(),
    blockService: new BlockService(),
    computationService: new ComputationService(),
    soilClimateDataService: new SoilClimateDataService(),
    mapService: new MapService(),
};

Object.values(services).forEach((service) => {
    service.assignService(services);
});

export const register = (props) => {
    let children = props.children;

    children = children || [];
    children = _.isArray(children) ? children : [children];

    children = (children || [])
        .map((child, index) => {
            if (typeof child === "function") {
                return (
                    <React.Fragment key={index}>
                        {child({
                            ...props,
                            ...services,
                        })}
                    </React.Fragment>
                );
            } else if (typeof child === "object") {
                return <child.type {...props} {...services} key={index} />;
            }

            return null;
        })
        .filter((d) => d);

    return <>{children}</>;
};
