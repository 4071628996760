import React, { useEffect } from "react";
import { store } from "../../redux";
import SurveySaveFixForm from "./SurveySaveFixForm";
import generateORMActionName from "../../redux/reducers/orm.action.gen";
import {
    CREATE_ACTION,
    EVENT_CHANNEL,
    ID_ATTRIBUTE,
    ORM_PROJECT_SLICE,
    ORM_WORKSPACE_SLICE,
    DELETE_ACTION,
    UPDATE_ACTION,
    WORKSPACE_ID,
    INDUSTRY,
} from "../../utils/constants";
import { generateProjectSkeleton } from "../../utils/functions";
import { FormWizard, PageGroup, PageNumberStepper, SurveyPageGroup } from "../FormWizard";
import orm from "../../models/orm.register";
import { SHARED_SELECTOR } from "../../selectors/appSelector";
import { connect } from "react-redux";
import { createSelector } from "redux-orm";

const pageGroupMap = {
    SURVEY: {},
    SURVEY_SAVE: {},
};

const MiniSurvey = ({ project, questionSets, deps, clonedProject }) => {
    useEffect(() => {
        const clonedProject = generateProjectSkeleton(project[ID_ATTRIBUTE], project.collectionId);

        /* 
            Remove all survey questions that are not missing / have conflicts.
            This is to only merge in questions that haven't been answered instead of the entire
            survey.
        */
        const {
            survey: { qMissing, qConflicts, incompleteSurveyConflict, ...answeredQs },
            ...projectDetails
        } = clonedProject;

        const clonedProjWithMissingSurveyQs = {
            ...projectDetails,
            survey: {
                qMissing,
                qConflicts,
                incompleteSurveyConflict,
                ...answeredQs,
            },
        };

        // Add skeleton project to redux
        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: CREATE_ACTION }),
            payload: clonedProjWithMissingSurveyQs,
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
                editingSourceProjectId: project[ID_ATTRIBUTE],
                editingProjectId: clonedProjWithMissingSurveyQs[ID_ATTRIBUTE],
            },
        });

        return function cleanup() {
            // Remove cloned project
            store.dispatch({
                type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: DELETE_ACTION }),
                payload: {
                    [ID_ATTRIBUTE]: clonedProjWithMissingSurveyQs[ID_ATTRIBUTE],
                },
            });
        };
    }, []);

    const industryCanShowQuestions = (industry, survey, requiredQuestionId, requiredAnswer) => {
        switch (industry) {
            case INDUSTRY.GRAZING.key:
                return project.survey[`q${requiredAnswer}`] === true;
            default:
                return false;
        }
    };

    if (!clonedProject) {
        return null;
    }

    return (
        <FormWizard
            pageGroupMap={pageGroupMap}
            className="mini-survey"
            displayDefNav={false}
            displayHelperText={false}
            displayCloseButton={false}
        >
            <div className="row mini-survey-header">{/* <span>Fix issues</span> */}</div>

            <div className="survey-container">
                <div className="survey-content-container mini-survey-content-container custom-scrollbar">
                    <SurveyPageGroup
                        groupId="SURVEY"
                        questionSets={questionSets}
                        pageId="SURVEY_QUESTION_SETS"
                        project={clonedProject}
                        saveChannel={EVENT_CHANNEL.EVENT_CMD_PROJECT_FORM_SURVEY_SAVE}
                        dependencyForQuestions={deps}
                        industryCanShowQuestions={industryCanShowQuestions}
                    />

                    <PageGroup groupId="SURVEY_SAVE">
                        <SurveySaveFixForm project={clonedProject} pageId="SAVE_FORM" />
                    </PageGroup>
                </div>
            </div>

            <div className="row mini-survey-control">
                <PageNumberStepper />
            </div>
        </FormWizard>
    );
};

const getProject = (editingProjectId) =>
    createSelector(orm, (session) => {
        const { [ORM_PROJECT_SLICE]: Project } = session;

        const project = Project.select(session, {
            include: [],
            filter: { deleted: false, [ID_ATTRIBUTE]: editingProjectId },
        })[0];

        if (!project) return null;

        return project;
    });

function mapORMStateToProps(state) {
    const workspace = SHARED_SELECTOR.EVERY_THING(state);

    const { selectedCollectionId, collectionReadOnly, editingSourceProjectId, editingProjectId, selectedProjectIds } =
        workspace;

    const clonedProject = getProject(editingProjectId)(state);

    return {
        collectionId: selectedCollectionId,
        collectionReadOnly: collectionReadOnly,
        editingSourceProjectId: editingSourceProjectId,
        editingProjectId: editingProjectId,
        selectedProjectIds: selectedProjectIds,
        clonedProject,
    };
}

export default connect(mapORMStateToProps)(MiniSurvey);
