import _ from 'lodash';
import { ERROR_CHANNEL } from '../../utils/constants';

const EventEmitter = {
    events: {},
    dispatch: function (event, data) {

        // console.log(this.events);

        if (!this.events[event]) return;

        this.events[event].forEach(callback => callback(data));
    },

    dispatchSeries: async function ({ events = [], errorController = {} } = {}) {

        // console.log(this.events);
        try {
            for (var i = 0; i < events.length; i++) {
                const { event, data } = events[i] ?? {};

                if (this.events[event]) {

                    for (var j = 0; j < this.events[event].length; j++) {
                        const sub = this.events[event][j];
                        await sub({
                            ...(data ?? {}),
                            ...({
                                errorController: (errorController ?? {})
                            })
                        });
                    }
                }
            }

            (errorController ?? {}).successChannel && this.dispatch((errorController ?? {}).successChannel, {
                type: 'success',
                data: null
            });

        } catch (error) {
            console.log(error);

            this.dispatch((errorController ?? {}).errorChannel ?? ERROR_CHANNEL.GENERAL, {
                message: error.message
            });
        }
    },

    subscribe: function (event, callback) {

        if (!this.events[event])
            this.events[event] = [];

        this.events[event].push(callback);
    },

    off: function (event, callback) {
        if (this.events[event]) {
            for (let i = 0; i < this.events[event].length; i++) {
                if (this.events[event][i] === callback) {
                    this.events[event].splice(i, 1);
                    i--;
                }
            }
        }
    },

    clear: function () {
        this.events = {};
    }
};


class EventHanlders {
    constructor() { this.events = {}; }

    addEvents(name, fn) {
        this.events[name] = fn;
    }

    addEventsViaDescriptor(arr) {
        (arr ?? []).forEach(desc => {
            this.addEvents(desc.event, desc.handler);
        });
    }

    addEventsIfNotExist(name, fn) {
        if (!this.events[name])
            this.events[name] = fn;
    }

    attachEvents() {
        _.keys(this.events ?? {}).forEach(eventName => {
            EventEmitter.subscribe(eventName, this.events[eventName]);
        });
    }

    detachEvents() {
        _.keys(this.events ?? {}).forEach(eventName => {
            EventEmitter.off(eventName, this.events[eventName]);
        });
    }
}

// window.eventobj = EventEmitter;

export { EventEmitter, EventHanlders };