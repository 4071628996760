import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "redux-orm";
import orm from "../../models/orm.register";
import {
    EVENT_CHANNEL,
    ERROR_CHANNEL,
    SUCCESS_CHANNEL,
    ID_ATTRIBUTE,
    DEFAULT_SELECTED_OPTION,
    ORM_PROJECT_SLICE,
    INDUSTRY,
} from "../../utils/constants";
import {
    FormWizard,
    PageGroup,
    ProjectForm,
    FundingForm,
    FormStatusController,
    schema,
    SurveyPageGroup,
} from "../FormWizard";
import { services, serviceWrapper } from "../../services";
import { SuccessNotifier } from "../Success";
import { EventEmitter } from "../EventEmitter";
import PropTypes from "prop-types";
import BaseORMModel from "../../models/BaseORMModel";
import _ from "lodash";
import MapStatusController from "../FormWizard/SurveyBlock";
import SoilTypeDetectionForm from "../FormWizard/SoilTypeDetectionForm";
import { useP2RAuth } from "../AuthContextProvider/authContext";

const { projectService } = services;

const ___AddProjectModal = ({
    project,
    sourceProjectId,
    collectionId,
    isNew,
    setModalClose,
    readOnly = false,
    isCopy,
}) => {
    const { profile } = useP2RAuth();

    const [canManuallySelectSoil, setCanManuallySelectSoil] = useState(
        (!!profile.selectedGroup.allowManualSoilSelection || (readOnly && project.soilClimateCodeId)) &&
            project.industry === "cane"
    );

    useEffect(() => {
        loadSurveyQuestionSets(project.industry);

        setCanManuallySelectSoil(
            (!!profile.selectedGroup.allowManualSoilSelection || (readOnly && project.soilClimateCodeId)) &&
                project.industry === "cane"
        );
    }, [project.industry]);

    const loadSurveyQuestionSets = async (industry) => {
        if (industry) {
            await serviceWrapper(
                {
                    successChannel: SUCCESS_CHANNEL.SURVEY_GET_INDUSTRY_SUCCESS,
                    errorChannel: ERROR_CHANNEL.SURVEY,
                },
                {
                    instance: projectService,
                    name: "getSurveyByType",
                    params: [industry],
                }
            );

            if (isNew && !isCopy && Object.keys(project.survey).length) {
                EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_CLEAR_SURVEY, {
                    payload: {
                        projectId: project[ID_ATTRIBUTE],
                    },
                });
            }
        }
    };

    const resetQuestions = (questions) => {
        if (questions.length === 0) {
            return;
        }

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_FORM_RESET_SURVEY_QUESTIONS, {
            payload: {
                data: {
                    projectId: project[ID_ATTRIBUTE],
                    industry: project.industry,
                    survey: {
                        ...project.survey,
                    },
                    questions: questions,
                },
            },
        });
    };

    const industryCanShowQuestions = (industry, survey, requiredQuestionId, requiredAnswer) => {
        switch (industry) {
            case INDUSTRY.GRAZING.key:
                return survey[`q${requiredAnswer}`] === true;
            default:
                return false;
        }
    };

    if (!project.industry) {
        return null;
    }

    return (
        <FormWizard
            pageGroupMap={{
                PROJECT: {},
                FUNDING: {},
                SURVEY: {},
                ...(canManuallySelectSoil
                    ? {
                          SOIL_TYPE_DECTION: {},
                      }
                    : {}),
                ...(isNew
                    ? {
                          MAP: {},
                      }
                    : {}),
            }}
            readOnly={readOnly}
            cancelMethod={setModalClose}
            order={[
                "PROJECT",
                "FUNDING",
                "SURVEY",
                canManuallySelectSoil ? "SOIL_TYPE_DECTION" : null,
                isNew ? "MAP" : null,
            ].filter((d) => d)}
            onSave={() => {
                // Potential race condition when project gets deleted before it can be saved...
                EventEmitter.dispatch(
                    isNew ? EVENT_CHANNEL.EVENT_CMD_PROJECT_SAVE_NEW : EVENT_CHANNEL.EVENT_CMD_PROJECT_SAVE,
                    {
                        payload: {
                            editingProject: project,
                            editingProjectId: project[ID_ATTRIBUTE],
                            editingSourceProjectId: sourceProjectId,
                            selectedCollectionId: collectionId,
                            isCopy: isCopy,
                        },
                    }
                );
                setModalClose && setModalClose();
            }}
        >
            <PageGroup project={project} groupId="PROJECT">
                <FormStatusController formData={project} pageId="DETAIL_FORM" schema={schema.project}>
                    <ProjectForm
                        editing={!isNew}
                        project={project}
                        saveChannel={EVENT_CHANNEL.EVENT_CMD_PROJECT_FORM_PROJECT_DETAIL_SAVE}
                    />
                </FormStatusController>
            </PageGroup>

            <PageGroup project={project} groupId="FUNDING">
                <FormStatusController
                    formData={project}
                    pageId="DETAIL_FORM"
                    schema={schema.funding}
                    isFormOptional={true}
                >
                    <FundingForm
                        project={project}
                        saveChannel={EVENT_CHANNEL.EVENT_CMD_PROJECT_FORM_FUNDING_DETAIL_SAVE}
                    />
                </FormStatusController>
            </PageGroup>

            <SuccessNotifier
                successChannel={SUCCESS_CHANNEL.SURVEY_GET_INDUSTRY_SUCCESS}
                errorChannel={ERROR_CHANNEL.SURVEY}
                startFn={() => {
                    return null;
                }}
                successFn={({ questions, deps }) => {
                    return (
                        <SurveyPageGroup
                            groupId="SURVEY"
                            questionSets={questions}
                            pageId="SURVEY_QUESTION_SETS"
                            project={project}
                            saveChannel={EVENT_CHANNEL.EVENT_CMD_PROJECT_FORM_SURVEY_SAVE}
                            dependencyForQuestions={deps}
                            nextInstruction={
                                canManuallySelectSoil
                                    ? "Custom soil and climate setup"
                                    : isNew
                                    ? "Geography setup"
                                    : null
                            }
                            industryCanShowQuestions={industryCanShowQuestions}
                            resetQuestions={resetQuestions}
                        />
                    );
                }}
                errorFn={(error) => {
                    return <div className="help-block">{error.message}</div>;
                }}
            />

            {canManuallySelectSoil && (
                <PageGroup project={project} groupId="SOIL_TYPE_DECTION">
                    <SoilTypeDetectionForm
                        project={project}
                        pageId="SOIL_TYPE_DECTION"
                        groupId="SOIL_TYPE_DECTION"
                        onClose={setModalClose}
                        saveChannel={EVENT_CHANNEL.EVENT_CMD_PROJECT_FORM_PROJECT_DETAIL_SAVE}
                        nextInstruction={isNew ? "Geography setup" : null}
                    />
                </PageGroup>
            )}

            {isNew && (
                <PageGroup groupId={"MAP"}>
                    <MapStatusController pageId={"BLOCK_EDITING"} project={project} setModalClose={setModalClose} />
                </PageGroup>
            )}
        </FormWizard>
    );
};

___AddProjectModal.propTypes = {
    project: PropTypes.object.isRequired,
    sourceProjectId: PropTypes.string.isRequired,
    collectionId: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    setModalClose: PropTypes.func, // From mapper.js
};

const __AddProjectModal = ({ project, sourceProjectId, collectionId, setModalClose, readOnly, isNew, isCopy }) => {
    if (!project || !collectionId || !sourceProjectId) return null;

    isNew = isNew ?? project[ID_ATTRIBUTE] === sourceProjectId;

    return (
        <___AddProjectModal
            project={project}
            collectionId={collectionId}
            sourceProjectId={sourceProjectId}
            isNew={isNew}
            isCopy={isCopy}
            setModalClose={setModalClose}
            readOnly={readOnly}
        />
    );
};

export const _AddProjectModal = React.memo(
    __AddProjectModal,
    (
        {
            project: prevProject,
            sourceProjectId: prevSourceProjectId,
            collectionId: prevCollectionId,
            readOnly: prevReadOnly,
        },
        {
            project: nextProject,
            sourceProjectId: nextSourceProjectId,
            collectionId: nextCollectionId,
            readOnly: nextReadOnly,
        }
    ) => {
        return (
            BaseORMModel.getVersionId(prevProject ?? {}) === BaseORMModel.getVersionId(nextProject ?? {}) &&
            prevSourceProjectId === nextSourceProjectId &&
            prevCollectionId === nextCollectionId &&
            prevReadOnly === nextReadOnly
        );
    }
);

const getEditingProject = (projectId) =>
    createSelector(orm, (session) => {
        const { [ORM_PROJECT_SLICE]: Project } = session;

        // many to many
        const project = Project.select(session, {
            filter: { [ID_ATTRIBUTE]: projectId },
        })[0];

        if (!project) return null;

        return project;
    });

const mapORMStateToProps = (state, ownProps) => {
    const { collectionId, projectId, sourceProjectId, readOnly } = ownProps;

    let project = getEditingProject(projectId)(state);

    if (!project) return null;

    const { fundingBody, fundingProgram } = project;

    // Restructure funding provider and program for funding detail
    const funding = {
        fundingBody: fundingBody ?? DEFAULT_SELECTED_OPTION,
        fundingProgram: fundingProgram ?? DEFAULT_SELECTED_OPTION,
    };

    project = _.omit(project, "fundingBody");
    project = _.omit(project, "fundingProgram");

    project.funding = funding;

    return {
        project,
        collectionId,
        sourceProjectId,
        readOnly,
    };
};

export default connect(mapORMStateToProps)(_AddProjectModal);
