import { services, serviceWrapper } from "../services";
import {
    ERROR_CHANNEL,
    SUCCESS_CHANNEL,
    CREATE_BATCH_ACTION,
    DELETE_ALL_ACTION,
    ORM_COLLECTION_SLICE,
    DELETE_ACTION,
    ORM_PROJECT_SLICE,
    ORM_BLOCK_SLICE,
    EVENT_CHANNEL,
    ORM_COLLECTION_EDITING_STATE_SLICE,
    UPDATE_ACTION,
    ORM_WORKSPACE_SLICE,
    WORKSPACE_ID,
    ID_ATTRIBUTE,
    UPDATE_BATCH_ACTION,
    ORM_SLICE,
} from "../utils/constants";
import { store } from "../redux";
import orm from "../models/orm.register";
import generateORMActionName from "../redux/reducers/orm.action.gen";
import { EventEmitter } from "../components/EventEmitter";
import _ from "lodash";
import { isCollectionReadOnly } from "../utils/collection.utils";

const { BlockBlobClient } = require("@azure/storage-blob");
const { groupService, collectionService, computationService } = services;

const collectionTitleEdited = async (event) => {
    const { payload, errorController } = event ?? {};
    const { title, oldTitle, [ID_ATTRIBUTE]: collectionId, updateEditingState = true } = payload ?? {};

    if (payload && payload[ID_ATTRIBUTE]) {
        // check if the new title is valid or not
        // we'll replace it with the old title
        const newTitle = title === "" ? oldTitle : title;

        let success = null;

        if (newTitle === oldTitle) {
            success = { ok: 1 };
        } else {
            success = await serviceWrapper(
                {
                    ...{
                        successChannel: SUCCESS_CHANNEL.COLLECTION_UPDATE_SUCCESS,
                    },
                    ...(errorController ?? {}),
                },
                {
                    instance: collectionService,
                    name: "updateCollection",
                    params: [collectionId, { title: newTitle }],
                }
            );
        }

        if ((success ?? {}).ok > 0) {
            store.dispatch({
                type: generateORMActionName({ slice: ORM_COLLECTION_SLICE, actionName: UPDATE_ACTION }),
                payload: {
                    [ID_ATTRIBUTE]: collectionId,
                    title: newTitle,
                },
            });
        }

        updateEditingState &&
            store.dispatch({
                type: generateORMActionName({ slice: ORM_COLLECTION_EDITING_STATE_SLICE, actionName: UPDATE_ACTION }),
                payload: {
                    [ID_ATTRIBUTE]: `${collectionId}-editing`,
                    collectionId,
                    editing: false,
                },
            });
    }
};

const collectionSelected = async (event) => {
    const { payload: collection, errorController } = event ?? {};

    if (collection) {
        store.dispatch({
            type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: DELETE_ALL_ACTION }),
            payload: {},
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: DELETE_ALL_ACTION }),
            payload: {},
        });

        const collectionReadOnly = isCollectionReadOnly(collection);

        store.dispatch({
            type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
                selectedCollectionId: collection[ID_ATTRIBUTE],
                selectedProjectIds: {},
                highlightedProjectIds: {},
                highlightedBlockIds: {},
                editingSourceProjectId: null,
                editingProjectId: null,
                newProjectId: null,

                collectionReadOnly,
            },
        });

        // TODO: loading projects and blocks

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
            payload: {
                progress: 0,
                message: "Loading",
                scale: 100,
                onClose: () => {
                    if (collection.requiresComputationCode) {
                        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_SHOW_COLLECTION_MIGRATION_MODAL, {
                            payload: {
                                collection: collection,
                            },
                        });
                    }
                },
            },
        });

        let _progress = 3;

        const dataLoadingContrib = 80;
        const projectBlockLoadingContrib = 10;
        const restLoadingContrib = 10;

        let collectionData = await serviceWrapper(
            {
                ...{
                    successChannel: null,
                },
                ...(errorController ?? {}),
            },
            {
                instance: collectionService,
                name: "getCollectionById",
                params: [
                    collection[ID_ATTRIBUTE],
                    (progress) => {
                        const { percentage = 0 } = progress ?? {};

                        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_REPORT_VIA_MODAL, {
                            payload: {
                                progress: (percentage / 100) * dataLoadingContrib,
                                message: `Loading`,
                                scale: 100,
                            },
                        });
                    },
                ],
            }
        );

        if (!collectionData) {
            return;
        }

        const projects = (collectionData.projects ?? []).filter(
            (project) => project.blocks && project.blocks.length > 0
        );

        const blocks = [];

        (projects ?? []).forEach((project) => {
            project.collectionId = collection[ID_ATTRIBUTE];

            const prBlocks = project.blocks ?? [];
            delete project.blocks;

            prBlocks.forEach((block) => {
                block.projectId = project[ID_ATTRIBUTE];
                blocks.push(block);
            });
        });

        // notify that starting to build the store
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_REPORT_VIA_MODAL, {
            payload: {
                progress: dataLoadingContrib,
                message: "Digesting",
                scale: 100,
            },
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: UPDATE_BATCH_ACTION }),
            payload: projects,
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: UPDATE_BATCH_ACTION }),
            payload: blocks,
        });

        // notify that redux is built
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_REPORT_VIA_MODAL, {
            payload: {
                progress: dataLoadingContrib + projectBlockLoadingContrib,
                message: "Drawing",
                scale: 100,
            },
        });

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_BLOCK_SET_EXTENT, {
            payload: {
                blockIds: (blocks ?? []).map((block) => block[ID_ATTRIBUTE]),
                projectIds: [],
            },
        });

        // notify that everything is finished
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_REPORT_VIA_MODAL, {
            payload: {
                progress: dataLoadingContrib + projectBlockLoadingContrib + restLoadingContrib,
                message: "Finished",
                scale: 100,
            },
        });
    }
};

const collectionDelete = async (event) => {
    const { payload: collection, errorController } = event ?? {};

    if (collection && collection[ID_ATTRIBUTE]) {
        const id = collection[ID_ATTRIBUTE];

        let success = await serviceWrapper(
            {
                ...{
                    successChannel: SUCCESS_CHANNEL.COLLECTION_DELETE_SUCCESS,
                },
                ...(errorController ?? {}),
            },
            {
                instance: collectionService,
                name: "deleteCollection",
                params: [id],
            }
        );

        // TODO: should do cascading deletion in here
        // delete workspace if it's referenced, editing, projects and blocks

        store.dispatch({
            type: generateORMActionName({ slice: ORM_COLLECTION_SLICE, actionName: DELETE_ACTION }),
            payload: collection,
        });
    }
};

const collectionCreate = async (event) => {
    const { payload, errorController } = event ?? {};
    const { group = {}, collection = {} } = payload ?? {};

    let newCollection = null;
    if (group && collection) {
        newCollection = await serviceWrapper(
            {
                ...{
                    successChannel: SUCCESS_CHANNEL.COLLECTION_CREATE_SUCCESS,
                },
                ...(errorController ?? {}),
            },
            {
                instance: collectionService,
                name: "createCollection",
                params: [group.id, collection],
            }
        );
    }

    if (newCollection) {
        EventEmitter.dispatchSeries({
            events: [
                {
                    event: EVENT_CHANNEL.EVENT_GROUP_SELECTED,
                    data: {
                        payload: group,
                    },
                },
                {
                    event: EVENT_CHANNEL.EVENT_COLLECTION_SELECTED,
                    data: {
                        payload: newCollection,
                    },
                },
            ],
            errorController,
        });
    }
    EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_FUNDING_LOADING, {});
};

const collectionLoading = async (event) => {
    const { payload } = event ?? {};

    const collection = await serviceWrapper(
        {
            successChannel: SUCCESS_CHANNEL.COLLECTION_GET_SUCCESS,
            errorChannel: ERROR_CHANNEL.COLLECTION_SELECTED,
        },
        {
            instance: collectionService,
            name: "getCollectionById",
            params: [payload.selectedCollectionId],
        }
    );

    if (collection) {
        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: CREATE_BATCH_ACTION }),
            payload: collection.projects,
        });
    }
};

const collectionUpload = async (event) => {
    const { payload } = event ?? {};
    const { file, blobDetails = {}, selectedColId, selectedGroupId } = payload;
    const controller = new AbortController();

    if (!file) throw new Error("No file to upload");

    const { blobName, url, startDate, endDate } = blobDetails;

    if (!url) throw new Error("No url to upload to");

    const blockBlobUrl = new BlockBlobClient(url);

    try {
        const uploadBlobResponse = await blockBlobUrl.uploadBrowserData(file, {
            blobHTTPHeaders: {
                blobContentType: file.type,
            },
            onProgress: (progressObject) => {
                const progress = parseFloat(((progressObject.loadedBytes / file.size) * 100).toFixed(2));

                EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
                    payload: {
                        progress,
                        message: `Uploading...`,
                        scale: 100,
                    },
                });
            },
            abortSignal: controller.signal,
        });

        const inspectedBlob = await serviceWrapper(
            {},
            {
                instance: collectionService,
                name: "inspectBlob",
                params: [
                    {
                        blobName: blobDetails.blobName,
                        extension: file.name.split(".").pop(),
                    },
                ],
            }
        );

        if (!inspectedBlob) throw new Error("Something went wrong during blob inspection");

        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COLLECTION_IMPORT_RESULT, {
            payload: {
                projects: inspectedBlob.projects ?? [],
                blobDetails,
                file: file,
                selectedColId: selectedColId ?? null,
                selectedGroupId,
            },
        });
    } catch (e) {
        throw e;
    }
};

const completeCollectionImport = async (event) => {
    const { payload } = event ?? {};
    const { uploadPayload, group } = payload;

    if (!uploadPayload || !group) throw new Error("Upload or group missing from payload");

    const tasksAndJobs = await serviceWrapper(
        {},
        {
            instance: collectionService,
            name: "completeBlobUpload",
            params: [uploadPayload],
        }
    );

    if (!tasksAndJobs) throw new Error("Couldn't start uploading jobs");

    const [projectAndBlocks, newCollection] = await serviceWrapper(
        {},
        {
            instance: computationService,
            name: "saveAndUpdateCollection",
            params: [tasksAndJobs],
        }
    );

    if (!newCollection) throw new Error("Couldn't finalise collection uploading");

    EventEmitter.dispatchSeries({
        events: [
            {
                event: EVENT_CHANNEL.EVENT_GROUP_SELECTED,
                data: {
                    payload: group,
                },
            },
            {
                event: EVENT_CHANNEL.EVENT_COLLECTION_SELECTED,
                data: {
                    payload: newCollection,
                },
            },
        ],
        // errorController
    });
};

const computeCollection = async (event) => {
    const { collectionId } = event.payload;

    const state = store.getState();
    const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

    const projects = session[ORM_PROJECT_SLICE].select(session, {
        include: [],
        filter: (project) => {
            console.log(project);
            return project.collectionId === collectionId;
        },
    });

    const projectIds = projects.map((project) => project[ID_ATTRIBUTE]);

    const blocks = session[ORM_BLOCK_SLICE].select(session, {
        include: [],
        filter: (block) => projectIds.includes(block.projectId),
    });

    await computationService.saveAndUpdateStore(blocks, projects);
};

const migrateCollection = async (event) => {
    const { collectionId, collectionReadOnly } = event.payload;

    await computeCollection(event);

    await serviceWrapper(
        {},
        {
            instance: collectionService,
            name: "updateCollection",
            params: [collectionId, { requiresComputationCode: null }],
        }
    );

    store.dispatch({
        type: generateORMActionName({ slice: ORM_COLLECTION_SLICE, actionName: UPDATE_ACTION }),
        payload: {
            [ID_ATTRIBUTE]: collectionId,
            requiresComputationCode: null,
        },
    });

    store.dispatch({
        type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
        payload: {
            [ID_ATTRIBUTE]: WORKSPACE_ID,
            collectionReadOnly: collectionReadOnly,
        },
    });
};

const collectionEventsDesc = [
    {
        event: EVENT_CHANNEL.EVENT_CMD_COLLECTION_TITLE_EDITING,
        handler: collectionTitleEdited,
    },
    {
        event: EVENT_CHANNEL.EVENT_COLLECTION_SELECTED,
        handler: collectionSelected,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_COLLECTION_API_DELETE,
        handler: collectionDelete,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_COLLECTION_API_CREATE,
        handler: collectionCreate,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_COLLECTION_LOADING,
        handler: collectionLoading,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_COLLECTION_UPLOAD,
        handler: collectionUpload,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_COMPLETE_COLLECTION_UPLOAD,
        handler: completeCollectionImport,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_COMPUTE_COLLECTION,
        handler: computeCollection,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_MIGRATE_COLLECTION,
        handler: migrateCollection,
    },
];

export default collectionEventsDesc;
