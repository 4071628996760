import { ORM } from 'redux-orm';
import ProjectORMModel from './ProjectORMModel';
import CollectionORMModel from './CollectionORMModel';
import BlockORMModel from './BlockORMModel';
import CollectionORMEditingModel from './CollectionORMEditingModel';
import FundingProgramORMModel from './FundingProgramORMModel';
import FundingBodyORMModel from './FundingBodyORMModel';
import WorkspaceORMModel from './WorkspaceORMModel';
import { ORM_SLICE } from '../utils/constants';

const orm = new ORM({
    stateSelector: state => {
        return state[ORM_SLICE];
    }
});

orm.register(CollectionORMModel, ProjectORMModel, BlockORMModel,
    CollectionORMEditingModel,
    FundingBodyORMModel,
    FundingProgramORMModel,
    WorkspaceORMModel);

export default orm;