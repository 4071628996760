/* 
    Warning: Cyclic import will occur if you attemp to import Routes and Scenes in the same file
*/
import Home from '../scenes/Home';
import Login from '../scenes/Login/Login';
import ManageAccount from '../scenes/ManageAccount/index';
import Register from '../scenes/Register/Register';
import ResetPassword from '../scenes/ResetPassword/ResetPassword';
import ConfirmResetPassword from '../scenes/ConfirmResetPassword/ConfirmResetPassword';
import About from '../scenes/About/About';
import { Config } from './config';
const Routes = Object.freeze({
    Home: {
        Route: "/home",
        Permissions: {},
        Scene: Home,
        authenticatedRoute: true
    },
    Login: {
        Route: "/login",
        Permissions: {},
        Scene: Login,
        isLogin: true,
        authenticatedRoute: false
    },
    ManageAccount: {
        Route: "/manage-account",
        Permissions: {},
        Scene: ManageAccount,
        authenticatedRoute: true
    },
    About: {
        Route: Config.PUBLIC_APP_URL,
        Permissions: {},
        Scene: About,
        authenticatedRoute: true
    },
    Logout: {
        Route: "/logout",
        authenticatedRoute: true
    },
    Register: {
        Route: "/register",
        Scene: Register,
    },
    ResetPassword: {
        Route: "/resetPassword",
        Scene: ResetPassword,
    },
    ConfirmResetPassword: {
        Route: '/confirmResetPassword',
        Scene: ConfirmResetPassword,
        showAlways: true
    },
    Default: {
        Route: "/home",
        Permissions: {},
        Scene: Home
    }
});


export default Routes; 