import React, { useEffect } from 'react';
import { EventEmitter } from '../../components/EventEmitter';
import { EVENT_CHANNEL } from '../../utils/constants';
import './manageaccount.scss';

// Entry point to open Manage Account modal inside src/components/modal/manageaccount.jsx
const ManageAccount = () => {

    useEffect(() => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_MANAGE_ACCOUNT_MODAL, {
            // payload
        });
    }, []);

    return null;
};

export default React.memo(ManageAccount);