const PRIVILEGE = {
    // can use the app, and manage group members
    CAN_BE_GROUP_ADMIN: 7,

    // can use the app, and manage group members (of same level or below)
    CAN_MANAGE_USERS: 1,

    // can only use the app
    CAN_USE: 6
};

const ROLES = {
    // do everything
    ROOT: "root",

    // can only manage groups, no data access
    // edit global metadata
    APP_ADMIN: "appAdmin",

    // everyone else...
    USER: "user"
};

/*
  Permission logic that helps the .... component decide to render
  the component passed in it's child
*/
const CAN_ADD_GROUP = "canAddGroup";
const CAN_EDIT_GROUP = "canEditGroup";
const CAN_INVITE_GROUP = "canInviteGroup";
const CAN_INVITE_AS = "canInviteAs";
const CAN_SEE_USER = "canSeeUser";
const CAN_REMOVE_USER = "canRemoveUser";
const CAN_ACCESS_FUNDING = "canAccessFunding";
const CAN_ACCESS_GROUPS = "canAccessGroups";
const CAN_ACCESS_RESET_PASSWORD = "canAccessResetPassword";
const CAN_USE_GROUP_DATA = 'canUseGroupData';

const permission = {
    [CAN_ADD_GROUP]: (role) => {
        return role == ROLES.APP_ADMIN || role == ROLES.ROOT;
    },
    [CAN_EDIT_GROUP]: (role, privilege) => {
        return role == ROLES.ROOT || role == ROLES.APP_ADMIN || privilege >= 7;
    },
    [CAN_INVITE_GROUP]: (role, privilege) => {
        if (role === ROLES.ROOT)
            return true;

        if (role === ROLES.APP_ADMIN)
            return true;

        if (role === ROLES.USER) {
            if (privilege % 2 === 1)
                return true;
        }

        return false;
    },
    [CAN_INVITE_AS]: (role, groupPrivilege, privilege) => {
        // allow inviters to invite anyone to the group as either
        // can use
        // or can use and manage users (cannot invite or remove group admins)
        if (role === ROLES.ROOT)
            return true;

        if (role === ROLES.APP_ADMIN)
            return true;

        if (role === ROLES.USER) {
            // if (privilege === 1) {
            //     return true;
            // }

            return privilege >= groupPrivilege;
        }

        return false;
    },
    [CAN_SEE_USER]: (role, userRole, userPrivilege, groupPrivilege) => {
        // current user is root, can't see user-mgt users who are roots
        if (role === ROLES.ROOT)
            return userPrivilege !== 1 || userPrivilege !== ROLES.ROOT;

        // current user is app-admin, can't see user-mgt users who are roots
        if (role === ROLES.APP_ADMIN) {
            return userPrivilege !== 1 || userPrivilege !== ROLES.ROOT;
        }


        if (role === ROLES.USER) {

            if (groupPrivilege === 1) {
                // can't see user-mgt users who are roots or app admin
                return userPrivilege !== 1
                    || (userRole !== ROLES.ROOT && userPrivilege !== ROLES.APP_ADMIN);
            }

            return userPrivilege !== 1;
        }

        return false;

    },
    [CAN_REMOVE_USER]: (myRole, theirRole, theirPrivilege, myPrivilege) => {
        if (myRole === ROLES.ROOT)
            return true;

        if (myRole === ROLES.APP_ADMIN) {

            if (theirPrivilege === 1 && (theirRole === ROLES.APP_ADMIN ||
                theirRole === ROLES.ROOT))
                return false;

            return true;
        }

        if (myRole === ROLES.USER) {
            // return groupPrivilege === 7 && (user = User)

            if (myPrivilege === 1) {
                return theirPrivilege !== 1;
            }

            // user,
            // is um
            // the other is can use or admin user, can remove
            // the other is um, root or admin, can't
            // the other is um, user, can't

            // is admin
            // the other is can use, can remove
            // the other is admin, can't remove
            // the other is um, can't remove

            return myPrivilege === 7 && theirPrivilege >= 6;
            // userPrivilege < groupPrivilege;
        }

        return false;
    },
    [CAN_ACCESS_FUNDING]: (role) => {
        return role == ROLES.ROOT || role == ROLES.APP_ADMIN;
    },
    [CAN_ACCESS_GROUPS]: (role) => {
        return true;
    },
    [CAN_ACCESS_RESET_PASSWORD]: (role) => {
        return true;
    },
    [CAN_USE_GROUP_DATA]: (role, groupPrivilege) => {
        return groupPrivilege >= 6;
    }
};

export {
    permission,
    CAN_ADD_GROUP,
    CAN_INVITE_GROUP,
    CAN_INVITE_AS,
    CAN_SEE_USER,
    CAN_EDIT_GROUP,
    CAN_ACCESS_FUNDING,
    CAN_ACCESS_GROUPS,
    CAN_ACCESS_RESET_PASSWORD,
    CAN_REMOVE_USER,
    PRIVILEGE,
    ROLES
};