import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';

import { EventEmitter, EventHanlders } from '../EventEmitter';
import { EVENT_CHANNEL } from '../../utils/constants';

import './notification.scss';
import 'react-toastify/dist/ReactToastify.min.css';

const Notification = ({ children }) => {
    return (
        <div className="notification">
            { children }
    
            <span className="close">
                <FontAwesomeIcon icon={faTimes} />
            </span>
        </div>
    );
};
export default Notification;

export const NotificationManager = () => {
    const [notifications, setNotifications] = useState([]);
    const [blockedNotifIds, setBlockedIds] = useState([]);
    const eventHandlers = useRef(new EventHanlders());

    function clearToastRef(toastId) {
        return () => setNotifications(notifications.filter(id => id !== toastId));
    }

    // useEffect(() => {
    //    console.log('n', notifications);
    //    eventHandlers.current.addEvents(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, async (payload) => {
    //        const { contents, id, shouldDismissCurrent = false } = payload;
    //        const opts = {
    //            toastId: id,
                // autoClose: false,
    //            closeOnClick: false,
    //            onClose: clearToastRef(id)
    //        };
    
    //        if (!notifications.includes(id)) {
    //            const id = toast(contents, opts);
    //            setNotifications([ ...notifications, id ]);
    //            return;
    //        }
    
    //        if (shouldDismissCurrent) {
    //            toast.dismiss(id);
    //            return;
    //        }

    //        toast.update(id, { ...opts, render: contents });
    //    });
    // }, [notifications]);

    // need to turn off the existing subscription, and reapply it when the state changes
    // otherwise it won't be able to pickup the new state values
    useEffect(() => {
        eventHandlers.current.addEvents(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, async (payload) => {
            const {
                contents,
                id,
                // should the manager dismiss any currently open notifications that match the passed id
                shouldDismissCurrent = false,
                // should the manager block any attempt at creating any new notifications that match
                // the passed id
                shouldBlockFutureThisId = false,
                type = '',
                autoClose = true,
            } = payload;
            const opts = {
                toastId: id,
                autoClose,
                closeOnClick: false,
                onClose: clearToastRef(id),
                className: 'notification ' + type
            };

            // if this id is in our blocked list
            // then don't create a notification for it...
            if (blockedNotifIds.includes(id)) {
                return;
            }

            // if we should block any future notifications under this id
            // then add the id to the state
            if (shouldBlockFutureThisId
                && !blockedNotifIds.includes(id)) {
                setBlockedIds([ ...blockedNotifIds, id ]);
            }

            // if the id doesn't exist in our state, then create a new notification
            // and add the id into the state
            if (!notifications.includes(id)) {
                const id = toast(contents, opts);
                setNotifications([ ...notifications, id ]);
                return;
            }

            // if we should dismiss the current notification
            // then we do so and remove it from the state
            if (shouldDismissCurrent) {
                toast.dismiss(id);
                setNotifications(notifications.filter(n => n !== id));
                return;
            }

            // update the current notification's contents
            toast.update(id, { ...opts, render: contents });
        });
        
        EventEmitter.subscribe(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, eventHandlers.current.events[EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION]);
        return () => EventEmitter.off(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, eventHandlers.current.events[EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION]);
    }, [notifications]);

    return (
        <ToastContainer
            limit={4}
            position="bottom-left"
            hideProgressBar={true}
            closeOnClick={false}
            draggable={false}
        />
    );
};