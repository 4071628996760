import React, { useRef, useEffect, useState } from 'react';
import { EventHanlders, EventEmitter } from '../EventEmitter';
import { EVENT_CHANNEL, SUCCESS_CHANNEL, ID_ATTRIBUTE, DEFAULT_SELECTED_OPTION } from '../../utils/constants';
import { MDBModal, MDBModalBody } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons/faTrashAlt";
import { createSelector } from 'redux-orm';
import orm from '../../models/orm.register';
import { connect } from 'react-redux';
import { SuccessNotifier } from '../Success';

import "./FundingSelector.scss";
import BaseORMModel from '../../models/BaseORMModel';

const selectFundingBody = ({ bodyId, fundingBodies, setFundingBody, setFundingProgram, force = false }) => {
    const currentFb = (fundingBodies ?? []).filter(body => body[ID_ATTRIBUTE] === bodyId)[0];
    if (currentFb) {
        setFundingBody(currentFb);
        force && setFundingProgram(null);
        return currentFb;
    }

    if (bodyId === DEFAULT_SELECTED_OPTION) {
        setFundingBody(null);
        setFundingProgram(null);
    }

    else {
        force && setFundingBody(undefined);
        force && setFundingProgram(undefined);
    }

    return null;
};

const selectFundingProgram = ({ programId, fundingBody, setFundingBody, setFundingProgram, force = false }) => {
    if (fundingBody) {
        const currentProgram = ((fundingBody).fundingPrograms ?? [])
            .filter(program => program[ID_ATTRIBUTE] === programId)[0];

        if (currentProgram) {
            setFundingProgram(currentProgram);
        } else {
            force && setFundingProgram(null);
        }

        return currentProgram;
    } else {
        force && setFundingProgram(undefined);
    }

    return null;
};

const FundingSelector = ({ fundingBodies, onFundingChanged,
    preFundingBodyFn = null, postFundingBodyFn = null,
    preFundingProgramFn = null, postFundingProgramFn = null,
    readOnly = false,
    selectedFundingBodyId,
    selectedFundingProgramId,
    className = null
} = {}) => {

    const [fundingBody, setFundingBody] = useState(undefined);
    const [fundingProgram, setFundingProgram] = useState(undefined);

    useEffect(() => {
        (fundingBody !== undefined || fundingProgram !== undefined) && onFundingChanged && onFundingChanged({
            fundingBody,
            fundingProgram
        });
    }, [fundingBody, fundingProgram]);

    useEffect(() => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_FUNDING_LOADING, {});
    }, []);

    // useEffect(() => {
    //     selectFundingBody({ bodyId: selectedFundingBodyId, fundingBodies, setFundingBody, setFundingProgram });
    // }, [selectedFundingBodyId]);

    // useEffect(() => {
    //     selectFundingProgram({ programId: selectedFundingProgramId, fundingBody, setFundingBody, setFundingProgram });
    // }, [selectedFundingProgramId]);

    useEffect(() => {
        let newBody = selectFundingBody({ bodyId: selectedFundingBodyId, fundingBodies, setFundingBody, setFundingProgram });
        let program = selectFundingProgram({ programId: selectedFundingProgramId, fundingBody: newBody, setFundingBody, setFundingProgram });
    }, [BaseORMModel.getVersionId(fundingBodies ?? [])]);

    const hasFundingBody = !fundingBody ? false : (fundingBodies ?? []).filter(body => body[ID_ATTRIBUTE] === fundingBody[ID_ATTRIBUTE]).length > 0;
    const hasFundingProgram = !fundingProgram ? false : (fundingBody.fundingPrograms ?? []).filter(program => program[ID_ATTRIBUTE] === fundingProgram[ID_ATTRIBUTE]).length > 0;

    return <>

        <div className={`funding-selector ${className ?? ''}`}>
            {
                preFundingBodyFn && preFundingBodyFn({
                    fundingBody, fundingProgram, fundingBodies
                })
            }

            <select className="browser-default custom-select"

                value={!fundingBody ? DEFAULT_SELECTED_OPTION : (!hasFundingBody ? DEFAULT_SELECTED_OPTION : fundingBody[ID_ATTRIBUTE])}

                onChange={(e) => {
                    const bodyId = (e.target ?? e.currentTarget).value;
                    selectFundingBody({ bodyId, fundingBodies, setFundingBody, setFundingProgram, force: true });
                }}

                disabled={readOnly}
            >
                <option value={DEFAULT_SELECTED_OPTION}>Choose your option</option>
                {
                    (fundingBodies ?? []).map((_fundingBody, index) => {
                        return <option key={index} value={_fundingBody[ID_ATTRIBUTE]}>
                            {
                                _fundingBody.fundingBody
                            }</option>;
                    })
                }
            </select>
            {
                postFundingBodyFn && postFundingBodyFn({
                    fundingBody, fundingProgram, fundingBodies
                })
            }

            {
                preFundingProgramFn && preFundingProgramFn({
                    fundingBody, fundingProgram, fundingBodies
                })
            }
            <select className="browser-default custom-select" value={!fundingProgram ? DEFAULT_SELECTED_OPTION :
                (!hasFundingProgram ? DEFAULT_SELECTED_OPTION : fundingProgram[ID_ATTRIBUTE])
            }

                onChange={(e) => {
                    const programId = (e.target ?? e.currentTarget).value;
                    selectFundingProgram({ programId, fundingBody, setFundingBody, setFundingProgram, force: true });
                }}

                disabled={readOnly}

            >
                <option
                    value={DEFAULT_SELECTED_OPTION}>Choose your option</option>
                {
                    fundingBody && (fundingBody.fundingPrograms ?? []).map((_fundingProgram, index) => {
                        return <option key={index} value={_fundingProgram[ID_ATTRIBUTE]}>{
                            _fundingProgram.projectReferenceNumber
                        }</option>;
                    })
                }
            </select>

            {
                postFundingProgramFn && postFundingProgramFn({
                    fundingBody, fundingProgram, fundingBodies
                })
            }
        </div>
    </>;
};

const getFundingData = createSelector(
    orm,
    session => {
        const { FundingBody } = session;

        // many to many
        const fundingBodies = FundingBody.select(session, {
            include: ['fundingPrograms'],
            filter: { deleted: false }
        });

        return fundingBodies;
    }
);


function mapORMStateToProps(state) {

    return {
        fundingBodies: getFundingData(state),
    };
}
export default connect(mapORMStateToProps)(FundingSelector);