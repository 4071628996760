import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { CREATE_ACTION, ORM_FUNDING_BODY_SLICE, ORM_FUNDING_PROGRAM_SLICE, MAX_FIELD_LENGTH, ID_ATTRIBUTE } from '../../utils/constants';
import { services, serviceWrapper } from '../../services';
import generateORMActionName from '../../redux/reducers/orm.action.gen';
import "./fundingsource.scss";

const { fundingService } = services;

const FundingProgramModal = ({ edit = undefined, fundingBodies = [], setModalClose }) => {
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();

    const onSubmit = async data => {
        const payload = {
            projectReferenceNumber: data.referenceNumber,
            projectTitle: data.projectTitle,
            proponentCodes: "",
        };
        const params = edit ? [edit.programId, payload] : [data.fundingProvider, payload];
        const fundingProgram = await serviceWrapper({ model: null }, {
            instance: fundingService,
            name: edit ? "updateFundingProgram" : "addFundingProgram",
            params: params
        });
        if (fundingProgram) {
            dispatch({
                type: generateORMActionName({ slice: ORM_FUNDING_PROGRAM_SLICE, actionName: CREATE_ACTION }),
                payload: {
                    ...fundingProgram,
                    fundingBody: edit ? edit.fundingBodyId : data.fundingProvider
                }
            });
            setModalClose && setModalClose();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label>Program title</label>
                <input name="projectTitle" placeholder="Add the name of the funding project" className="form-control mb-3" defaultValue={edit ? edit.projectTitle : ""} ref={register({
                    required: { value: true, message: "You must enter a name of the funding program/project" },
                    maxLength: { value: MAX_FIELD_LENGTH, message: "Project title can only consist of 100 characters" }
                })} />

                {errors.projectTitle &&
                    <div className="help-block with-errors">{errors.projectTitle.message}</div>}

                <label>Reference number</label>
                <input name="referenceNumber" className="form-control mb-3" placeholder="Add the project reference ID" defaultValue={edit ? edit.referenceNumber : ""} ref={register({
                    required: { value: true, message: "You must enter a project reference ID" },
                    maxLength: { value: MAX_FIELD_LENGTH, message: "Reference number can only consist of 100 characters" }
                })} />

                {errors.referenceNumber &&
                    <div className="help-block with-errors">{errors.referenceNumber.message}</div>}
                <label>Funding provider</label>
                <select
                    className="funding-source-select browser-default custom-select form-control"
                    placeholder="Add the project ID"
                    name="fundingProvider"
                    disabled={edit ? true : null}
                    defaultValue={edit ? edit.fundingBodyId : ""}
                    ref={register({
                        validate: {
                            required: value => value.trim() !== "" || "Please select a funding provider"
                        }
                    })}>
                    <option value="">Select a funding provider</option>
                    {(fundingBodies ?? []).map((data) =>
                        <option key={data[ID_ATTRIBUTE]} value={data[ID_ATTRIBUTE]}>{data.fundingBody}</option>
                    )}
                </select>

                {errors.fundingProvider &&
                    <div className="help-block with-errors">{errors.fundingProvider.message}</div>}
            </div>
            <div className="d-flex justify-content-center form-button-container">
                <button type="button" className="btn btn-default" onClick={(e) => setModalClose && setModalClose()}>Cancel</button>
                <button type="submit" className="btn btn-primary">Add program</button>
            </div>
        </form>
    );
};

export default FundingProgramModal;