import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CollectionAddProject from './CollectionAddProject';
import CollectionExport from './CollectionExport';
import { SHARED_SELECTOR } from '../../selectors/appSelector';

const Toolbar = (props) => {
    return (
        <div className="align-items-center d-flex flex-row">
            <CollectionAddProject {...props} />
            <CollectionExport {...props} />
        </div>
    );
};

function mapORMStateToProps(state) {
    const workspace = SHARED_SELECTOR.EVERY_THING(state);
    return {
        collectionId: workspace.selectedCollectionId,
        collectionReadOnly: workspace.collectionReadOnly
    };
}

Toolbar.propTypes = {
    collectionId: PropTypes.string,
    collectionReadOnly: PropTypes.bool
};

export default connect(mapORMStateToProps)(Toolbar);