import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { EventEmitter } from '../EventEmitter';
import { ID_ATTRIBUTE } from '../../utils/constants';
import { FundingSelector } from '../FundingSelector';
import { yupResolver } from '@hookform/resolvers';
import { useFormWizard } from './FormWizardContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import FormHelper from './helper';

const NON_DIGIT_REGEX = /[^0-9]/g;
const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0
});
const DAY_FORMAT = new Intl.NumberFormat();

const DEF_MODAL_CLOSE = () => { };

const FundingDetailForm = ({
    project,
    saveChannel,
    schema,
}) => {
    const { readOnly, cancelMethod } = useFormWizard();
    const { register, errors, control, trigger, getValues, setValue } = useForm({ resolver: yupResolver(schema) });


    /** 
        Utility function that updates the redux store to the saveChannel using the data from the form
        @param {Javascript Event} 
        @returns {void} 
    */
    const updateProject = async (e) => {
        await trigger(); // run form validation
        const { funding, organisationCash, organisationDays, landholderCash, landholderDays, ...restOfValues } = getValues();
        const santisedText = [organisationCash, organisationDays, landholderCash, landholderDays].map(
            item => Number(item.replace(NON_DIGIT_REGEX, ""))
        );
        saveChannel &&
            EventEmitter.dispatch(saveChannel, {
                payload: {
                    data: {
                        ...project,
                        ...restOfValues ?? {},
                        organisationCash: santisedText[0],
                        organisationDays: santisedText[1],
                        landholderCash: santisedText[2],
                        landholderDays: santisedText[3],
                        fundingProgram: funding.fundingProgram,
                        fundingBody: funding.fundingBody
                    },
                }
            });
    };

    /** 
        Utility function that sanitises the value using an Intl formatter
        @param {Intl.Formatter} 

        @returns {Function} (value)
    */
    const sanitiseNumber = (formatter) => value => {
        // Split once on decimal
        const numberParts = String(value).trim().split(".");
        const wholePartOfNumber = numberParts.shift();
        const fractionalPartOfNumber = numberParts.join(".");

        // Clean whole and fractional part of number
        const cleanWholePart = wholePartOfNumber.replace(NON_DIGIT_REGEX, '');
        const cleanFractionalPart = fractionalPartOfNumber.replace(NON_DIGIT_REGEX, '');

        // Join whole and fractional part to make a new clean number
        const cleanNumber = cleanWholePart + (cleanFractionalPart && "." + cleanFractionalPart);
        return formatter.format(cleanNumber);
    };

    return (
        <>
            <FontAwesomeIcon
                className="close-button"
                onClick={cancelMethod}
                size="1x"
                className={"ml-auto mr-1 modal-close"}
                icon={faTimesCircle}
            />
            <h1 className="text-center">Funding</h1>
            <form 
                className="funding-form"
                onSubmit={(e) => e.preventDefault()}
            >
                <h2>By delivery organisation</h2>
                <div className="row">
                    <div className="col-6 text-center">
                        <label>Cash</label>
                        <input
                            type="text"
                            placeholder="$0"
                            defaultValue={sanitiseNumber(CURRENCY_FORMAT)((project ?? {}).organisationCash ?? "")}
                            name="organisationCash"
                            className=""
                            ref={register({})}
                            onBlur={(e) => {
                                const number = sanitiseNumber(CURRENCY_FORMAT)(e.target.value);
                                setValue("organisationCash", number);
                                updateProject();
                            }}
                            disabled={readOnly}
                        />
                        {errors.organisationCash &&
                            <div className="help-block with-errors">{errors.organisationCash.message}</div>
                        }
                    </div>
                    <div className="col-6 text-center">
                        <label>Days</label>
                        <input
                            type="text"
                            name="organisationDays"
                            defaultValue={sanitiseNumber(DAY_FORMAT)((project ?? {}).organisationDays ?? "")}
                            className=""
                            placeholder="0"
                            ref={register({})}
                            onBlur={(e) => {
                                const number = sanitiseNumber(DAY_FORMAT)(e.target.value);
                                setValue("organisationDays", number);
                                updateProject();
                            }}
                            disabled={readOnly}
                        />
                        {errors.organisationDays &&
                            <div className="help-block with-errors">{errors.organisationDays.message}</div>
                        }
                    </div>
                </div>
                <h2>By landholder (in-kind)</h2>
                <div className="row">
                    <div className="col-6 text-center">
                        <label>Cash</label>
                        <input
                            type="text"
                            name="landholderCash"
                            defaultValue={sanitiseNumber(CURRENCY_FORMAT)((project ?? {}).landholderCash ?? "")}
                            className=""
                            ref={register({})}
                            onBlur={(e) => {
                                const number = sanitiseNumber(CURRENCY_FORMAT)(e.target.value);
                                setValue("landholderCash", number);
                                updateProject();
                            }}
                            disabled={readOnly}
                        />
                        {errors.landholderCash &&
                            <div className="help-block with-errors">{errors.landholderCash.message}</div>
                        }
                    </div>
                    <div className="col-6 text-center">
                        <label>Days</label>
                        <input
                            type="text"
                            name="landholderDays"
                            className=""
                            defaultValue={sanitiseNumber(DAY_FORMAT)((project ?? {}).landholderDays ?? "")}
                            ref={register({})}
                            onBlur={(e) => {
                                const number = sanitiseNumber(DAY_FORMAT)(e.target.value);
                                setValue("landholderDays", number);
                                updateProject();
                            }}
                            disabled={readOnly}
                        />
                        {errors.landholderDays &&
                            <div className="help-block with-errors">{errors.landholderDays.message}</div>
                        }
                    </div>
                </div>
                <Controller
                    control={control}
                    name="funding"
                    render={({ onChange, onBlur, value }) => (
                        <FundingSelector
                            onFundingChanged={({ fundingBody, fundingProgram }) => {
                                onChange({
                                    fundingBody: (fundingBody ?? {})[ID_ATTRIBUTE],
                                    fundingProgram: (fundingProgram ?? {})[ID_ATTRIBUTE]
                                });
                                updateProject();
                            }} preFundingBodyFn={() => {
                                return <h2 className="big-ex form-element">Funding provider</h2>;
                            }} preFundingProgramFn={() => {
                                return <h2 className="big-ex form-element">Funding program</h2>;
                            }}
                            selectedFundingBodyId={project.funding.fundingBody ?? "-1"} // -1 === "choose your option"
                            selectedFundingProgramId={project.funding.fundingProgram ?? "-1"}
                            readOnly={readOnly}
                        />
                    )}
                />
                {(errors.funding ?? {}).fundingBody &&
                    <div className="help-block with-errors">{errors.funding.fundingBody.message}</div>
                }

                {(errors.funding ?? {}).fundingProgram &&
                    <div className="help-block with-errors">{errors.funding.fundingProgram.message}</div>
                }
            </form>
            <FormHelper nextText="Fill out project survey"/>
        </>
    );
};

export default React.memo(FundingDetailForm); 