import { HttpMethods, ServerType, Url } from '../utils/constants';
import { UrlBuilder, https, httpsWithProgress, AppResponseError } from '../api';
import BaseService from './base.service';

class CollectionService extends BaseService {
    constructor(props) {
        super(props);
    }

    async createCollection(groupId, data) {
        let url = UrlBuilder(ServerType.Data, Url.GetCollectionsMetadataByGroupIdPath, [groupId]);

        // console.log(url);
        // console.log(data);
        // return data;
        // throw new Error("aaa");
        return await https({ url, method: HttpMethods.Post, data: data ?? {} });
    }

    async getCollectionById(id, onProgress = () => { }) {
        let url = UrlBuilder(ServerType.Data, Url.GetCollectionsById, [id]);

        // console.log(url);
        // console.log(data);
        // return data;
        // throw new Error("aaa");
        return await httpsWithProgress({
            url, method: HttpMethods.Get, data: {},
            onProgress,
            userRequest: true
        });
    }


    async updateCollection(id, data) {
        let url = UrlBuilder(ServerType.Data, Url.GetCollectionsById, [id]);
        return await https({ url, method: HttpMethods.Put, data: data ?? {} });
    }

    async deleteCollection(id) {
        // return {
        //     _id: id
        // };

        let url = UrlBuilder(ServerType.Data, Url.GetCollectionsById, [id]);
        return await https({ url, method: HttpMethods.Delete, data: {} });
    }

    async getMask() {

        let url = UrlBuilder(ServerType.Data, Url.GetMask, []);
        return await https({ url, method: HttpMethods.Get, data: {} });
    }

    // async getCollectionById(id) {
    //   let url = UrlBuilder(ServerType.Data, Url.CollectionById, [id]);
    //   return await https({ url, method: HttpMethods.Get });
    //};

    async copyCollection(collectionId, groupId) {
        const url = UrlBuilder(ServerType.Data, Url.CopyCollectionByGroupIdPath, [groupId, collectionId]);
        return await https({ url, method: HttpMethods.Post, data: {} });
    }

    async submitCollection(id, data) {
        const url = UrlBuilder(ServerType.Data, Url.SubmitCollection, [id]);
        return await https({ url, method: HttpMethods.Post, data });
    }

    async getBlobDetails() {
        const url = UrlBuilder(ServerType.Data, Url.Connector, []);
        return await https({ url, method: HttpMethods.Get });
    }

    async inspectBlob(data) {
        const url = UrlBuilder(ServerType.Data, Url.Inspect, []);
        return await https({ url, method: HttpMethods.Post, data });
    }

    async completeBlobUpload(data) {
        const url = UrlBuilder(ServerType.Data, Url.Complete, []);
        return await https({ url, method: HttpMethods.Post, data });
    }
}

export default CollectionService;