import React from "react";
import { EventEmitter } from "../EventEmitter";
import { Checkbox } from "../CheckboxOrRadio";
import "./grazingPracticePicker.scss";
import { GRAZING_PRACTICE_FLAG_QUESTION_ID } from "../../utils/constants";

const PickerCheckbox = (props) => {
    const { id, label, checked, questionId, onChange } = props;

    return (
        <div>
            <Checkbox
                id={id}
                name={id}
                className="app-checkbox"
                checked={checked}
                onChange={(e) => onChange(questionId, e.target.checked)}
            />
            <label className="ml-2" htmlFor={id}>
                {label}
            </label>
        </div>
    );
};

/**
 * Grazing industry surveys are dynamic. A project can be a set of different practices
 *
 * - Pasture Management
 * - StreambanK Management
 * - Gullies Management
 *
 * In order to achieve this, users must be able to pick which practice the project will
 * target so that we can adjust which questions are shown on the UI.
 *
 * This component is used to pick thoses practices.
 * When a practice is selected we flag the survey with a particular ID that is used to determine
 * which practice are selected in various area (frontend, API, etc..)
 */
const GrazingPracticePicker = (props) => {
    const { project, saveChannel } = props;

    const toggleQuestionSurvey = (question, value) => {
        EventEmitter.dispatch(saveChannel, {
            payload: {
                data: {
                    ...project,
                    survey: {
                        ...project.survey,
                        [question]: value,
                    },
                },
                localOnly: true,
            },
        });
    };

    return (
        <div className="grazing-practice-picker">
            <h2>Please select the pratice(s) associated with this project</h2>

            <div className="picker-container">
                <PickerCheckbox
                    id="pasture"
                    label="Pasture"
                    questionId={GRAZING_PRACTICE_FLAG_QUESTION_ID.Q0_1}
                    checked={project.survey[GRAZING_PRACTICE_FLAG_QUESTION_ID.Q0_1] === true}
                    onChange={toggleQuestionSurvey}
                />

                <PickerCheckbox
                    id="streambank"
                    label="Streambank"
                    questionId={GRAZING_PRACTICE_FLAG_QUESTION_ID.Q0_2}
                    checked={project.survey[GRAZING_PRACTICE_FLAG_QUESTION_ID.Q0_2] === true}
                    onChange={toggleQuestionSurvey}
                />

                <PickerCheckbox
                    id="gullies"
                    label="Gullies"
                    questionId={GRAZING_PRACTICE_FLAG_QUESTION_ID.Q0_3}
                    checked={project.survey[GRAZING_PRACTICE_FLAG_QUESTION_ID.Q0_3] === true}
                    onChange={toggleQuestionSurvey}
                />
            </div>
        </div>
    );
};

export default GrazingPracticePicker;
