const SUCCESS_CHANNEL = {
    COLLECTION_GET_ALL_SUCCESS: "COLLECTION_GET_ALL_SUCCESS",
    COLLECTION_DELETE_SUCCESS: "COLLECTION_DELETE_SUCCESS",
    COLLECTION_CREATE_SUCCESS: "COLLECTION_CREATE_SUCCESS",
    COLLECTION_UPDATE_SUCCESS: "COLLECTION_UPDATE_SUCCESS",
    COLLECTION_GET_SUCCESS: "COLLECTION_GET_SUCCESS",

    // Auth
    USER_RESET_PASS_BY_EMAIL_SUCCESS: "USER_RESET_PASS_BY_EMAIL_SUCCESS",
    USER_CONFIRM_RESET_PASSWORD: "USER_CONFIRM_RESET_PASSWORD",
    LOGIN: "LOGIN",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",

    PROJECT_SAVE_SUCCESS: "PROJECT_SAVE_SUCCESS",

    // Survey
    SURVEY_GET_INDUSTRY_SUCCESS: "SURVEY_GET_INDUSTRY_SUCCESS",

    // groups
    INVITE_GROUP_USER_SUCCESS: 'INVITE_GROUP_USER_SUCCESS',

    PROJECT_GET_SOIL_CLIMATE_CODE: "PROJECT_GET_SOIL_CLIMATE_CODE" 
};

export default SUCCESS_CHANNEL;