import { services, serviceWrapper } from "../services";
import { SUCCESS_CHANNEL, EVENT_CHANNEL, ERROR_CHANNEL } from "../utils/constants";

const { userService } = services;

const resetPassWithEmail = async (event) => {
    const { payload, getPublicToken } = event;

    await getPublicToken();
    await serviceWrapper(
        {
            successChannel: SUCCESS_CHANNEL.USER_RESET_PASS_BY_EMAIL_SUCCESS,
            errorChannel: ERROR_CHANNEL.RESET_PASSWORD_BY_EMAIL,
        },
        {
            instance: userService,
            name: "resetPassWithEmail",
            params: [payload.email],
        }
    );
};

const confirmResetPass = async (event) => {
    const { payload, getPublicToken, setUserToken } = event;

    await getPublicToken();

    await serviceWrapper(
        {
            errorChannel: ERROR_CHANNEL.CONFIRM_RESET_PASSWORD,
        },
        {
            instance: userService,
            name: "confirmResetPassword",
            params: [payload],
        }
    );

    setUserToken(null);

    const loginResult = await serviceWrapper(
        {
            successChannel: SUCCESS_CHANNEL.USER_CONFIRM_RESET_PASSWORD,
            errorChannel: ERROR_CHANNEL.CONFIRM_RESET_PASSWORD,
        },
        {
            instance: userService,
            name: "authenticateUser",
            params: [
                {
                    username: payload.username,
                    password: payload.password,
                },
            ],
        }
    );

    if (loginResult) {
        setUserToken(loginResult.token);
    }
};

const userEventDesc = [
    {
        event: EVENT_CHANNEL.EVENT_CMD_RESET_PASS_WITH_EMAIL,
        handler: resetPassWithEmail,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_CONFIRM_PASS_RESET,
        handler: confirmResetPass,
    },
];

export default userEventDesc;
