import { ID_ATTRIBUTE } from "../utils/constants";

export default class UserGroup {
    constructor({ id, name, _v, privilege,
        dateCreated, dateUpdated, users = [] }) {

        this.id = id;
        this.name = name;
        this.privilege = privilege;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
        this.users = users ?? [];
        this._v = _v;
    }

    static deserialise({ [ID_ATTRIBUTE]: groupId, name, _v, privilege, dateCreated, dateUpdated }) {

        return new UserGroup({
            id: groupId,
            name,
            _v,
            privilege,
            dateCreated,
            dateUpdated
        });
    }

    toReduxStore() {
        return {
            id: this.id,
            name: this.name,
            _v: this._v,
            privilege: this.privilege,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated
        };
    }

    static fromReduxStore({ id, name, _v, privilege, dateCreated, dateUpdated }) {

        return new UserGroup({ id, name, _v, privilege, dateCreated, dateUpdated });
    }
}