/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";

export const MapEventContext = React.createContext();
export const useMapEvent = () => useContext(MapEventContext);

export const MapEventContextProvider = ({ ...props }) => {

    return (
        <MapEventContext.Provider
            value={props.value ?? {}}
        >
            {props.children}
        </MapEventContext.Provider>
    );
};
