export const ACTIONS = {};

export const UPDATE_ACTION = "UPDATE_ACTION";
export const CREATE_ACTION = "CREATE_ACTION";
export const DELETE_ACTION = "DELETE_ACTION";
export const DELETE_ADVANCE_ACTION = "DELETE_ADVANCE_ACTION";
export const DELETE_SOFT_ACTION = "DELETE_SOFT_ACTION";

export const CREATE_BATCH_ACTION = "CREATE_BATCH_ACTION";
export const UPDATE_BATCH_ACTION = "UPDATE_BATCH_ACTION";
export const DELETE_BATCH_ACTION = "DELETE_BATCH_ACTION";
export const DELETE_SOFT_BATCH_ACTION = "DELETE_SOFT_BATCH_ACTION";
export const UPDATE_RELATION_ACTION = "UPDATE_RELATION_ACTION";

export const DELETE_ALL_ACTION = "DELETE_ALL_ACTION";
export const DELETE_SOFT_ALL_ACTION = "DELETE_SOFT_ALL_ACTION";