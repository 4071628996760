import { EventHanlders } from '../components/EventEmitter';
import groupEventsDesc from './group.event.register';
import collectionEventsDesc from './collection.event.register';
import fundingEventsDesc from './funding.event.register';
import userEventDesc from './user.event.register';
import projectEventsDesc from './project.event.register';
import blockEventsDesc from './block.event.register';

const appEventWatcher = new EventHanlders();

appEventWatcher.addEventsViaDescriptor(groupEventsDesc);
appEventWatcher.addEventsViaDescriptor(collectionEventsDesc);
appEventWatcher.addEventsViaDescriptor(fundingEventsDesc);
appEventWatcher.addEventsViaDescriptor(userEventDesc);
appEventWatcher.addEventsViaDescriptor(projectEventsDesc);
appEventWatcher.addEventsViaDescriptor(blockEventsDesc);

export default appEventWatcher;