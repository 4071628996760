import React, { useEffect, useMemo, useState, useRef } from "react";
import { faUsersMedical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EVENT_CHANNEL, ID_ATTRIBUTE } from "../../utils/constants";
import { services, serviceWrapper } from "../../services";
import CanPeformAction from "../../components/CanPerformAction";
import { EventEmitter, EventHanlders } from "../../components/EventEmitter";
import { useP2RAuth } from "../../components/AuthContextProvider/authContext";
import GroupUserManagement from "./GroupUserManagement";
import { CAN_ADD_GROUP } from "../../utils/permission";
import GroupTable from "../../components/Table/GroupTable";
import "./groups.scss";

const Groups = () => {
    const { profile } = useP2RAuth();
    const { groupService } = services;
    const [groups, setGroups] = useState(null);
    const eventHandlers = useRef(new EventHanlders());

    const getUserData = async () => {
        if (profile.id) {
            const groupData = await serviceWrapper(
                { model: null },
                {
                    instance: groupService,
                    name: "getGroupsByUserId",
                    params: [profile.id],
                }
            );

            setGroups(groupData);
        }
    };

    const addUserToGroup = ({ groupId, userId, privilege }) => {
        const next = groups.map((group) => {
            if (group[ID_ATTRIBUTE] !== groupId) {
                return group;
            }

            return {
                ...group,
                users_info: [...group.users_info, { [ID_ATTRIBUTE]: userId, privilege: privilege }],
            };
        });

        setGroups(next);
    };

    const removeUserFromGroup = ({ groupId, userId }) => {
        const next = groups.map((group) => {
            if (group[ID_ATTRIBUTE] !== groupId) {
                return group;
            }

            return {
                ...group,
                users_info: group.users_info.filter((ui) => ui[ID_ATTRIBUTE] !== userId),
            };
        });

        setGroups(next);
    };

    const tableData = useMemo(() => {
        if (!groups) return [];

        return Object.keys(groups).map((groupId) => {
            const currentGroup = groups[groupId];

            // Filter current user from group memberships to receive privilege
            const user = currentGroup.users_info.find((userInfo) => userInfo[ID_ATTRIBUTE] === profile.id);

            return {
                [ID_ATTRIBUTE]: currentGroup[ID_ATTRIBUTE],
                name: currentGroup.name,
                allowManualSoilSelection: currentGroup.allowManualSoilSelection,
                members: currentGroup.users_info.length,
                privilege: user ? user.privilege : undefined,
            };
        });
    }, [groups, profile.id]);

    useEffect(() => {
        getUserData();
    }, [profile.id]);

    useEffect(() => {
        eventHandlers.current.addEvents(EVENT_CHANNEL.EVENT_GROUP_UPDATED, () => {
            getUserData();
        });

        EventEmitter.subscribe(
            EVENT_CHANNEL.EVENT_GROUP_UPDATED,
            eventHandlers.current.events[EVENT_CHANNEL.EVENT_GROUP_UPDATED]
        );

        return () => {
            EventEmitter.off(
                EVENT_CHANNEL.EVENT_GROUP_UPDATED,
                eventHandlers.current.events[EVENT_CHANNEL.EVENT_GROUP_UPDATED]
            );
        };
    }, []);

    if (!profile.username && !tableData) {
        return <p>Loading...</p>;
    }

    const openCreateGroupModal = () => {
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_CREATE_GROUP_MODAL, {});
    };

    return (
        <div className="groups-tab tab-content">
            <h2 className="group-title">All groups</h2>
            <CanPeformAction action={CAN_ADD_GROUP}>
                <button className="btn btn-primary create-group-btn" type="button" onClick={openCreateGroupModal}>
                    Create a group&nbsp;
                    <FontAwesomeIcon className="create-group-icon" icon={faUsersMedical} />
                </button>
            </CanPeformAction>
            <div>
                <GroupTable
                    data={tableData}
                    renderRowSubComponent={(row) => (
                        <GroupUserManagement
                            group={row.original}
                            currentUser={profile}
                            currUserPrivilegeForGroup={row.original.privilege}
                            onUserRemoved={removeUserFromGroup}
                            onUserAdded={addUserToGroup}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default Groups;
