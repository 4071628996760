import { HttpMethods, ServerType, Url } from '../utils/constants';
import { UrlBuilder, https } from '../api';
import BaseService from './base.service';

class GroupService extends BaseService {
    constructor(props) {
        super(props);
    }

    /**
     * Service method that gets the collection of a particular group
     * @param {String} id - Id of the group
     * @returns {Array} collections 
     */
    async getCollectionsByGroupId(id) {
        const url = UrlBuilder(ServerType.Data, Url.GetCollectionsMetadataByGroupIdPath, [id]);
        return await https({ url, method: HttpMethods.Get });
    }

    /**
     * Service method that gets all the groups belonging to a particular user
     * @param {String} id - Id of the user
     * @returns {Array} groups
     */
    async getGroupsByUserId(id) {
        let url = UrlBuilder(ServerType.Data, Url.GetGroupsByUserIdPath, [id]);
        const groups = await https({ url, method: HttpMethods.Get });
        return groups;
    }

    /**
     * Service method that creates a new group
     * @param newGroupData - object containing information of the new group
     * @param newGroupData.name - name of the new group
     * @returns dateCreated: "2020-07-01T05:44:33.010Z"
                dateUpdated: "2020-07-01T05:44:33.010Z"
                name: "New group Test"
                users_info: []
                __v: 0
                _id: "5efc22c12fe5f11fe0b3cd30"
     */
    async addGroup(newGroupData) {
        console.log(newGroupData);
        let url = UrlBuilder(ServerType.Data, Url.Group);
        const newGroup = await https({ url, method: HttpMethods.Post, data: newGroupData });
        return newGroup;
    }

    /**
     * Service method that sends an invite to a user for a particular group
     * @param userInviteData - Object containing information of user that is being invited to a particular group
     * @param userInviteData.group - Object containing group information
     * @param userInviteData.group._id - The id of the group
     * @param userInviteData.invitee - Object containing information of the invitee
     * @param userInviteData.invitee.email - The email of the invitee
     * @param userInviteData.invitee.privilege - The allowed permission of the invitee for the particular group 
     * @returns dateCreated: "2020-07-01T05:49:41.870Z"
                dateUpdated: "2020-07-01T05:49:41.870Z"
                name: "New Group Test 4"
                users: [{_id: "5abce13fb1e6bd504422412a", privilege: 1, username: "admin@truii.com", firstname: "Admin",…},…]
                __v: 0
                _id: "5efc23f52fe5f11fe0b3cd32"
     */
    async addUserToGroup(userInviteData) {
        let url = UrlBuilder(ServerType.Authentication, Url.GroupInvite);
        const newGroup = await https({ url, method: HttpMethods.Put, data: userInviteData });
        return newGroup;
    }

    /**
     * Service method that updates a particular group's metadata, i.e. name
     * @param {*} updateGroupDetail 
     * @param {*} id - The id of the group
     */
    async updateGroupDetailById(updateGroupDetail, id) {
        let url = UrlBuilder(ServerType.Data, Url.PutGroupDetailById, [id]);
        const group = await https({ url, method: HttpMethods.Put, data: updateGroupDetail });
        return group;
    }

    /**
     * Service method that grabs the detail of a particular group.
     * @param {*} id - The id of the group
     */
    async getGroupDetailByGroupId(id) {
        let url = UrlBuilder(ServerType.Authentication, Url.GroupDetailById, [id]);
        const group = await https({ url, method: HttpMethods.Get });
        return group;
    }

    /**
     * 
     * @param {*} id 
     */
    async removeUserFromGroup(data) {
        let url = UrlBuilder(ServerType.Authentication, Url.RemoveUserFromGroup);
        const group = await https({ url, method: HttpMethods.Put, data });
        return group;
    }

    /**
     * Service method that gets all the groups that the specified group submits to
     * @param {*} groupId group id
     */
    async getGroupReceiversByGroupId(groupId) {
        const url = UrlBuilder(ServerType.Data, Url.GetGroupReceiversByGroupId, [groupId]);
        return await https({ url, method: HttpMethods.Get });
    }

    /**
     * Service method that adds parent groups to a group - name and id
     * @param {*} receivers - array of group ids 
     * @param {*} id - The id of the source group
     */
    async addReceiversToGroup(id, receivers) {
        let url = UrlBuilder(ServerType.Data, Url.AddReceiverstoGroup, [id]);
        return await https({ url, method: HttpMethods.Post, data: { receivers: receivers ?? [] } });
    }

    /**
     * Service method that fetches all current parent groups
     * @param {*} id - The id of the source group
     */
    async GetGroupReceiversByGroupId(id) {
        let url = UrlBuilder(ServerType.Data, Url.GetGroupReceiversByGroupId, [id]);
        return await https({ url, method: HttpMethods.Get });
    }
}

export default GroupService;