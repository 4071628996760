import React, { useEffect } from 'react';
import { PAGE_STATUSES } from '../../utils/constants';
import { useFormWizard } from './FormWizardContext';
import './FormWizard';

const FormStepper = ({ stepNumber, status, setCurrentStep, pageGroupName, pageGroupIndex, currentStep }) => {
    // TODO need to disable all steppers if current step form is incomplete.
    const { pageGroup: currentPageGroup, index: currentPageGroupIndex } = currentStep;
    const currentPage = currentPageGroup === pageGroupName && currentPageGroupIndex === pageGroupIndex;
    const pageCompleted = status === PAGE_STATUSES.COMPLETE;

    return (
        <button className={`btn btn-default ${pageCompleted ? "btn btn-primary" : ""} form-step-btn ${currentPage ? "form-stepper-current" : ""}`}
            onClick={(e) => {
                // Update currentPage
                setCurrentStep && setCurrentStep({
                    pageGroup: pageGroupName,
                    index: pageGroupIndex
                });
            }}
        // disabled={currentPageMap.status === PAGE_STATUSES.INCOMPLETE && currentPageMap.status !== PAGE_STATUSES.OPTIONAL}
        >
            {stepNumber}
        </button>
    );
};

function areEquals(prevProps, nextProps) {
    const { status: prevStatus, stepNumber: prevStepNumber, setCurrentStep: prevSetCS, pageGroupName: prevPageGroupName, pageGroupIndex: prevPageGroupIndex, currentStep: prevCurrentStep } = prevProps;
    const { status: nextStatus, stepNumber: nextStepNumber, setCurrentStep: nextSetCS, pageGroupName: nextPageGroupName, pageGroupIndex: nextPageGroupIndex, currentStep: nextCurrentStep } = nextProps;
    const prevCurrentPage = prevCurrentStep.pageGroup === prevPageGroupName && prevCurrentStep.index === prevPageGroupIndex;
    const nextCurrentPage = nextCurrentStep.pageGroup === nextPageGroupName && nextCurrentStep.index === nextPageGroupIndex;

    return prevStatus === nextStatus &&
        prevStepNumber === nextStepNumber &&
        prevSetCS === nextSetCS &&
        prevPageGroupName === nextPageGroupName &&
        prevCurrentPage === nextCurrentPage;
}

export default React.memo(FormStepper, areEquals);