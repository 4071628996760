import React, { useState } from "react";
import { useFormWizard } from "./FormWizardContext";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormHelper from "./helper";
import { Radio } from "../CheckboxOrRadio";
import { EventEmitter } from "../EventEmitter";
import FormStatusController from "./FormStatusController";
import * as yup from "yup";
import { ID_ATTRIBUTE } from "../../utils/constants";
import SoilTypeSelector from "./SoilTypeSelector";

const defaultSoilClimateCode = {
    type: "automatic",
    code: "",
    regionNodeId: null,
    catchmentNodeId: null,
    climateNodeId: null,
    soilNodeId: null,
};

const schema = yup.object().shape({
    type: yup.string().oneOf(["automatic", "manual"]).required(),
    code: yup.string().when("type", {
        is: "manual",
        then: yup.string().required(),
        otherwise: yup.string().optional(),
    }),
    regionNodeId: yup.string().nullable().optional(),
    catchmentNodeId: yup.string().nullable().optional(),
    climateNodeId: yup.string().nullable().optional(),
    soilNodeId: yup.string().nullable().optional(),
});

const SoilTypeDetectionForm = (props) => {
    const { readOnly } = useFormWizard();

    const {
        project,
        groupId,
        pageId,
        onClose,
        displayForm,
        saveChannel,
        nextInstruction
    } = props;

    const [detectionType, setDetectionType] = useState(
        (project.soilClimateCode && {
            [ID_ATTRIBUTE]: null,
            ...project.soilClimateCode,
            type: "manual",
        }) ?? defaultSoilClimateCode
    );

    const updateProject = (type) => {
        EventEmitter.dispatch(saveChannel, {
            payload: {
                data: {
                    ...project,
                    soilClimateCode: type.code.length > 0 ? { ...type } : null,
                },
            },
        });
    };

    return (
        <FormStatusController
            formData={detectionType}
            schema={schema}
            groupId={groupId}
            pageId={pageId}
            displayForm={displayForm}
        >
            <>
                <FontAwesomeIcon
                    className="close-button"
                    onClick={onClose}
                    size="1x"
                    className="ml-auto mr-1 modal-close"
                    icon={faTimesCircle}
                />

                <h1 className="text-center">
                    {`${project.project_title
                            ? project.project_title
                            : "No Name"
                        } - Soil`}
                </h1>

                <div className="question-set-container">
                    <div className="font-weight-bold">
                        Soil type for project
                    </div>

                    <div className="question-container">
                        <div className="d-flex">
                            <div className="radio-container">
                                <div className="custom-control custom-radio">
                                    <Radio
                                        className="custom-control-input"
                                        name="automatic"
                                        id="automatic"
                                        value="automatic"
                                        onChange={() => {
                                            const next = {
                                                ...defaultSoilClimateCode,
                                            };

                                            setDetectionType(next);

                                            updateProject(next);
                                        }}
                                        checked={
                                            detectionType.type === "automatic"
                                        }
                                        disabled={readOnly}
                                    />

                                    <label
                                        className="orange-check-form custom-control-label"
                                        htmlFor="automatic"
                                    />
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                Automatically determine soil type from soils
                                layer
                            </div>
                        </div>

                        <div className="d-flex">
                            <div className="radio-container">
                                <div className="custom-control custom-radio">
                                    <Radio
                                        className="custom-control-input"
                                        name="manual"
                                        id="manual"
                                        value="manual"
                                        onChange={() => {
                                            const next = {
                                                ...detectionType,
                                                type: "manual",
                                            };

                                            setDetectionType(next);

                                            updateProject(next);
                                        }}
                                        checked={
                                            detectionType.type === "manual"
                                        }
                                        disabled={readOnly}
                                    />

                                    <label
                                        className="orange-check-form custom-control-label"
                                        htmlFor="manual"
                                    />
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                Manually set the soil type
                            </div>
                        </div>
                    </div>
                </div>

                {detectionType.type === "manual" && (
                    <SoilTypeSelector
                        code={detectionType}
                        codeChanged={(code) => {
                            const next = {
                                ...detectionType,
                                ...code,
                            };

                            setDetectionType(next);

                            updateProject(next);
                        }}
                        readOnly={readOnly}
                    />
                )}

                <FormHelper nextText={nextInstruction} />
            </>
        </FormStatusController>
    );
};

export default React.memo(SoilTypeDetectionForm);
