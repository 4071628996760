import React, { useState, useEffect } from 'react';

const InputField = ({ value: _value, ...rest }) => {
    const [value, setValue] = useState(_value.value);

    useEffect(() => {
        setValue(_value.value);
    }, [_value]);

    return (
        <input
            {...rest}
            type="text"
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
            }}
        />
    );
};

export default InputField;