import React from "react";

const GrazingDrawLabel = () => {
    return (
        <div>
            <h2>Draw the managed pasture area on the map using the 'draw polygon tool'</h2>
            <h2>Draw the managed streambank area on the map using the 'draw line tool'</h2>
            <h2>Draw the managed gullies area on the map using the 'set marker tool' (Only 1 marker can be placed)</h2>
        </div>
    );
};

export default GrazingDrawLabel;
