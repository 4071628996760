import { Model, ORM, fk, many, attr } from "redux-orm";
// import generateActionName from "../redux/reducers/action.gen";

import BaseORMModel from "./BaseORMModel";
import { ORM_FUNDING_BODY_SLICE, ORM_FUNDING_PROGRAM_SLICE, ID_ATTRIBUTE } from "../utils/constants";

export default class FundingBodyORMModel extends BaseORMModel { }
FundingBodyORMModel.modelName = ORM_FUNDING_BODY_SLICE;
FundingBodyORMModel.fields = {
    [ID_ATTRIBUTE]: attr(),
    fundingBody: attr()
};