const ERROR_CHANNEL = {
    GENERAL: "GENERAL",
    LOG_PURPOSE_ONLY: "LOG_PURPOSE_ONLY",
    GROUP_SELECTING: "GROUP_SELECTING",
    GROUP_SELECTED: "GROUP_SELECTED",

    // Collection
    COLLECTION_SELECTED: "COLLECTION_SELECTED",
    COLLECTION_SUBMITTED: 'COLLECTION_SUBMITTED',
    COLLECTION_DELETE_ERROR: 'COLLECTION_DELETE_ERROR',

    // Auth channels
    LOGIN: "LOGIN",
    REGISTER: "REGISTER",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_BY_EMAIL: "RESET_PASSWORD_BY_EMAIL",
    CONFIRM_RESET_PASSWORD: "CONFIRM_RESET_PASSWORD",
    COLLECTION_CREATE_ERROR: "COLLECTION_CREATE_ERROR",
    PROJECT_SAVE: "PROJECT_SAVE",

    // Survey
    SURVEY: "SURVEY",

    // groups
    INVITE_GROUP_USER: 'INVITE_GROUP_USER'
};

export default ERROR_CHANNEL;