import React from "react";
import { services, serviceWrapper } from "../services";
import {
    ORM_PROJECT_SLICE,
    ORM_BLOCK_SLICE,
    EVENT_CHANNEL,
    UPDATE_ACTION,
    ORM_WORKSPACE_SLICE,
    WORKSPACE_ID,
    ID_ATTRIBUTE,
    ORM_SLICE,
    UPDATE_BATCH_ACTION,
    DELETE_BATCH_ACTION,
} from "../utils/constants";
import { store } from "../redux";
import generateORMActionName from "../redux/reducers/orm.action.gen";
import { EventEmitter } from "../components/EventEmitter";
import orm from "../models/orm.register";
import _ from "lodash";
import L from "leaflet";

const { computationService, blockService } = services;

const highlightBlocks = (event) => {
    const { payload, errorController } = event ?? {};
    if (payload && orm && store) {
        let { highlighted = false, blocks = [] } = payload;

        highlighted = highlighted ?? false;
        blocks = blocks ?? [];

        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        const workspace = session[ORM_WORKSPACE_SLICE].select(session, {
            include: [],
            filter: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
            },
        })[0];

        if (!workspace) return;

        let { highlightedBlockIds = {} } = workspace ?? {};

        highlightedBlockIds = highlightedBlockIds ?? {};

        blocks.forEach((block) => {
            highlighted && (highlightedBlockIds[block[ID_ATTRIBUTE]] = 1);
            !highlighted && delete highlightedBlockIds[block[ID_ATTRIBUTE]];
        });

        // update redux
        store.dispatch({
            type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
                highlightedBlockIds,
            },
        });
    }
};

const setExtentFromBlocks = (event) => {
    const { payload, errorController } = event ?? {};
    if (payload && orm && store) {
        let { projectIds = [], blockIds = [] } = payload;

        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        // delete projects if necessary
        const blockMap = _.keyBy(
            blockIds.map((id) => {
                return {
                    [ID_ATTRIBUTE]: id,
                };
            }),
            (block) => block[ID_ATTRIBUTE]
        );

        const projectMap = _.keyBy(
            projectIds.map((id) => {
                return {
                    [ID_ATTRIBUTE]: id,
                };
            }),
            (project) => project[ID_ATTRIBUTE]
        );

        const dbBlocks = !blockIds.length
            ? []
            : session[ORM_BLOCK_SLICE].select(session, {
                  include: [],
                  filter: (d) => {
                      return blockMap[d[ID_ATTRIBUTE]] ? true : false;
                  },
              }) ?? [];

        const dbProjects = !projectIds.length
            ? []
            : session[ORM_PROJECT_SLICE].select(session, {
                  include: [{ blocks: [] }],
                  filter: (d) => {
                      return projectMap[d[ID_ATTRIBUTE]] ? true : false;
                  },
              }) ?? [];

        const blocks = _.flattenDeep(dbProjects.map((project) => project.blocks ?? [])).concat(dbBlocks);

        const geojson = {
            type: "FeatureCollection",
            features: blocks
                .map((block) => {
                    if (!block.geometry) return null;

                    return {
                        type: "Feature",
                        geometry: block.geometry,
                    };
                })
                .filter((d) => d),
        };

        if (!geojson.features.length) return;

        const geoLayer = L.geoJSON(geojson);
        const bounds = geoLayer.getBounds();
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_MAP_SET_EXTENT, {
            payload: bounds,
        });
    }
};

const deleteBlocks = async (event) => {
    const { payload, errorController } = event ?? {};

    if (payload && orm && store) {
        let { blocks = [], couldDeleteProjects = false, couldApiUpdate = true, notify = false } = payload ?? {};

        // call api to delete blocks
        if (couldApiUpdate) {
            if (notify) {
                EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
                    payload: {
                        progress: 0,
                        message: "Deleting selected block(s)",
                        scale: 100,
                    },
                });
            }

            let blockIds = await serviceWrapper(
                {
                    ...{
                        successChannel: null,
                    },
                    ...(errorController ?? {}),
                },
                {
                    instance: blockService,
                    name: "deleteBlocks",
                    params: [(blocks ?? []).map((block) => block[ID_ATTRIBUTE])],
                }
            );

            // update redux store
            blocks = (blockIds ?? []).map((blockId) => {
                return {
                    [ID_ATTRIBUTE]: blockId,
                };
            });
        }

        store.dispatch({
            type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: DELETE_BATCH_ACTION }),
            payload: blocks,
        });

        if (notify) {
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
                payload: {
                    progress: 100,
                    message: "Finished deleting block(s)",
                    scale: 100,
                },
            });

            // trigger a notification to say we're done deleting blocks
            const contents = (
                <span>
                    <strong>{blocks.length} block(s) </strong> deleted
                </span>
            );
            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, {
                contents,
                autoClose: true,
            });
        }

        if (!couldDeleteProjects) return;

        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        // delete projects if necessary
        const blockMap = _.keyBy(blocks, (block) => block[ID_ATTRIBUTE]);
        const dbBlocks =
            session[ORM_BLOCK_SLICE].select(session, {
                include: [],
                filter: (d) => {
                    return blockMap[d[ID_ATTRIBUTE]] ? true : false;
                },
            }) ?? [];
        const projectMap = _.groupBy(dbBlocks, (block) => block.projectId);

        const projects =
            session[ORM_PROJECT_SLICE].select(session, {
                include: [
                    {
                        blocks: [],
                    },
                ],
                filter: (d) => {
                    return projectMap[d[ID_ATTRIBUTE]] ? true : false;
                },
            }) ?? [];

        const deleteRequiredProjects = projects.filter(
            (project) => project.blocks.length === projectMap[project[ID_ATTRIBUTE]].length
        );

        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: DELETE_BATCH_ACTION }),
            payload: deleteRequiredProjects.map((d) => ({ [ID_ATTRIBUTE]: d[ID_ATTRIBUTE] })),
        });

        // unhighlight projects and blocks
        const workspace = session[ORM_WORKSPACE_SLICE].select(session, {
            include: [],
            filter: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
            },
        })[0];

        if (!workspace) return;

        // unhighlight blocks
        let { highlightedProjectIds = {}, highlightedBlockIds = {} } = workspace ?? {};
        blocks.forEach((block) => {
            delete highlightedBlockIds[block[ID_ATTRIBUTE]];
        });

        deleteRequiredProjects.forEach((project) => {
            delete highlightedProjectIds[project[ID_ATTRIBUTE]];
        });

        // unhighlight projects
        store.dispatch({
            type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
                highlightedProjectIds,
                highlightedBlockIds,
            },
        });
    }
};

const deleteHighlightedBlocks = async () => {
    const state = store.getState();
    const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

    const workspace = session[ORM_WORKSPACE_SLICE].select(session, {
        include: [],
        filter: {
            [ID_ATTRIBUTE]: WORKSPACE_ID,
        },
    })[0];

    const highlightedBlockIds = Object.keys(workspace.highlightedBlockIds);

    const highlightedBlocks = session[ORM_BLOCK_SLICE].select(session, {
        include: [],
        filter: (block) => {
            return highlightedBlockIds.some((id) => id === block[ID_ATTRIBUTE]);
        },
    });

    if (highlightedBlocks.length > 0) {
        await deleteBlocks({
            payload: {
                blocks: highlightedBlocks,
            },
        });
    }
};

const updateBlocks = async (event) => {
    const { payload, errorController } = event ?? {};

    if (payload) {
        const { blocks, couldApiUpdate = true } = payload ?? {};

        if (!couldApiUpdate) {
            store.dispatch({
                type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: UPDATE_BATCH_ACTION }),
                payload: blocks ?? [],
            });
        } else {
            let { projects, blocks: savedBlocks } = await serviceWrapper(
                {
                    ...{
                        successChannel: null,
                    },
                    ...(errorController ?? {}),
                },
                {
                    instance: computationService,
                    name: "saveAndUpdateStore",
                    params: [blocks],
                }
            );

            // when update blocks, certain blocks could be deleted by someone else,
            // what should be doing in here is about cleaning up the store for those being deleted
            // iterate through block list and find out what blocks are not returned to delete from the store
            const savedBlockMap = _.keyBy(savedBlocks ?? [], (block) => block[ID_ATTRIBUTE]);
            const shouldDeleteBlocks = (blocks ?? []).filter((block) => savedBlockMap[block[ID_ATTRIBUTE]] == null);
            store.dispatch({
                type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: DELETE_BATCH_ACTION }),
                payload: shouldDeleteBlocks,
            });
        }
        // store.dispatch({
        //     type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: UPDATE_BATCH_ACTION }),
        //     payload: blocks ?? []
        // });
    }
};

const createBlock = async (event) => {
    const { payload, errorController } = event ?? {};

    if (payload) {
        const { block, projectId, couldApiUpdate = true } = payload;

        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        if (!couldApiUpdate) {
            store.dispatch({
                type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: UPDATE_ACTION }),
                payload: {
                    ...(block ?? {}),
                    conflictProperties: {
                        conflict: true,
                        lookupFails: null,
                        lookupFailureImpactAreas: null,
                        lookupFailureImpactRatio: null,
                        outsideOfRegion: null,
                        paddockCodesFailsToResolve: null,
                        erosionRateProviderError: null,
                    },
                    projectId,
                },
            });
        } else {
            const project = session[ORM_PROJECT_SLICE].select(session, {
                include: [],
                filter: {
                    [ID_ATTRIBUTE]: projectId,
                },
            })[0];

            if (!project) return;

            let { projects, blocks } = await serviceWrapper(
                {
                    ...{
                        successChannel: null,
                    },
                    ...(errorController ?? {}),
                },
                {
                    instance: computationService,
                    name: "saveAndUpdateStore",
                    params: [
                        [
                            {
                                [ID_ATTRIBUTE]: block[ID_ATTRIBUTE],
                                geometry: block.geometry,
                                projectId,
                            },
                        ],
                    ],
                }
            );
        }

        // highlight block if project is highlighted
        const workspace = session[ORM_WORKSPACE_SLICE].select(session, {
            include: [],
            filter: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
            },
        })[0];

        if (!workspace) return;

        let { highlightedProjectIds = {}, highlightedBlockIds = {} } = workspace ?? {};
        if (highlightedProjectIds[projectId]) {
            highlightedBlockIds[block[ID_ATTRIBUTE]] = 1;
        }

        store.dispatch({
            type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
                highlightedBlockIds,
            },
        });
    }
};

const blockEventsDesc = [
    {
        event: EVENT_CHANNEL.EVENT_CMD_BLOCK_BATCH_HIGHLIGHT,
        handler: highlightBlocks,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_BLOCK_BATCH_DELETE,
        handler: deleteBlocks,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_BLOCK_DELETE_HIGHLIGHTED,
        handler: deleteHighlightedBlocks,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_BLOCK_BATCH_UPDATE,
        handler: updateBlocks,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_BLOCK_CREATE,
        handler: createBlock,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_BLOCK_SET_EXTENT,
        handler: setExtentFromBlocks,
    },
];

export default blockEventsDesc;
