import { HttpMethods, ServerType, Url, ID_ATTRIBUTE, ORM_PROJECT_SLICE, UPDATE_BATCH_ACTION } from '../utils/constants';
import { UrlBuilder, https } from '../api';
import BaseService from './base.service';
import ProgressController from '../components/ProgressDisplay/progressController';
import { store } from '../redux';
import generateORMActionName from '../redux/reducers/orm.action.gen';
import _ from 'lodash';

class BlockService extends BaseService {
    constructor(props) {
        super(props);
    }

    async deleteBlocks(blockIds) {
        const url = UrlBuilder(ServerType.Data, Url.DeleteBlocks, []);
        const data = {
            blocksDeletedIds: blockIds ?? []
        };

        return await https({ url, method: HttpMethods.Put, data, userRequest: true });
    }

}

export default BlockService;