export const SEPARATOR = "_____";

export const MAP_OPERATION_MODE = {
    READ_ONLY: 0,
    EDIT: 1,
};

export const SELECT_ALL_TOOLBAR = {
    OFF: 0,
    ON: 1,
};

export const EDIT_MODE = {
    START: 0,
    EDIT: 1,
    STOP: 2,
};

export const REMOVE_MODE = {
    START: 0,
    REMOVE: 1,
    STOP: 2,
};

export const DRAWING_MODE = {
    START: 0,
    REMOVE: 1,
    STOP: 2,
};

export const MAP_BOUNDS_WINDOW_PROPS_NAME = "mainMapBounds";
export const REPAINT_TILES_ON_UPDATE = true;

export const GEOMETRY_POLYGON_TYPE = "Polygon";
export const GEOMETRY_LINE_STRING_TYPE = "LineString";
export const GEOMETRY_POINT = "Point";
