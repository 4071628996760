import React, { PureComponent, createRef } from "react";
import Chart from "chart.js";
import { ComboSeriesChart } from "./ComboSeriesChart";
// import './barchart.scss';

const BarChart = ({ className, data }) => {
    Chart.defaults.global.defaultFontSize = 16;
    // Chart.defaults.global.defaultFontColor = "#77777a";
    Chart.defaults.global.defaultFontFamily = "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif";

    const chart = {
        type: "horizontalBar",
        data: {
            labels: data.label,
            datasets: [{
                data: data.data,
                backgroundColor: "#FF671B",
            }]
        },
        // {
        //     "datasets": [
        //         { "data": [25, 10.526315789473683], "label": "Very low", "backgroundColor": "#D12A2F", "borderColor": "#ecf7f9", "dataRaw": [3, 2] },
        //         { "data": [41.66666666666667, 21.052631578947366], "label": "Low", "backgroundColor": "#FF5F0F", "borderColor": "#ecf7f9", "dataRaw": [5, 4] },
        //         { "data": [8.333333333333332, 10.526315789473683], "label": "Moderate", "backgroundColor": "#FCAB2E", "borderColor": "#ecf7f9", "dataRaw": [1, 2] },
        //         { "data": [16.666666666666664, 26.31578947368421], "label": "High", "backgroundColor": "#69A32E", "borderColor": "#ecf7f9", "dataRaw": [2, 5] },
        //         { "data": [8.333333333333332, 31.57894736842105], "label": "Very high", "backgroundColor": "#009944", "borderColor": "#ecf7f9", "dataRaw": [1, 6] }],
        //     "labels": ["Current", "Target"]
        // }, 
        options: {
            interaction: { mode: "index" },
            tooltips: {
                enabled: true,
                intersect: false, mode: "index",
                callbacks: {
                    label: function (tooltipItem, data) {
                        return `${tooltipItem.xLabel != null && tooltipItem.xLabel != 0 ? `${tooltipItem.xLabel}%` : 'No change'}`
                        // var label = data.datasets[tooltipItem.datasetIndex].label || '';
                        // // return 'shit';
                        // if (label) {
                        //     label += ': ';
                        // }
                        // label += Math.round(tooltipItem.yLabel * 100) / 100;
                        // return label;
                    }
                }
            },
            hover: { mode: "index", intersect: false },
            legend: {
                display: false,
                onClick: false
            },
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    // afterTickToLabelConversion: function(scaleInstance) {
                    //     scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;
                    //     scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
                    //   },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 6,
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false,
                        zeroLineColor: '#252628',
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                    gridLines: {
                        display: false,
                    },
                }]
            },

            // scales: {
            //     yAxes: [
            //         {
            //             // id: "left", position: "left",
            //             // scaleLabel: {
            //             //    display: true, labelString: "",
            //             //    fontSize: 15,
            //             //fontStyle: 400
            //             //},
            //             // display: true,
            //             // stacked: true,
            //             gridLines: {
            //                 display: false,
            //                 // drawBorder: false, color: "#32a2b3"
            //             },
            //             ticks: {
            //                 beginAtZero: true,
            //                 // fontSize: 15,
            //                 // fontStyle: 400
            //             }
            //         }
            //     ],
            //     xAxes: [{
            //         // display: false,
            //         // stacked: true,
            //         ticks: {
            //             beginAtZero: true,
            //             maxTicksLimit: 6,
            //         },
            //         gridLines: {
            //             display: false,
            //             drawBorder: false,
            //             zeroLineColor: '#252628',
            //         }
            //     }]
            // },

            plugins: {
                flexiTickArea: false, datalabels: false,
                stacked100: false, doughnutlabel: false, labels: false,
                deferred: { yOffset: "30%", delay: 500 }
            }
        }
    };

    return <ComboSeriesChart data={chart} className={className ?? ''}
    />
};

export default BarChart;

// export default class BarChart extends PureComponent {
//     constructor(props) {
//         super(props);
//         this.canvasRef = createRef();
//         Chart.defaults.global.defaultFontSize = 16;
//         Chart.defaults.global.defaultFontColor = "#77777a";
//         Chart.defaults.global.defaultFontFamily = "'Source Sans Pro', sans-serif";
//     }

//     componentDidUpdate() {
//         this.myChart.data.datasets[0].data = this.props.data.data;
//         this.myChart.data.labels = this.props.data.label;
//         this.myChart.update();
//     }

//     componentDidMount() {
//         this.myChart = new Chart(this.canvasRef.current, {
//             type: "horizontalBar",
//             data: {
//                 labels: this.props.data.label,
//                 datasets: [{
//                     data: this.props.data.data,
//                     backgroundColor: "#FF671B",
//                 }]
//             },
//             options: {
//                 legend: {
//                     display: false,
//                 },
//                 responsive: true,
//                 // maintainAspectRatio: false,
//                 scaleFontColor: "#FFFFFF",
//                 scales: {
//                     xAxes: [{
//                         // afterTickToLabelConversion: function(scaleInstance) {
//                         //     scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;
//                         //     scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
//                         //   },
//                         ticks: {
//                             beginAtZero: true,
//                             maxTicksLimit: 6,
//                         },
//                         gridLines: {
//                             display: false,
//                             drawBorder: false,
//                             zeroLineColor: '#252628',
//                         }
//                     }],
//                     yAxes: [{
//                         ticks: {
//                             beginAtZero: true,
//                         },
//                         gridLines: {
//                             display: false,
//                         },
//                     }]
//                 }
//             }
//         });
//     }

//     render() {
//         return (
//             <canvas style={{}} className={this.props.className} ref={this.canvasRef} />
//         );
//     }
// }