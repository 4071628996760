import React from "react";
import { services, serviceWrapper } from "../services";
import {
    ORM_PROJECT_SLICE,
    ORM_WORKSPACE_SLICE,
    EVENT_CHANNEL,
    CREATE_ACTION,
    UPDATE_ACTION,
    ID_ATTRIBUTE,
    WORKSPACE_ID,
    ORM_SLICE,
    ORM_BLOCK_SLICE,
    DELETE_ADVANCE_ACTION,
    DELETE_BATCH_ACTION,
    UPDATE_BATCH_ACTION,
} from "../utils/constants";
import { store } from "../redux";
import { INDUSTRY, GRAZING_STREAMBANK_SIZES } from "../utils/miscellaneous";
import orm from "../models/orm.register";
import generateORMActionName from "../redux/reducers/orm.action.gen";
import { EventEmitter } from "../components/EventEmitter";
import mergeWith from "lodash/mergeWith";
import { generateProjectSkeleton, keyValueStringContent } from "../utils/functions";

const { projectService, computationService, blockService } = services;

const addOrCreateProject = async (event) => {
    const { payload } = event ?? {};

    if (!payload) return;

    const { collectionId, projectId, collectionReadOnly, blocks = [], isNew, isCopy } = payload;

    const skeletonProject = generateProjectSkeleton(projectId, collectionId, isNew);

    // Add skeleton project to redux
    store.dispatch({
        type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: CREATE_ACTION }),
        payload: skeletonProject,
    });

    const editingSourceProjectId = projectId ?? skeletonProject[ID_ATTRIBUTE];

    const blocksWithProjId = blocks.map((block) => {
        block.projectId = skeletonProject[ID_ATTRIBUTE];
        return block;
    });

    // Add blocks to redux
    store.dispatch({
        type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: UPDATE_BATCH_ACTION }),
        payload: blocksWithProjId,
    });

    // Set workspace
    store.dispatch({
        type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
        payload: {
            [ID_ATTRIBUTE]: WORKSPACE_ID,
            editingProjectId: skeletonProject[ID_ATTRIBUTE],
            editingSourceProjectId,
        },
    });

    // Event to open modal with skeleton project detail
    EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_COLLECTION_ADD_PROJECT, {
        payload: {
            projectId: skeletonProject[ID_ATTRIBUTE],
            collectionId,
            sourceProjectId: editingSourceProjectId,
            collectionReadOnly,
            isNew,
            isCopy,
        },
    });
};

const saveProjectDetail = async (event) => {
    const { payload } = event ?? {};
    const { data } = payload ?? {};

    store.dispatch({
        type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: UPDATE_ACTION }),
        payload: data,
    });
};

const saveFundingDetail = async (event) => {
    const { payload } = event ?? {};
    const { data } = payload ?? {};

    store.dispatch({
        type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: UPDATE_ACTION }),
        payload: data,
    });
};

const saveProjects = async (event) => {
    const { payload } = event ?? {};
    const { editingProjectId = "", editingSourceProjectId = "" } = payload ?? {};

    if (editingProjectId && editingSourceProjectId) {
        // Replace _id of editing to source for overwriting purposes
        // newProjectData[ID_ATTRIBUTE] = editingSourceProjectId;
        await serviceWrapper(
            {},
            {
                instance: computationService,
                name: "updateProjectAndUpdateStore",
                params: [editingProjectId, editingSourceProjectId],
            }
        );

        // delete the editing projects
        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: DELETE_ADVANCE_ACTION }),
            payload: {
                cascade: true,
                filter: {
                    [ID_ATTRIBUTE]: editingProjectId,
                },
            },
        });
    }
};

// the payload should be an array of ProjectORM instances

const highlightProjectsAndBlocks = (event) => {
    const { payload } = event ?? {};
    if (payload && orm && store) {
        let { highlighted = false, projects = [], blocks = [] } = payload;

        highlighted = highlighted ?? false;
        projects = projects ?? [];

        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        const workspace = session[ORM_WORKSPACE_SLICE].select(session, {
            include: [],
            filter: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
            },
        })[0];

        if (!workspace) return;

        let { highlightedProjectIds = {}, highlightedBlockIds = {} } = workspace ?? {};

        highlightedBlockIds = highlightedBlockIds ?? {};

        blocks.forEach((block) => {
            highlighted && (highlightedBlockIds[block[ID_ATTRIBUTE]] = 1);
            !highlighted && delete highlightedBlockIds[block[ID_ATTRIBUTE]];
        });

        highlightedProjectIds = highlightedProjectIds ?? {};

        projects.forEach((project) => {
            highlighted && (highlightedProjectIds[project[ID_ATTRIBUTE]] = 1);
            !highlighted && delete highlightedProjectIds[project[ID_ATTRIBUTE]];
        });

        // update redux
        store.dispatch({
            type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
                highlightedProjectIds,
                highlightedBlockIds,
            },
        });
    }
};

const highlightProjects = (event) => {
    const { payload } = event ?? {};
    if (payload && orm && store) {
        let { highlighted = false, projects = [] } = payload;

        highlighted = highlighted ?? false;
        projects = projects ?? [];

        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        const workspace = session[ORM_WORKSPACE_SLICE].select(session, {
            include: [],
            filter: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
            },
        })[0];

        if (!workspace) return;

        let { highlightedProjectIds = {} } = workspace ?? {};

        highlightedProjectIds = highlightedProjectIds ?? {};

        projects.forEach((project) => {
            highlighted && (highlightedProjectIds[project[ID_ATTRIBUTE]] = 1);
            !highlighted && delete highlightedProjectIds[project[ID_ATTRIBUTE]];
        });

        // update redux
        store.dispatch({
            type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
                highlightedProjectIds,
            },
        });
    }
};

const saveProjectNew = async (event) => {
    const { payload } = event ?? {};
    const {
        editingProjectId = "",
        selectedCollectionId = "",
        editingProject: newProjectData = {},
        isCopy,
    } = payload ?? {};

    if (editingProjectId && newProjectData) {
        // Remove the random generated uuid
        // delete newProjectData[ID_ATTRIBUTE];

        // Api call to save project
        const { projects } = await serviceWrapper(
            {},
            {
                instance: computationService,
                name: "createProjectAndUpdateStore",
                params: [editingProjectId, selectedCollectionId],
            }
        );

        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: DELETE_ADVANCE_ACTION }),
            payload: {
                cascade: true,
                filter: {
                    [ID_ATTRIBUTE]: editingProjectId,
                },
            },
        });

        if (projects && projects[0] != null) {
            const project = projects[0];

            store.dispatch({
                type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
                payload: {
                    [ID_ATTRIBUTE]: WORKSPACE_ID,
                    selectedProjectIds: {
                        [project[ID_ATTRIBUTE]]: 1,
                    },
                },
            });

            EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROJECT_BLOCK_SET_EXTENT, {
                payload: {
                    isProject: true,
                    objectId: project[ID_ATTRIBUTE],
                },
            });

            if (isCopy) {
                EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_BLOCK_DELETE_HIGHLIGHTED, {});
            }
        }
    }
};

const resetSurveyQuestions = (event) => {
    const { payload } = event ?? {};
    const { data } = payload ?? {};
    const nextSurvey = data.survey;

    //Reset each provided questions to null
    data.questions.forEach((question) => {
        const beforeKey = `q${question.id}_before`;
        const afterKey = `q${question.id}_after`;

        if (nextSurvey[beforeKey]) {
            nextSurvey[beforeKey] = null;
        }

        if (nextSurvey[afterKey]) {
            nextSurvey[afterKey] = null;
        }
    });

    //Reset industry specific survey questions
    if (data.industry === INDUSTRY.GRAZING.key) {
        /**
         * If Grazing streambank practice is not enabled
         * then reset the stream size to default value of medium
         */
        if (data.survey && data.survey.q0_2 === false) {
            nextSurvey.q0_4 = GRAZING_STREAMBANK_SIZES.MEDIUM;
        }
    }

    store.dispatch({
        type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: UPDATE_ACTION }),
        payload: {
            [ID_ATTRIBUTE]: data.projectId,
            survey: {
                ...nextSurvey,
            },
        },
    });
};

const saveSurvey = async (event) => {
    const { payload } = event ?? {};
    const { data } = payload ?? {};

    const state = store.getState();
    const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

    const { [ORM_PROJECT_SLICE]: Project } = session;

    const project = Project.select(session, {
        filter: { [ID_ATTRIBUTE]: data[ID_ATTRIBUTE] },
    })[0];

    /**
     * Combine the current survey with the answers
     * from the action payload. The payload only contain a subset
     * of answers for a project survey. We must reconstruct the entire survey here
     * and pass it to the store action
     */
    const combinedSurvey = {
        ...project.survey,
        ...data.survey,
    };

    store.dispatch({
        type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: UPDATE_ACTION }),
        payload: {
            [ID_ATTRIBUTE]: data[ID_ATTRIBUTE],
            survey: combinedSurvey,
        },
    });
};

// Has to select workspace for now...
const cancelProject = (event) => {
    const { payload } = event ?? {};
    const { projectId: projectIdToDelete } = payload ?? {};

    // Reset Workspace
    setTimeout(() => {
        store.dispatch({
            type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
                editingSourceProjectId: null,
                editingProjectId: null,
            },
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: DELETE_ADVANCE_ACTION }),
            payload: {
                cascade: true,
                filter: {
                    [ID_ATTRIBUTE]: projectIdToDelete,
                },
            },
        });
    }, 200);
};

const clearProjectSurvey = (event) => {
    const { payload } = event ?? {};
    const { projectId } = payload;
    // Reset survey of projec that is being edited
    store.dispatch({
        type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: UPDATE_ACTION }),
        payload: {
            [ID_ATTRIBUTE]: projectId,
            survey: {},
        },
    });
};

const setExtentOfProjectOrBlock = (event) => {
    const { payload } = event ?? {};
    if (payload && payload.objectId !== undefined && orm && store) {
        let { isProject = true, objectId } = payload;

        // trigger fit bounds
        EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_BLOCK_SET_EXTENT, {
            payload: {
                blockIds: isProject ? [] : [objectId].filter((d) => d),
                projectIds: isProject ? [objectId].filter((d) => d) : [],
            },
        });
    }
};

const selectProjects = (event) => {
    const { payload } = event ?? {};
    if (payload && orm && store) {
        let { selected = false, projects = [], blocks = [] } = payload;

        selected = selected ?? false;
        projects = projects ?? [];

        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        const workspace = session[ORM_WORKSPACE_SLICE].select(session, {
            include: [],
            filter: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
            },
        })[0];

        if (!workspace) return;

        let { selectedProjectIds = {}, highlightedProjectIds = {}, highlightedBlockIds = {} } = workspace ?? {};

        // control selection
        selectedProjectIds = selectedProjectIds ?? {};

        projects.forEach((project) => {
            selected && (selectedProjectIds[project[ID_ATTRIBUTE]] = 1);
            !selected && delete selectedProjectIds[project[ID_ATTRIBUTE]];
        });

        // control block highlights
        highlightedBlockIds = highlightedBlockIds ?? {};

        blocks.forEach((block) => {
            !selected && delete highlightedBlockIds[block[ID_ATTRIBUTE]];
        });

        // control project highlights
        highlightedProjectIds = highlightedProjectIds ?? {};

        projects.forEach((project) => {
            !selected && delete highlightedProjectIds[project[ID_ATTRIBUTE]];
        });

        store.dispatch({
            type: generateORMActionName({ slice: ORM_WORKSPACE_SLICE, actionName: UPDATE_ACTION }),
            payload: {
                [ID_ATTRIBUTE]: WORKSPACE_ID,
                selectedProjectIds,
                ...(!selected ? { highlightedProjectIds } : {}),
                ...(!selected ? { highlightedBlockIds } : {}),
            },
        });
    }
};

const patchSurveyForProjects = async (event) => {
    const { payload } = event ?? {};
    const { projectsToPatch, projectWithSurvey } = payload ?? {};

    if (!projectsToPatch || !projectWithSurvey) return;

    const customiser = (objValue, srcValue) => {
        if (srcValue == null && objValue !== null) {
            return objValue;
        }
    };

    const patchedProjects = projectsToPatch.reduce((_projectsToPatch, project) => {
        const mergedSurvey = mergeWith({}, projectWithSurvey.survey ?? {}, project.survey, customiser);
        const sourceSurvey = computationService.pullSurvey(project.survey ?? {});
        const newSurvey = computationService.pullSurvey(mergedSurvey ?? {});

        // surveys are identical after merging
        if (keyValueStringContent(sourceSurvey) === keyValueStringContent(newSurvey)) return _projectsToPatch;

        _projectsToPatch.push({
            [ID_ATTRIBUTE]: project[ID_ATTRIBUTE],
            ...(project ?? {}),
            survey: mergedSurvey,
        });

        return _projectsToPatch;
    }, []);

    const patchedProjectIds = patchedProjects.map((project) => project[ID_ATTRIBUTE]);

    const state = store.getState();
    const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

    const blocks = session[ORM_BLOCK_SLICE].select(session, {
        include: [],
        filter: (block) => patchedProjectIds.includes(block.projectId),
    });

    await computationService.saveAndUpdateStore(blocks, patchedProjects);
};

const forceCompute = async (event) => {
    const { payload } = event;
    const { projects } = payload;

    const state = store.getState();
    const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

    const blocks = session[ORM_BLOCK_SLICE].select(session, {
        include: [],
        filter: (block) => projects.some((project) => project[ID_ATTRIBUTE] === block.projectId),
    });

    await computationService.saveAndUpdateStore(blocks, projects);
};

const projectsDelete = async (event) => {
    const { payload } = event;

    if (!payload) {
        // no payload was attached to the event
        return;
    }
    if (!orm || !store) {
        return;
    }

    const { projects = [], blocks = [] } = payload;

    EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
        payload: {
            progress: 0,
            message: "Deleting projects/blocks",
            scale: 100,
        },
    });

    const projectIds = projects.map((p) => p[ID_ATTRIBUTE]);

    if (projectIds.length > 0) {
        await serviceWrapper(
            {},
            {
                instance: projectService,
                name: "deleteProjects",
                params: [projectIds],
            }
        );

        store.dispatch({
            type: generateORMActionName({ slice: ORM_PROJECT_SLICE, actionName: DELETE_BATCH_ACTION }),
            payload: projectIds.map((pId) => ({ [ID_ATTRIBUTE]: pId })),
        });
    }

    const blockIds = blocks.map((b) => b[ID_ATTRIBUTE]);

    if (blockIds.length > 0) {
        await serviceWrapper(
            {},
            {
                instance: blockService,
                name: "deleteBlocks",
                params: [blockIds],
            }
        );

        store.dispatch({
            type: generateORMActionName({ slice: ORM_BLOCK_SLICE, actionName: DELETE_BATCH_ACTION }),
            payload: blockIds.map((bId) => ({ [ID_ATTRIBUTE]: bId })),
        });
    }

    EventEmitter.dispatch(EVENT_CHANNEL.EVENT_CMD_PROGRESS_DISPLAY_MODAL, {
        payload: {
            progress: 100,
            message: "Finished deleting projects/blocks",
            scale: 100,
        },
    });

    // trigger a notification to say we're done deleting projects
    const contents = (
        <span>
            <strong>{projectIds.length} project(s) </strong>({blockIds.length} blocks) deleted
        </span>
    );

    EventEmitter.dispatch(EVENT_CHANNEL.EVENT_SHOW_NOTIFICATION, {
        contents,
        autoClose: true,
    });
};

const projectEventsDesc = [
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_BLOCK_BATCH_HIGHLIGHT,
        handler: highlightProjectsAndBlocks,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_BATCH_HIGHLIGHT,
        handler: highlightProjects,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_BATCH_SELECT,
        handler: selectProjects,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_BLOCK_SET_EXTENT,
        handler: setExtentOfProjectOrBlock,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_CANCEL,
        handler: cancelProject,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_SAVE,
        handler: saveProjects,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_SAVE_NEW,
        handler: saveProjectNew,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_CLEAR_SURVEY,
        handler: clearProjectSurvey,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_FORM_PROJECT_DETAIL_SAVE,
        handler: saveProjectDetail,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_FORM_FUNDING_DETAIL_SAVE,
        handler: saveFundingDetail,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_FORM_SURVEY_SAVE,
        handler: saveSurvey,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_FORM_RESET_SURVEY_QUESTIONS,
        handler: resetSurveyQuestions,
    },

    {
        event: EVENT_CHANNEL.EVENT_CMD_CREATE_OR_EDIT_PROJECT,
        handler: addOrCreateProject,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_PATCH_SURVEY,
        handler: patchSurveyForProjects,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECTS_DELETE,
        handler: projectsDelete,
    },
    {
        event: EVENT_CHANNEL.EVENT_CMD_PROJECT_FORCE_COMPUTE,
        handler: forceCompute,
    },
];

export default projectEventsDesc;
