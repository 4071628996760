import React, { useState, useEffect } from "react";
import Select from "react-select";
import { services, serviceWrapper } from "../../services";
import { ID_ATTRIBUTE } from "../../utils/constants";

const { soilClimateDataService } = services;

const SoilClimateCascadingSelect = (props) => {
    const {
        label,
        parentId,
        nodeType,
        selectedNodeId,
        onChange,
        readOnly,

        classNamePrefix,
        className
    } = props;

    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const getNodeChildren = async () => {
        setLoading(true);

        const children = await serviceWrapper(
            {},
            {
                instance: soilClimateDataService,
                name: "getNodeChildren",
                params: [parentId],
            }
        );

        let selectedOption = null;

        const options = children.map((child) => {
            const option = {
                label: child.nodeType[nodeType].name,
                value: child[ID_ATTRIBUTE],
                meta: child,
                code: child.nodeType[nodeType].code,
            };

            if (selectedNodeId && option.value === selectedNodeId) {
                selectedOption = option;
            }

            return option;
        });

        setOptions(options);

        if (selectedOption) {
            handleOnChange(selectedOption);
        }

        setLoading(false);
    };

    const handleOnChange = (node) => {
        setValue(node);
        onChange(node);
    };

    useEffect(() => {
        setOptions([]);
        setValue(null);

        if (parentId) {
            getNodeChildren();
        }
    }, [parentId]);

    return (
        <div className={`py-1 ${className}`}>
            <label htmlFor={`${nodeType}-select`}>{label}</label>

            <Select
                id={`${nodeType}-select`}
                isLoading={loading}
                isDisabled={loading || !parentId || readOnly}
                value={value}
                options={options}
                onChange={handleOnChange}

                classNamePrefix="multiselect"
                className="multiselect"
            />
        </div>
    );
};

export default SoilClimateCascadingSelect;
