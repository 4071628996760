import { useRef, useState, useEffect } from "react";

const EventSubscriber = ({ eventHandlers, children }) => {


    useEffect(() => {

        eventHandlers.attachEvents();

        return () => {
            eventHandlers.detachEvents();
        };

    }, [eventHandlers]);

    return children ?? null;
};

export default EventSubscriber;