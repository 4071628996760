import _ from 'lodash';

export const COLLECTION_ACTION = {
    EDIT: { state: "edit", label: "edit" },
    DELETE: { state: "delete", label: "delete" },
    COPY: { state: "copy", label: "copy" },
    SUBMIT: { state: 'submit', label: 'submit' }
};

export const COLLECTION_ACTION_MAP = _.keyBy(_.values(COLLECTION_ACTION ?? {}) ?? [], d => d.state);

export const COLLECTION_WAS_SUBMITTED_PROP = 'submittedToGroupId';

export const COLLECTION_IS_RECEIVED_PROP = 'receivedFromGroupId';

export const COLLECTION_CATEGORY = {
    SUBMISSION: {
        state: "submission",
        label: "Submitted",
        filter: col => (col[COLLECTION_WAS_SUBMITTED_PROP] ?? '') !== ''
    },
    SUBMISSION_RECEIVING: {
        state: "submissionReceiving",
        label: "Submitted to me",
        filter: col => (col[COLLECTION_IS_RECEIVED_PROP] ?? '') !== ''
    },
    ALL: {
        state: "active",
        label: "Active",
        filter: col => (col[COLLECTION_WAS_SUBMITTED_PROP] ?? '') === ''
            && (col[COLLECTION_IS_RECEIVED_PROP] ?? '') === ''
    },
    NON_SUBMISSION: { state: "nonSubmission", label: "nonSubmission" },
};

export const COLLECTION_CATEGORY_MAP = _.keyBy(_.values(COLLECTION_CATEGORY ?? {}) ?? [], d => d.state);
