import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
    PRIVILEGE,
    GROUP_SLICE,
    CREATE_ACTION,
    MAX_FIELD_LENGTH,
    ID_ATTRIBUTE,
    EVENT_CHANNEL,
} from "../../utils/constants";
import { services, serviceWrapper } from "../../services";
import generateActionName from "../../redux/reducers/action.gen";
import AutocompleteMultiSelect from "../../components/AutocompleteMultiSelect/AutocompleteMultiSelect";
import "./groups.scss";
import { useSelector } from "react-redux";
import { Checkbox } from "../../components/CheckboxOrRadio";
import { EventEmitter } from "../../components/EventEmitter";
import { useP2RAuth } from "../../components/AuthContextProvider/authContext";

const { groupService } = services;

const AddGroupModal = ({ setModalClose }) => {
    const { profile } = useP2RAuth();
    const [groupReceivers, setGroupReceivers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isCreatingGroup, setCreatingGroup] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();
    const [allowManualSoilSelection, setAllowManualSoilSelection] = useState(false);

    const onChangeOfSelectedGroups = (groups) => {
        setGroupReceivers(groups);
    };

    const getGroups = async () => {
        if (profile.id) {
            let groupData = await serviceWrapper(
                { model: null },
                {
                    instance: groupService,
                    name: "getGroupsByUserId",
                    params: [profile.id],
                }
            );

            setGroups(groupData);
        }
    };

    useEffect(() => {
        getGroups();
    }, [profile.id]);

    /* Dispatch new group to the back end */
    const onSubmit = async (data) => {
        setCreatingGroup(true);

        const newGroupResult = await serviceWrapper(
            { model: null },
            {
                instance: groupService,
                name: "addGroup",
                params: [{ name: data.groupName, allowManualSoilSelection }],
            }
        );

        /* Add new receivers to the backend */
        const newReceivers = await serviceWrapper(
            { model: null },
            {
                instance: groupService,
                name: "addReceiversToGroup",
                params: [newGroupResult[ID_ATTRIBUTE], groupReceivers.map((group) => group.value)],
            }
        );

        // Put user to the new group
        /* e.g. request payload
           {
               group: { [ID_ATTRIBUTE]: ""},
               invitee: {email: "", privilege}
           }
        */

        /* Privileges */
        if (newGroupResult) {
            const newGroupWithUser = await serviceWrapper(
                { model: null },
                {
                    instance: groupService,
                    name: "addUserToGroup",
                    params: [
                        {
                            group: {
                                [ID_ATTRIBUTE]: newGroupResult[ID_ATTRIBUTE],
                            },
                            invitee: {
                                email: data.email,
                                privilege: PRIVILEGE.CAN_BE_GROUP_ADMIN,
                            },
                        },
                    ],
                }
            );

            if (newGroupWithUser && newReceivers) {
                dispatch({
                    type: generateActionName({ slice: GROUP_SLICE, actionName: CREATE_ACTION }),
                    payload: {
                        ...newGroupResult,
                        users_info: [...newGroupWithUser.users],
                    },
                });

                setCreatingGroup(false);

                EventEmitter.dispatch(EVENT_CHANNEL.EVENT_GROUP_UPDATED, {});

                setModalClose && setModalClose();
            }
        }
    };

    // filter the groups available to select from (for parents) if manual soil selection has been
    // enabled or not...
    let filteredGroupsSoilSelection = groups;
    if (allowManualSoilSelection) {
        filteredGroupsSoilSelection = groups.filter(
            (g) => (g.allowManualSoilSelection || false) === allowManualSoilSelection
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="group-modal-form">
            <div className="form-group">
                <label htmlFor="groupName">Name</label>
                <input
                    name="groupName"
                    placeholder="Give the group a name"
                    className="form-control"
                    ref={register({
                        required: { value: true, message: "You must give a name for the group" },
                        maxLength: {
                            value: MAX_FIELD_LENGTH,
                            message: "Group name can only consist of 100 characters",
                        },
                    })}
                />

                {errors.groupName && <div className="help-block with-errors">{errors.groupName.message}</div>}

                <label htmlFor="email">Owner email</label>
                <input
                    name="email"
                    placeholder="Add the owner/administrator's email address"
                    className="form-control"
                    ref={register({
                        required: { value: true, message: "Enter an owner email of the group" },
                        validate: {
                            emailCheck: (value) =>
                                /^.+@.+[\.].+$/.test(value) ||
                                "That's not an email address. It must be like something@something.com",
                        },
                    })}
                />

                {errors.email && <div className="help-block with-errors">{errors.email.message}</div>}
            </div>

            <div className="form-group">
                <label>Parent groups</label>
                <AutocompleteMultiSelect options={filteredGroupsSoilSelection} onChange={onChangeOfSelectedGroups} />
            </div>

            <div className="form-group">
                <div className="checkbox-group">
                    <Checkbox
                        className="app-checkbox"
                        id="allowManualSoilSelection"
                        name="allowManualSoilSelection"
                        checked={allowManualSoilSelection}
                        onChange={(e) => setAllowManualSoilSelection(e.target.checked)}
                    />

                    <label className="ml-2" htmlFor="allowManualSoilSelection">
                        Allow manual soil selection
                    </label>
                </div>

                {allowManualSoilSelection && <span className="help-block">*This option cannot be changed later</span>}
            </div>

            <div className="mt-3 mb-3 form-button-container d-flex justify-content-center">
                <button type="button" className="btn btn-default" onClick={() => setModalClose && setModalClose()}>
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary" disabled={isCreatingGroup}>
                    Create group
                </button>
            </div>
        </form>
    );
};

export default AddGroupModal;
