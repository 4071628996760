/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { MDBDropdownItem } from "mdbreact";
import { faSave } from "@fortawesome/pro-solid-svg-icons/faSave";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons/faPaperPlane";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons/faSpinnerThird";
import { faSlash } from "@fortawesome/pro-regular-svg-icons/faSlash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./collection.menu.scss";
import BaseORMModel from "../../models/BaseORMModel";
import _ from "lodash";
import ContentEditable from "react-contenteditable";
import { COLLECTION_ACTION_MAP, COLLECTION_ACTION, COLLECTION_WAS_SUBMITTED_PROP } from "./collectionMenuConstant";

const _CollectionDropdownItem = ({
    collection,
    isSelected = false,

    actions = null,
    onTitleChanged = () => {},
    onEditingEntered = () => {},
    onEditingSaved = () => {},
    onEditingCanceled = () => {},
    onDeleteRequested = () => {},
    onCollectionSelected = () => {},
    onCopyTriggered = () => {},
    onSubmitTriggered = () => {},
    preventDefaultDismiss = false,
}) => {
    const editingState = collection.editingState ?? {};
    const editing = (editingState ?? {}).editing ?? false;
    const newTitle = (editingState ?? {}).title ?? "";
    const isCopying = editingState.isCopying ?? false;

    const actionStates = _.keyBy(
        (actions ?? []).filter((action) => COLLECTION_ACTION_MAP[action]).map((state) => COLLECTION_ACTION_MAP[state]),
        (d) => d.state
    );

    const hasBeenSubmitted = (collection[COLLECTION_WAS_SUBMITTED_PROP] ?? "") !== "";

    return (
        <MDBDropdownItem
            href="#"
            onClick={(e) => {
                if (editing) {
                    e.stopPropagation();
                }

                !isSelected && onCollectionSelected && onCollectionSelected(collection);
            }}
            toggle={!preventDefaultDismiss}
        >
            <div className="line-collection">
                <span className={`${isSelected ? "collection-selected" : ""}`}>
                    {!editing && (
                        <div>
                            <span>{collection.title}</span>

                            {collection.submittedToGroup && <b> ({collection.submittedToGroup.name})</b>}
                            {collection.receivedFromGroup && <b> ({collection.receivedFromGroup.name})</b>}
                        </div>
                    )}

                    {editing && (
                        <ContentEditable
                            className="contentEditable in-edit-mode collection-title"
                            html={newTitle} // innerHTML of the editable div
                            disabled={false} // use true to disable editing
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            onBlur={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            onKeyDown={(e) => {
                                // This is a hack. I hate MDB!!!
                                if (e.which === 32) e.which = 320;
                            }}
                            onChange={(e) => {
                                const editedTitle = e.currentTarget.textContent;
                                onTitleChanged && onTitleChanged(editedTitle);
                            }} // handle innerHTML change
                        />
                    )}

                    {isSelected && <FontAwesomeIcon className="ml-1" icon={faCheck} />}
                </span>

                <div className="edit-remove-inline">
                    {actionStates[COLLECTION_ACTION.EDIT.state] != null && !editing && !hasBeenSubmitted && (
                        <FontAwesomeIcon
                            className="hover-effect action"
                            icon={faPencil}
                            onClick={(e) => {
                                e.stopPropagation();
                                !hasBeenSubmitted && onEditingEntered && onEditingEntered();
                            }}
                            title="Rename"
                        />
                    )}
                    {actionStates[COLLECTION_ACTION.COPY.state] != null && !editing && (
                        <FontAwesomeIcon
                            className="hover-effect"
                            icon={isCopying ? faSpinnerThird : faCopy}
                            spin={isCopying}
                            onClick={onCopyTriggered}
                            title="Copy"
                        />
                    )}
                    {actionStates[COLLECTION_ACTION.SUBMIT.state] != null && !editing && !hasBeenSubmitted && (
                        <FontAwesomeIcon
                            className="hover-effect"
                            icon={faPaperPlane}
                            onClick={($event) => {
                                $event.stopPropagation();
                                !hasBeenSubmitted && onSubmitTriggered && onSubmitTriggered($event);
                            }}
                            title="Submit"
                        />
                    )}
                    {actionStates[COLLECTION_ACTION.EDIT.state] != null && editing && (
                        <FontAwesomeIcon
                            className="hover-effect action"
                            icon={faSave}
                            onClick={(e) => {
                                e.stopPropagation();
                                onEditingSaved && onEditingSaved();
                            }}
                        />
                    )}
                    {actionStates[COLLECTION_ACTION.EDIT.state] != null && editing && (
                        <FontAwesomeIcon
                            className="hover-effect action"
                            icon={faTimesCircle}
                            onClick={(e) => {
                                e.stopPropagation();
                                // save the data, remove the collection from state
                                onEditingCanceled && onEditingCanceled();
                            }}
                        />
                    )}
                    {actionStates[COLLECTION_ACTION.DELETE.state] != null && isCopying && (
                        <FontAwesomeIcon
                            className="hover-effect action"
                            icon={faSlash}
                            mask={faTrashAlt}
                            onClick={($event) => $event.stopPropagation()}
                            title="Delete"
                        />
                    )}
                    {actionStates[COLLECTION_ACTION.DELETE.state] != null && !isCopying && (
                        <FontAwesomeIcon
                            className="hover-effect action"
                            icon={faTrashAlt}
                            onClick={(e) => {
                                e.stopPropagation();
                                // save the data, remove the collection from state
                                onDeleteRequested && onDeleteRequested();
                            }}
                            title="Delete"
                        />
                    )}
                </div>
            </div>
        </MDBDropdownItem>
    );
};

const areTwoCollectionEqual = (prevProps, nextProps) => {
    return (
        BaseORMModel.getVersionId(prevProps.collection ?? {}) ===
            BaseORMModel.getVersionId(nextProps.collection ?? {}) && prevProps.isSelected === nextProps.isSelected
    );
};

const CollectionDropdownItem = React.memo(_CollectionDropdownItem, areTwoCollectionEqual);

export default CollectionDropdownItem;
